// DiFi rate limit is 2 requests per user per second, 600 seems like a safe option.
export const DEFAULT_DEBOUNCE_TIME = 600;

export const profileFeatures = {
    branding: 'branding',
    performance: 'performance',
    purchases: 'purchases',
    accountMaintenance: 'accountMaintenance',
    accounts: 'accounts',
    accessGroups: 'accessGroups',
    productProfile: 'productProfile',
    productOverview: 'productOverview',
    accountDocuments: 'accountDocuments',
    accountOrders: 'accountOrders'
} as const;
