import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AixChangesService {
    private _changes: boolean;

    constructor() {
        this._changes = false;
    }

    set changes(hasChanges: boolean) {
        this._changes = hasChanges;
    }

    get changes(): boolean {
        return this._changes;
    }
}
