import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { Outage } from '../../models/outages/model';

export class LoadOutagesActionTypes {
    static readonly LOAD_OUTAGES = type('[Login] Load Outages');
    static readonly LOAD_OUTAGES_SUCCESS = type('[Login] Load Outages Success');
    static readonly LOAD_OUTAGES_FAILURE = type('[Login] Load Outages Failure');
}

export type LoadOutagesActions = LoadOutages | LoadOutagesSuccess | LoadOutagesFailure;

export class LoadOutages implements AixAction {
    readonly type = LoadOutagesActionTypes.LOAD_OUTAGES;
}

export class LoadOutagesSuccess implements AixAction {
    readonly type = LoadOutagesActionTypes.LOAD_OUTAGES_SUCCESS;

    constructor(public payload: Outage[]) {}
}

export class LoadOutagesFailure implements AixAction {
    readonly type = LoadOutagesActionTypes.LOAD_OUTAGES_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
