import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// validation function
export function validateIntlPhoneFactory(): ValidatorFn {
    const intlPhoneValidator = (
        c: AbstractControl,
        countryCode: string
    ): ValidationErrors | null => {
        let result = null;

        if ((!countryCode || countryCode === '1') && c.value && c.value.length > 0) {
            const phone = c.value.replace(/[^0-9]/g, '');
            if (phone.length !== 10) {
                result = {
                    validatePhone: {
                        valid: false
                    }
                };
            }
        }

        return result;
    };

    return intlPhoneValidator as ValidatorFn;
}
