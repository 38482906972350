export const FsFriendsAndFamilyFundsUIData = {
    /**
     * TODO: Move this into properties on the Fund returned from the API
     */

    FSCIF: {
        symbol: 'FSCIF',
        productPage: 'https://fsinvestments.com/investments/funds/fs-credit-income-fcrix',
        factSheet:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=FS-CIF-I',
        prospectus:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=PR-CIF',
        boardType: 'trustees',
        sharesOrStock: 'shares'
    },
    FSCREIT: {
        symbol: 'FSCREIT',
        productPage: 'https://fsinvestments.com/investments/funds/fs-credit-reit-zfreix',
        factSheet:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=FS-REIT-COMBO',
        prospectus:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=PR-REIT',
        boardType: 'directors',
        sharesOrStock: 'stock'
    },
    FSMS: {
        symbol: 'FSMS',
        productPage: 'https://fsinvestments.com/investments/funds/fs-multi-strategy-i',
        factSheet:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=FS-MS-COMBO',
        prospectus:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=PR-MS',
        boardType: 'N/A',
        sharesOrStock: 'N/A'
    },
    FSETR: {
        symbol: 'FSETR',
        productPage: 'https://fsinvestments.com/investments/funds/fs-energy-total-return-i',
        factSheet:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=FS-ETR-COMBO',
        prospectus:
            'https://merrillconnect.iscorp.com/fsq/docservice/viewDocument?fgid=1148&mcItemNbr=PR-ETR',
        boardType: 'trustees',
        sharesOrStock: 'shares'
    },
    FSICII: {
        symbol: 'FSIC II',
        productPage: '',
        factSheet: '',
        prospectus: '',
        boardType: 'directors',
        sharesOrStock: 'stock'
    },
    FSICIII: {
        symbol: 'FSIC III',
        productPage: '',
        factSheet: '',
        prospectus: '',
        boardType: 'directors',
        sharesOrStock: 'stocks'
    },
    FSICIV: {
        symbol: 'FSIC IV',
        productPage: '',
        factSheet: '',
        prospectus: '',
        boardType: 'directors',
        sharesOrStock: 'stocks'
    },
    FSEP: {
        symbol: 'FSEP',
        productPage: '',
        factSheet: '',
        prospectus: '',
        boardType: 'trustees',
        sharesOrStock: 'shares'
    },
    FSGCO: {
        symbol: 'FSGCO',
        productPage: '',
        factSheet: '',
        prospectus: '',
        boardType: 'trustees',
        sharesOrStock: 'shares'
    }
};
