import { AfterViewInit, Component, HostListener, input, OnDestroy } from '@angular/core';
import { DynamicColorStyle } from '@trade-platform/ui-utils';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgFor, NgStyle } from '@angular/common';

export interface PageSectionItem {
    /**
     * Html element id that this menu item will scroll to.
     */
    id: string;
    /**
     * Menu item label.
     */
    label: string;
    /**
     * Function that determines whether the menu item is visible or not.
     */
    isHidden: () => boolean;
}

const DEFAULT_PAGE_SECTION = { label: 'none', id: 'none', isHidden: () => true };

/**
 * This component has to be wrapped around a <div class="sidebar--left"></div>
 *
 * @example
 * <div class="sidebar--left u-top132">
 *   <aix-sidebar-menu [stickyHeaderOffset]="132" [pageSections]="pageSections">
 *   </aix-sidebar-menu>
 * </div>
 */
@Component({
    selector: 'aix-sidebar-menu',
    templateUrl: `sidebar.component.html`,
    standalone: true,
    imports: [NgFor, NgStyle]
})
export class AixSidebarMenuComponent implements AfterViewInit, OnDestroy {
    stickyHeaderOffset = input(0);

    /**
     * Place an anchor tag on top of each page section you want to scroll to,
     * then use that anchor id to fill the `PageSectionItem.id`
     *
     * @example
     * <a id="user-credentials-section"></a>
     *
     * @example
     * readonly pageSections: PageSectionItem[] = [
     *   { label: 'User Credentials', id: 'user-credentials-section', isHidden: () => false }
     * ]
     */
    pageSections = input<PageSectionItem[]>([]);
    styles = input<DynamicColorStyle>({
        links: {
            color: null
        }
    });

    private subscriptions: Subscription[] = [];
    private isScrollingToSection = false;
    activePageSection = DEFAULT_PAGE_SECTION;
    interval: any;

    elems: HTMLElement[] = [];

    hover = -1;

    ngAfterViewInit(): void {
        this.initializeActivePageSectionListeners();
    }

    initializeActivePageSectionListeners() {
        // correct the initial active link manually after page layout is done
        this.subscriptions.push(
            interval(1000)
                .pipe(take(1))
                .subscribe(() => {
                    this.activePageSection = this.pageSections()[0];
                })
        );
    }

    @HostListener('window:scroll', [])
    onScroll() {
        const maxScroll = document.body.scrollHeight - document.body.offsetHeight;
        if (!this.isScrollingToSection) {
            if (!this.elems.length) {
                this.pageSections()
                    .filter(section => !section.isHidden())
                    .forEach(section => {
                        const elem = document.querySelector(`#${section.id}`);
                        this.elems.push(elem as HTMLElement);
                    });
            }

            const ranges: { min: number; max: number }[] = [];
            this.elems
                .filter(item => item)
                .forEach((elem, index) => {
                    const range = { min: 0, max: 0 };

                    if (index === 0) {
                        range.min = 0;
                        range.max =
                            window.pageYOffset +
                            elem.getBoundingClientRect().top +
                            elem.getBoundingClientRect().height;
                    } else {
                        range.min = ranges[index - 1].max;
                        range.max = range.min + elem.getBoundingClientRect().height;
                    }

                    ranges.push(range);
                });

            ranges.forEach((r, i) => {
                const s = Math.round((window.pageYOffset * document.body.scrollHeight) / maxScroll);
                const sections = this.pageSections().filter(section => !section.isHidden());

                if (s >= r.min && s <= r.max) {
                    this.setActiveSectionById(sections[i].id);
                }
            });
        }
    }

    setActiveSectionById(id: string) {
        this.activePageSection =
            this.pageSections().find(section => section.id === id) || this.pageSections()[0];
    }

    selectPageSection(section: PageSectionItem) {
        const element = document.querySelector(`#${section.id}`) as HTMLDivElement;
        this.isScrollingToSection = true;
        this.setActiveSectionById(element.id);
        this.removeInterval();
        this.interval = setInterval(() => (this.isScrollingToSection = false), 1100);

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - this.stickyHeaderOffset();
        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    removeInterval() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    ngOnDestroy(): void {
        this.removeInterval();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
