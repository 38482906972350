import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { AixSanitizePipe, ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { isFailure, RemoteData, RemoteDataModule } from 'ngx-remotedata';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorWrapper } from '../../store/models/common';
import { getErrorFromState } from '../remote-data/utils';
import { IsFormFailurePipe } from '../remote-data/pipes';
import { NgIf, AsyncPipe } from '@angular/common';
import { AixNotificationComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-error-box',
    templateUrl: './error-box.html',
    styleUrls: ['./error-box.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        AixNotificationComponent,
        AsyncPipe,
        AixSanitizePipe,
        RemoteDataModule,
        IsFormFailurePipe
    ]
})
export class AixErrorBoxComponent implements OnInit {
    @Input()
    states: Observable<RemoteData<any, string | ErrorWrapper>>[];

    @Input() canClose = false;

    enableDebugging: boolean;
    errorText$: Observable<string>;
    debugText$: Observable<string>;
    error$: Observable<RemoteData<any, string | ErrorWrapper> | null>;

    constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {
        this.enableDebugging = this.environment.enableDebugging;
    }

    ngOnInit(): void {
        this.error$ = combineLatest(this.states).pipe(
            map(states => {
                const failure = states.find(isFailure);
                return failure ? failure : null;
            })
        );

        type ErrObs = Observable<RemoteData<any, string | ErrorWrapper>>;

        this.errorText$ = getErrorFromState(this.error$ as ErrObs).pipe(
            map(err =>
                err instanceof ErrorWrapper ? err.options.errorText : ErrorWrapper.defaultErrorText
            )
        );
        this.debugText$ = getErrorFromState(this.error$ as ErrObs).pipe(
            map(err => (err instanceof ErrorWrapper ? err.debugText : err))
        );
    }
}
