import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderFinalize,
    OrderFinalizeActionTypes,
    OrderFinalizeFailure,
    OrderFinalizeSuccess
} from './actions';
import { OrderFinalizeService } from './service';

@Injectable()
export class OrderFinalizeEffects {
    constructor(private actions$: Actions, private orderFinalizeService: OrderFinalizeService) {}

    orderFinalize$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFinalize>(OrderFinalizeActionTypes.ORDER_FINALIZE),
            switchMap(action =>
                this.orderFinalizeService.orderFinalize(action.payload.orderId).pipe(
                    map(_ => new OrderFinalizeSuccess()),
                    catchHttpError(error => of(new OrderFinalizeFailure({ error })))
                )
            )
        )
    );
}
