import { JsonDecoder } from 'ts.data.json';
import { OrderMakeChangesResponse } from '../../models/order-make-changes/model';

export const orderMakeChangesResponseDecoder = JsonDecoder.object<OrderMakeChangesResponse>(
    {
        id: JsonDecoder.string,
        status: JsonDecoder.string
    },
    'OrderMakeChangesResponse'
);
