import {
    AixAction,
    ErrorWrapper,
    OrderIdPayload,
    OrderSignPayload,
    type
} from '@trade-platform/ui-shared';

export class OrderMapSignaturesActionTypes {
    static readonly ORDER_MAP_SIGNATURES = type('[Order Map Signatures] Order Map Signatures');
    static readonly ORDER_MAP_SIGNATURES_SUCCESS = type(
        '[Order Map Signatures] Order Map Signatures Success'
    );
    static readonly ORDER_MAP_SIGNATURES_FAILURE = type(
        '[Order Map Signatures] Order Map Signatures Failure'
    );
}

export type OrderMapSignaturesActions =
    | OrderMapSignatures
    | OrderMapSignaturesSuccess
    | OrderMapSignaturesFailure;

export class OrderMapSignatures implements AixAction {
    readonly type = OrderMapSignaturesActionTypes.ORDER_MAP_SIGNATURES;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderMapSignaturesSuccess implements AixAction {
    readonly type = OrderMapSignaturesActionTypes.ORDER_MAP_SIGNATURES_SUCCESS;

    constructor(public payload: OrderSignPayload) {}
}

export class OrderMapSignaturesFailure implements AixAction {
    readonly type = OrderMapSignaturesActionTypes.ORDER_MAP_SIGNATURES_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
