<ul class="aix-viewer__data-list">
    @for (document of documentsToDisplay; track document; let i = $index) {
    <li
        class="u-flex aix-viewer__data-list__item"
        [data-testing]="'document-list-item'"
        [ngClass]="{ active: currentDocument === document.id }"
    >
        @if (document.type !== fileType.unassignedSupplemental && document.type !==
        fileType.unassignedForm) {
        <div
            class="aix-viewer__data-list__item-text br-primary__color"
            [data-testing]="'document-list-item-label'"
            (click)="activateDocument(document.id)"
            [class.u-disabled-link]="isDisabledFile(document)"
        >
            {{ document.screenName }} @if (document.isPrivate) {
            <span
                aixTooltip
                [aixTooltipContent]="'Private documents are only visible to members within your organization with the same Reviewer / Compliance role.'"
                [aixIcon]="'fa-lock'"
            ></span>
            }
        </div>
        } @if (document.type === fileType.unassignedSupplemental || document.type ===
        fileType.unassignedForm) {
        <div
            class="aix-viewer__data-list__item-text br-primary__color"
            [data-testing]="'document-list-item-label'"
            (click)="activateDocument(document.id)"
            [class.u-disabled-link]="isDisabledFile(document)"
        >
            Unlabeled Document
        </div>
        }
        <!-- ToDo: Need to fix css cascade -->
        <aix-loading-button
            [isLoading]="fileLoading === document.id && (storeFacade.documentViewer$ | async)!.downloading[titleLoading]"
            (click)="download(document.id)"
            [buttonStyle]="'tertiary'"
            [icon]="'fa-regular fa-download'"
            style="padding: 0px 8px 0px 8px"
        ></aix-loading-button>
    </li>
    }
</ul>
