import { HttpHeaders } from '@angular/common/http';
import { err, JsonDecoder } from 'ts.data.json';

/**
 * Encodes both the http response payload and its headers in the same type
 */
export interface ResponseWithHeaders<T, H> {
    data: T;
    headers: H;
}

const headersToRecordDecoder = <H>(decoder: JsonDecoder.Decoder<H>) =>
    new JsonDecoder.Decoder<H>((headers: any) => {
        if (headers instanceof HttpHeaders) {
            const obj = headers.keys().reduce((acc, key) => {
                acc[key] = headers.get(key);
                return acc;
            }, {} as Record<string, string | null>);
            return decoder.decode(obj);
        } else {
            return err('"headers" is not an HttpHeaders instance');
        }
    });

/**
 * Http Response decoder that also extracts http headers
 * @see ResponseWithHeaders
 */
export const responseWithHeadersDecoder = <T, H extends Record<string, any>>(
    dataDecoder: JsonDecoder.Decoder<T>,
    headersDecoder: JsonDecoder.Decoder<H>,
    decoderName: string
) =>
    JsonDecoder.object<ResponseWithHeaders<T, H>>(
        {
            data: dataDecoder,
            headers: headersToRecordDecoder(headersDecoder)
        },
        `ResponseWithHeaders<${decoderName}>`,
        {
            data: 'body'
        }
    );
