import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { brandDecoder } from '../../decoders/branding/decoder';
import { Brand, BrandingRequest } from '../../models/branding/model';

export const createFormData = (obj: any): FormData => {
    const formData = new FormData();

    for (const property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
            if (obj[property] instanceof Date) {
                formData.append(property, obj[property].toISOString());
            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                formData.append(property, JSON.stringify(obj[property]));
            } else {
                formData.append(property, obj[property]);
            }
        }
    }
    return formData;
};

@Injectable({ providedIn: 'root' })
export class BrandingService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    loadBranding() {
        return this.http
            .get<Brand>(`${this.environment.adminApi}brands`)
            .pipe(concatMap(p => fromPromise(brandDecoder.decodeToPromise(p))));
    }

    saveBranding(request: BrandingRequest): Observable<Brand> {
        const body: FormData = createFormData(request);

        return this.http
            .post<Brand>(`${this.environment.adminApi}brands`, body)
            .pipe(concatMap(p => fromPromise(brandDecoder.decodeToPromise(p))));
    }
}
