<section>
    <ul class="filter-list filter-list__aside">
        @for (pageSection of pageSections(); track pageSection; let i = $index) {
        <li
            [hidden]="pageSection.isHidden()"
            class="br-primary__color--hover"
            (mouseover)="hover = i"
            (mouseleave)="hover = -1"
            [ngStyle]="{ color: hover === i ? styles().links.color : null }"
            [class.br-link]="activePageSection.id === pageSection.id"
            [class.u-fw600]="activePageSection.id === pageSection.id"
            (click)="selectPageSection(pageSection)"
        >
            <span
                class="filter-list-label"
                [ngStyle]="{
                    color: activePageSection.id === pageSection.id ? styles().links.color : null
                }"
                [class.br-primary__color]="activePageSection.id === pageSection.id"
            >
                {{ pageSection.label }}
            </span>
        </li>
        }
    </ul>
</section>
