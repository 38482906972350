<div [ngClass]="headerClassNames" [data-testing]="config.refId!">
    @if (config.label) {
    <h5 class="u-mb8">
        {{ config.label }}
    </h5>
    } @if (config.description) {
    <p class="aix-form__group-message u-mt14">
        {{ config.description }}
    </p>
    }
</div>

<div [ngClass]="bodyClassNames" class="aix-form__container">
    @for (field of config.children;track field) { @if (relationsManager.fieldToRelations(field,
    'SHOW')) {
    <ng-container aixDynamicField [config]="field"> </ng-container>
    } } @if (notifications && notifications.length > 0) {
    <div class="u-full-width u-mt8 u-mb8">
        @for (notification of notifications;track notification;let i = $index) {
        <div class="aix-form__group-notification u-mb8">
            <aix-notification
                [data-testing]="notification.refId! + '__change_notification_' + i"
                [status]="notification.status!"
                [canClose]="true"
                [isOpen]="true"
                (onCloseNotification)="onNotificationClosed(i)"
            >
                <span [innerHTML]="notification.text | aixSanitize"></span>
            </aix-notification>
        </div>
        }
    </div>
    }
</div>
