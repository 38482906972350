import { Type } from '@angular/core';
import { DropdownCellrenderer } from '@trade-platform/ui-components';

export abstract class DropdownCellrendererMap {
    private static CELLRENDERER_MAP: { [key: string]: Type<DropdownCellrenderer> } = {};

    static add(key: string, type: Type<DropdownCellrenderer>) {
        DropdownCellrendererMap.CELLRENDERER_MAP[key] = type;
    }

    static get(key: string) {
        if (!DropdownCellrendererMap.exists(key)) {
            throw new Error(`Cellrenderer "${key}" isn't present in the cellrenderers map.`);
        }
        return DropdownCellrendererMap.CELLRENDERER_MAP[key];
    }

    static exists(key: string) {
        return DropdownCellrendererMap.CELLRENDERER_MAP[key] !== undefined;
    }
}
