import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'aix-truncate-lines-cell-renderer',
    templateUrl: 'aix-truncate-lines-cell-renderer.component.html',
    styleUrls: ['aix-truncate-lines-cell-renderer.component.scss'],
    standalone: true
})
export class AixTruncateLinesCellRendererComponent implements ICellRendererAngularComp {
    params: any;
    lines: number;

    agInit(params: any): void {
        this.params = params;
        this.lines = params.lines ?? 2;
    }

    refresh(): boolean {
        return false;
    }
}
