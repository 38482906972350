import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// validation function
export function validateZipFactory(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        let result = null;

        if (c.value && c.value.length > 0) {
            const zip = c.value.replace(/[^0-9]/g, '');
            if (zip.length !== 5 && zip.length !== 9) {
                result = {
                    aixZip: {
                        valid: false
                    }
                };
            }
        }

        return result;
    };
}
// Type '{ aixZip: { valid: boolean; }; }' is not assignable to type 'null
