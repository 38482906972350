import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderSigningCompleteActions, OrderSigningCompleteActionTypes } from './actions';

export type OrderSigningCompleteState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderSigningCompleteReducer = (
    state: OrderSigningCompleteState = initialState,
    action: OrderSigningCompleteActions
): OrderSigningCompleteState => {
    switch (action.type) {
        case OrderSigningCompleteActionTypes.ORDER_SIGNING_COMPLETE:
            return inProgress();
        case OrderSigningCompleteActionTypes.ORDER_SIGNING_COMPLETE_SUCCESS:
            return success(null);
        case OrderSigningCompleteActionTypes.ORDER_SIGNING_COMPLETE_FAILURE:
            return failure<null, ErrorWrapper>(action.payload.error);
        default:
            return state;
    }
};
