import * as sanitizeHtml_ from 'sanitize-html';
const sanitizeHtmlFn = sanitizeHtml_;

const sanitizeOptions = {
    allowedTags: [
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'blockquote',
        'p',
        'a',
        'ul',
        'ol',
        'nl',
        'li',
        'b',
        'i',
        'strong',
        'em',
        'strike',
        'code',
        'hr',
        'br',
        'div',
        'span',
        'table',
        'thead',
        'caption',
        'tbody',
        'tr',
        'th',
        'td',
        'pre',
        'img',
        'label'
    ],
    allowedAttributes: {
        a: ['href', 'name', 'target', 'data-action', 'data-action-value'],
        img: ['src'],
        '*': ['class'],
        ol: ['start', 'type', 'square']
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'data', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: false,
    allowedIframeHostnames: <never>[]
};

/**
 * Strips insecure tags and attributes from the given html string.
 * When in a template, don't use this directly, use the `aixSanitize` pipe instead.
 */
export const sanitizeHtml = (html: string) => sanitizeHtmlFn(html, sanitizeOptions);
