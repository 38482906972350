import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import { LoadOrders, LoadOrdersFailure, LoadOrdersSuccess, OrdersActionTypes } from './actions';
import { OrdersService } from './service';

@Injectable()
export class OrdersEffects {
    constructor(private actions$: Actions, private ordersService: OrdersService) {}

    loadOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadOrders>(OrdersActionTypes.LOAD_ORDERS),
            switchMap(action =>
                this.ordersService.getOrders(action.payload.paginationOptions).pipe(
                    map(
                        ordersPage =>
                            new LoadOrdersSuccess({
                                ordersPage,
                                reducerSuffix: action.payload.reducerSuffix
                            })
                    ),
                    catchHttpError(error =>
                        of(
                            new LoadOrdersFailure({
                                error,
                                reducerSuffix: action.payload.reducerSuffix
                            })
                        )
                    )
                )
            )
        )
    );
}
