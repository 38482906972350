import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';
import { UserSharedRepCode } from '../../models/shared-rep-codes-grid/model';
import { AixNotification } from '../../models/notifications/model';

export class SharedRepCodesGridActionTypes {
    static readonly OPEN_NOTIFICATION = type('[SharedRepCodesGrid] Open Notification');
    static readonly CLOSE_NOTIFICATION = type('[SharedRepCodesGrid] Close Notification');
    static readonly OPEN_UNSHARE_MODAL = type('[SharedRepCodesGrid] Open Unshare Modal');
}

export type SharedRepCodesGridActions =
    | OpenSharedRepCodesGridNotification
    | CloseSharedRepCodesGridNotification
    | OpenUnshareRepCodeModal;

export class OpenSharedRepCodesGridNotification implements AixAction {
    readonly type = SharedRepCodesGridActionTypes.OPEN_NOTIFICATION;

    constructor(public payload: { notification: AixNotification }) {}
}

export class CloseSharedRepCodesGridNotification implements AixAction {
    readonly type = SharedRepCodesGridActionTypes.CLOSE_NOTIFICATION;

    constructor() {}
}

export class OpenUnshareRepCodeModal implements AixAction {
    readonly type = SharedRepCodesGridActionTypes.OPEN_UNSHARE_MODAL;

    constructor(public payload: { rowData: UserSharedRepCode }) {}
}
