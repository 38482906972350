import { AbstractControl } from '@angular/forms';
import { fromObservable } from '../utils/observable-utils';
import { stringIsNotEmpty } from '../utils/string';

export const exactLengthValidatorFactory =
    (exactLength: number) =>
    (ctrl: AbstractControl): { [key: string]: any } | null => {
        const val = fromObservable(ctrl.value);
        return stringIsNotEmpty(val) && val.length !== exactLength
            ? { exactlength: `This field must be ${exactLength} characters long.` }
            : null;
    };
