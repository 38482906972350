@if (!filesGenerated && !showError) {
<div class="loading-documents">
    <aix-loading [relative]="true"></aix-loading>
    <span class="loading-message">We're generating your documents</span>
</div>
} @if (showError) {
<div class="error-box">
    <div class="error-box__message">We apologize for the delay.</div>
    <div class="error-box__dev-message">
        A connectivity error has occurred. Please try again in a few minutes.
    </div>
</div>
} @if (filesGenerated) {
<div [data-testing]="'pdf-generated-message'">
    <div class="order-step-content-title u-mb24">
        <div>
            @switch (viewState) { @case ('docu-sign') {
            <p class="order-step-content-subtitle">
                @if (signaturesCompleted) {
                <span> {{ pdfTitles['send for esign'] }} </span>
                } @if (!signaturesCompleted && !smsAuthentication) {
                <span> {{ getPdfTitle(order.status) }} </span>
                } @if (!signaturesCompleted && smsAuthentication) {
                <span>
                    <span>{{ pdfTitles.smsAuthentication }}</span>
                    <br />
                    <span
                        >{{ pdfSubtitles.changeSignerDetails }}<a
                            (click)="onMakeChanges.emit()"
                            class="br-link"
                            >edit order</a
                        >.</span
                    >
                </span>
                }
            </p>
            } @case ('wet-sign') {
            <p class="order-step-content-subtitle">
                @if (unfinishedUploads) {
                <span> {{ pdfTitles['pending uploads'] }} </span>
                } @if (!unfinishedUploads) {
                <span> {{ pdfTitles['send for wetsign'] }} </span>
                }
            </p>
            } @default {
            <p class="order-step-content-subtitle">
                @if (order?.status === 'ready to send') {
                <span>
                    {{ pdfTitles['preview'] }},
                    <a (click)="viewOrder()" class="br-link">preview PDF</a>.
                </span>
                } @if (order?.status === 'ready to send' && !wetSignOnly) {
                <span> {{ pdfTitles['preview2'] }} </span>
                } @if (order?.status === 'ready to send' && wetSignOnly) {
                <span> {{ pdfTitles['choose wetsign only'] }} </span>
                }
            </p>
            } }
        </div>
    </div>
</div>
}
