import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Data, Route, Router } from '@angular/router';
import {
    AixErrorAction,
    ErrorWrapper,
    Profile,
    UserTenantDataTypes
} from '@trade-platform/ui-shared';
import { getFromStorage } from '@trade-platform/ui-utils';
import { routeConstants } from './constants';
import { entityType } from '@trade-platform/lib-enums';
import { Store } from '@ngrx/store';

@Injectable()
export class CanLoadByRole {
    constructor(public router: Router, private store: Store) {}

    private goToTermsAndConditions() {
        this.router.navigate(routeConstants.routes.terms.index());
    }

    canLoad(route: Route): boolean {
        let result = false;
        const profile = getFromStorage('profile') as Profile;

        if (profile) {
            if (profile.acceptedTerms) {
                const tenantTypes = (route.data as Data).tenantTypes;
                if (
                    (tenantTypes?.includes(entityType.firm as UserTenantDataTypes) &&
                        profile.firm) ||
                    (tenantTypes?.includes(entityType.fundSponsor as UserTenantDataTypes) &&
                        profile.fundSponsor) ||
                    (tenantTypes?.includes(entityType.holdingOption as UserTenantDataTypes) &&
                        profile.holdingOption) ||
                    !tenantTypes
                ) {
                    const userRoles: string[] = profile.roles.map(item => item.name);
                    result = userRoles.reduce(
                        (acum, curr) =>
                            acum ||
                            !!(route.data as Data).roles.find((role: string) => role === curr),
                        false as boolean
                    );
                }
            } else {
                this.goToTermsAndConditions();
                return false;
            }

            if (!result) {
                this.store.dispatch(
                    new AixErrorAction({
                        error: new ErrorWrapper(
                            `User "${profile?.email}" tried to access to "/${route.path}" but it has no access by role.`
                        )
                    })
                );
                this.router.navigate(routeConstants.routes.notFound.index(), { replaceUrl: true });
            }
        }

        return result;
    }
}

export const canLoadByRole: CanMatchFn = (route: Route) => {
    return inject(CanLoadByRole).canLoad(route);
};
