import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderMarkNigoResponse } from '@trade-platform/ui-shared';
import { OrderNigoActions, OrderNigoActionTypes } from './actions';

export type OrderNigoState = RemoteData<OrderMarkNigoResponse, ErrorWrapper>;
const initialState = notAsked<OrderMarkNigoResponse, ErrorWrapper>();

export function orderNigoReducer(
    state: OrderNigoState = initialState,
    action: OrderNigoActions
): OrderNigoState {
    switch (action.type) {
        case OrderNigoActionTypes.ORDER_MARK_NIGO:
            return inProgress({} as OrderMarkNigoResponse);
        case OrderNigoActionTypes.ORDER_MARK_NIGO_SUCCESS:
            return success(action.payload);
        case OrderNigoActionTypes.ORDER_MARK_NIGO_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
