import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderTakeOffline,
    OrderTakeOfflineActionTypes,
    OrderTakeOfflineFailure,
    OrderTakeOfflineSuccess
} from './actions';
import { OrderTakeOfflineService } from './service';

@Injectable()
export class OrderTakeOfflineEffects {
    constructor(
        private actions$: Actions,
        private orderTakeOfflineService: OrderTakeOfflineService
    ) {}

    orderTakeOffline$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderTakeOffline>(OrderTakeOfflineActionTypes.ORDER_TAKE_OFFLINE),
            switchMap(action =>
                this.orderTakeOfflineService.orderTakeOffline(action.payload.orderId).pipe(
                    map(res => new OrderTakeOfflineSuccess(res)),
                    catchHttpError(error => of(new OrderTakeOfflineFailure({ error })))
                )
            )
        )
    );
}
