import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Input,
    OnInit,
    Type,
    ViewContainerRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FieldConfigDecorationConfig } from '@trade-platform/form-fields';

export interface FieldDecoration {
    params: NonNullable<FieldConfigDecorationConfig['decoration']>['params'];
    hostObservables: FieldDecorationDirective['hostObservables'];
}

type HostObservables = {
    hasFocus: BehaviorSubject<boolean>;
};

@Directive({
    selector: '[aixFieldDecoration]',
    standalone: true
})
export class FieldDecorationDirective implements OnInit {
    @Input() params: FieldDecoration['params'];
    @Input() decoration: Type<FieldDecoration>;

    /**
     * If the host dynamic component needs to expose something, it has to be done via observables.
     *
     * @example
     * hostObservables.hasFocus = new BehaviorSubject(false);
     */
    @Input() hostObservables: HostObservables;

    private component: ComponentRef<FieldDecoration>;

    constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) {}

    ngOnInit() {
        const component = this.resolver.resolveComponentFactory<FieldDecoration>(this.decoration);
        this.component = this.container.createComponent(component);
        this.component.instance.params = this.params;
        this.component.instance.hostObservables = this.hostObservables;
    }
}
