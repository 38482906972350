import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderCancelActionTypes {
    static readonly ORDER_CANCEL = type('[Order Cancel] Order Cancel');
    static readonly ORDER_CANCEL_SUCCESS = type('[Order Cancel] Order Cancel Success');
    static readonly ORDER_CANCEL_FAILURE = type('[Order Cancel] Order Cancel Failure');
}

export type OrderCancelActions = OrderCancel | OrderCancelSuccess | OrderCancelFailure;

export class OrderCancel implements AixAction {
    readonly type = OrderCancelActionTypes.ORDER_CANCEL;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderCancelSuccess implements AixAction {
    readonly type = OrderCancelActionTypes.ORDER_CANCEL_SUCCESS;
}

export class OrderCancelFailure implements AixAction {
    readonly type = OrderCancelActionTypes.ORDER_CANCEL_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
