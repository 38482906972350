import { JsonDecoder } from 'ts.data.json';
import { Message, Outage } from '../../models/outages/model';

export const messageDecoder = JsonDecoder.object<Message>(
    {
        status: JsonDecoder.string,
        createdAt: JsonDecoder.string,
        message: JsonDecoder.string
    },
    'Message'
);

export const outageDecoder = JsonDecoder.object<Outage>(
    {
        title: JsonDecoder.optional(JsonDecoder.string),
        status: JsonDecoder.string,
        createdAt: JsonDecoder.string,
        tenants: JsonDecoder.array(JsonDecoder.string, 'Tenant[]'),
        messages: JsonDecoder.array(messageDecoder, 'Message[]')
    },
    'Outages'
);

export const outagesDecoder = JsonDecoder.array<Outage>(outageDecoder, 'Outage[]');
