import { getFromStorage } from '@trade-platform/ui-utils';
import { Auth } from '../../models/auth/model';
import { Observable, Subscriber } from 'rxjs';

export enum FileUploadStatus {
    NOT_STARTED,
    OTHER,
    UPLOADING,
    REMOVING,
    UPLOADED,
    WITH_ERRORS
}

export interface FileUploadDocument {
    readonly fileName: string;
    readonly uploadStatus: FileUploadStatus;
    readonly progress: number;
    readonly responseCode: number;
    // the upload result object
    document: any;
}

export interface IFileUploadCallbacks {
    onProgressCallback?: (fileUpload: FileUpload) => void;
    onFinishedCallback?: (fileUpload: FileUpload) => void;
    onUploadedCallback?: (fileUpload: FileUpload) => void;
    onErrorCallback?: (fileUpload: FileUpload) => void;
    onUploadStartedCallback?: (fileUpload: FileUpload) => void;
}

export class FileUpload implements FileUploadDocument {
    protected callbacks: IFileUploadCallbacks;
    document: any;

    get fileName(): string {
        return this._file.name;
    }

    protected _file: File;
    get file(): File {
        return this._file;
    }

    protected _xhr: XMLHttpRequest;
    get xhr(): XMLHttpRequest {
        return this._xhr;
    }

    protected _uploadStatus: FileUploadStatus = FileUploadStatus.NOT_STARTED;
    get uploadStatus(): FileUploadStatus {
        return this._uploadStatus;
    }

    /**
     * A number between 0 and 100
     */
    protected _progress: number;
    get progress(): number {
        return this._progress;
    }

    protected _sentBytes: number;
    get sentBytes(): number {
        return this._sentBytes;
    }

    protected _responseCode: number;
    get responseCode(): number {
        return this._responseCode;
    }

    protected _responseText: string;
    get responseText(): string {
        return this._responseText;
    }

    httpSuccessStatusCodes = [200, 201];

    upload(
        file: File,
        url: string,
        headers: { [key: string]: string | number | boolean },
        params: { [key: string]: string | number | boolean | Blob | undefined | null },
        callbacks: IFileUploadCallbacks = {},
        responseType: XMLHttpRequestResponseType = 'json',
        method: 'GET' | 'POST' | 'PUT' = 'POST',
        withCredentials = false
    ): FileUpload {
        this._file = file;
        this.callbacks = callbacks;
        const xhr = new XMLHttpRequest();
        this._xhr = xhr;
        this.setCallbacks(xhr, file);
        xhr.open(method, url, true);
        xhr.responseType = responseType;
        xhr.withCredentials = withCredentials;
        this.setHeaders(xhr, headers);

        const formData = this.createFormData(file, params);
        xhr.send(formData);
        this._uploadStatus = FileUploadStatus.UPLOADING;
        if (this.callbacks.onUploadStartedCallback) {
            this.callbacks.onUploadStartedCallback(this);
        }
        return this;
    }

    cancel() {
        this._xhr.abort();
    }

    protected setCallbacks(xhr: XMLHttpRequest, file: File) {
        xhr.onload = () => this.onload(file, xhr);
        xhr.onerror = () => this.handleError(file, xhr);
        xhr.upload.onprogress = (e: ProgressEvent) => this.updateProgress(file, e);
    }

    protected setHeaders(
        xhr: XMLHttpRequest,
        headers: { [key: string]: string | number | boolean }
    ) {
        if (!headers) {
            return;
        }

        if (!headers['Accept']) {
            xhr.setRequestHeader('Accept', 'application/json');
        }
        if (!headers['Cache-Control']) {
            xhr.setRequestHeader('Cache-Control', 'no-cache');
        }
        if (!headers['X-Requested-With']) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        }

        Object.keys(headers).forEach((headerName: string) => {
            if (!headers) {
                return;
            }
            const headerValue = headers[headerName];
            if (headerValue !== undefined && headerValue !== null) {
                xhr.setRequestHeader(headerName, (headerValue || '').toString());
            }
        });
    }

    protected createFormData(
        file: File,
        params: { [key: string]: string | number | boolean | Blob | undefined | null }
    ): FormData {
        const formData = new FormData();
        if (params) {
            Object.keys(params).forEach((paramName: string) => {
                if (!params) {
                    return;
                }
                const paramValue = params[paramName];
                if (paramValue !== undefined && paramValue !== null) {
                    formData.append(
                        paramName,
                        paramValue instanceof Blob ? paramValue : paramValue.toString()
                    );
                }
            });
        }

        formData.append('file', file, file.name);
        return formData;
    }

    protected onload(file: File, xhr: XMLHttpRequest) {
        if (xhr.readyState !== 4) {
            return;
        }

        if (this.progress !== 100) {
            this.updateProgress(file);
        }

        if (this.httpSuccessStatusCodes.indexOf(xhr.status) > -1) {
            this.finished(file, xhr);
        } else {
            this.handleError(file, xhr);
        }
    }

    protected updateProgress(file: File, e?: ProgressEvent) {
        if (e) {
            if (e.lengthComputable) {
                this._progress = Math.round(100 * (e.loaded / e.total));
                this._sentBytes = e.loaded;
            } else {
                this._progress = 0;
                this._sentBytes = 0;
            }
        } else {
            this._progress = 100;
            this._sentBytes = file.size;
        }

        if (this.callbacks.onProgressCallback) {
            this.callbacks.onProgressCallback(this);
        }
    }

    protected handleError(file: File, xhr: XMLHttpRequest): void {
        this._uploadStatus = FileUploadStatus.WITH_ERRORS;
        this.setResponse(file, xhr);

        if (this.callbacks.onErrorCallback) {
            this.callbacks.onErrorCallback(this);
        }
        if (this.callbacks.onFinishedCallback) {
            this.callbacks.onFinishedCallback(this);
        }
    }

    protected finished(file: File, xhr: XMLHttpRequest) {
        this._uploadStatus = FileUploadStatus.UPLOADED;
        this.setResponse(file, xhr);

        if (this.callbacks.onUploadedCallback) {
            this.callbacks.onUploadedCallback(this);
        }
        if (this.callbacks.onFinishedCallback) {
            this.callbacks.onFinishedCallback(this);
        }
    }

    protected setResponse(file: File, xhr: XMLHttpRequest) {
        this._responseCode = xhr.status;
        if (xhr.responseType === '' || xhr.responseType === 'text') {
            this._responseText =
                xhr.responseText || xhr.statusText || (xhr.status ? xhr.status.toString() : '');
        } else {
            // Custom error messages for uploading conflicts.
            // Based on box status codes https://developer.box.com/docs/error-codes
            if (
                xhr.status === 409 &&
                xhr.response.message &&
                xhr.response.message.indexOf('item_name_in_use') !== -1
            ) {
                this._responseText = `File with the same name: ${file.name} has already been uploaded. Please upload a file with a different name.`;
            } else {
                this._responseText = xhr.statusText || (xhr.status ? xhr.status.toString() : '');
            }
        }
    }

    /*
     * Upload service
     * use this for uploading files to the server
     */
    public uploadService(
        file: File,
        url: string,
        params: { [key: string]: string | number | boolean | Blob | undefined | null },
        returnData = false
    ): Observable<any> {
        // config
        const token = (getFromStorage('auth') as Auth).accessToken;
        if (!params['Content-Type']) params['Content-Type'] = 'multipart/form-data';

        // return observable for upload
        return new Observable((observer: Subscriber<any>) => {
            const activeFileUpload = new FileUpload().upload(
                file,
                url,
                { Authorization: `Bearer ${token}` },
                params,
                {
                    onUploadedCallback: (data: FileUpload) => {
                        if (returnData) {
                            observer.next(data.xhr.response);
                            if (data.xhr.response) observer.complete();
                        } else {
                            observer.next();
                            observer.complete();
                        }
                    },
                    onErrorCallback: err => {
                        observer.error(err);
                        observer.complete();
                    }
                }
            );

            return {
                unsubscribe() {
                    activeFileUpload.cancel();
                }
            };
        });
    }
}
