import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AixUiComponentsModule } from '@trade-platform/ui-components';
import { AixUiUtilsModule, ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { AixDynamicButtonComponent } from './components/dynamic-form/components/button/button.component';
import { AixDynamicCheckboxComponent } from './components/dynamic-form/components/checkbox/checkbox.component';
import { AixDynamicCheckboxgroupComponent } from './components/dynamic-form/components/checkboxgroup/checkboxgroup.component';
import { AixDynamicCurrencyComponent } from './components/dynamic-form/components/currency/currency.component';
import { AixDynamicDateComponent } from './components/dynamic-form/components/date/date.component';
import { AixDynamicDropdownComponent } from './components/dynamic-form/components/dropdown/dropdown.component';
import { DynamicFieldDirective } from './components/dynamic-form/components/dynamic-field.directive';
import { AixDynamicFreeTextComponent } from './components/dynamic-form/components/free-text/free-text.component';
import { AixDynamicGroupComponent } from './components/dynamic-form/components/group/group.component';
import { AixDynamicGroupLightComponent } from './components/dynamic-form/components/group-light/group-light.component';
import { AixDynamicInputComponent } from './components/dynamic-form/components/input/input.component';
import { AixDynamicNotificationComponent } from './components/dynamic-form/components/notification/notification.component';
import { AixDynamicNumberComponent } from './components/dynamic-form/components/number/number.component';
import { AixDynamicPercentageComponent } from './components/dynamic-form/components/percentage/percentage.component';
import { AixDynamicRadiogroupComponent } from './components/dynamic-form/components/radiogroup/radiogroup.component';
import { AixDynamicRepeaterComponent } from './components/dynamic-form/components/repeater/repeater.component';
import { AixDynamicSsnComponent } from './components/dynamic-form/components/ssn/ssn.component';
import { AixDynamicEinComponent } from './components/dynamic-form/components/ein/ein.component';
import { AixDynamicTelephoneComponent } from './components/dynamic-form/components/telephone/telephone.component';
import { AixDynamicTextareaComponent } from './components/dynamic-form/components/textarea/textarea.component';
import { AixDynamicToggleComponent } from './components/dynamic-form/components/toggle/toggle.component';
import { AixDynamicZipComponent } from './components/dynamic-form/components/zip/zip.component';
import { AixDynamicNextPendingFieldDirective } from './components/dynamic-form/directives/dynamic-next-pending-field';
import { DynamicPendingFieldsManagerService } from './components/dynamic-form/directives/dynamic-pending-fields-manager.service';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { FormUIDGenerator } from './components/dynamic-form/dynamic-form-store/utils';
import { ReactiveFormsModule } from '@angular/forms';
import { AixFormErrorsPipe } from './components/pipes/form-errors';
import { FieldDecorationDirective } from './components/dynamic-form/components/field-decoration/field-decoration.directive';
import { RemoteDataModule } from 'ngx-remotedata';
import { AixPhonePipe } from './components/pipes/phone.pipe';
import { AixZipPipe } from './components/pipes/zip.pipe';
import { AixDynamicExpandableTextComponent } from './components/dynamic-form/components/expandable-text/expandable-text.component';
import { AixDynamicCheckboxgroup2Component } from './components/dynamic-form/components/checkboxgroup/checkboxgroup.component2';
import { AixDynamicIntlPhoneComponent } from './components/dynamic-form/components/intl-phone/intl-phone.component';

const LIB_PROVIDERS = [FormUIDGenerator, DynamicPendingFieldsManagerService];

const DIRECTIVES = [
    DynamicFieldDirective,
    AixDynamicNextPendingFieldDirective,
    FieldDecorationDirective
];

const COMPONENTS = [
    // Dynamic Form
    DynamicFormComponent,
    AixDynamicButtonComponent,
    AixDynamicCheckboxComponent,
    AixDynamicCheckboxgroupComponent,
    AixDynamicCheckboxgroup2Component,
    AixDynamicCurrencyComponent,
    AixDynamicDateComponent,
    AixDynamicDropdownComponent,
    AixDynamicFreeTextComponent,
    AixDynamicNotificationComponent,
    AixDynamicGroupComponent,
    AixDynamicInputComponent,
    AixDynamicNumberComponent,
    AixDynamicPercentageComponent,
    AixDynamicRadiogroupComponent,
    AixDynamicSsnComponent,
    AixDynamicEinComponent,
    AixDynamicTelephoneComponent,
    AixDynamicIntlPhoneComponent,
    AixDynamicTextareaComponent,
    AixDynamicToggleComponent,
    AixDynamicZipComponent,
    AixDynamicRepeaterComponent,
    AixDynamicGroupLightComponent,
    AixDynamicExpandableTextComponent
];

const PIPES = [
    // Pipes
    AixFormErrorsPipe,
    AixPhonePipe,
    AixZipPipe
];

export interface DynamicFormsConfig {
    environment: IEnvironment;
}

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AixUiComponentsModule,
        AixUiUtilsModule,
        RemoteDataModule,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    providers: LIB_PROVIDERS
})
export class DynamicFormsModule {
    static forRoot(config: DynamicFormsConfig): ModuleWithProviders<DynamicFormsModule> {
        return {
            ngModule: DynamicFormsModule,
            providers: [{ provide: ENVIRONMENT, useValue: config.environment }]
        };
    }
}
