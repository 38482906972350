import {
    ChangeDetectorRef,
    ComponentRef,
    Directive,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    Type,
    ViewContainerRef
} from '@angular/core';
import { AixDynamicButtonComponent } from './button/button.component';
import { AixDynamicCheckboxComponent } from './checkbox/checkbox.component';
import { AixDynamicCheckboxgroupComponent } from './checkboxgroup/checkboxgroup.component';
import { AixDynamicCurrencyComponent } from './currency/currency.component';
import { AixDynamicDateComponent } from './date/date.component';
import { AixDynamicDropdownComponent } from './dropdown/dropdown.component';
import { FieldConfig } from '@trade-platform/form-fields';
import { Field } from './field.interface';
import { AixDynamicFreeTextComponent } from './free-text/free-text.component';
import { AixDynamicGroupComponent } from './group/group.component';
import { AixDynamicInputComponent } from './input/input.component';
import { AixDynamicNotificationComponent } from './notification/notification.component';
import { AixDynamicNumberComponent } from './number/number.component';
import { AixDynamicPercentageComponent } from './percentage/percentage.component';
import { AixDynamicRadiogroupComponent } from './radiogroup/radiogroup.component';
import { AixDynamicRepeaterComponent } from './repeater/repeater.component';
import { AixDynamicSsnComponent } from './ssn/ssn.component';
import { AixDynamicTelephoneComponent } from './telephone/telephone.component';
import { AixDynamicTextareaComponent } from './textarea/textarea.component';
import { AixDynamicToggleComponent } from './toggle/toggle.component';
import { AixDynamicZipComponent } from './zip/zip.component';
import { AixDynamicEinComponent } from './ein/ein.component';
import { AixDynamicGroupLightComponent } from './group-light/group-light.component';
import { AixDynamicExpandableTextComponent } from './expandable-text/expandable-text.component';
import { AixDynamicCheckboxgroup2Component } from './checkboxgroup/checkboxgroup.component2';
import { AixDynamicIntlPhoneComponent } from './intl-phone/intl-phone.component';

const components: { [type: string]: Type<Field> } = {
    button: AixDynamicButtonComponent,
    checkbox: AixDynamicCheckboxComponent,
    checkboxGroup: AixDynamicCheckboxgroupComponent,
    checkboxGroup2: AixDynamicCheckboxgroup2Component,
    currency: AixDynamicCurrencyComponent,
    date: AixDynamicDateComponent,
    dropdown: AixDynamicDropdownComponent,
    freeText: AixDynamicFreeTextComponent,
    expandableText: AixDynamicExpandableTextComponent,
    notification: AixDynamicNotificationComponent,
    group: AixDynamicGroupComponent,
    groupLight: AixDynamicGroupLightComponent,
    textInput: AixDynamicInputComponent,
    number: AixDynamicNumberComponent,
    percentage: AixDynamicPercentageComponent,
    radioGroup: AixDynamicRadiogroupComponent,
    repeater: AixDynamicRepeaterComponent,
    ssn: AixDynamicSsnComponent,
    ein: AixDynamicEinComponent,
    telephone: AixDynamicTelephoneComponent,
    intlPhone: AixDynamicIntlPhoneComponent,
    textarea: AixDynamicTextareaComponent,
    toggle: AixDynamicToggleComponent,
    zip: AixDynamicZipComponent
};

@Directive({
    selector: '[aixDynamicField]',
    standalone: true
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
    @Input() config: FieldConfig;

    component: ComponentRef<Field>;

    constructor(private container: ViewContainerRef, private cd: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.component) {
            this.component.instance.config = this.config;
        }
    }

    ngOnInit() {
        if (!components[this.config.type]) {
            const supportedTypes = Object.keys(components).join(', ');
            throw new Error(`
                Trying to use an unsupported type (${this.config.type}).
                Supported types: ${supportedTypes}

            `);
        }
        this.component = this.container.createComponent(components[this.config.type]);
        this.component.instance.config = this.config;
    }
}
