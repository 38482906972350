<aix-modal
    #cancelChangesModal
    [modalTitle]="'Discard changes?'"
    modalType="confirmation"
    (optionSelected)="onCancelChangesModal($event)"
>
    <ng-template>
        <p class="u-mb16">Your changes will not be saved if you leave this page.</p>
    </ng-template>
</aix-modal>
