import { IEnvironment } from '@trade-platform/ui-utils';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment: IEnvironment = {
    id: 'containerized',
    appVersion: '{{{ADVISOR_UI_VERSION}}}',
    production: true,
    enableDebugging: false,
    assets: {
        logo: 'https://assets.aixplatform.com/images/fs-logo-transparent-white.png'
    },
    auth0: {
        key: '{{{AUTH0_KEY}}}',
        domain: '{{{AUTH0_DOMAIN}}}'
    },
    login: {
        scope: 'openid profile email',
        response_type: 'id_token token',
        audience: '{{{LOGIN_AUDIENCE}}}',
        redirect_uri: '{{{REDIRECT_URI}}}'
    },
    api: '{{{API_URL}}}',
    outagesApi: '{{{OUTAGE_API}}}',
    adminApi: '{{{ADMIN_API_URL}}}',
    redirectApi: '{{{REDIRECT_API_URL}}}',
    apiDomain: '{{{API_DOMAIN}}}',
    adminApiDomain: '{{{ADMIN_API_DOMAIN}}}',
    brandingBucket: '{{{S3_BUCKET_NAME}}}',
    connectionsBucket: '{{{S3_BUCKET_CONNECTIONS}}}',
    ignoreOutages: false,
    contactDetails: {
        supportEmail: '{{{SUPPORT_EMAIL}}}',
        supportPhone: '{{{SUPPORT_PHONE}}}',
        docusignEmail: '{{{DOCUSIGN_EMAIL}}}'
    },
    sentry: {
        swallowExceptions: false,
        reportToSentry: true,
        environment: '{{{ENVIRONMENT}}}',
        sendLatestActions: true,
        dsn: '{{{SENTRY_DSN}}}'
    },
    fileUploadMaxSize: '{{{FILE_UPLOAD_MAX_SIZE}}}',
    environment: '{{{ENVIRONMENT}}}',
    docusignRedirectHost: '{{{DOCUSIGN_REDIRECT_HOST}}}'
};
