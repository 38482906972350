import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AixAuthService } from '../../services/auth-service';

// Ensures logout came from an internal component and not a manual url entry;
@Injectable()
export class LogoutGuard {
    constructor(public authService: AixAuthService) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.canLogout;
    }
}
