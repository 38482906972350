import { v4 as uuid } from 'uuid';

export function debounceListener(delay = 300) {
    return function (
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor & { [key: string]: any }
    ) {
        const timeoutKey = uuid();
        const original = descriptor.value;

        descriptor.value = function (...args: any[]) {
            clearTimeout(this[timeoutKey]);
            this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
        };

        return descriptor;
    };
}
