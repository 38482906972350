import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';
import { SetOrderStepPayload } from '../../models/order-steps/model';

export class OrderStepsActionTypes {
    static readonly SET_CURRENT_STEP = type('[Order Steps] Set Current Step');
}

export type OrderStepsActions = SetCurrentOrderStep;

export class SetCurrentOrderStep implements AixAction {
    readonly type = OrderStepsActionTypes.SET_CURRENT_STEP;

    constructor(public payload: SetOrderStepPayload) {}
}
