import { Component, effect, input, output } from '@angular/core';
import { NgFor } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

export interface CheckboxRendererItem {
    displayName: string;
    description: string;
    checked: boolean;
    disabled: boolean;
    childNodes: CheckboxRendererItem[];
}

@Component({
    selector: 'aix-checkbox-item',
    templateUrl: 'aix-checkbox-item.component.html',
    styleUrls: ['./aix-checkbox-item.component.scss'],
    standalone: true,
    imports: [AixDataTestingDirective, NgFor]
})
export class AixCheckboxItemComponent {
    isRequired = input<boolean>(false);
    isValid = input<boolean>(false);
    isDirty = input<boolean>(false);
    checkboxData = input<any>();

    onCheckboxChange = output<string>();
    onCheckboxClick = output<boolean>();

    touched = false;

    constructor() {
        effect(() => {
            this.setChildren(this.checkboxData().checked);
        });
    }

    onClick() {
        this.touched = true;
        this.onCheckboxClick.emit(true);
    }

    onChange() {
        if (this.touched) {
            this.onCheckboxChange.emit('dirty');
        }
    }

    setChildren(checked: boolean) {
        this.checkboxData().checked = checked;
        if (this.checkboxData().childNodes?.length > 0 && !checked) {
            this.disableChildren(this.checkboxData());
        }
        if (this.checkboxData().childNodes?.length > 0 && checked) {
            this.enableChildren(this.checkboxData());
        }
        this.onChange();
    }

    disableChildren(child: any) {
        child.childNodes.forEach((_child: CheckboxRendererItem) => {
            _child.disabled = true;
            _child.checked = false;
            if (_child.childNodes?.length > 0) {
                this.disableChildren(_child);
            }
        });
    }

    enableChildren(child: any) {
        child.childNodes.forEach((_child: CheckboxRendererItem) => {
            _child.disabled = false;
        });
    }
}
