import { JsonDecoder } from 'ts.data.json';
import { OrderSigningMethodResponse } from '../../models/order-signing-method/model';

export const orderSigningMethodResponseDecoder = JsonDecoder.optional(
    JsonDecoder.object<OrderSigningMethodResponse>(
        {
            id: JsonDecoder.optional(JsonDecoder.string),
            status: JsonDecoder.optional(JsonDecoder.string),
            signingMethod: JsonDecoder.optional(JsonDecoder.string)
        },
        'OrderSigningMethodResponse'
    )
);
