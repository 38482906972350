import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, getDataFromState, Profile } from '@trade-platform/ui-shared';
import { select, Store } from '@ngrx/store';
import { debounceTime, take } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-need-assistance-card',
    templateUrl: './need-assistance-card.html',
    styleUrls: ['./need-assistance-card.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe]
})
export class AixNeedAssistanceComponent {
    profile$: Observable<Profile>;
    supportEmail: string;
    supportPhone: string;

    constructor(
        public store: Store<AppState>,
        @Inject(ENVIRONMENT) private environment: IEnvironment
    ) {
        const state$ = store.pipe(select(state => state.profile));
        this.profile$ = getDataFromState(state$).pipe(debounceTime(50));
        this.profile$.pipe(take(1)).subscribe((profile: Profile) => {
            this.supportEmail =
                profile.organization?.supportEmail || this.environment.contactDetails.supportEmail;
            this.supportPhone =
                profile.organization?.supportPhoneNumber ||
                this.environment.contactDetails.supportPhone;
        });
    }
}
