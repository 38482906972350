import { HostListener, Directive } from '@angular/core';

@Directive()
export abstract class AixUnloadGuardDirective {
    abstract canLeave(): boolean;

    @HostListener('window:beforeunload')
    onUnload() {
        return this.canLeave();
    }
}
