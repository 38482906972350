/**
 * Action Types
 */
import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';
import { FlashMessage } from '../../models/flash-messages/model';

export class ActionTypes {
    static readonly ADD_FLASH_MESSAGE = type('[FlashMessage] Add Flash Message');
    static readonly REMOVE_FLASH_MESSAGE = type('[FlashMessage] Remove Flash Message');
    static readonly CLEAR_FLASH_MESSAGES = type('[FlashMessage] Clear Flash Messages');
}

/**
 * Available Action Types
 */
export type FlashMessagesActions =
    | ClearFlashMessagesAction
    | AddFlashMessageAction
    | RemoveFlashMessageAction;

/**
 * Actions
 */

export class ClearFlashMessagesAction implements AixAction {
    readonly type = ActionTypes.CLEAR_FLASH_MESSAGES;
}

export class AddFlashMessageAction implements AixAction {
    readonly type = ActionTypes.ADD_FLASH_MESSAGE;

    constructor(public payload: { message: FlashMessage }) {}
}

export class RemoveFlashMessageAction implements AixAction {
    readonly type = ActionTypes.REMOVE_FLASH_MESSAGE;

    constructor(public payload: { uid: string }) {}
}
