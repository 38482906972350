<div class="doc-preview__thumbnail" [class.doc-preview__thumbnail-selected]="isSelected()">
    @if (thumbnail()) {
    <img class="doc-preview__thumbnail-image" [src]="thumbnail()" />
    } @if (!thumbnail()) {
    <div class="doc-preview__unavailable">
        <p>Preview unavailable</p>
    </div>
    }

    <div class="doc-preview__hover">
        <div
            class="doc-preview__hover-outline"
            [data-testing]="'doc-preview-thumbnail'"
            (click)="onActionClicked()"
        ></div>
    </div>
</div>
