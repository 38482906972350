import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    ENVIRONMENT,
    IEnvironment,
    objectHasValue,
    stringIsNotEmpty
} from '@trade-platform/ui-utils';
import { v4 as uuid } from 'uuid';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, first, map, take, withLatestFrom } from 'rxjs/operators';
import { DynamicFormStore } from '../../dynamic-form-store';
import {
    DynamicFormControlSetDirtyAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { DynamicFormState, RadioGroupControlState } from '../../dynamic-form-store/model';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { OptionFieldConfig, RadiogroupFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { handleServerValdationErrors } from '../../dynamic-form.utils';
import {
    AixDataTestingDirective,
    AixExpansionPanelComponent,
    AixTooltipDirective
} from '@trade-platform/ui-components';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';

@Component({
    selector: 'aix-dynamic-radiogroup',
    styleUrls: ['./radiogroup.component.scss'],
    templateUrl: './radiogroup.component.html',
    standalone: true,
    imports: [
        NgIf,
        AixDataTestingDirective,
        AixTooltipDirective,
        NgClass,
        NgFor,
        AixDynamicNextPendingFieldDirective,
        AixExpansionPanelComponent
    ]
})
export class AixDynamicRadiogroupComponent implements Field, OnInit, OnDestroy {
    @ViewChild('expansionPanelRef', { static: false })
    expansionPanelRef: AixExpansionPanelComponent;
    @ViewChild('expansionPanelRef', { read: ElementRef })
    expansionPanelElemRef: ElementRef;

    // Static
    static HOST_CLASS = 'aix-flex-grid__col aix-form__grid';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicRadiogroupComponent.HOST_CLASS;

    @HostBinding('attr.data-testing')
    dataTesting: string;

    @HostBinding('attr.aix-control')
    aixControl: string;

    // Store Data
    templateData = {
        options: [] as RadioGroupControlState['extra']['options'],
        optionsInColumns: [] as Array<RadioGroupControlState['extra']['options']>,
        selectedOption: undefined as OptionFieldConfig | undefined,
        selectedOptionLabel: '',
        fieldIsDisabled: false,
        fieldIsRequired: false,
        fieldIsDirty: false,
        ctrlIsInvalid: false,
        ctrlHasRequiredError: false,
        canDisplayErrors: false,
        ctrlErrors: null as ValidationErrors | null
    };
    userInputEmitter$ = new Subject<OptionFieldConfig>();

    // Other
    config: RadiogroupFieldConfig;
    private subscriptions: Subscription[] = [];
    bodyClassNames: string[];
    controlName: string;
    ceil = Math.ceil;

    constructor(
        public helper: DynamicFormHelper,
        private store: Store<Record<string, DynamicFormState>>,
        private formStore: DynamicFormStore,
        private elemRef: ElementRef,
        private cd: ChangeDetectorRef,
        @Inject(ENVIRONMENT) private environment: IEnvironment
    ) {
        this.cd.detach();
    }

    ngOnInit() {
        const formUID = this.formStore.formUID;
        const refId = this.config.refId as string;
        this.aixControl = refId;
        this.calculateClassNames();
        this.controlName = this.config.name + uuid();

        // Currently binding a directive to the host is not possible in Angular (but on their roadmap - https://angular.io/guide/roadmap#support-adding-directives-to-host-elements);
        // Conditionally set this value based on the active environment here instead;
        if (this.environment.environment !== 'production') {
            this.dataTesting = refId;
        }

        // Control initialization
        this.formStore.addRadioGroup(this.config);

        const ctrlStore$ = this.formStore.getControlStoreByRefId<RadioGroupControlState>(refId);
        const valueToRender$ = this.formStore.getDataStore<string>(
            getDataPathByFieldConfig(this.config)
        );

        this.subscriptions.push(
            ctrlStore$.pipe(withLatestFrom(valueToRender$)).subscribe(([ctrl, selection]) => {
                const options = ctrl.extra.options;

                // options
                this.templateData.options = options;

                // optionsInColumns
                if (this.config.columns && this.config.columns > 1) {
                    this.templateData.optionsInColumns = this.toColumns(options);
                }

                // selectedOption
                // FIXME: move to single iteration
                this.templateData.selectedOption =
                    options.find(opt => opt.value === selection) ||
                    options.find(opt => opt.otherValues && opt.otherValues.includes(selection)) ||
                    options.find(opt => opt.otherValues && opt.otherValues.includes('*'));

                // Selected option has been filtered, so we have to clear the data
                if (selection && !this.templateData.selectedOption) {
                    this.store.dispatch(
                        new DynamicFormSetDataAction(
                            formUID,
                            getDataPathByFieldConfig(this.config),
                            null
                        )
                    );

                    this.open();
                }

                // selectedOptionLabel
                this.templateData.selectedOptionLabel = this.templateData.selectedOption
                    ? ' – ' + this.templateData.selectedOption.label
                    : '';

                // fieldIsDisabled
                this.templateData.fieldIsDisabled =
                    ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                // fieldIsRequired
                const hasRequiredValidator = (ctrl.fieldConfig.validation as ValidatorFn[]).some(
                    val => val === Validators.required
                );
                this.templateData.fieldIsRequired = ctrl.requiredByRelation || hasRequiredValidator;

                // fieldIsDirty
                this.templateData.fieldIsDirty = ctrl.isDirty;

                const ctrlErrors = ctrl.validation;

                // ctrlHasRequiredError
                this.templateData.ctrlHasRequiredError = ctrlErrors && ctrlErrors['required'];

                // ctrlInvalid
                this.templateData.ctrlIsInvalid = ctrlErrors !== null;

                // Server Validation errors
                handleServerValdationErrors(ctrl, evt =>
                    (this.elemRef.nativeElement as HTMLElement).dispatchEvent(evt)
                );

                this.cd.detectChanges();
            })
        );

        const ctrlInvalid$ = ctrlStore$.pipe(map(ctrlStore => ctrlStore.validation !== null));
        this.subscriptions.push(
            combineLatest([valueToRender$, ctrlInvalid$])
                .pipe(
                    filter(([value, ctrlInvalid]) => objectHasValue(value) && !ctrlInvalid),
                    take(1)
                )
                .subscribe(_ => {
                    this.close();
                })
        );

        // User Input
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(option => {
                if (this.expansionPanelRef) {
                    this.expansionPanelRef.toggle();

                    // Scroll to expansion panel header when we close the panel
                    if (this.expansionPanelElemRef) {
                        this.scrollToField();
                    }
                }

                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        option.value
                    )
                );
                this.store.dispatch(new DynamicFormControlSetDirtyAction(formUID, refId));
                this.helper.dispatchStoreActions(this.store, this.config, option.value);
            })
        );

        // Notifications
        this.subscriptions.push(
            combineLatest([valueToRender$.pipe(first()), this.userInputEmitter$])
                .pipe(
                    filter(
                        ([defaultValue, option]) =>
                            stringIsNotEmpty(defaultValue) && defaultValue !== option.value
                    )
                )
                .subscribe(() => {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                })
        );

        // On Load Store Actions
        this.subscriptions.push(
            valueToRender$
                .pipe(first())
                .subscribe(value =>
                    this.helper.dispatchOnLoadStoreActions(this.store, this.config, value)
                )
        );
        this.cd.detectChanges();
    }

    scrollToField() {
        const element = this.elemRef.nativeElement;
        element.dispatchEvent(
            new CustomEvent(FieldEvent.SCROLL, {
                detail: element,
                bubbles: true,
                cancelable: true
            })
        );
    }

    open() {
        if (this.expansionPanelRef && this.expansionPanelRef.isClosed()) {
            this.expansionPanelRef?.toggle();
        }
        this.cd.detectChanges();
    }

    close() {
        if (this.expansionPanelRef && !this.expansionPanelRef.isClosed()) {
            this.expansionPanelRef?.toggle();
        }
        this.cd.detectChanges();
    }

    onOpenOrClose() {
        this.cd.detectChanges();
    }

    private toColumns(options: OptionFieldConfig[]) {
        return options
            .reduce(
                (acc: OptionFieldConfig[][], option) => {
                    if (option.isTitle) {
                        acc.push([]);
                    }
                    acc[acc.length - 1].push(option);
                    return acc;
                },
                [[]]
            )
            .filter((item: any[]) => item.length);
    }

    trackByOptionFn(index: number, item: OptionFieldConfig) {
        return item.value;
    }

    trackByOptionArrayFn(index: number, options: OptionFieldConfig[]) {
        return options.map(opt => opt.refId).join('-');
    }

    private calculateClassNames() {
        let classNames = this.config.classNames
            ? [
                  AixDynamicRadiogroupComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicRadiogroupComponent.HOST_CLASS;
        classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;
        this.classNames = classNames;

        if (this.config && this.config.classNames && this.config.classNames.body) {
            this.bodyClassNames = this.helper.parseHeaderProperties(this.config.classNames.body);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeRadioGroup(this.config);
    }
}
