import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { BaseOrder } from '@trade-platform/ui-shared';
import { commentTag } from '@trade-platform/lib-enums';
import { OrderStepsMachine } from './+xstate/steps-machine.service';
import { Init } from './+xstate/steps-machine.events';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'aix-order-steps',
    templateUrl: './order-steps.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterOutlet]
})
export class AixOrderStepsComponent implements OnChanges {
    @Input() order: BaseOrder;

    commentTag = commentTag;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public profileStoreFacade: ProfileStoreFacade,
        private stepsMachine: OrderStepsMachine
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.order && changes.order.currentValue) {
            this.stepsMachine.send(new Init());
        }
    }
}
