@if (fileUploadError && !filesUploading.length) {
<div class="flex-center-center">
    <aix-notification
        [isOpen]="true"
        (onCloseNotification)="fileUploadError = false;"
        status="error"
    >
        The file failed to upload. Please check the file size and/or extension and try again.
    </aix-notification>
</div>
} @if (!filesUploading.length) {
<div class="assign-box">
    @if (!filesUploading.length) {
    <div class="aix-upload-link" [class.aix-upload-link__disabled]="disabled">
        <div class="upload-title-container">
            @if (hasUploadTitle) {
            <span class="upload-title">Upload</span>
            } @if (hasUploadTitle) {
            <span class="aix-notification-close-button fa-regular fa-xmark" (click)="hide()"></span>
            }
        </div>

        <label
            [hidden]="!fileError"
            [ngStyle]="styles.links"
            class="aix-upload-link__label aix-upload-link__label--error"
            (mouseover)="onHoverUpload($event.target)"
            (mouseleave)="onLeaveUpload($event.target)"
        >
            <aix-notification class="u-mt16" [isOpen]="true" [canClose]="false" status="warning">
                @if (!filePasswordError) {
                <span> {{ fileErrorMessage }} </span>
                } @if (filePasswordError) {
                <div>
                    <p><strong>{{ fileErrorMessage }}</strong></p>
                    <p>Please remove any password protection and upload again.</p>
                </div>
                }
            </aix-notification>
            @if (fileErrorTroubleshootingTips.length > 0) {
            <span class="u-mt24">
                Troubleshooting Tips
                <ul class="u-mt8">
                    @for (tip of fileErrorTroubleshootingTips; track tip) {
                    <li class="aix-upload-troubleshooting--tip u-label-color">{{ tip }}</li>
                    }
                </ul>
            </span>
            } @if (!isModal && showCloseUploaderButton) {
            <span>
                <aix-button
                    [ngStyle]="styles.links"
                    (click)="returnToUploader()"
                    class="u-mt8"
                    [buttonType]="closeUploaderButtonType"
                    [buttonLabel]="'Close Uploader'"
                >
                </aix-button>
            </span>
            }
        </label>

        <label
            class="aix-upload-link__label u-mt24"
            [data-testing]="'upload-link-input'"
            [class.aix-upload-link__label--dragging]="isDragOver"
            (mouseover)="onHoverUpload($event.target)"
            (mouseleave)="onLeaveUpload($event.target)"
            (dragover)="onDragOver($event)"
            (dragenter)="onDragOver($event)"
            (dragstart)="onDragOver($event)"
            (dragend)="onDragOut($event)"
            (dragexit)="onDragOut($event)"
            (dragleave)="onDragOut($event)"
            (drop)="onDragAndDrop($event)"
        >
            <span
                [ngStyle]="styles.links"
                class="aix-upload-link__icon fa-light br-link fa-upload u-mb8"
            ></span>
            <input
                #uploadButtonRef
                type="file"
                [accept]="accept"
                [attr.multiple]="multiple ? '' : null"
                (change)="onFileSelected($event)"
                [disabled]="!isReady"
            />
            <span>
                Drag to upload or
                <span [ngStyle]="styles.links" class="fake-link br-link">choose a file</span>.
            </span>
            <span class="u-font-size--12 u-mt8"
                >Accepted file types: {{ acceptedTypeDisplayNames }}</span
            >
        </label>
    </div>
    }
</div>
} @if (filesUploadCount < filesUploading.length && !fileUploadError) {
<div class="aix-upload-progress u-mt16">
    <div class="aix-sign-document aix-sign-document--smallerflex-left-top">
        <div class="flex-center-center">
            <aix-loading
                [relative]="true"
                [style]="{ position: 'relative' }"
                [size]="16"
            ></aix-loading>
            <div class="aix-sign-document__main u-ml8">
                <span class="aix-sign-document__name" [data-testing]="'processing-uploads-message'">
                    Processing {{ filesUploading.length }} {{ filesUploading.length > 1 ? 'files' :
                    'file' }}...
                </span>
            </div>
        </div>
    </div>

    <div class="u-mt16">
        @if (activeFileUpload && activeFileUpload.uploadStatus === UploadStatus.WITH_ERRORS) {
        <p class="aix-upload-error error-box">
            <span class="error-box__message"
                >Error: {{ activeFileUpload.responseText || 'Unknown error' }}</span
            >
        </p>
        }
    </div>
</div>
} @if (filesUploadCount === filesUploading.length && filesUploadCount > 0 && !fileUploadError) {
<div class="aix-upload-completed flex-col flex-center-center u-mt24">
    <span class="aix-upload-link__icon fa-regular fa-check br-primary__color"></span>
    <span class="aix-upload-completed-message">Upload complete</span>
</div>
}
