import { EventEmitter, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InactivityService {
    readonly startInactivityTimer$ = new EventEmitter<{ inactivityTimeout: number }>();
    readonly showInactivityModal$ = new EventEmitter<never>();

    startInactivityTimer(arg: { inactivityTimeout: number }) {
        this.startInactivityTimer$.emit(arg);
    }

    showInactivityModal() {
        this.showInactivityModal$.emit();
    }
}
