import { MachineConfig } from 'xstate';
import { StepsEvent } from './steps-machine.events';
import { StepsContext, StepsSchema } from './steps-machine.schema';

const context: StepsContext = {
    order: null,
    activeStep: null
};

export const stepsMachineConfig: MachineConfig<StepsContext, StepsSchema, StepsEvent> = {
    id: 'steps',
    context,
    initial: 'boot',
    states: {
        boot: {
            on: {
                INIT: 'process'
            }
        },
        process: {
            entry: 'processOrder',
            on: {
                '': 'route'
            }
        },
        route: {
            entry: 'processActiveStep',
            on: {
                '': 'boot'
            }
        }
    }
};
