import { getFromStorage } from '../../ui-utils/src';
import { languageBase } from './base.map';
import { Language } from './language.interface';

let languageMap: Language = languageBase;

export const setLanguage = () => {
    const languageOptions = (<any>getFromStorage('profile')).organization?.languageOptions;
    setOptions(languageOptions);
};

const setOptions = (options: any) => {
    for (const property in options) {
        if (typeof property == 'object') {
            setOptions(property);
        } else if (languageMap.hasOwnProperty(property)) {
            // @ts-ignore
            languageMap[property] = options[property];
        }
    }
};

export const getLanguage = (page: string) => {
    // @ts-ignore
    return languageMap[page];
};
if (getFromStorage('profile')) {
    setLanguage();
}
