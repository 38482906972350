import { JsonDecoder, err, ok } from 'ts.data.json';

export interface ErrorWrapperStatusMap {
    [status: number]: string;
}

/**
 * Type that wraps errors that will be fed to the error-box component.
 * Useful when you need to customize the default message.
 */
export class ErrorWrapper {
    static readonly defaultErrorText =
        'Sorry but something went wrong. Please contact support at <a href="mailto:support@aixplatform.com">support@aixplatform.com</a> or&nbsp;833-524-9249.';
    /**
     *
     * @param debugText The error technical description message that is visible when the environment has the `enableDebugging` flag to true.
     * @param errorText The error description message that is always visible to the final user.
     */
    constructor(
        public debugText: string,
        public options: ErrorWrapperOptions = { errorText: ErrorWrapper.defaultErrorText }
    ) {}
}

export interface ErrorWrapperOptions {
    errorText: string;
    status?: number;
}

// Docusign stuff

export type StringDate = string;
export type StringDocusignDate = string;
export const stringDocusignDateDecoder = JsonDecoder.string.map(s =>
    s && s.slice(-1) !== 'Z' ? s + 'Z' : s
);

// Custom decoder to coerce ids into numbers
export const idNumberDecoder = () =>
    new JsonDecoder.Decoder<number>((json: any) => {
        if (typeof json === 'number') {
            return ok<number>(json);
        } else if (typeof json === 'string') {
            return ok<number>(parseInt(json, 10));
        } else {
            return err('Expected a string or number');
        }
    });
