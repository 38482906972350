import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlFieldConfig } from '@trade-platform/form-fields';
import { DynamicPendingFieldsManagerService } from './dynamic-pending-fields-manager.service';

export interface DynamicNextPendingField {
    config: ControlFieldConfig;
    margin?: number;
}

@Directive({
    selector: '[aixDynamicNextPendingField]',
    standalone: true
})
export class AixDynamicNextPendingFieldDirective implements OnInit, OnDestroy {
    @Input('aixDynamicNextPendingField') field: DynamicNextPendingField;

    object = Object;

    constructor(
        private elem: ElementRef,
        public pendingFieldsManager: DynamicPendingFieldsManagerService
    ) {}

    ngOnInit() {
        setTimeout(
            () =>
                this.pendingFieldsManager.addField(this.field.config.refId as string, {
                    config: this.field.config,
                    elem: this.elem.nativeElement,
                    margin: this.field.margin ? this.field.margin : 0
                }),
            0
        );
    }

    ngOnDestroy() {
        setTimeout(
            () => this.pendingFieldsManager.removeField(this.field.config.refId as string),
            0
        );
    }
}
