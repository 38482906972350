import {
    AixAction,
    ErrorWrapper,
    OrderCancelEnvelopeRequest,
    type
} from '@trade-platform/ui-shared';

export class OrderCancelEnvelopeActionTypes {
    static readonly ORDER_CANCEL_ENVELOPE = type('[Order Cancel Envelope] Order Cancel Envelope');
    static readonly ORDER_CANCEL_ENVELOPE_SUCCESS = type(
        '[Order Cancel Envelope] Order Cancel Envelope Success'
    );
    static readonly ORDER_CANCEL_ENVELOPER_FAILURE = type(
        '[Order Cancel Envelope] Order Cancel Envelope Failrue'
    );
}

export type OrderCancelEnvelopeActions =
    | OrderCancelEnvelopeAction
    | OrderCancelEnvelopeSuccessAction
    | OrderCancelEnvelopeFailureAction;

export class OrderCancelEnvelopeAction implements AixAction {
    readonly type = OrderCancelEnvelopeActionTypes.ORDER_CANCEL_ENVELOPE;

    constructor(public payload: OrderCancelEnvelopeRequest) {}
}

export class OrderCancelEnvelopeSuccessAction implements AixAction {
    readonly type = OrderCancelEnvelopeActionTypes.ORDER_CANCEL_ENVELOPE_SUCCESS;

    constructor(public payload: boolean) {}
}

export class OrderCancelEnvelopeFailureAction implements AixAction {
    readonly type = OrderCancelEnvelopeActionTypes.ORDER_CANCEL_ENVELOPER_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
