<div class="header--page" [ngClass]="pageClasses()">
    @if (title()) {
    <div class="-heading">
        <h2 [data-testing]="'page-section-title'">{{ title() }}</h2>
        <ng-content select="[footer]"></ng-content>
    </div>
    } @if (!title()) {
    <div #headerRef class="-heading">
        <ng-content select="[header]"></ng-content>
    </div>
    }

    <div
        #controlsRef
        class="-controls aix-toolbar"
        [hidden]="!controlsRef?.childNodes?.length"
        [data-testing]="dataTestingName()"
    >
        <ng-content select="[controls]"></ng-content>
    </div>

    <div #extraRef class="-extra" [hidden]="!extraRef?.childNodes?.length">
        <ng-content select="[extra]"></ng-content>
    </div>
</div>
