import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiFormError, ApiFormErrorItem } from '@trade-platform/ui-utils';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ErrorWrapper } from '../models/common';
import { UpdateActionTypes, UpdateFailure, UpdateSuccess } from '../update/actions';
import { catchHttpError, getErrorText } from '../utils/catch-http-error';
import {
    LoadOrder,
    LoadOrderActionTypes,
    LoadOrderFailure,
    LoadOrderSuccess,
    UpdateOrder,
    UpdateOrderFailure,
    UpdateOrderFiles,
    UpdateOrderFilesFailure,
    UpdateOrderFilesSuccess,
    UpdateOrderSuccess
} from './actions';
import { MaintenanceOrderService } from './maintenance/service';
import { OrderService } from './service';

@Injectable()
export class OrderEffects {
    constructor(
        private actions$: Actions,
        private orderService: OrderService,
        private maintenanceOrderService: MaintenanceOrderService
    ) {}

    loadOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadOrder>(LoadOrderActionTypes.LOAD_ORDER),
            debounceTime(100),
            switchMap(action => {
                const get$ =
                    action.payload.reducerSuffix === 'maintenance'
                        ? this.maintenanceOrderService.getMaintenanceOrder(action.payload.orderId)
                        : action.payload.reducerSuffix === 'buy'
                        ? this.orderService.getOrder(action.payload.orderId)
                        : null;

                if (get$ === null) {
                    throw new Error(
                        `Unknown reducerSuffix "${action.payload.reducerSuffix}" found. Check the OrderType type to see which values are allowed.`
                    );
                }

                return get$.pipe(
                    map(
                        order =>
                            new LoadOrderSuccess({
                                order,
                                reducerSuffix: action.payload.reducerSuffix
                            })
                    ),
                    catchHttpError(error =>
                        of(
                            new LoadOrderFailure({
                                error,
                                reducerSuffix: action.payload.reducerSuffix
                            })
                        )
                    )
                );
            })
        )
    );

    updateOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateOrder>(UpdateActionTypes.UPDATE),
            filter(
                action =>
                    action.payload.reducerSuffix === 'orderUpdate' ||
                    action.payload.reducerSuffix === 'orderProgressUpdate'
            ),
            switchMap(action => {
                return this.orderService
                    .updateOrder(
                        action.payload.updatePayload,
                        action.payload.updatePayloadExtras
                            ? action.payload.updatePayloadExtras.formCompleted
                            : false
                    )
                    .pipe(
                        map(
                            result =>
                                new UpdateSuccess({
                                    result,
                                    reducerSuffix: action.payload.reducerSuffix
                                }) as UpdateOrderSuccess
                        ),
                        catchError((error: string | HttpErrorResponse) => {
                            let err: ErrorWrapper | ApiFormError;
                            if (error instanceof HttpErrorResponse) {
                                if (
                                    error.status === 400 &&
                                    error.error &&
                                    Array.isArray(error.error.message)
                                ) {
                                    err = new ApiFormError(
                                        error.error.code as string,
                                        error.error.message as ApiFormErrorItem[],
                                        error.error.path as string
                                    );
                                } else {
                                    err = new ErrorWrapper(error.message, {
                                        errorText: getErrorText(error)
                                    });
                                }
                            } else {
                                err = new ErrorWrapper(error);
                            }
                            return of(
                                new UpdateFailure({
                                    error: err,
                                    reducerSuffix: action.payload.reducerSuffix
                                }) as UpdateOrderFailure
                            );
                        })
                    );
            })
        )
    );

    updateFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateOrderFiles>(UpdateActionTypes.UPDATE),
            filter(action => action.payload.reducerSuffix === 'orderFilesUpdate'),
            switchMap(action => {
                if (
                    action.payload.updatePayloadExtras &&
                    action.payload.updatePayloadExtras.orderId
                ) {
                    return this.orderService
                        .updateOrderFiles(
                            action.payload.updatePayload,
                            action.payload.updatePayloadExtras.orderId
                        )
                        .pipe(
                            map(
                                result =>
                                    new UpdateSuccess({
                                        result,
                                        reducerSuffix: action.payload.reducerSuffix
                                    }) as UpdateOrderFilesSuccess
                            ),
                            catchHttpError(error =>
                                of(
                                    new UpdateFailure({
                                        error,
                                        reducerSuffix: action.payload.reducerSuffix
                                    }) as UpdateOrderFilesFailure
                                )
                            )
                        );
                } else {
                    return of(
                        new UpdateFailure({
                            error: new ErrorWrapper('orderId not provided on UpdateOrderFiles'),
                            reducerSuffix: action.payload.reducerSuffix
                        }) as UpdateOrderFilesFailure
                    );
                }
            })
        )
    );
}
