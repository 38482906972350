import { StringDocusignDate } from '../../../store/models/common';

export enum Status {
    Active = 'Active'
}

export enum TabLabel {
    DateSigned = 'Date Signed',
    InitialHere = 'Initial Here',
    SignHere = 'Sign Here'
}

export enum Tab {
    DateSigned = 'DateSigned',
    InitialHere = 'InitialHere',
    SignHere = 'SignHere'
}

export enum TabValue {
    Empty = ''
}

export interface TabStatus {
    Status: Status;
    TabName: Tab;
    TabType: Tab;
    TabLabel: TabLabel;
    TabValue: TabValue;
    XPosition: number;
    YPosition: number;
    DocumentID: number;
    PageNumber: number;
}

export interface TabStatuses {
    TabStatus: TabStatus[];
}

export interface CustomFields {
    CustomField: string;
}

export interface XfdfField {
    value: string;
}

export interface XfdfFields {
    field: XfdfField;
}

export interface Xfdf {
    fields: XfdfFields;
}

export interface FormData {
    xfdf: Xfdf;
}

export interface Attachment {
    Data: string;
    Label: string;
}

export interface RecipientAttachment {
    Attachment: Attachment;
}

export interface RecipientStatus {
    Signed?: StringDocusignDate;
    Sent?: StringDocusignDate;
    Type: string;
    Email: string;
    AutoRespondedReason?: string;
    Status: 'AutoResponded' | 'Completed' | 'Sent' | 'Created' | 'Delivered' | 'Declined';
    UserName: string;
    RecipientId: string;
    TabStatuses: TabStatuses;
    CustomFields: CustomFields;
    RoutingOrder: number;
    Delivered?: string;
    AccountStatus: string;
    DeclineReason: string;
    Declined?: string;
    RecipientIPAddress: string;
    FormData: FormData;
    RecipientAttachment: RecipientAttachment;
}

export interface RecipientStatuses {
    RecipientStatus: RecipientStatus[];
}

export interface DocumentStatus {
    ID: number;
    Name: string;
    Sequence: number;
    TemplateName: string;
}

export interface DocumentStatuses {
    DocumentStatus: DocumentStatus[];
}

export interface EnvelopeStatus {
    Sent: StringDocusignDate;
    Email: string;
    Status: string;
    Created: StringDocusignDate;
    Subject: string;
    ACHolder: string;
    ACStatus: string;
    UserName: string;
    EnvelopeID: string;
    ACStatusDate: StringDocusignDate;
    CustomFields: string;
    ACHolderEmail: string;
    TimeGenerated: string;
    AutoNavigation: boolean;
    EnvelopePDFHash: string;
    SenderIPAddress: string;
    SigningLocation: string;
    ACHolderLocation: string;
    DocumentStatuses: DocumentStatuses;
    AuthoritativeCopy: boolean;
    RecipientStatuses: RecipientStatuses;
    EnvelopeIdStamping: boolean;
}

export interface DocuSignEnvelopeInformation {
    EnvelopeStatus: EnvelopeStatus;
}

export interface SigningStatus {
    DocuSignEnvelopeInformation: DocuSignEnvelopeInformation;
}
