import { JsonDecoder } from 'ts.data.json';
import { Investor } from '../../models/investor/model';

export const investorDecoder = JsonDecoder.object<Investor>(
    {
        entityType: JsonDecoder.optional(JsonDecoder.string),
        investorType: JsonDecoder.optional(JsonDecoder.string),
        id: JsonDecoder.optional(JsonDecoder.string),
        firstName: JsonDecoder.optional(JsonDecoder.string),
        middleName: JsonDecoder.optional(JsonDecoder.string),
        lastName: JsonDecoder.optional(JsonDecoder.string),
        fullName: JsonDecoder.optional(JsonDecoder.string),
        company: JsonDecoder.optional(JsonDecoder.string),
        title: JsonDecoder.optional(JsonDecoder.string),
        prefix: JsonDecoder.optional(JsonDecoder.string),
        suffix: JsonDecoder.optional(JsonDecoder.string),
        contactType: JsonDecoder.optional(JsonDecoder.string),
        emailPrimary: JsonDecoder.optional(JsonDecoder.string),
        emailConsent: JsonDecoder.optional(JsonDecoder.boolean),
        emailWork: JsonDecoder.optional(JsonDecoder.string),
        emailAlternate: JsonDecoder.optional(JsonDecoder.string),
        phoneHome: JsonDecoder.optional(JsonDecoder.string),
        phoneHomeCountryCode: JsonDecoder.optional(JsonDecoder.string),
        phoneMobile: JsonDecoder.optional(JsonDecoder.string),
        phoneMobileCountryCode: JsonDecoder.optional(JsonDecoder.string),
        phoneWork: JsonDecoder.optional(JsonDecoder.string),
        phoneWorkCountryCode: JsonDecoder.optional(JsonDecoder.string),
        preferredContactNumber: JsonDecoder.optional(JsonDecoder.string),
        addressCity: JsonDecoder.optional(JsonDecoder.string),
        addressState: JsonDecoder.optional(JsonDecoder.string),
        addressStreet: JsonDecoder.optional(JsonDecoder.string),
        addressZip: JsonDecoder.optional(JsonDecoder.string),
        addressCountry: JsonDecoder.optional(JsonDecoder.string),
        businessAddressCity: JsonDecoder.optional(JsonDecoder.string),
        businessAddressState: JsonDecoder.optional(JsonDecoder.string),
        businessAddressStreet: JsonDecoder.optional(JsonDecoder.string),
        businessAddressZip: JsonDecoder.optional(JsonDecoder.string),
        businessAddressCountry: JsonDecoder.optional(JsonDecoder.string),
        altAddressCity: JsonDecoder.optional(JsonDecoder.string),
        altAddressState: JsonDecoder.optional(JsonDecoder.string),
        altAddressStreet: JsonDecoder.optional(JsonDecoder.string),
        altAddressZip: JsonDecoder.optional(JsonDecoder.string),
        altAddressCountry: JsonDecoder.optional(JsonDecoder.string),
        birthDate: JsonDecoder.optional(JsonDecoder.string),
        gender: JsonDecoder.optional(JsonDecoder.string),
        citizenshipType: JsonDecoder.optional(JsonDecoder.string),
        citizenshipCountry: JsonDecoder.optional(JsonDecoder.string),
        residenceCountry: JsonDecoder.optional(JsonDecoder.string),
        contractFinancialPlanningDate: JsonDecoder.optional(JsonDecoder.string),
        contractInvestmentAdviseryDate: JsonDecoder.optional(JsonDecoder.string),
        relationshipInceptionDate: JsonDecoder.optional(JsonDecoder.string),
        investmentExperienceLevel: JsonDecoder.optional(JsonDecoder.string),
        investmentKnowledgeLevel: JsonDecoder.optional(JsonDecoder.string),
        investmentObjective: JsonDecoder.optional(JsonDecoder.string),
        liquidityNeed: JsonDecoder.optional(JsonDecoder.string),
        liquidNetWorth: JsonDecoder.optional(JsonDecoder.number),
        riskProfile: JsonDecoder.optional(JsonDecoder.string),
        taxSensitivity: JsonDecoder.optional(JsonDecoder.string),
        timeHorizon: JsonDecoder.optional(JsonDecoder.string),
        referredBy: JsonDecoder.optional(JsonDecoder.string),
        description: JsonDecoder.optional(JsonDecoder.string),
        taxIdNumber: JsonDecoder.optional(JsonDecoder.string).map(taxIdNumber => taxIdNumber || ''),
        percentage: JsonDecoder.optional(JsonDecoder.string),
        taxIdType: JsonDecoder.optional(JsonDecoder.string),
        entityStateOfFormation: JsonDecoder.optional(JsonDecoder.string),
        foreignInvestorType: JsonDecoder.optional(JsonDecoder.string),
        skipSignatures: JsonDecoder.optional(JsonDecoder.boolean)
    },
    'Investor'
);
