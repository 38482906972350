import { AixAction } from '../aix-action';
import { type } from '../models/type-cache';
import { Register, RegisterToken } from '../../models/register/model';
import { ErrorWrapper } from '@trade-platform/ui-shared';

/**
 * Action Types
 */
export class RegisterActionTypes {
    static readonly UNLOAD_REGISTER = type('[Register] Unload Register');
    static readonly SET_REGISTER = type('[Register] Set Register');
    static readonly LOAD_REGISTER_FROM_STORAGE = type('[Register] Load Register From Storage');
    static readonly PING_REGISTER = type('[Register] Ping Register');
    static readonly PING_REGISTER_SUCCESS = type('[Register] Ping Register Success');
    static readonly PING_REGISTER_FAILURE = type('[Register] Ping Register Failure');
    static readonly SEND_REGISTER = type('[Register] Send Register');
    static readonly SEND_REGISTER_SUCCESS = type('[Register] Send Register Success');
    static readonly SEND_REGISTER_FAILURE = type('[Register] Send Register Failure');
}

/**
 * Available Action Types
 */
export type RegisterActions =
    | UnloadRegisterAction
    | SetRegisterAction
    | LoadRegisterFromStorageAction
    | PingRegisterAction
    | PingRegisterSuccessAction
    | PingRegisterFailureAction
    | SendRegisterAction
    | SendRegisterSuccessAction
    | SendRegisterFailureAction;

/**
 * Unload Register
 */
export class UnloadRegisterAction implements AixAction {
    readonly type = RegisterActionTypes.UNLOAD_REGISTER;

    constructor() {}
}

/**
 * Set Register
 */
export class SetRegisterAction implements AixAction {
    readonly type = RegisterActionTypes.SET_REGISTER;

    constructor(public payload: RegisterToken) {}
}

/**
 * Load From Storage
 */
export class LoadRegisterFromStorageAction implements AixAction {
    readonly type = RegisterActionTypes.LOAD_REGISTER_FROM_STORAGE;
}

/**
 * Send Register Information
 */
export class PingRegisterAction implements AixAction {
    readonly type = RegisterActionTypes.PING_REGISTER;

    constructor(public jwt: string) {}
}
export class PingRegisterSuccessAction implements AixAction {
    readonly type = RegisterActionTypes.PING_REGISTER_SUCCESS;
}
export class PingRegisterFailureAction implements AixAction {
    readonly type = RegisterActionTypes.PING_REGISTER_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
export class SendRegisterAction implements AixAction {
    readonly type = RegisterActionTypes.SEND_REGISTER;

    constructor(public jwt: string, public payload?: { userData: Register }) {}
}
export class SendRegisterSuccessAction implements AixAction {
    readonly type = RegisterActionTypes.SEND_REGISTER_SUCCESS;
}
export class SendRegisterFailureAction implements AixAction {
    readonly type = RegisterActionTypes.SEND_REGISTER_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
