import { AixAction } from '../aix-action';
import { type } from '../models/type-cache';
import { Auth } from '../../models/auth/model';

export class AuthActionTypes {
    static readonly LOAD_AUTH_FROM_STORAGE = type('[Auth] Load Auth From Storage');
    static readonly SET_AUTH = type('[Auth] Set Auth');
    static readonly UNLOAD_AUTH = type('[Auth] Unload Auth');
}

export type AuthActions = LoadAuthFromStorageAction | SetAuthAction | UnloadAuthAction;

export class LoadAuthFromStorageAction implements AixAction {
    readonly type = AuthActionTypes.LOAD_AUTH_FROM_STORAGE;
}

export class SetAuthAction implements AixAction {
    readonly type = AuthActionTypes.SET_AUTH;

    constructor(public payload: Auth) {}
}

export class UnloadAuthAction implements AixAction {
    readonly type = AuthActionTypes.UNLOAD_AUTH;
}
