import { escapeRegExp } from 'lodash-es';

export const applyRefIdToTemplate = (
    jsonStringTemplate: string,
    fieldName: string,
    refId: string
) => {
    const regExpSafeFieldName = escapeRegExp(fieldName);
    const separator = new RegExp(`"refId":"@${regExpSafeFieldName}"`, 'g');
    const replacement = `"refId":"${refId}"`;
    return jsonStringTemplate.replace(separator, replacement);
};
