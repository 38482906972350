import { Component, HostBinding, input, output } from '@angular/core';
import { AixLoadingComponent } from '../aix-loading/aix-loading.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'aix-loading-button',
    templateUrl: 'aix-loading-button.component.html',
    styleUrls: ['aix-loading-button.component.scss'],
    standalone: true,
    imports: [NgIf, AixLoadingComponent, NgClass]
})
export class AixLoadingButtonComponent {
    readonly styles = {
        loading: {
            verticalAlign: 'middle',
            display: 'inline-flex',
            position: 'relative'
        }
    };

    label = input('');
    icon = input<string>();
    isDisabled = input(false);
    buttonStyle = input<'primary' | 'secondary' | 'tertiary'>('primary');
    isLoading = input(false);

    @HostBinding('class')
    public get hostClass(): string {
        switch (this.buttonStyle()) {
            case 'secondary':
                return 'aix-btn aix-btn--secondary br-btn--secondary';
            case 'tertiary':
                return 'aix-btn aix-btn--link br-link';
            case 'primary':
            default:
                return 'aix-btn aix-btn-primary br-btn';
        }
    }
    @HostBinding('class.aix-btn-inactive')
    public get disabled(): boolean {
        return this.isDisabled();
    }

    btnClick = output();

    constructor() {}
}
