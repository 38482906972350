import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { isComplexObject } from '@trade-platform/ui-utils';

@Pipe({
    name: 'aixFormErrors',
    standalone: true
})
export class AixFormErrorsPipe implements PipeTransform {
    REGISTERED_ERRORS = [
        {
            code: 'required',
            msg: (_: any) => ' is required.',
            default: (_: any) => 'This field is required.'
        },
        {
            code: 'email',
            msg: (_: any) => ' must be a valid email address.',
            default: (_: any) => 'Please enter a valid email address.'
        },
        {
            code: 'valid',
            msg: (_: any) => ' is invalid.',
            default: (_: any) => 'This field is invalid.'
        },
        {
            code: 'min',
            msg: ({ min, actual }: { min: number; actual: any }) => ` must be at least ${min}.`,
            default: ({ min, actual }: { min: number; actual: any }) =>
                `This field must be at least ${min}.`
        },
        {
            code: 'max',
            msg: ({ max, actual }: { max: number; actual: any }) => ` must be at most ${max}.`,
            default: ({ max, actual }: { max: number; actual: any }) =>
                `This field must be at most ${max}.`
        },
        {
            code: 'minlength',
            msg: ({
                requiredLength,
                actualLength
            }: {
                requiredLength: number;
                actualLength: any;
            }) => ` field must be at least ${requiredLength} characters long.`,
            default: ({
                requiredLength,
                actualLength
            }: {
                requiredLength: number;
                actualLength: any;
            }) => `This field must be at least ${requiredLength} characters long.`
        },
        {
            code: 'maxlength',
            msg: ({
                requiredLength,
                actualLength
            }: {
                requiredLength: number;
                actualLength: any;
            }) => ` field must be at most ${requiredLength} characters long.`,
            default: ({
                requiredLength,
                actualLength
            }: {
                requiredLength: number;
                actualLength: any;
            }) => `This field must be at most ${requiredLength} characters long.`
        }
    ];
    /**
     * errors = {required: true, minLength: false, maxLength: false}
     */
    transform(errors: ValidationErrors | null, fieldName?: string): string {
        let result = '';

        if (errors) {
            result = Object.keys(errors)
                .map(key => {
                    const nativeErr = this.REGISTERED_ERRORS.find(item => item.code === key);
                    if (nativeErr) {
                        return fieldName
                            ? fieldName + nativeErr.msg(errors[key])
                            : nativeErr.default(errors[key]);
                    } else if (isComplexObject(errors[key])) {
                        return Object.keys(errors[key]).map(subKey => {
                            const nativeError = this.REGISTERED_ERRORS.find(
                                item => item.code === subKey
                            );
                            if (nativeError) {
                                return fieldName
                                    ? fieldName + nativeError.msg(errors[key][subKey])
                                    : nativeError.default(errors[key][subKey]);
                            } else {
                                return errors[key][subKey].toString();
                            }
                        });
                    } else {
                        return errors[key];
                    }
                })
                .join(', ');
        }

        return result;
    }
}
