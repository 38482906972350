import {
    AixAction,
    ErrorWrapper,
    OrderDocumentDeclinePayload,
    type
} from '@trade-platform/ui-shared';

export class OrderDocumentDeclineActionTypes {
    static readonly ORDER_DOCUMENT_DECLINE = type(
        '[Order Document Decline] Order Document Decline'
    );
    static readonly ORDER_DOCUMENT_DECLINE_SUCCESS = type(
        '[Order Document Decline] Order Document Decline Success'
    );
    static readonly ORDER_DOCUMENT_DECLINE_FAILURE = type(
        '[Order Document Decline] Order Document Decline Failure'
    );
}

export type OrderDocumentDeclineActions =
    | OrderDocumentDecline
    | OrderDocumentDeclineSuccess
    | OrderDocumentDeclineFailure;

export class OrderDocumentDecline implements AixAction {
    readonly type = OrderDocumentDeclineActionTypes.ORDER_DOCUMENT_DECLINE;

    constructor(public payload: OrderDocumentDeclinePayload) {}
}

export class OrderDocumentDeclineSuccess implements AixAction {
    readonly type = OrderDocumentDeclineActionTypes.ORDER_DOCUMENT_DECLINE_SUCCESS;
}

export class OrderDocumentDeclineFailure implements AixAction {
    readonly type = OrderDocumentDeclineActionTypes.ORDER_DOCUMENT_DECLINE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
