import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WrappedOption } from './dropdown';
import { DropdownCellrenderer } from './dropdown-cellrenderer.directive';

@Component({
    selector: 'aix-default-dropdown-cellrenderer',
    template: ` {{ option.label }} `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class DefaultDropdownCellrendererComponent implements DropdownCellrenderer {
    option: WrappedOption;
}
