import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderSigningMethodResponse } from '@trade-platform/ui-shared';
import { OrderSigningMethodActions, OrderSigningMethodActionTypes } from './actions';

export type OrderSigningMethodState = RemoteData<
    OrderSigningMethodResponse | undefined,
    ErrorWrapper
>;
const initialState = notAsked<OrderSigningMethodResponse, ErrorWrapper>();

export function orderSigningMethodReducer(
    state: OrderSigningMethodState = initialState,
    action: OrderSigningMethodActions
): OrderSigningMethodState {
    switch (action.type) {
        case OrderSigningMethodActionTypes.ORDER_SIGNING_METHOD:
            return inProgress({} as OrderSigningMethodResponse);
        case OrderSigningMethodActionTypes.ORDER_SIGNING_METHOD_SUCCESS:
            return success(action.payload);
        case OrderSigningMethodActionTypes.ORDER_SIGNING_METHOD_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
