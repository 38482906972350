import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderFormsCompleteActionTypes {
    static readonly ORDER_FORMS_COMPLETE = type('[Order Forms Complete] Order Forms Complete');
    static readonly ORDER_FORMS_COMPLETE_SUCCESS = type(
        '[Order Forms Complete] Order Forms Complete Success'
    );
    static readonly ORDER_FORMS_COMPLETE_FAILURE = type(
        '[Order Forms Complete] Order Forms Complete Failure'
    );
}

export type OrderFormsCompleteActions =
    | OrderFormsComplete
    | OrderFormsCompleteSuccess
    | OrderFormsCompleteFailure;

export class OrderFormsComplete implements AixAction {
    readonly type = OrderFormsCompleteActionTypes.ORDER_FORMS_COMPLETE;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderFormsCompleteSuccess implements AixAction {
    readonly type = OrderFormsCompleteActionTypes.ORDER_FORMS_COMPLETE_SUCCESS;
}

export class OrderFormsCompleteFailure implements AixAction {
    readonly type = OrderFormsCompleteActionTypes.ORDER_FORMS_COMPLETE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
