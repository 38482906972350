import { Component, input, model } from '@angular/core';

@Component({
    selector: 'aix-tab',
    templateUrl: 'aix-tab.component.html',
    styleUrls: ['aix-tab.component.scss'],
    standalone: true
})
export class AixTabComponent {
    title = input<string>();
    active = model(false);
}
