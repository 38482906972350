import { Component, input, output } from '@angular/core';
import { ProductsFilterType } from '@trade-platform/ui-shared';
import { NgFor } from '@angular/common';
import { AixCheckboxPanelComponent, AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-filter-sidebar',
    templateUrl: './filter-sidebar.component.html',
    styleUrls: ['./filter-sidebar.component.scss'],
    standalone: true,
    imports: [AixDataTestingDirective, NgFor, AixCheckboxPanelComponent]
})
export class AixFilterSidebarComponent {
    activeFilter = input<any>();
    queues = input<any>();
    numberShowList = input<number>(7);

    removeFilters = output();
    selectFilter = output<{ filter: any; key: ProductsFilterType }>();

    selectAllProducts() {
        this.removeFilters.emit();
    }

    setFilter(event: any, keySelect: ProductsFilterType) {
        this.selectFilter.emit({ filter: event, key: keySelect });
    }
}
