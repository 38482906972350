@if (modalShow) {
<div class="aix-bg" [style.z-index]="modalZIndex()">
    <div class="aix-modal" [ngStyle]="{ width: modalWidth }">
        <div class="aix-modal-content">
            <div class="aix-modal-title-container">
                <h2 class="aix-modal-title" [data-testing]="'modal-title'">{{ modalTitle() }}</h2>
                @if (closeable()) {
                <span
                    class="fa-regular fa-xmark aix-modal-close-button"
                    [data-testing]="'modal-close-button'"
                    (click)="closeModal()"
                ></span>
                }
            </div>

            <div
                class="u-mt14"
                [class.u-mb24]="buttonOptions() && buttonOptions().length"
                [data-testing]="'modal-message'"
            >
                <ng-container *ngTemplateOutlet="modalTemplate()"></ng-container>
            </div>

            @if (buttonOptions()) {
            <div class="aix-toolbar">
                @for (button of buttonOptions(); track button) {
                <aix-button
                    [attr.data-testing]="button"
                    [isDisabled]="buttonDisableFunction()(button)"
                    (click)="buttonClicked(button)"
                    [buttonLabel]="button"
                    [buttonType]="buttonClasses(button) ? primaryButtonType : linkButtonType"
                >
                </aix-button>
                }
            </div>
            }
        </div>
    </div>
</div>
}
