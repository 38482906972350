import { JsonDecoder } from 'ts.data.json';
import { Firm } from '../../models/firm/model';

// Firm

export const firmDecoder = JsonDecoder.object<Firm>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.optional(JsonDecoder.string),
        companyName: JsonDecoder.optional(JsonDecoder.string),
        addressLine1: JsonDecoder.optional(JsonDecoder.string),
        addressLine2: JsonDecoder.optional(JsonDecoder.string),
        city: JsonDecoder.optional(JsonDecoder.string),
        state: JsonDecoder.optional(JsonDecoder.string),
        zip: JsonDecoder.optional(JsonDecoder.string),
        phone1: JsonDecoder.optional(JsonDecoder.string),
        phone2: JsonDecoder.optional(JsonDecoder.string),
        firmType: JsonDecoder.optional(JsonDecoder.string),
        aiiFirmId: JsonDecoder.optional(JsonDecoder.string),
        crdnNumber: JsonDecoder.optional(JsonDecoder.string)
    },
    'Firm'
);
