import { fromEventPattern, of } from 'rxjs';
import { EventObject, interpret, Machine, MachineOptions, State } from 'xstate';
import { Inject, Injectable } from '@angular/core';
import { loginMachineConfig } from './login-machine.config';
import { LoginContext } from './login-machine.schema';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
    HasNoOutages,
    HasOutages,
    IgnoreOutages,
    LoadProfileFailure,
    LoadTokenFailure,
    LoadTokenSuccess,
    LoginEvent,
    Logout,
    TermsAccepted,
    TermsNotAccepted
} from './login-machine.events';
import {
    AddFlashMessageAction,
    AixEffectActions,
    AixErrorAction,
    ApplyBrandingAction,
    AppState,
    CloseByButtonBehaviour,
    ErrorWrapper,
    FlashMessageType,
    LoadAuthFromStorageAction,
    LoadProfileAction,
    LoadProfileFailAction,
    LoadProfileFromStorageAction,
    LoadProfileSuccessAction,
    LoadTermsAction,
    Profile,
    UpdateProfileSuccessAction
} from '@trade-platform/ui-shared';
import { ENVIRONMENT, getFromStorage, IEnvironment, LogService } from '@trade-platform/ui-utils';
import { Logger } from 'typescript-logging';
import * as Sentry from '@sentry/browser';
import { Store } from '@ngrx/store';
import { v4 as uuid } from 'uuid';
import { AixAuthService } from '../services/auth-service';
import { AixLoginApiService } from '../services/login-api-service';
import { routeConstants } from '../routing/constants';
import { getOutagesForTenant } from '../misc/has-outages';

@Injectable()
export class LoginMachine {
    readonly LOG: Logger;

    loginMachineOptions: Partial<MachineOptions<LoginContext, LoginEvent>> = {
        services: {
            fetchToken: async () => {
                this.LOG.info('parsing hash...');
                return new Promise((resolve, reject) => {
                    this.authService
                        .parseHash()
                        .then(() => {
                            resolve(new LoadTokenSuccess());
                        })
                        .catch(error => {
                            reject(new LoadTokenFailure(error));
                        });
                });
            },
            fetchOutages: (ctx, event) => {
                this.LOG.info('requesting outages from api...');
                if (this.environment.ignoreOutages) {
                    return of(new IgnoreOutages());
                } else {
                    return this.apiService.outagesService.getOutages().pipe(
                        switchMap(outages => {
                            if (getOutagesForTenant(outages, true).length > 0) {
                                return of(new HasOutages());
                            } else {
                                return of(new HasNoOutages());
                            }
                        }),
                        catchError(error => {
                            this.LOG.error('Get outages call failed.', error);
                            return of(new HasNoOutages());
                        })
                    );
                }
            },
            fetchProfile: (ctx, event) => {
                this.LOG.info('requesting profile from api...');
                this.store.dispatch(new LoadProfileAction());
                return this.actions$
                    .ofClass<UpdateProfileSuccessAction>(UpdateProfileSuccessAction)
                    .pipe(
                        map(action => {
                            const profile: Profile = action.payload;

                            this.store.dispatch(new ApplyBrandingAction());
                            this.store.dispatch(new LoadProfileSuccessAction(profile));

                            if (profile.acceptedTerms) {
                                return new TermsAccepted();
                            } else {
                                this.store.dispatch(new LoadTermsAction());
                                return new TermsNotAccepted();
                            }
                        }),
                        catchError(error => {
                            this.store.dispatch(new LoadProfileFailAction(error));
                            return of(new LoadProfileFailure(error));
                        })
                    );
            }
        },
        guards: {
            isLoggedInAuth0: () => this.authService.isLoggedInAuth0(),
            isLoggedInApp: () => this.authService.isLoggedInApp(),
            isLoginSuccessful: () => this.authService.isLoginSuccessful(),
            isLoginFailure: () => this.authService.isLoginFailure()
        },
        actions: {
            goToOutage: (ctx, event) => {
                this.LOG.info('navigating to outages');
                this.router.navigate(['/outage']);
            },
            goToTerms: (ctx, event) => {
                this.LOG.info('navigating to terms');
                if (!/end-user-access-agreement/.test(this.router.url)) {
                    this.router.navigate(routeConstants.routes.terms.index());
                }
            },
            goToLoading: (ctx, event) => {
                this.LOG.info('navigating to loading');
                this.router.navigate(['/']);
            },
            login: (ctx, event) => {
                this.LOG.info(() => `navigating to login...event is ${JSON.stringify(event)}`);
                let urlAfterLogin = null;
                if (event instanceof Logout) {
                    urlAfterLogin = event.urlAfterLogin;
                }
                this.authService.logIn(urlAfterLogin as string);
            },
            loginSSO: (ctx, event) => {
                this.LOG.info(() => `navigating to login SSO...event is ${JSON.stringify(event)}`);
                this.authService.logInSSO();
            },
            register: (ctx, event) => {
                this.LOG.info(() => `entering in register...event is ${JSON.stringify(event)}`);
                if (!/register/.test(this.router.url)) {
                    this.router.navigate(routeConstants.routes.register.index());
                }
            },
            logout: (ctx, event) => {
                this.LOG.info(() => `navigating to logout...event is ${JSON.stringify(event)}`);
                let urlAfterLogin = null;
                if (event instanceof Logout && event.urlAfterLogin) {
                    urlAfterLogin = event.urlAfterLogin;
                }
                this.authService.logOut(urlAfterLogin as string);
            },
            onAlreadyLoggedIn: (ctx, event) => {
                this.LOG.info('Already logged in');
                this.store.dispatch(new LoadProfileFromStorageAction());
                this.store.dispatch(new LoadAuthFromStorageAction());
                const profile = getFromStorage('profile') as Profile;
                this.authService.setUserID(profile);
                Sentry.getCurrentScope().setUser({
                    email: profile.email,
                    username: profile.fullName
                });
            },
            onLoginSuccessful: (ctx, event) => {
                this.LOG.info('Logged in successfully');
            },
            onLoginFailure: (ctx, event) => {
                this.LOG.info(() => `Log in error: ${this.authService.authError.errorDescription}`);
                this.store.dispatch(
                    new AixErrorAction({
                        error: new ErrorWrapper(
                            `LOGIN MACHINE -- In onLoginFailure in login-machine: ${this.authService.authError.errorDescription}`
                        )
                    })
                );
                this.authService.unloadData();
                this.store.dispatch(
                    new AddFlashMessageAction({
                        message: {
                            uid: uuid(),
                            text: 'Error login: ' + this.authService.authError.errorDescription,
                            type: FlashMessageType.ERROR,
                            closeBehaviour: new CloseByButtonBehaviour()
                        }
                    })
                );
            },
            onNoLoginAttempt: (ctx, event) => {
                this.LOG.info('No log in attempt');
                this.authService.unloadData();
                this.authService.callLogout(true);
            },
            applyBranding: (ctx, event) => {
                this.store.dispatch(new ApplyBrandingAction());
            },
            redirectAfterLogin: (ctx, event) => {
                this.authService.redirectOnLogin();
            },
            printError: (ctx, event: any) => {
                this.LOG.error(() => `Error is ${JSON.stringify(event.errors)}`);
            }
        }
    };

    private _loginMachine = Machine(loginMachineConfig).withConfig(this.loginMachineOptions);
    private service = interpret(this._loginMachine, { devTools: true }).start();

    loginState$ = fromEventPattern<[State<LoginContext, LoginEvent>, EventObject]>(
        handler => {
            return this.service.onTransition(handler);
        },
        (_, service) => service.stop()
    ).pipe(map(([state, _]) => state));

    send(event: LoginEvent) {
        this.service.send(event);
    }

    constructor(
        public authService: AixAuthService,
        public apiService: AixLoginApiService,
        public router: Router,
        public store: Store<AppState>,
        public logService: LogService,
        private actions$: AixEffectActions,
        @Inject(ENVIRONMENT) private readonly environment: IEnvironment
    ) {
        this.LOG = this.logService.getLogger('xstate.services.login-machine.service');
    }
}
