import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderCancelReviewActionTypes {
    static readonly ORDER_CANCEL_REVIEW = type('[Order Cancel Review] Order Cancel Review');
    static readonly ORDER_CANCEL_REVIEW_SUCCESS = type(
        '[Order Cancel Review] Order Cancel Review Success'
    );
    static readonly ORDER_CANCEL_REVIEW_FAILURE = type(
        '[Order Cancel Review] Order Cancel Review Failure'
    );
}

export type OrderCancelReviewActions =
    | OrderCancelReview
    | OrderCancelReviewSuccess
    | OrderCancelReviewFailure;

export class OrderCancelReview implements AixAction {
    readonly type = OrderCancelReviewActionTypes.ORDER_CANCEL_REVIEW;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderCancelReviewSuccess implements AixAction {
    readonly type = OrderCancelReviewActionTypes.ORDER_CANCEL_REVIEW_SUCCESS;
}

export class OrderCancelReviewFailure implements AixAction {
    readonly type = OrderCancelReviewActionTypes.ORDER_CANCEL_REVIEW_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
