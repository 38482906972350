import { ApiFormError } from '@trade-platform/ui-utils';
import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { DocumentTypeActionTypes, DocumentTypesActions } from './actions';
import { ErrorWrapper, SupplementalFile } from '@trade-platform/ui-shared';

export type DocumentTypesState = RemoteData<SupplementalFile[], ErrorWrapper | ApiFormError>;

export function createDocumentTypeReducer(
    state: DocumentTypesState = notAsked(),
    action: DocumentTypesActions
): DocumentTypesState {
    switch (action.type) {
        case DocumentTypeActionTypes.LOAD_DOCUMENT_TYPES:
            return inProgress();
        case DocumentTypeActionTypes.LOAD_DOCUMENT_TYPES_SUCCESS:
            return success(action.payload);
        case DocumentTypeActionTypes.LOAD_DOCUMENT_TYPES_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
