import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderReinstateResponse, orderReinstateResponseDecoder } from '@trade-platform/ui-shared';

@Injectable()
export class OrderReinstateService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderReinstate(orderId: string): Observable<OrderReinstateResponse> {
        return this.http
            .post<OrderReinstateResponse>(`${this.environment.api}orders/${orderId}/reinstate`, {
                id: orderId
            })
            .pipe(concatMap(p => fromPromise(orderReinstateResponseDecoder.decodeToPromise(p))));
    }
}
