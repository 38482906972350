import { JsonDecoder } from 'ts.data.json';
import { Address } from '../../models/user/model';

export const addressDecoder = JsonDecoder.object<Address>(
    {
        addressStreet: JsonDecoder.optional(JsonDecoder.string),
        addressState: JsonDecoder.optional(JsonDecoder.string),
        addressCity: JsonDecoder.optional(JsonDecoder.string),
        addressZip: JsonDecoder.optional(JsonDecoder.string)
    },
    'Address'
);
