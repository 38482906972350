import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorWrapper, getErrorText } from '@trade-platform/ui-shared';
import {
    OrderDocusignSend,
    OrderDocusignSendActionTypes,
    OrderDocusignSendFailure,
    OrderDocusignSendSuccess
} from './actions';
import { OrderDocusignSendService } from './service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class OrderDocusignSendEffects {
    constructor(
        private actions$: Actions,
        private orderDocusignSendService: OrderDocusignSendService
    ) {}

    orderDocusignSend$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderDocusignSend>(OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND),
            switchMap(action =>
                this.orderDocusignSendService
                    .orderDocusignSend({
                        orderId: action.payload.orderId,
                        emailSubject: action.payload.emailSubject,
                        emailBody: action.payload.emailBody
                    })
                    .pipe(
                        map(res => new OrderDocusignSendSuccess()),
                        catchError((error: string | HttpErrorResponse) => {
                            let err;
                            if (error instanceof HttpErrorResponse) {
                                if (error.headers.has('request-id')) {
                                    if (
                                        error.status === 422 &&
                                        error.error?.message.includes('duplicate')
                                    ) {
                                        err = new ErrorWrapper(error.message, {
                                            errorText: `<p>Something went wrong. Please check that each signing party has a unique name and email address. If the problem persists, contact <a href="mailto:support@aixplatform.com">support@aixplatform.com</a> and include this issue code so that we can help resolve the problem as quickly as possible.<br/>
                                            <b>Issue Code:</b>&nbsp;${error.headers.get(
                                                'request-id'
                                            )}.</p>`
                                        });
                                    } else {
                                        err = new ErrorWrapper(error.message, {
                                            errorText: `<p>Something went wrong. Please contact <a href="mailto:support@aixplatform.com">support@aixplatform.com</a> and include this issue code so that we can help resolve the problem as quickly as possible.<br/>
                                            <b>Issue Code:</b>&nbsp;${error.headers.get(
                                                'request-id'
                                            )}.</p>`
                                        });
                                    }
                                } else {
                                    err = new ErrorWrapper(error.message, {
                                        errorText: getErrorText(error)
                                    });
                                }
                            } else {
                                err = new ErrorWrapper(error);
                            }
                            return of(new OrderDocusignSendFailure({ error: err }));
                        })
                    )
            )
        )
    );
}
