import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderTakeOfflineActionTypes {
    static readonly ORDER_TAKE_OFFLINE = type('[Order Take Offline] Take Offline');
    static readonly ORDER_TAKE_OFFLINE_SUCCESS = type('[Order Take Offline] Take Offline Success');
    static readonly ORDER_TAKE_OFFLINE_FAILURE = type('[Order Take Offline] Take Offline Failure');
}

export type OrderTakeOfflineActions =
    | OrderTakeOffline
    | OrderTakeOfflineSuccess
    | OrderTakeOfflineFailure;

export class OrderTakeOffline implements AixAction {
    readonly type = OrderTakeOfflineActionTypes.ORDER_TAKE_OFFLINE;

    constructor(public payload: OrderIdPayload) {}
}
export class OrderTakeOfflineSuccess implements AixAction {
    readonly type = OrderTakeOfflineActionTypes.ORDER_TAKE_OFFLINE_SUCCESS;

    constructor(public payload: null) {}
}
export class OrderTakeOfflineFailure implements AixAction {
    readonly type = OrderTakeOfflineActionTypes.ORDER_TAKE_OFFLINE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
