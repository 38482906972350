import { Pipe, PipeTransform } from '@angular/core';
import { createSeparatorMask, unMaskSeparatorValue } from '@trade-platform/ui-utils';

@Pipe({
    name: 'aixZip',
    standalone: true
})
export class AixZipPipe implements PipeTransform {
    private readonly ZIP_MAX_LENGTH = 9;
    private mask = createSeparatorMask([5], this.ZIP_MAX_LENGTH, '-');

    transform(zip: string) {
        return this.mask(unMaskSeparatorValue(zip) as string);
    }
}
