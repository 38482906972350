import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgIf, NgStyle, NgClass, NgFor } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-step-progress',
    templateUrl: './step-progress.html',
    styleUrls: ['./step-progress.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, AixDataTestingDirective, NgFor]
})
export class AixStepProgressComponent implements OnInit {
    maxN = 0;
    progressN = 0;
    percentage = 0;
    wordWrapText: string[] = ['Complete'];

    @Input() showTotal = true;

    @Input()
    set progress(progress: number) {
        this.progressN = Math.floor(progress);
        this.updateProgress();
    }
    @Input()
    set maxSteps(max: number) {
        this.maxN = max;
        this.updateProgress();
    }
    @Input()
    set label(l: string) {
        this.wordWrapText = l.split(' ');
    }

    @Input()
    type: 'linear' | 'circular' = 'linear';
    @Input()
    sqSize = 120;
    @Input()
    strokeLinecap: 'round' | 'butt' = 'round';
    @Input()
    strokeWidth = 8;
    @Input()
    strokeColor: string | null = null;
    @Input()
    fontSize = 24;
    @Input()
    subtext = true;

    innerStrokeWidth: number;
    radius: number;
    viewBox: string;
    dashArray: number;
    dashOffset: number;
    stepX: number;
    stepY: number;

    constructor() {}

    ngOnInit() {
        this.updateProgress();
    }

    updateProgress() {
        this.percentage = (this.progressN * 100) / this.maxN;

        this.innerStrokeWidth = this.strokeWidth;
        this.radius = (this.sqSize - this.strokeWidth) / 2;
        this.viewBox = `0 0 ${this.sqSize} ${this.sqSize}`;
        this.dashArray = this.radius * Math.PI * 2;
        this.dashOffset = this.dashArray - (this.dashArray * this.percentage) / 100;
        this.stepY = this.subtext
            ? Math.ceil(this.sqSize / 2 + this.fontSize / 4.1667)
            : Math.ceil(this.sqSize / 2 + this.fontSize / 4.1667) + this.fontSize;
        this.stepX = this.stepY - 2;
    }
}
