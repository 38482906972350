<aix-header-section [title]="'Map Signatures'" [data-testing]="'map-signatures-header'">
    <aix-button
        (click)="cancelMapSignatures.emit()"
        [data-testing]="'cancel-mapping'"
        [buttonType]="cancelMappingButtonType"
        [buttonLabel]="'Cancel Mapping'"
    >
    </aix-button>
</aix-header-section>

<p>
    Prior to sending for eSignature, signature fields will need to be added to any forms that were
    completed outside of the platform.
</p>
<p>
    Please ensure all information is finalized before proceeding. Any changes to the documents will
    result in signature fields needing to be re-mapped.
</p>

<aix-formlist
    [filterBy]="isFormNotOnboarded"
    [forms]="(storeFacade.orderSuccess$ | async)?.forms"
    [clickable]="true"
    [includeRemove]="false"
    (clickElem)="goToForm($event)"
></aix-formlist>

<div class="u-flex flex-right-top u-mt16">
    <aix-button
        (click)="submitForm.emit()"
        [data-testing]="'start-mapping'"
        [buttonType]="startMappingButtonType"
        [buttonLabel]="'Map Signatures'"
    >
    </aix-button>
</div>
