import { isComplexObject } from '@trade-platform/ui-utils';
import { JsonDecoder } from 'ts.data.json';
import { OrderForm, OrderFormRecord } from '../../models/order-form/model';

const flattenMap = (map: { [refId: string]: Record<string, unknown> }) => {
    return Object.keys(map).reduce((acc, key) => {
        acc[key] =
            isComplexObject(map[key]) && map[key].hasOwnProperty('path') ? map[key].path : map[key];
        return acc;
    }, {} as any);
};

export const orderFormDecoder = JsonDecoder.object<OrderForm>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.string,
        status: JsonDecoder.string,
        index: JsonDecoder.number,
        fields: JsonDecoder.succeed,
        map: JsonDecoder.succeed.map(flattenMap),
        documentOwner: JsonDecoder.optional(JsonDecoder.string),
        isOnboarded: JsonDecoder.optional(JsonDecoder.boolean).map(isOnboarded =>
            isOnboarded === undefined ? true : isOnboarded
        ),
        isRequired: JsonDecoder.optional(JsonDecoder.boolean).map(isRequired =>
            isRequired === undefined ? true : isRequired
        ),
        isOpen: JsonDecoder.optional(JsonDecoder.boolean).map(isOnboarded =>
            isOnboarded === undefined ? true : isOnboarded
        ),
        noSignatures: JsonDecoder.optional(JsonDecoder.boolean),
        file: JsonDecoder.optional(JsonDecoder.succeed)
    },
    'OrderForm'
);

export const orderFormRecordDecoder = JsonDecoder.object<OrderFormRecord>(
    {
        id: JsonDecoder.string,
        documentOwner: JsonDecoder.string,
        name: JsonDecoder.string,
        fields: JsonDecoder.succeed
    },
    'OrderFormRecord'
);
