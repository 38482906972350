import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderMarkNigoResponse, orderMarkNigoResponseDecoder } from '@trade-platform/ui-shared';

@Injectable()
export class OrderNigoService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    markOrderNigo(orderId: string): Observable<OrderMarkNigoResponse> {
        return this.http
            .post<OrderMarkNigoResponse>(`${this.environment.api}orders/${orderId}/nigo`, {
                id: orderId
            })
            .pipe(concatMap(p => fromPromise(orderMarkNigoResponseDecoder.decodeToPromise(p))));
    }
}
