import { ComponentRef, Directive, effect, input, Type, ViewContainerRef } from '@angular/core';
import { DefaultDropdownCellrendererComponent } from './default-dropdown.cellrenderer';
import { WrappedOption } from './dropdown';

export interface DropdownCellrenderer {
    option: WrappedOption;
}

@Directive({
    selector: '[aixDropdownCellrenderer]',
    standalone: true
})
export class DropdownCellrendererDirective {
    option = input<WrappedOption>();
    cellRenderer = input<Type<DropdownCellrenderer>>();

    component: ComponentRef<DropdownCellrenderer>;

    constructor(private container: ViewContainerRef) {
        effect(() => {
            const cellRenderer = this.cellRenderer() || DefaultDropdownCellrendererComponent;
            this.component = this.container.createComponent(cellRenderer);
            this.component.instance.option = <WrappedOption>this.option();
        });
    }
}
