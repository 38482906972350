import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FieldDecoration } from '@trade-platform/dynamic-forms';
import { IsInProgressPipe, RemoteData } from 'ngx-remotedata';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'aixRemoteDataInProgressFieldDecoration',
    template: `
        <span>
            @if (showProgress) {
            <i class="fa-solid fa-spinner fa-spin"></i>
            }
        </span>
    `,
    standalone: true,
    imports: [NgIf]
})
export class RemoteDataInProgressFieldDecorationComponent
    implements OnInit, OnDestroy, FieldDecoration
{
    params: { storeName: string };
    hostObservables: FieldDecoration['hostObservables'];

    showProgress = false;
    private state$: Observable<RemoteData<any, any>>;
    private subscriptions: Subscription[] = [];
    static readonly REF = 'RemoteDataInProgressFieldDecoration';

    constructor(private store: Store<any>, private cd: ChangeDetectorRef) {
        this.cd.detach();
    }

    ngOnInit(): void {
        this.initDecoration();
    }

    initDecoration() {
        const storeName = this.params.storeName.split('.') as [string];
        this.state$ = this.store.pipe(select(...storeName));
        this.subscriptions.push(
            combineLatest([this.state$, this.hostObservables.hasFocus]).subscribe(
                ([data, hasFocus]) => {
                    // joanllenas: For some reason `state$ | async | isInProgress` doesn't work
                    //             consistently, so I have done it manually via pipe instance.
                    this.showProgress = hasFocus && new IsInProgressPipe().transform(data);
                    this.cd.detectChanges();
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
