import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { createNumericalMask, stringIsNotEmpty, unMaskFloatValue } from '@trade-platform/ui-utils';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, first, map, withLatestFrom } from 'rxjs/operators';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { CurrencyFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import {
    DynamicFormControlSetDirtyAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { DynamicFormStore } from '../../dynamic-form-store';
import { handleServerValdationErrors } from '../../dynamic-form.utils';
import { AixFormErrorsPipe } from '../../../pipes/form-errors';
import { AixCurrencyComponent, AixDataTestingDirective } from '@trade-platform/ui-components';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'aix-dynamic-currency',
    styleUrls: ['./currency.component.scss'],
    templateUrl: './currency.component.html',
    standalone: true,
    imports: [
        AixCurrencyComponent,
        AixDynamicNextPendingFieldDirective,
        NgIf,
        AixDataTestingDirective,
        AixFormErrorsPipe
    ]
})
export class AixDynamicCurrencyComponent implements Field, OnInit, OnDestroy {
    static HOST_CLASS = 'aix-flex-grid__col';

    @HostBinding('class')
    classNames = AixDynamicCurrencyComponent.HOST_CLASS;

    @ViewChild('inputField', { read: ElementRef, static: true })
    inputField: ElementRef;

    @HostBinding('attr.aix-control')
    aixControl: string;

    // Store Data
    templateData = {
        fieldIsDisabled: false,
        fieldIsRequired: false,
        fieldIsDirty: false,
        ctrlIsInvalid: false,
        ctrlHasRequiredError: false,
        canDisplayErrors: false,
        ctrlErrors: null as ValidationErrors | null
    };
    userInputEmitter$ = new Subject<string>();
    debouncer = new Subject<string>();

    // Other
    config: CurrencyFieldConfig;
    private subscriptions: Subscription[] = [];

    value: string | null;

    private mask: ReturnType<typeof createNumericalMask>;
    private isTyping = false;

    constructor(
        private helper: DynamicFormHelper,
        private store: Store<any>,
        private formStore: DynamicFormStore,
        private elemRef: ElementRef,
        private cd: ChangeDetectorRef
    ) {
        this.cd.detach();

        const formUID = this.formStore.formUID;

        // User input
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(value => {
                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        value
                    )
                );

                // Store Actions
                this.helper.dispatchStoreActions(this.store, this.config, value);
            }),
            this.debouncer
                .pipe(debounceTime(this.helper.INPUT_DEBOUNCE))
                .subscribe(value => this.userInputEmitter$.next(value))
        );
    }

    ngOnInit() {
        this.mask = createNumericalMask(
            '$',
            'start',
            this.config.allowDecimal !== false
                ? (this.config.decimalLimit || 0) > 0
                    ? this.config.decimalLimit
                    : 2
                : 0
        );

        this.classNames = this.calculateClassNames();

        const refId = this.config.refId as string;

        // Control initialization
        this.aixControl = refId;
        this.formStore.addControl(this.config);

        // Store Control Observables
        const ctrlStore$ = this.formStore.getControlStoreByRefId(refId);
        const valueToRender$ = this.formStore
            .getDataStore<string>(getDataPathByFieldConfig(this.config))
            .pipe(
                map(raw => {
                    if (raw === null || raw === undefined) {
                        return null;
                    }

                    const result: string[] = ('' + raw).split('');
                    const negative = this.config.allowNegative && result[0] === '-';
                    const val = negative
                        ? ['-'].concat(this.mask(unMaskFloatValue(raw))).join('')
                        : this.mask(unMaskFloatValue(raw));

                    return val;
                })
            );

        this.subscriptions.push(
            ctrlStore$
                .pipe(auditTime(50), withLatestFrom(valueToRender$))
                .subscribe(([ctrl, valueToRender]) => {
                    if (!this.isTyping) {
                        // update input by calculated expressions, patch values or data loads from store actions
                        this.valueToSend(valueToRender);
                    }

                    // fieldIsDisabled
                    this.templateData.fieldIsDisabled =
                        ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                    // fieldIsRequired
                    const hasRequiredValidator = (
                        ctrl.fieldConfig.validation as ValidatorFn[]
                    ).some(val => val === Validators.required);
                    this.templateData.fieldIsRequired =
                        ctrl.requiredByRelation || hasRequiredValidator;

                    // fieldIsDirty
                    this.templateData.fieldIsDirty = ctrl.isDirty;

                    // ctrlErrors
                    this.templateData.ctrlErrors = ctrl.validation;

                    // ctrlHasRequiredError
                    this.templateData.ctrlHasRequiredError =
                        ctrl.validation && ctrl.validation['required'];

                    // ctrlIsInvalid
                    this.templateData.ctrlIsInvalid = ctrl.validation !== null;

                    // canDisplayErrors
                    const hasNonRequiredErrors =
                        !!ctrl.validation &&
                        Object.keys(ctrl.validation).some(key => key !== 'required');
                    this.templateData.canDisplayErrors =
                        (ctrl.validation !== null && ctrl.isDirty) || hasNonRequiredErrors;

                    // Server Validation errors
                    handleServerValdationErrors(ctrl, evt =>
                        (this.elemRef.nativeElement as HTMLElement).dispatchEvent(evt)
                    );

                    this.cd.detectChanges();

                    this.isTyping = false;
                })
        );

        // Change Notifications
        this.subscriptions.push(
            combineLatest([
                valueToRender$.pipe(
                    first(),
                    map(rawValue => this.valueToSend(rawValue))
                ),
                this.userInputEmitter$
            ])
                .pipe(
                    filter(
                        ([defaultValue, userInput]) =>
                            stringIsNotEmpty(defaultValue) && defaultValue !== userInput
                    )
                )
                .subscribe(_ => {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                })
        );

        // On Load Store Actions
        this.subscriptions.push(
            valueToRender$
                .pipe(
                    first(),
                    map(rawValue => this.valueToSend(rawValue))
                )
                .subscribe(value =>
                    this.helper.dispatchOnLoadStoreActions(this.store, this.config, value)
                )
        );

        this.cd.detectChanges();
    }

    valueChange(value: string) {
        this.isTyping = true;
        this.value = value;
        this.debouncer.next(value);
    }

    setDirty() {
        this.store.dispatch(
            new DynamicFormControlSetDirtyAction(
                this.formStore.formUID,
                this.config.refId as string
            )
        );
    }

    valueToSend(rawValue: string | null) {
        this.value = rawValue;
        return rawValue;
    }

    calculateClassNames() {
        let classNames = this.config.classNames
            ? [
                  AixDynamicCurrencyComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicCurrencyComponent.HOST_CLASS;
        classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;
        return classNames;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeControl(this.config);
    }
}
