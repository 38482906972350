import { JsonDecoder } from 'ts.data.json';
import { OrderReinstateResponse } from '../../models/order-reinstate/model';

export const orderReinstateResponseDecoder = JsonDecoder.object<OrderReinstateResponse>(
    {
        id: JsonDecoder.string,
        status: JsonDecoder.string
    },
    'OrderReinstateResponse'
);
