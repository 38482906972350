import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AixFootnoteService } from './aix-footnote.service';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-footnotes',
    templateUrl: './aix-footnotes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, AsyncPipe]
})
export class AixFootnotesContainerComponent {
    constructor(public footnoteService: AixFootnoteService) {}

    returnToFootnote(footnoteId: string) {
        const el = document.getElementById('aix-footnote-' + footnoteId);
        if (el !== null) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
