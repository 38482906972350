/**
 * Scrolls to the top of the page
 */
export const scrollToTop = (): void => {
    window.scrollTo(0, 0);
};

/**
 * Cross-browser keyCode access
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
 */
export const keyCode = (event: KeyboardEvent) =>
    event.key !== undefined ? event.key : event['keyCode'];

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
