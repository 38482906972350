import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import { LoadOutagesActions, LoadOutagesActionTypes } from './actions';
import { Outage } from '../../models/outages/model';

export type OutagesState = RemoteData<Outage[], ErrorWrapper>;
const initialState = notAsked<Outage[], ErrorWrapper>();

export function outagesReducer(
    state: OutagesState = initialState,
    action: LoadOutagesActions
): OutagesState {
    switch (action.type) {
        case LoadOutagesActionTypes.LOAD_OUTAGES:
            return inProgress([] as Outage[]);
        case LoadOutagesActionTypes.LOAD_OUTAGES_SUCCESS:
            return success(action.payload);
        case LoadOutagesActionTypes.LOAD_OUTAGES_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
