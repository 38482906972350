import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AixAction,
    AixEffectActions,
    AppState,
    getDataFromState,
    LoadFirmsFailAction,
    LoadFirmsSuccessAction,
    LoadProfileFailAction,
    LoadProfileSuccessAction,
    ProfilePasswordResetFailure,
    ProfilePasswordResetSuccess,
    ResetMFAFailure,
    ResetMFASuccess,
    SetProfileAction,
    UnloadProfileAction,
    UpdateProfileFailAction,
    UpdateProfileSuccessAction
} from '@trade-platform/ui-shared';
import { extractStreamValue } from '@trade-platform/ui-utils';

/**
 * Façade for all stores globally defined in the @trade-platform/ui-shared library
 */
@Injectable({ providedIn: 'root' })
export class ProfileStoreFacade {
    /**
     * Profile
     */
    readonly profileRemoteData$ = this.store.pipe(select(state => state.profile));
    readonly profileSuccess$ = getDataFromState(this.profileRemoteData$);

    get profile() {
        return extractStreamValue(this.profileSuccess$);
    }

    /**
     * Shared Firms
     */
    readonly sharedFirmsRemoteData$ = this.store.pipe(select(state => state.sharedFirms));

    readonly actions = {
        get: {
            success$: this.actions$.ofClass(LoadProfileSuccessAction),
            failure$: this.actions$.ofClass(LoadProfileFailAction)
        },
        update: {
            success$: this.actions$.ofClass(UpdateProfileSuccessAction),
            failure$: this.actions$.ofClass(UpdateProfileFailAction)
        },
        passwordReset: {
            success$: this.actions$.ofClass(ProfilePasswordResetSuccess),
            failure$: this.actions$.ofClass(ProfilePasswordResetFailure)
        },
        mfaReset: {
            success$: this.actions$.ofClass(ResetMFASuccess),
            failure$: this.actions$.ofClass(ResetMFAFailure)
        },
        unload: {
            success$: this.actions$.ofClass(UnloadProfileAction)
        },
        set: {
            success$: this.actions$.ofClass(SetProfileAction)
        },
        getSharedFirms: {
            success$: this.actions$.ofClass<LoadFirmsSuccessAction>(LoadFirmsSuccessAction),
            failure$: this.actions$.ofClass<LoadFirmsFailAction>(LoadFirmsFailAction)
        }
    };

    constructor(public store: Store<AppState>, public actions$: AixEffectActions) {}

    dispatch(action: AixAction) {
        this.store.dispatch(action);
    }
}
