import { AixAction, ErrorWrapper, LoadOrderFormPayload, type } from '@trade-platform/ui-shared';

export class LoadOrderFormActionTypes {
    static readonly LOAD_ORDER_FORM = type('[Order Form] Load Order Form');
    static readonly LOAD_ORDER_FORM_SUCCESS = type('[Order Form] Load Order Form Success');
    static readonly LOAD_ORDER_FORM_FAILURE = type('[Order Form] Load Order Form Failure');
}

export type LoadOrderFormActions = LoadOrderForm | LoadOrderFormSuccess | LoadOrderFormFailure;

export class LoadOrderForm implements AixAction {
    readonly type = LoadOrderFormActionTypes.LOAD_ORDER_FORM;

    constructor(public payload: LoadOrderFormPayload) {}
}

export class LoadOrderFormSuccess implements AixAction {
    readonly type = LoadOrderFormActionTypes.LOAD_ORDER_FORM_SUCCESS;

    constructor(public payload: { form: any }) {}
}

export class LoadOrderFormFailure implements AixAction {
    readonly type = LoadOrderFormActionTypes.LOAD_ORDER_FORM_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
