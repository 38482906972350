import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class OrderSigningCompleteService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderSigningComplete(orderId: string): Observable<any> {
        return this.http.post<any>(`${this.environment.api}orders/${orderId}/signingComplete`, {
            id: orderId
        });
    }
}
