import { JsonDecoder } from 'ts.data.json';
import { eSignDecoder } from '../order/decoder';
import { OrderResendResponse } from '../../models/order-resend/model';

export const orderResendResponseDecoder = JsonDecoder.object<OrderResendResponse>(
    {
        id: JsonDecoder.string,
        eSign: eSignDecoder
    },
    'OrderResendResponse'
);
