import {
    DocumentViewerInitializedPayload,
    DocumentViewerNotificationPayload,
    DocumentViewerSetActionPayload,
    DocumentViewerSetDocumentPayload,
    DocumentViewerSetFilePayload,
    DocumentViewerSetFilesPayload,
    DocumentViewerSetFormPayload,
    DocumentViewerSetPermissionsPayload,
    DocumentViewerSetTabPayload,
    DocumentViewerShowModalPayload
} from '../../models/document-viewer/model';
import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';

export class DocumentViewerActionTypes {
    static readonly RESET = type('[Document Viewer] Reset');
    static readonly SET_ACTIVE_TAB = type('[Document Viewer] Set Tab');
    static readonly SET_ACTIVE_FILE = type('[Document Viewer] Set File');
    static readonly SET_ACTIVE_FORM = type('[Document Viewer] Set Form');
    static readonly SET_FILES_TO_DISPLAY = type('[Document Viewer] Set Files To Display');
    static readonly SET_ACTIVE_DOCUMENT = type('[Document Viewer] Set Document');
    static readonly SET_OFFLINE = type('[Document Viewer] Set Offline');
    static readonly SET_ACTION = type('[Document Viewer] Set Action');
    static readonly SET_PERMISSIONS = type('[Document Viewer] Set Permissions');
    static readonly DOWNLOAD = type('[Document Viewer] Download');
    static readonly DOWNLOAD_ERROR = type('[Document Viewer] Download Error');
    static readonly SHOW_NOTIFICATION = type('[Document Viewer] Show Notification');
    static readonly HIDE_NOTIFICATION = type('[Document Viewer] Hide Notification');
    static readonly SET_INITIALIZED = type('[Document Viewer] Set Initialized');
    static readonly SHOW_MODAL = type('[Document Viewer] Show Modal');
    static readonly RETURN_TRADE_PACKAGE = type('[Document Viewer] Return Trade Package');
}

export type DocumentViewerActions =
    | DocumentViewerReset
    | DocumentViewerSetTab
    | DocumentViewerSetFile
    | DocumentViewerSetForm
    | DocumentViewerSetFiles
    | DocumentViewerSetDocument
    | DocumentViewerSetOffline
    | DocumentViewerSetAction
    | DocumentViewerSetPermissions
    | DocumentViewerDownload
    | DocumentViewerDownloadError
    | DocumentViewerShowNotification
    | DocumentViewerHideNotification
    | DocumentViewerSetInitialized
    | DocumentViewerShowModal
    | DocumentViewerReturnTradePackage;

export class DocumentViewerReset implements AixAction {
    readonly type = DocumentViewerActionTypes.RESET;

    constructor() {}
}

export class DocumentViewerSetTab implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_ACTIVE_TAB;

    constructor(public payload: DocumentViewerSetTabPayload) {}
}

export class DocumentViewerSetFile implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_ACTIVE_FILE;

    constructor(public payload: DocumentViewerSetFilePayload) {}
}

export class DocumentViewerSetForm implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_ACTIVE_FORM;

    constructor(public payload: DocumentViewerSetFormPayload) {}
}

export class DocumentViewerSetFiles implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_FILES_TO_DISPLAY;

    constructor(public payload: DocumentViewerSetFilesPayload) {}
}

export class DocumentViewerSetDocument implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_ACTIVE_DOCUMENT;

    constructor(public payload: DocumentViewerSetDocumentPayload) {}
}

export class DocumentViewerSetOffline implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_OFFLINE;

    constructor(public payload: { offline: boolean }) {}
}

export class DocumentViewerSetAction implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_ACTION;

    constructor(public payload: DocumentViewerSetActionPayload) {}
}

export class DocumentViewerSetPermissions implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_PERMISSIONS;

    constructor(public payload: DocumentViewerSetPermissionsPayload) {}
}

export class DocumentViewerDownload implements AixAction {
    readonly type = DocumentViewerActionTypes.DOWNLOAD;

    constructor(public payload: { downloading: { [key: string]: boolean } }) {}
}

export class DocumentViewerDownloadError implements AixAction {
    readonly type = DocumentViewerActionTypes.DOWNLOAD_ERROR;

    constructor(public payload: { downloadError: boolean }) {}
}

export class DocumentViewerShowNotification implements AixAction {
    readonly type = DocumentViewerActionTypes.SHOW_NOTIFICATION;

    constructor(public payload: DocumentViewerNotificationPayload) {}
}

export class DocumentViewerHideNotification implements AixAction {
    readonly type = DocumentViewerActionTypes.HIDE_NOTIFICATION;

    constructor() {}
}

export class DocumentViewerSetInitialized implements AixAction {
    readonly type = DocumentViewerActionTypes.SET_INITIALIZED;

    constructor(public payload: DocumentViewerInitializedPayload) {}
}

export class DocumentViewerShowModal implements AixAction {
    readonly type = DocumentViewerActionTypes.SHOW_MODAL;

    constructor(public payload: DocumentViewerShowModalPayload) {}
}

export class DocumentViewerReturnTradePackage implements AixAction {
    readonly type = DocumentViewerActionTypes.RETURN_TRADE_PACKAGE;

    constructor() {}
}
