import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, first, withLatestFrom } from 'rxjs/operators';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { InputFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { DynamicFormState } from '../../dynamic-form-store/model';
import { DynamicFormStore } from '../../dynamic-form-store';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import { stringIsNotEmpty } from '@trade-platform/ui-utils';
import {
    DynamicFormControlSetDirtyAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { AixFormErrorsPipe } from '../../../pipes/form-errors';
import { NgIf } from '@angular/common';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';
import { AixDataTestingDirective, AixTextareaComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-dynamic-textarea',
    styleUrls: ['./textarea.component.scss'],
    templateUrl: './textarea.component.html',
    standalone: true,
    imports: [
        AixTextareaComponent,
        AixDynamicNextPendingFieldDirective,
        NgIf,
        AixDataTestingDirective,
        AixFormErrorsPipe
    ]
})
export class AixDynamicTextareaComponent implements Field, OnInit, OnDestroy {
    // Static
    static HOST_CLASS = 'aix-flex-grid__col';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicTextareaComponent.HOST_CLASS;

    @ViewChild('inputField', { read: ElementRef, static: true })
    inputField: ElementRef;

    @HostBinding('attr.aix-control')
    aixControl: string;

    value: string;

    // Store Data
    templateData = {
        valueToRender: '',
        fieldIsDisabled: false,
        fieldIsRequired: false,
        fieldIsDirty: false,
        ctrlIsInvalid: false,
        ctrlHasRequiredError: false,
        canDisplayErrors: false,
        ctrlErrors: null as ValidationErrors | null
    };
    userInputEmitter$ = new Subject<string>();
    debouncer = new Subject<string>();

    // Other
    config: InputFieldConfig;
    private subscriptions: Subscription[] = [];

    constructor(
        private helper: DynamicFormHelper,
        private store: Store<Record<string, DynamicFormState>>,
        private formStore: DynamicFormStore,
        private elemRef: ElementRef,
        private cd: ChangeDetectorRef
    ) {
        this.cd.detach();

        const formUID = this.formStore.formUID;

        // User Input
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(value => {
                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        value
                    )
                );

                // Store Actions
                this.helper.dispatchStoreActions(this.store, this.config, value);
            }),
            this.debouncer
                .pipe(debounceTime(this.helper.INPUT_DEBOUNCE))
                .subscribe(value => this.userInputEmitter$.next(value))
        );
    }

    ngOnInit() {
        this.classNames = this.calculateClassNames();

        const refId = this.config.refId as string;

        // Control initialization
        this.aixControl = refId;
        this.formStore.addControl(this.config);

        // Store Control Observables
        const ctrlStore$ = this.formStore.getControlStoreByRefId(refId);
        const valueToRender$ = this.formStore.getDataStore<string>(
            getDataPathByFieldConfig(this.config)
        );

        this.subscriptions.push(
            ctrlStore$
                .pipe(auditTime(50), withLatestFrom(valueToRender$))
                .subscribe(([ctrl, valueToRender]) => {
                    // valueToRender
                    this.templateData.valueToRender = valueToRender;

                    // fieldIsDisabled
                    this.templateData.fieldIsDisabled =
                        ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                    // fieldIsRequired
                    const hasRequiredValidator = (
                        ctrl.fieldConfig.validation as ValidatorFn[]
                    ).some(val => val === Validators.required);
                    this.templateData.fieldIsRequired =
                        ctrl.requiredByRelation || hasRequiredValidator;

                    // fieldIsDirty
                    this.templateData.fieldIsDirty = ctrl.isDirty;

                    // ctrlErrors
                    this.templateData.ctrlErrors = ctrl.validation;

                    // ctrlHasRequiredError
                    this.templateData.ctrlHasRequiredError =
                        ctrl.validation && ctrl.validation['required'];

                    // ctrlIsInvalid
                    this.templateData.ctrlIsInvalid = ctrl.validation !== null;

                    // canDisplayErrors
                    const hasNonRequiredErrors =
                        !!ctrl.validation &&
                        Object.keys(ctrl.validation).some(key => key !== 'required');
                    this.templateData.canDisplayErrors =
                        (ctrl.validation !== null && ctrl.isDirty) || hasNonRequiredErrors;

                    this.cd.detectChanges();
                })
        );

        // Notifications
        this.subscriptions.push(
            combineLatest([valueToRender$.pipe(first()), this.userInputEmitter$])
                .pipe(
                    filter(
                        ([defaultValue, userInput]) =>
                            stringIsNotEmpty(defaultValue) && defaultValue !== userInput
                    )
                )
                .subscribe(_ => {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                })
        );

        // On Load Store Actions
        this.subscriptions.push(
            valueToRender$
                .pipe(first())
                .subscribe(value =>
                    this.helper.dispatchOnLoadStoreActions(this.store, this.config, value)
                )
        );

        this.cd.detectChanges();
    }

    valueChange(value: string) {
        this.value = value;
        this.debouncer.next(value);
    }

    setDirty() {
        this.store.dispatch(
            new DynamicFormControlSetDirtyAction(
                this.formStore.formUID,
                this.config.refId as string
            )
        );
    }

    private calculateClassNames() {
        let classNames = this.config.classNames
            ? [
                  AixDynamicTextareaComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicTextareaComponent.HOST_CLASS;
        classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;
        return classNames;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeControl(this.config);
    }
}
