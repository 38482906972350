import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { ProductFavorite } from '../../models/product-favorites/model';

export class ProductFavoritesActionTypes {
    static readonly UPDATE_PRODUCT_FAVORITES = type('[Product Favorites] Update Product Favorites');
    static readonly UPDATE_PRODUCT_FAVORITES_SUCCESS = type(
        '[Product Favorites] Update Product Favorites Success'
    );
    static readonly UPDATE_PRODUCT_FAVORITES_FAILURE = type(
        '[Product Favorites] Update Product Favorites Failure'
    );
}

export type ProductFavoritesActions =
    | UpdateProductFavoritesAction
    | UpdateProductFavoritesSuccessAction
    | UpdateProductFavoritesFailureAction;

export class UpdateProductFavoritesAction implements AixAction {
    readonly type = ProductFavoritesActionTypes.UPDATE_PRODUCT_FAVORITES;

    constructor(public id: number, public value: boolean | undefined) {}
}

export class UpdateProductFavoritesSuccessAction implements AixAction {
    readonly type = ProductFavoritesActionTypes.UPDATE_PRODUCT_FAVORITES_SUCCESS;

    constructor(public payload: ProductFavorite) {}
}

export class UpdateProductFavoritesFailureAction implements AixAction {
    readonly type = ProductFavoritesActionTypes.UPDATE_PRODUCT_FAVORITES_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
