<aix-modal
    #inactivityModal
    [closeable]="false"
    [modalTitle]="'Stay logged in?'"
    [modalZIndex]="7000"
    (optionSelected)="onInactivityModalOptionSelected($event)"
    (onOpenModal)="isModalOpen = true"
    (onCloseModal)="isModalOpen = false"
>
    <ng-template>
        <p class="u-fw500">{{ getModalTextLineOne() }}</p>
        <p class="u-mb16">{{ getModalTextLineTwo() }}</p>
    </ng-template>
</aix-modal>
