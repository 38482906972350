import { Firm } from '../models/firm/model';
import { FundSponsor } from '../models/fund-sponsor/model';
import { Profile, ProfileHoldingOption, UserTenantDataTypes } from '../models/profile/model';
import { entityType } from '@trade-platform/lib-enums';
import {
    DynamicFormComponent,
    getDataByPath,
    getFormControlStateByRefId
} from '@trade-platform/dynamic-forms';
import { FieldConfig } from '@trade-platform/form-fields';
import { SetFormDataAction } from '../store/form/actions';

export interface UserTenantData {
    type: UserTenantDataTypes;
    data: Firm | ProfileHoldingOption | FundSponsor;
}

export const getUserEntityData = (profile: Profile): UserTenantData | null => {
    if (profile && profile.organization) {
        switch (profile.organization.type) {
            case entityType.firm:
                return {
                    type: profile.organization.type,
                    data: profile.firm as Firm
                };
            case entityType.holdingOption:
                return {
                    type: profile.organization.type,
                    data: profile.holdingOption as ProfileHoldingOption
                };
            case entityType.fundSponsor:
                return {
                    type: profile.organization.type,
                    data: profile.fundSponsor as FundSponsor
                };
        }
    }
    return null;
};

export const computeSetFormDataAction = (
    dynamicFormRef: DynamicFormComponent,
    action: SetFormDataAction
) => {
    const fieldConfig = {
        ...getFormControlStateByRefId(
            dynamicFormRef.store,
            dynamicFormRef.formStore.formUID,
            action.payload.refId
        ).fieldConfig,
        relations: [
            {
                action: 'SET_DATA',
                connective: action.payload.connective || 'AND',
                when: action.payload.when
            }
        ]
    };
    const relationResult = dynamicFormRef.relationsManager.fieldToRelations(
        fieldConfig as FieldConfig,
        'SET_DATA'
    );
    if (relationResult) {
        if (action.payload.newValue) {
            dynamicFormRef.patchValue(action.payload.newValue);
        } else if (action.payload.params?.storePath) {
            const groupName = fieldConfig.group
                ? `${fieldConfig.group}.${fieldConfig.name}`
                : fieldConfig.name;
            const value: any = getDataByPath(
                dynamicFormRef.store,
                dynamicFormRef.formStore.formUID,
                groupName
            );
            const paths: string[] = ['order', ...action.payload.params.storePath.split('.')];
            const patchValue =
                action.payload.params.actionValue === 'passthrough'
                    ? value
                    : action.payload.params.actionValue[value];

            const order: any = paths.reduceRight(
                (order: any, path, index) => ({
                    [path]: index === paths.length - 1 ? patchValue : order
                }),
                { order: {} }
            );

            dynamicFormRef.patchValue(order);
        }
    }
};
