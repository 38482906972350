import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import { PrivacyPolicyActions, PrivacyPolicyActionTypes } from './actions';
import { PrivacyPolicy } from '../../models/privacy-policy/model';

export type PrivacyPolicyState = RemoteData<PrivacyPolicy, ErrorWrapper>;

export function privacyPolicyReducer(
    state: PrivacyPolicyState = notAsked(),
    action: PrivacyPolicyActions
): PrivacyPolicyState {
    switch (action.type) {
        case PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY:
            return inProgress({} as PrivacyPolicy);
        case PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY_SUCCESS:
            return success(action.payload);
        case PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY_FAIL:
            return failure(action.payload.error);
        case PrivacyPolicyActionTypes.CLEAR_PRIVACY_POLICY:
            return notAsked();
        default:
            return state;
    }
}
