import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import {
    AddFlashMessageAction,
    AixErrorAction,
    AppState,
    Auth,
    CloseByButtonBehaviour,
    constants,
    ErrorWrapper,
    FlashMessageType,
    isFeatureEnabled,
    Profile,
    Role,
    SetAuthAction,
    UnloadAuthAction,
    UnloadProfileAction,
    UnloadRegisterAction
} from '@trade-platform/ui-shared';
import {
    ENVIRONMENT,
    getFromStorage,
    IEnvironment,
    LogService,
    setToStorage,
    withStorage
} from '@trade-platform/ui-utils';
import { v4 as uuid } from 'uuid';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { WebAuth } from 'auth0-js';
import { Logger } from 'typescript-logging';
import qs from 'qs';
import { routeConstants } from '../routing/constants';
import crypto from 'crypto';
import { profileHasRoles } from '../routing/guards/profile-checklist.guard';

interface UrlAfterLoginParts {
    urlAfterLogin: string;
    username?: string;
}

@Injectable({ providedIn: 'root' })
export class AixAuthService {
    auth0: WebAuth;
    authResult: any;
    authError: any;

    readonly LOG: Logger;

    private _canLogout = false;
    get canLogout() {
        return this._canLogout;
    }
    set canLogout(canLogout: boolean) {
        this._canLogout = canLogout;
    }

    /**
     * When forceLogout is true, deactivate guards (for unsaved changes) will be bypassed;
     */
    private _forceLogout = false;
    get forceLogout() {
        return this._forceLogout;
    }
    set forceLogout(forceLogout: boolean) {
        this._forceLogout = forceLogout;
    }

    constructor(
        private readonly router: Router,
        public readonly store: Store<AppState>,
        private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private readonly logService: LogService,
        @Inject(ENVIRONMENT) private readonly environment: IEnvironment
    ) {
        this.LOG = this.logService.getLogger('app.services.auth-service');
        this.auth0 = new WebAuth({
            domain: this.environment.auth0.domain,
            clientID: this.environment.auth0.key,
            redirectUri: window.location.href,
            audience: this.environment.login.audience,
            responseType: this.environment.login.response_type,
            scope: this.environment.login.scope
        });
    }

    onOutageError() {
        this.LOG.info('Outage Error, navigating to /outage');
        this.router.navigate(['/outage']);
    }

    async parseHash() {
        const nonceToValidate = withStorage(storage => storage.getItem('nonce'));
        const stateToValidate = withStorage(storage => storage.getItem('state'));
        this.LOG.debug(() => `Validating nonce from storage: ${nonceToValidate}`);

        const parseHashOpts: {
            nonce?: string;
            state?: string;
        } = {};
        if (nonceToValidate && nonceToValidate !== '' && nonceToValidate !== 'null') {
            parseHashOpts.nonce = nonceToValidate as string;
        }
        if (stateToValidate && stateToValidate !== '' && stateToValidate !== 'null') {
            parseHashOpts.state = stateToValidate as string;
        }
        return new Promise<void>((resolve, reject) => {
            this.auth0.parseHash(parseHashOpts, (error: any, authResult: any) => {
                if (error) {
                    try {
                        this.store.dispatch(
                            new AixErrorAction({
                                error: new ErrorWrapper(
                                    `LOGIN -- auth0.parseHash error: ${JSON.stringify(
                                        error
                                    )}. Nonce to validate: ${nonceToValidate}; State to validate: ${stateToValidate}.`
                                )
                            })
                        );
                    } catch (err) {
                        this.store.dispatch(
                            new AixErrorAction({
                                error: new ErrorWrapper(`LOGIN -- auth0.parseHash error`)
                            })
                        );
                    }
                }
                this.authResult = authResult;
                this.authError = error;

                this.updateSession(this.authResult);

                resolve();
            });
        });
    }

    setUserID(profile: Profile) {
        this.angulartics2GoogleTagManager.setUsername(profile.email);
        this.angulartics2GoogleTagManager.startTracking();
    }

    isLoggedInAuth0(): boolean {
        const auth = getFromStorage('auth') as Auth & { expiresAt: string };
        if (auth && auth.accessToken && auth.expiresAt) {
            return new Date().getTime() < parseInt(auth.expiresAt, 10);
        }
        return false;
    }

    isLoggedInApp(): boolean {
        return this.isLoggedInAuth0() && !!getFromStorage('profile');
    }

    isLoginSuccessful(): boolean {
        return (
            this.authResult &&
            this.authResult.accessToken &&
            this.authResult.idToken &&
            this.isLoggedInAuth0()
        );
    }

    isLoginFailure(): boolean {
        if (this.authError) {
            try {
                this.store.dispatch(
                    new AixErrorAction({
                        error: new ErrorWrapper(
                            `LOGIN -- In isLoginFailure: ${JSON.stringify(this.authError)}}).`
                        )
                    })
                );
            } catch (err) {
                // Do nothing
            }
        }

        return this.authError;
    }

    onError(error: any) {
        this.store.dispatch(
            new AixErrorAction({
                error: new ErrorWrapper(
                    'LOGIN -- Error getting user information: ' + error.toString()
                )
            })
        );
        this.store.dispatch(
            new AddFlashMessageAction({
                message: {
                    uid: uuid(),
                    text: 'Error getting user information: ' + error.toString(),
                    type: FlashMessageType.ERROR,
                    closeBehaviour: new CloseByButtonBehaviour()
                }
            })
        );
    }

    unloadData() {
        this.store.dispatch(new UnloadRegisterAction());
        this.store.dispatch(new UnloadProfileAction());
        this.store.dispatch(new UnloadAuthAction());
    }

    updateSession(authResult: any) {
        if (authResult) {
            this.store.dispatch(
                new SetAuthAction({
                    accessToken: authResult.accessToken,
                    expiresAt: new Date().getTime() + authResult.expiresIn * 1000
                })
            );
        }
    }

    /**
     * Checks if the logged in user is allowed to access the urlAfterLogin, based on role and allowed features;
     * @param route The urlAfterLogin route the user is attempting to acccess;
     * @param profile The users profile data to check against;
     */
    private urlAfterLoginPermissionCheck(route: string, profile: Profile): boolean {
        let hasPermission = false;
        const routeObj = this.router.config.find(r => r.path === route);
        if (routeObj && routeObj.data && routeObj.data.roles) {
            const roles: string[] = profile.roles.map((item: Role) => item.name);
            roles.forEach(role => {
                if (routeObj.data?.roles.indexOf(role) > -1) {
                    hasPermission = routeObj.data?.feature
                        ? isFeatureEnabled(routeObj.data.feature)
                        : true;
                }
            });
            return hasPermission;
        }
        return true;
    }

    redirectOnLogin() {
        this.LOG.info('Trying to redirect after login');
        const urlAfterLogin = getFromStorage<string>('urlAfterLogin');
        const profile = getFromStorage<Profile>('profile');
        if (profile) {
            this.setUserID(profile);
        }
        this.LOG.debug(() => `urlAfterLogin is "${urlAfterLogin}"`);
        withStorage(storage => storage.removeItem('urlAfterLogin'));

        const hasUrlAfterLogin = urlAfterLogin && urlAfterLogin !== '/';

        let hasPermissionToUrlAfterLogin = true;
        if (hasUrlAfterLogin) {
            const route = (urlAfterLogin as string).split('/')[1];
            hasPermissionToUrlAfterLogin = this.urlAfterLoginPermissionCheck(
                route,
                profile as Profile
            );
        }

        // Profile re-routing by role
        let hasProfileRouting: string | null = null;
        if (profile?.organization?.routing) {
            // TODO: Replace routing with profile?.organization?.routing
            const routing: any = profile?.organization?.routing;

            Object.keys(routing).some(role => {
                if (profileHasRoles([{ name: role }], profile as Profile)) {
                    hasProfileRouting = routing[role];
                }
                return hasProfileRouting;
            });
        }

        if (hasProfileRouting && routeConstants.routes[hasProfileRouting]) {
            this.router.navigate((routeConstants.routes[hasProfileRouting] as any).index());
        } else if (hasUrlAfterLogin && hasPermissionToUrlAfterLogin) {
            this.LOG.info(() => `Redirecting to "${urlAfterLogin}"`);
            this.router.navigateByUrl(urlAfterLogin as string);
        } else if (profile) {
            Sentry.getCurrentScope().setUser({ email: profile.email, username: profile.fullName });

            const userRoles: string[] = profile.roles.map(item => item.name);
            const logRedirect = (role: string, route: (string | { filter: string })[]) =>
                this.LOG.debug(() => `Role ${role} being redirected to "${route.join('/')}"`);

            if (userRoles.includes(constants.profileTypes.SYSADMIN)) {
                logRedirect(constants.profileTypes.SYSADMIN, routeConstants.routes.admin.users());
                this.router.navigate(routeConstants.routes.admin.users());
            } else if (
                userRoles.includes(constants.profileTypes.ADVISOR) &&
                (isFeatureEnabled('purchases') ||
                    isFeatureEnabled('accountMaintenance') ||
                    isFeatureEnabled('productOverview'))
            ) {
                logRedirect(constants.profileTypes.ADVISOR, routeConstants.routes.products.index());
                this.router.navigate(routeConstants.routes.products.index());
            } else if (
                userRoles.includes(constants.profileTypes.ACTIVE_INVESTOR) &&
                (isFeatureEnabled('purchases') ||
                    isFeatureEnabled('accountMaintenance') ||
                    isFeatureEnabled('productOverview'))
            ) {
                logRedirect(
                    constants.profileTypes.ACTIVE_INVESTOR,
                    routeConstants.routes.products.index()
                );
                this.router.navigate(routeConstants.routes.products.index());
            } else if (
                userRoles.includes(constants.profileTypes.ASSISTANT) &&
                (isFeatureEnabled('purchases') ||
                    isFeatureEnabled('accountMaintenance') ||
                    isFeatureEnabled('productOverview'))
            ) {
                logRedirect(
                    constants.profileTypes.ASSISTANT,
                    routeConstants.routes.products.index()
                );
                this.router.navigate(routeConstants.routes.products.index());
            } else if (
                userRoles.includes(constants.profileTypes.ADVISOR) &&
                isFeatureEnabled('performance')
            ) {
                logRedirect(constants.profileTypes.ADVISOR, routeConstants.routes.accounts.index());
                this.router.navigate(routeConstants.routes.accounts.index());
            } else if (
                userRoles.includes(constants.profileTypes.ACTIVE_INVESTOR) &&
                isFeatureEnabled('performance')
            ) {
                logRedirect(
                    constants.profileTypes.ACTIVE_INVESTOR,
                    routeConstants.routes.accounts.index()
                );
                this.router.navigate(routeConstants.routes.accounts.index());
            } else if (
                userRoles.includes(constants.profileTypes.ASSISTANT) &&
                isFeatureEnabled('performance')
            ) {
                logRedirect(
                    constants.profileTypes.ASSISTANT,
                    routeConstants.routes.accounts.index()
                );
                this.router.navigate(routeConstants.routes.accounts.index());
            } else if (
                userRoles.includes(constants.profileTypes.SIGNER) &&
                isFeatureEnabled('purchases')
            ) {
                logRedirect(constants.profileTypes.SIGNER, routeConstants.routes.purchase.status());
                this.router.navigate(routeConstants.routes.purchase.status());
            } else if (
                userRoles.includes(constants.profileTypes.REVIEWER) &&
                userRoles.includes(constants.profileTypes.AUTHORIZER) &&
                isFeatureEnabled('purchases')
            ) {
                logRedirect(
                    constants.profileTypes.AUTHORIZER,
                    routeConstants.routes.purchase.status()
                );
                this.router.navigate(routeConstants.routes.purchase.status());
            } else if (
                userRoles.includes(constants.profileTypes.SUBMITTER) &&
                isFeatureEnabled('purchases')
            ) {
                logRedirect(
                    constants.profileTypes.SUBMITTER,
                    routeConstants.routes.purchase.status()
                );
                this.router.navigate(routeConstants.routes.purchase.status());
            } else if (
                userRoles.includes(constants.profileTypes.ADMIN) &&
                isFeatureEnabled('purchases')
            ) {
                logRedirect(constants.profileTypes.ADMIN, routeConstants.routes.purchase.status());
                this.router.navigate(routeConstants.routes.purchase.status());
            } else if (
                userRoles.includes(constants.profileTypes.ADMIN) &&
                isFeatureEnabled('performance')
            ) {
                logRedirect(constants.profileTypes.ADMIN, routeConstants.routes.accounts.index());
                this.router.navigate(routeConstants.routes.accounts.index());
            } else {
                // Fallthrough when no features are available to tenant
                this.router.navigateByUrl('profile');
            }
        } else {
            this.LOG.warn(`Profile doesn't have value and we can't use "urlAfterLogin"`);
        }
    }

    logIn(urlAfterLogin?: string): void {
        withStorage(storage => storage.clear());

        let callbackUrl = window.location.href;
        let urlAfterLoginParts: UrlAfterLoginParts | null = null;
        if (urlAfterLogin && urlAfterLogin !== '/register') {
            setToStorage('urlAfterLogin', urlAfterLogin);

            urlAfterLoginParts = this.parseUrlAfterLogin(urlAfterLogin);
            urlAfterLogin = urlAfterLoginParts.urlAfterLogin;
            callbackUrl = `${window.location.protocol}//${window.location.host}/#${urlAfterLogin}`;
        }

        this.LOG.debug(
            () =>
                `Logging in with urlAfterLogin: "${urlAfterLogin}", auth0 redirectUri: "${callbackUrl}"`
        );

        const nonce = this.getNonce();
        const state = this.getState() as string;

        // TODO: login_hint is supposed to be a string
        // FIXME: fix login_hint
        this.auth0.authorize({
            nonce,
            state,
            clientID: this.environment.auth0.key,
            redirectUri: callbackUrl,
            audience: this.environment.login.audience,
            responseType: this.environment.login.response_type,
            login_hint: {
                forceSSOConnection: false,
                connection: null,
                tenant: this.getTenantFromHost(),
                username: urlAfterLoginParts ? urlAfterLoginParts.username : null
            } as any
        });
    }

    logInSSO() {
        const parsedSearch = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        // If there is a redirect passed in the initial /sso call as a query param, navigate there after login
        // Ex. /sso?connection=connectionName&redirect=/orders/buy/product/999?shareClass=999
        // Can be used to support deep linking into platform direct to a specific page
        if (parsedSearch.redirect) {
            setToStorage('urlAfterLogin', parsedSearch.redirect);
        }

        // TODO: login_hint is supposed to be a string
        // FIXME: fix login_hint
        this.auth0.authorize({
            nonce: this.getNonce(),
            state: this.getState() as string,
            clientID: this.environment.auth0.key,
            redirectUri: `${window.location.protocol}//${window.location.host}`,
            audience: this.environment.login.audience,
            responseType: this.environment.login.response_type,
            login_hint: {
                forceSSOConnection: true,
                connection: parsedSearch.connection,
                tenant: this.getTenantFromHost()
            } as any
        });
    }

    logOut(urlAfterLogin?: string): void {
        withStorage(storage => storage.clear());

        if (urlAfterLogin && urlAfterLogin !== '/register') {
            setToStorage('urlAfterLogin', urlAfterLogin);
        }
        const callbackUrl = urlAfterLogin
            ? `${window.location.protocol}//${window.location.host}/#${urlAfterLogin}`
            : `${window.location.protocol}//${window.location.host}`;
        this.LOG.debug(
            () =>
                `Logging out with urlAfterLogin: "${urlAfterLogin}", auth0 redirectUri: "${callbackUrl}"`
        );
        this.auth0.logout({
            clientID: this.environment.auth0.key,
            returnTo: callbackUrl
        });
        window.location.href = 'assets/logout.html';
    }

    callLogout(usePreviousUrl = false) {
        this.canLogout = true;
        this.router.navigate(
            routeConstants.routes.logout.index(),
            routeConstants.routes.logout.params(usePreviousUrl)
        );
    }

    /**
     * Parse the redirect url after login and extract out any Auth0 related data;
     * Extracts out:
     *     - username
     * @param urlAfterLogin {string} - the url to redirect to after a successful login;
     * @returns UrlAfterLoginParts - the parsed url and any parts that were extracted;
     */
    parseUrlAfterLogin(urlAfterLogin: string): UrlAfterLoginParts {
        // Return parts;
        const parts = {
            urlAfterLogin
        } as UrlAfterLoginParts;

        // Build URL (using dumby base url);
        const url = new URL(urlAfterLogin, 'https://parse.url.after.login');

        // Parse out useful url params;
        if (url.searchParams.has('username')) {
            parts.username = url.searchParams.get('username') as string;
            url.searchParams.delete('username');
        }

        // Set parsed urlArterLogin;
        parts.urlAfterLogin = `${url.pathname}${url.search}`;

        return parts;
    }

    getNonce() {
        // Code snippet from PS team at Auth0, with a minor modification to work with IE and set localStorage
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const array = new Uint8Array(40);
        // @ts-ignore: crypto is still 'experimental' in IE and can be accessed as msCrypto
        const crypto = window.crypto || window.msCrypto;
        crypto.getRandomValues(array);

        // Map function does not work on Typed Arrays in IE
        let ieCompatibleArray = Array.from(array);
        ieCompatibleArray = ieCompatibleArray.map(x =>
            validChars.charCodeAt(x % validChars.length)
        );

        const n = String.fromCharCode.apply(null, ieCompatibleArray);
        const nonce = btoa(
            JSON.stringify({
                n,
                redirect_uri: window.location.origin,
                ts: new Date().getTime()
            })
        );
        this.LOG.debug(() => `Nonce created: ${nonce}`);
        withStorage(storage => storage.setItem('nonce', nonce));
        return nonce;
    }

    /**
     * Code snippet from auth0.js lib, which uses this method to generate a state, when not passed;
     * Minor modification to set localStorage;
     */
    getState() {
        const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
        const state = this.generateForCustomCharacters(32, charset);
        this.LOG.debug(() => `State created: ${state}`);
        withStorage(storage => storage.setItem('state', state));
        return state;
    }

    /**
     * Code snippet from crypto-random-string lib by sindresorhus
     */
    generateForCustomCharacters(length: number, characters: string) {
        // Generating entropy is faster than complex math operations, so we use the simplest way
        const characterCount = characters.length;
        const maxValidSelector = Math.floor(0x10000 / characterCount) * characterCount - 1; // Using values above this will ruin distribution when using modular division
        const entropyLength = 2 * Math.ceil(1.1 * length); // Generating a bit more than required so chances we need more than one pass will be really low
        let string = '';
        let stringLength = 0;

        while (stringLength < length) {
            // In case we had many bad values, which may happen for character sets of size above 0x8000 but close to it
            const entropy = crypto.randomBytes(entropyLength);
            let entropyPosition = 0;

            while (entropyPosition < entropyLength && stringLength < length) {
                const entropyValue = entropy.readUInt16LE(entropyPosition);
                entropyPosition += 2;
                if (entropyValue > maxValidSelector) {
                    // Skip values which will ruin distribution when using modular division
                    continue;
                }

                string += characters[entropyValue % characterCount];
                stringLength++;
            }
        }

        return string;
    }

    getTenantFromHost() {
        const location = window.location.host;
        return location.split('.')[0];
    }
}
