/**
 *
 * @param code Error code i.e. "MINIMUM_AMOUNT_ERROR"
 * @param debugInfo Developer info (optional, only in development env.)
 * @param message Error message
 * @param path The path (group + name) to the form field within the FormGroup.
 *             Used to display validation errors in visual context.
 */
export interface ApiFormErrorItem {
    code: string;
    debugInfo: string;
    message: string;
    path: string;
    hideMessage?: boolean;
    accountNumber?: string;
    custodianName?: string;
}

export class ApiFormError {
    constructor(public code: string, public messages: ApiFormErrorItem[], public path: string) {}
}
