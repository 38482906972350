import { PipeTransform } from '@angular/core';

/**
 * Get the transformed value with the given pipe;
 * @param value {any} - the value to transform;
 * @param pipe {PipeTransform} - the pipe to use to transform the given value;
 * @param pipeParams {any[]]} - the extra params to pass to the pipe transform - defaults to [];
 * @param defaultValue {any} - the default value to use if the value is falsey - defaults to '-';
 * @returns {string} - the transformed value or the default value if the value is falsey;
 */
export const getTransformedValue = (
    value: any,
    pipe: PipeTransform,
    pipeParams: any[] = [],
    defaultValue: any = '—'
): string => {
    if (value) {
        return pipe.transform(value, ...pipeParams);
    }
    return defaultValue;
};
