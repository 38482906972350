import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import {
    AcceptTermsActions,
    AcceptTermsActionTypes,
    TermsActions,
    TermsActionTypes
} from './actions';
import { AcceptTermResponse, TermsAndConditions } from '../../models/terms/model';

export type TermsState = RemoteData<TermsAndConditions, ErrorWrapper>;

export function termsReducer(state: TermsState = notAsked(), action: TermsActions): TermsState {
    switch (action.type) {
        case TermsActionTypes.LOAD_TERMS:
            return inProgress({} as TermsAndConditions);
        case TermsActionTypes.LOAD_TERMS_SUCCESS:
            // case TermsActionTypes.SET_TERMS:
            return success(action.payload);
        case TermsActionTypes.LOAD_TERMS_FAIL:
            return failure(action.payload.error);
        case TermsActionTypes.CLEAR_TERMS:
            return notAsked();
        default:
            return state;
    }
}

export type AcceptTermsState = RemoteData<AcceptTermResponse, ErrorWrapper>;

export function acceptTermsReducer(
    state: AcceptTermsState = notAsked(),
    action: AcceptTermsActions
): AcceptTermsState {
    switch (action.type) {
        case AcceptTermsActionTypes.ACCEPT_TERMS:
            return inProgress({} as AcceptTermResponse);
        case AcceptTermsActionTypes.ACCEPT_TERMS_SUCCESS:
            return success(action.payload);
        case AcceptTermsActionTypes.ACCEPT_TERMS_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}
