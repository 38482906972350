import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderFormComment } from '@trade-platform/ui-shared';
import { OrderFormCommentActions, OrderFormCommentActionTypes } from './actions';

export type OrderFormCommentState = RemoteData<OrderFormComment, ErrorWrapper>;

const initialState = notAsked<OrderFormComment, ErrorWrapper>();

export const orderFormCommentReducer = (
    state: OrderFormCommentState = initialState,
    action: OrderFormCommentActions
): OrderFormCommentState => {
    switch (action.type) {
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_GET:
            return inProgress({} as OrderFormComment);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_GET_SUCCESS:
            return success(action.payload);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_GET_FAILURE:
            return failure(action.payload.error);

        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_CREATE:
            return inProgress({} as OrderFormComment);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_CREATE_SUCCESS:
            return success(action.payload);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_CREATE_FAILURE:
            return failure(action.payload.error);

        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_EDIT:
            return inProgress({} as OrderFormComment);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_EDIT_SUCCESS:
            return success(action.payload);

        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_DELETE:
            return inProgress({} as OrderFormComment);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_DELETE_SUCCESS:
            return success(action.payload);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_DELETE_FAILURE:
            return failure(action.payload.error);

        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_UPDATE:
            return inProgress({} as OrderFormComment);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_UPDATE_SUCCESS:
            return success(action.payload);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_UPDATE_FAILURE:
            return failure(action.payload.error);

        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_RESOLVE:
            return inProgress({} as OrderFormComment);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_RESOLVE_SUCCESS:
            return success(action.payload);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_RESOLVE_FAILURE:
            return failure(action.payload.error);

        default:
            return state;
    }
};

export type OrderFormCommentsState = RemoteData<OrderFormComment[], ErrorWrapper>;
const initialState2 = notAsked<OrderFormComment[], ErrorWrapper>();

export const orderFormCommentsReducer = (
    state: OrderFormCommentsState = initialState2,
    action: OrderFormCommentActions
): OrderFormCommentsState => {
    switch (action.type) {
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_LIST:
            return inProgress([]);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_LIST_SUCCESS:
            return success(action.payload);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENT_LIST_FAILURE:
            return failure(action.payload.error);

        case OrderFormCommentActionTypes.ORDER_FORM_COMMENTS_RESOLVE:
            return inProgress([]);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENTS_RESOLVE_SUCCESS:
            return success(action.payload.comments);
        case OrderFormCommentActionTypes.ORDER_FORM_COMMENTS_RESOLVE_FAILURE:
            return failure(action.payload.error);

        default:
            return state;
    }
};
