import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { concatMap, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { JsonDecoder } from 'ts.data.json';

@Injectable()
export class OrderFinishMapSignaturesService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    mapSignatures(payload: { orderId: string }): Observable<any> {
        return this.http
            .post(`${this.environment.api}orders/${payload.orderId}/finishMapSignatures`, null)
            .pipe(
                concatMap(p => {
                    return fromPromise(JsonDecoder.succeed.decodeToPromise(p));
                })
            );
    }
}
