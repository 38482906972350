import { Pipe, PipeTransform } from '@angular/core';
import { kebabCase } from 'lodash-es';

@Pipe({
    name: 'aixKebabCasePipe',
    pure: true,
    standalone: true
})
export class AixKebabCasePipe implements PipeTransform {
    transform(value: string) {
        return kebabCase(value);
    }
}
