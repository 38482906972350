import { Pipe, PipeTransform } from '@angular/core';
import { eSignEnvelopeRecipient } from '@trade-platform/ui-shared';

@Pipe({
    name: 'aixSignerFilter',
    standalone: true
})
export class AixSignerFilterPipe implements PipeTransform {
    transform(signers: eSignEnvelopeRecipient[], type: string) {
        if (signers) {
            return signers.filter((s: eSignEnvelopeRecipient) => s.type === type);
        }
        return signers;
    }
}
