<div class="aix-viewer__header" *ngrxLet="storeFacade.documentViewer$ as documentViewer">
    @for (tab of tabs;track tab) {
    <div
        class="aix-viewer__header-title br-primary__color"
        [attr.data-testing]="tab?.toLowerCase()"
        (click)="onClickTab(tab)"
        [ngClass]="{
            'br-primary__border-color': documentViewer.tab === tab,
            inactive: documentViewer.tab !== tab
        }"
    >
        {{ tab }}
    </div>
    }
</div>

<div class="aix-viewer__content" *ngrxLet="storeFacade.documentViewer$ as documentViewer">
    @for (tab of tabs;track tab) {
    <section class="-no-padding" [hidden]="documentViewer.tab !== tab">
        <aix-header-section
            [data-testing]="'tab-content-title'"
            [title]="tab === 'Order' ? 'Order #' + storeFacade.order?.orderNumber : tab"
        >
        </aix-header-section>

        @if (tab === documentViewer.tab) {
        <div class="aix-viewer__body-content">
            @if (tab === 'Order') { @if (requiredDocuments?.length) {
            <h5>Required Forms</h5>
            }
            <aix-document-list
                [documentsToDisplay]="requiredDocuments"
                [currentDocument]="documentViewer.currentDocument"
                (clickDownload)="downloadDocument($event)"
            >
            </aix-document-list>
            @if (supplementalFiles?.length) {
            <h5 class="u-mt24">Supporting Documents</h5>
            }
            <aix-document-list
                [documentsToDisplay]="supplementalFiles"
                [currentDocument]="documentViewer.currentDocument"
                [offset]="requiredDocuments.length"
                (clickDownload)="downloadDocument($event)"
            >
            </aix-document-list>

            } @if (tab === 'Comments') {
            <aix-order-form-comments
                [canAddComments]="(
                    documentViewer.action === 'approve' ||
                    documentViewer.action === 'sign' ||
                    documentViewer.action === 'wetSign') &&
                    documentViewer.hasPermission"
                [canReply]="documentViewer.action === 'resolve' && documentViewer.hasPermission"
                [orderId]="(storeFacade.orderSuccess$ | async)?.id"
                [selectedFormId]="documentViewer.currentFile?.formId"
                [currentDocument]="documentViewer.currentFile!"
                [documentName]="documentViewer.currentFile?.screenName"
                [comments]="(storeFacade.orderFormCommentsSuccess$ | async) | orderFormCommentsFilter:false:true:filesToDisplayIds"
                [mode]="commentsMode"
            >
            </aix-order-form-comments>
            }
        </div>
        }
    </section>
    }
</div>
