import { getFromStorage } from '@trade-platform/ui-utils';
import { Profile } from '../../models/profile/model';
import { constants } from '../../utils/constants';

/**
 * Returns if a user can buy or maintenance a product
 */
export const canBuy = () => {
    const profile = getFromStorage<Profile>('profile');
    if (profile && profile.roles && profile.roles.length > 0) {
        return (
            profile.roles.find(
                role =>
                    role.name === constants.profileTypes.ADVISOR ||
                    role.name === constants.profileTypes.ACTIVE_INVESTOR ||
                    role.name === constants.profileTypes.ASSISTANT
            ) !== undefined &&
            profile.features.find(
                feature => feature.name === 'purchases' || feature.name === 'accountMaintenance'
            ) !== undefined
        );
    }

    return false;
};
