import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'aixRemoveTrailingZeroes',
    standalone: true
})
export class AixRemoveTrailingZeroesPipe implements PipeTransform {
    transform(n: number | string): string {
        let num = '0';
        if (n) {
            if (typeof n === 'number') {
                num = isNaN(n) ? '0' : n.toString();
            } else if (typeof n === 'string') {
                num = isNaN(parseFloat(n)) ? '0' : n;
            } else {
                throw new TypeError(
                    'Type must be either number or string but ' + typeof n + ' was provided'
                );
            }

            if (num.indexOf('.') > -1) {
                const lastChar = (str: string) => str[str.length - 1];
                const removeLastChar = (str: string) => str.substring(0, str.length - 1);
                while (lastChar(num) === '0') {
                    num = removeLastChar(num);
                }
                if (lastChar(num) === '.') {
                    num = removeLastChar(num);
                }
            }
        }
        return num;
    }
}
