import {
    BaseOrder,
    DocumentFile,
    OrderForm,
    RecipientStatus,
    RequiredDocument
} from '@trade-platform/ui-shared';
import { firmTypes } from '../pdf-generation/pdf-generation.constants';
import {
    AixRequiredFilesMenuOption,
    AixUploadedFilesMenuOption,
    DocumentOwner
} from '../../../file-upload/filelist/filelist';
import { AixUploadedFormFilesMenuOption } from '../../../form-upload/form-filelist/form-filelist';
import { entityType, fileType, orderStatus } from '@trade-platform/lib-enums';
import { getFilesToDisplay } from '../../../order-document-viewer/document-viewer/document-viewer.helper';
import { objectHasValue } from '@trade-platform/ui-utils';
import { get } from 'lodash-es';

export const TA_SUBMISSION = 'taSubmission';
export const PENDING_MANUAL_SUBMISSION = 'pendingManualSubmission';
export const ELECTRONIC_SUBMISSION = 'electronicSubmission';

export const DOCUSIGN_FILETYPE = 'Docusign Certificate of Completion';
export const COVERSHEET_FILETYPE = 'AIX Cover Sheet';

export const DOCUSIGN_ENVELOPE_EXPIRATION_DAYS = 120;

export const getUploadedFormFiles = (order: BaseOrder): AixUploadedFormFilesMenuOption[] => {
    const uploads = Object.keys(order.files)
        .map(id => ({
            isOpen: false,
            isUploading: false,
            isUploadCompleted: true,
            file: order.files[parseInt(id, 10)],
            form: order.files[parseInt(id, 10)].formId
                ? order.forms[order.files[parseInt(id, 10)].formId as string]
                : null
        }))
        .filter(
            item => item.file.type === fileType.form || item.file.type === fileType.unassignedForm
        );
    const notOnboarded = Object.keys(order.forms)
        .map(f => order.forms[f])
        .filter(
            f =>
                f.isOnboarded === false &&
                order.files.find(file => file.formId === f.id) === undefined
        )
        .map(f => ({
            isOpen: false,
            isUploading: false,
            isUploadCompleted: false,
            file: null,
            form: f
        }));
    const combined = [...uploads, ...notOnboarded];
    const labeledUploads = combined
        .filter(item => item.form)
        .sort((a, b): number => (a.form as OrderForm).index - (b.form as OrderForm).index);
    const unlabeledUploads = combined.filter(item => !item.form);

    return [...labeledUploads, ...unlabeledUploads];
};

/**
 * Returns an array of user uploaded files (mapped or unmapped) that were uploaded during the same status as the given order;
 * It also will only return files that have a `formId` in the `acceptedFormIds` array, if passed;
 * @param order The order to return files from (based on its current status);
 * @param acceptedFormIds An optional filter array, that will restrict which forms get returned, based on `formId`;
 */
export const getUserUploadedFormFiles = (order: BaseOrder, acceptedFormIds: string[] = []) => {
    return order.files.filter(
        f =>
            (f.type === fileType.form || f.type === fileType.unassignedForm) &&
            f.isUserUploaded &&
            f.orderStatus === order.status &&
            (acceptedFormIds.length === 0 ||
                (f.formId && acceptedFormIds.indexOf(f.formId) > -1) ||
                f.type === fileType.unassignedForm) &&
            (order.approvals && order.approvals.length > 0
                ? f.config && f.config.id === order.approvals[0].config.id
                : true)
    );
};

export const getFinishedApprovalFormUploads = (order: BaseOrder) => {
    const orderApprovals = order.approvals;
    if (objectHasValue(orderApprovals) && orderApprovals.length >= 1) {
        return order.files.filter(
            f => f.type === fileType.form && f.config && f.config.id === orderApprovals[0].config.id
        );
    } else {
        return [];
    }
};

export const getApprovalUploadedFormFiles = (order: BaseOrder) => {
    return order.files.filter(
        f =>
            (f.type === fileType.form || f.type === fileType.unassignedForm) &&
            (f.orderStatus === orderStatus.pendingCustodianApproval ||
                f.orderStatus === orderStatus.pendingFirmApproval ||
                f.orderStatus === orderStatus.pendingFundSponsorApproval) &&
            f.isUserUploaded
    );
};

export const getUploadedSupplementalFiles = (order: BaseOrder): AixUploadedFilesMenuOption[] => {
    return Object.keys(order.files)
        .map(id => ({
            id: id,
            file: order.files[parseInt(id, 10)],
            contextMap: order.files[parseInt(id, 10)].contextMap,
            isOpen: false,
            isRelationOpen: false,
            replacePayload: null,
            unresolvedFileComments: [],
            type: 'uploaded',
            supplementalFileType: order.files[parseInt(id, 10)].supplementalFileType
        }))
        .filter(
            item =>
                (item.file.type === fileType.supplemental ||
                    item.file.type === fileType.unassignedSupplemental) &&
                (!item.file.supplementalFileType ||
                    (item.file.supplementalFileType.name !== DOCUSIGN_FILETYPE &&
                        item.file.supplementalFileType.name !== COVERSHEET_FILETYPE))
        ) as AixUploadedFilesMenuOption[];
};

export const getRequiredDocumentEntityName = (
    order: BaseOrder,
    required: RequiredDocument
): string | null => {
    if (required.fundId && order.fund.fundSponsor?.name) {
        return order.fund.fundSponsor?.name;
    } else if (required.holdingOptionId && order.holdingOption?.name) {
        return order.holdingOption.name;
    } else if (required.firmId && order.firm.name) {
        return order.firm.name;
    } else {
        return null;
    }
};

export const getUploadedFileIndex = (order: BaseOrder, index = 0) => {
    const totalFileCount = order.files.length;
    const uploadedFileCount = getUploadedSupplementalFiles(order).length;
    return totalFileCount - uploadedFileCount + index;
};

export const getFileIndexByFormId = (order: BaseOrder, formId: string): number | null => {
    const files = getFilesToDisplay(order);

    for (let i = 0; i < order.files.length; i++) {
        if (files[i].formId === formId) {
            return i;
        }
    }

    return null;
};

export const getFileIndexByFileId = (order: BaseOrder, fileId: string) => {
    const files = getFilesToDisplay(order);
    let index = 0;
    for (let i = 0; i < files.length; i++) {
        if (files[i].id === fileId) {
            index = i;
        }
    }
    return index;
};

export const getRequiredFilesToUpload = (order: BaseOrder): AixRequiredFilesMenuOption[] => {
    const requiredFiles = order.requiredSupplementalFiles;
    return requiredFiles
        .map(item => ({
            id: item.id,
            file: item,
            isOpen: false,
            contextMap: item.contextMap as string,
            type: 'required'
        }))
        .filter(item => {
            const isUploaded = getUploadedSupplementalFiles(order).find(
                upload =>
                    upload.file.supplementalFileTypeId === item.id &&
                    upload.file.contextMap &&
                    upload.file.contextMap === item.contextMap &&
                    ((upload.file.firmId && upload.file.firmId === item.file.firmId) ||
                        (upload.file.fundId && upload.file.fundId === item.file.fundId) ||
                        (upload.file.holdingOptionId &&
                            upload.file.holdingOptionId === item.file.holdingOptionId))
            );
            return !isUploaded;
        }) as AixRequiredFilesMenuOption[];
};

export const getUnfinishedRequiredUploads = (order: BaseOrder) => {
    const requiredFiles = order.requiredSupplementalFiles.filter(item => !item.dismissedDate);
    let unfinishedUploads = 0;

    if (requiredFiles) {
        const files = order.files;
        const arrayFiles = Object.keys(files)
            .map(id => ({ id: id, file: files[parseInt(id, 10)], isOpen: false }))
            .filter(item => item.file.type === fileType.supplemental);

        // Count unfinished uploads
        requiredFiles.forEach(reqFile => {
            const found = arrayFiles.find(
                item =>
                    item.file.supplementalFileTypeId === reqFile.id &&
                    item.file.contextMap &&
                    item.file.contextMap === reqFile.contextMap &&
                    ((reqFile.firmId && reqFile.firmId === item.file.firmId) ||
                        (reqFile.fundId && reqFile.fundId === item.file.fundId) ||
                        (reqFile.holdingOptionId &&
                            reqFile.holdingOptionId === item.file.holdingOptionId))
            );

            if (!found) {
                unfinishedUploads++;
            }
        });
    }

    return unfinishedUploads;
};

export const getUnlabeledSupplementalUploads = (order: BaseOrder) => {
    return order.files.filter(
        f => f.type === fileType.unassignedSupplemental && !f.supplementalFileTypeId
    ).length;
};

export const getTotalSupplementalUploads = (order: BaseOrder) => {
    return getUploadedSupplementalFiles(order).length + getRequiredFilesToUpload(order).length;
};

export const getFinishedSupplementalUploads = (order: BaseOrder) => {
    return (
        getTotalSupplementalUploads(order) -
        (getUnfinishedRequiredUploads(order) + getUnlabeledSupplementalUploads(order))
    );
};

export const areFormUploadsCompletedInCurrentStep = (order: BaseOrder): boolean => {
    let allFormsUploaded = true;
    Object.keys(order.forms).forEach(f => {
        // in wetsign, user must download and upload:
        // - any not onboarded forms
        // - all onboarded forms with signatures (where noSignatures is false)
        if (!order.forms[f].isOnboarded || !order.forms[f].noSignatures) {
            const fileIndex = order.files.findIndex(
                file =>
                    (file.type === fileType.form || file.type === fileType.unassignedForm) &&
                    file.formId === f &&
                    file.orderStatus === order.status // form must be uploaded in current step
            );
            if (fileIndex === -1) {
                allFormsUploaded = false;
            }
        }
    });
    return allFormsUploaded;
};

export const getFilteredFormIds = (order: BaseOrder, acceptedFormIds: string[] = []) => {
    return Object.keys(order.forms).filter(
        f =>
            (acceptedFormIds.length === 0 &&
                !(order.forms[f].isOnboarded && order.forms[f].noSignatures)) ||
            acceptedFormIds.indexOf(f) > -1
    );
};

export const areApprovalFormUploadsCompleted = (
    order: BaseOrder,
    acceptedFormIds: string[] = []
) => {
    return (
        getFinishedApprovalFormUploads(order).length ===
        getFilteredFormIds(order, acceptedFormIds).length
    );
};

export const hasMaxFormUploads = (order: BaseOrder, acceptedFormIds: string[] = []) => {
    return (
        getUserUploadedFormFiles(order, acceptedFormIds).length ===
        getFilteredFormIds(order, acceptedFormIds).length
    );
};

export const getFirmTypeLabel = (order: BaseOrder) => {
    return order && order.firm && order.firm.firmType && order.firm.firmType === firmTypes.ibd
        ? 'broker dealer'
        : 'custodian';
};

export const getFormFile = (order: BaseOrder, form: OrderForm): DocumentFile | null | undefined => {
    if (order) {
        return order.files.find(f => f.formId === form.id);
    }
    return null;
};

export const getFormByIndex = (order: BaseOrder, index: number) => {
    return Object.keys(order.forms)
        .map(f => order.forms[f])
        .find(f => f.index === index);
};

/**
 * Returns true if the docusign envelope can't be voided
 */
export const isDocusignEnvelopeDead = (order: BaseOrder) => {
    if (
        !order ||
        !order.signingStatus ||
        !order.signingStatus.DocuSignEnvelopeInformation ||
        !order.signingStatus.DocuSignEnvelopeInformation.EnvelopeStatus ||
        !order.signingStatus.DocuSignEnvelopeInformation.EnvelopeStatus.RecipientStatuses ||
        !order.signingStatus.DocuSignEnvelopeInformation.EnvelopeStatus.RecipientStatuses
            .RecipientStatus
    ) {
        return false;
    }
    const envelopeStatus = order.signingStatus.DocuSignEnvelopeInformation.EnvelopeStatus;

    // check if any signatures are declined or undeliverable
    if (
        envelopeStatus.RecipientStatuses.RecipientStatus.find(
            (s: RecipientStatus) => s.Status === 'AutoResponded' || s.Status === 'Declined'
        )
    ) {
        return true;
    }

    // check if envelope is completed
    if (envelopeStatus.Status === 'Completed') {
        return true;
    }

    // check if envelope is expired
    const date = new Date();
    const expiration = date.getDate() - DOCUSIGN_ENVELOPE_EXPIRATION_DAYS;
    date.setDate(expiration);
    if (Date.parse(envelopeStatus.Created) < date.valueOf()) {
        return true;
    }

    return false;
};

/**
 * Determines if there was at least one user uploaded file with the same order status as the given order;
 * @param order {Order} - the order to check for user uploaded files with the same status;
 * @returns {boolean} - true if there was at least one file uploaded with the same order status as the given order, false otherwise;
 */
export const hasUploadedFilesPerStatus = (order: BaseOrder): boolean => {
    return order.files.reduce(
        (acc, curr) =>
            acc || (!!curr.isUserUploaded && curr.orderStatus === order.status && !!curr.formId),
        false as boolean
    );
};

/**
 * Determine if the order is in a pending submission status;
 * @param order {Order}
 * @returns {boolean}
 */
export const isPendingSubmission = (order: BaseOrder): boolean => {
    const submissionStatuses = [
        orderStatus.pendingApprovalProcess,
        orderStatus.pendingSubmissionToCustodian,
        orderStatus.pendingSubmissionToFundSponsor,
        orderStatus.pendingSubmissionToTransferAgent,
        orderStatus.pendingManualSubmission
    ];
    if (order && submissionStatuses.includes(order.status)) {
        return true;
    }
    return false;
};

/**
 * Get a list of "owners" in order of most important to least important;
 * Ordering: entity -> investors (highest to lowest priority);
 * @param order {BaseOrder} - the order data to extract the account owners information from;
 * @returns {DocumentOwner[]} - an array of account owners in order of most important to least important;
 */
export const getOwners = (order: BaseOrder): DocumentOwner[] => {
    const owners = [] as DocumentOwner[];

    if (order?.account) {
        // Entity
        if (order.account.entity?.id) {
            owners.push({
                id: order.account.entity.id,
                contextMap: 'account.entity.fullName',
                alternativeContextMaps: ['account.entity.firstName'],
                fullName: order.account.entity.fullName as string
            } as DocumentOwner);
        }

        // Investors
        if (order.account.investors && order.account.investors.length > 0) {
            order.account.investors.forEach((investor, i) => {
                owners.push({
                    id: investor.id,
                    contextMap: `account.investors[${i}].fullName`,
                    fullName: investor.fullName
                } as DocumentOwner);
            });
        }
    }

    if (order.advisor?.displayName) {
        owners.push({
            id: null,
            contextMap: 'advisor.displayName',
            alternativeContextMaps: ['advisor.fullName'],
            fullName: order.advisor.displayName
        } as DocumentOwner);
    }

    if (!order.account && order.createdBy && !order.advisor) {
        owners.push({
            id: null,
            contextMap: 'createdBy.fullName',
            fullName: order.createdBy.fullName
        } as DocumentOwner);
    }

    if (order.requiredSupplementalFiles && order.requiredSupplementalFiles.length > 0) {
        order.requiredSupplementalFiles.forEach(requiredSupplementalFile => {
            if (
                requiredSupplementalFile.contextMap &&
                !owners.some(owner => owner.contextMap === requiredSupplementalFile.contextMap) &&
                !owners.some(
                    owner =>
                        owner.alternativeContextMaps &&
                        requiredSupplementalFile.contextMap &&
                        owner.alternativeContextMaps.includes(requiredSupplementalFile.contextMap)
                ) &&
                get(order, requiredSupplementalFile.contextMap)
            ) {
                owners.push({
                    id: null,
                    contextMap: requiredSupplementalFile.contextMap,
                    fullName: get(order, requiredSupplementalFile.contextMap)
                } as DocumentOwner);
            }
        });
    }

    return owners;
};

export interface DocumentOrganization {
    name: string;
    entity: {
        type: string;
        id: number;
    };
}

export const getOrganizations = (order: BaseOrder): DocumentOrganization[] => {
    const organizations = [] as DocumentOrganization[];

    if (order.firm && order.firm.name) {
        organizations.push({
            name:
                order.firm.name === 'Alternative Investment Exchange' || order.firm.name === 'AIX'
                    ? 'Your Firm'
                    : order.firm.name,
            entity: { type: entityType.firm, id: parseInt(order.firm.id) }
        });
    }

    if (order.fund && order.fund.fundSponsor?.name) {
        organizations.push({
            name: order.fund.fundSponsor?.name,
            entity: { type: entityType.fundSponsor, id: order.fund.id }
        });
    }

    if (
        order.holdingOption &&
        order.holdingOption?.name &&
        order.holdingOption.shortCode !== 'DWS'
    ) {
        organizations.push({
            name: order.holdingOption.name,
            entity: { type: entityType.holdingOption, id: order.holdingOption.id }
        });
    }

    return organizations;
};
