import {
    AixAction,
    ErrorWrapper,
    OrderApprovalForm,
    OrderIdPayload,
    type
} from '@trade-platform/ui-shared';

export class OrderApprovalFormsActionTypes {
    static readonly ORDER_APPROVAL_FORMS = type('[OrderApprovalForms] Load Order Approval Forms');
    static readonly ORDER_APPROVAL_FORMS_SUCCESS = type(
        '[OrderApprovalForms] Load Order Approval Forms Success'
    );
    static readonly ORDER_APPROVAL_FORMS_FAILURE = type(
        '[OrderApprovalForms] Load Order Approval Forms Failure'
    );
}

export class LoadOrderApprovalForms implements AixAction {
    readonly type = OrderApprovalFormsActionTypes.ORDER_APPROVAL_FORMS;

    constructor(public payload: OrderIdPayload) {}
}

export class LoadOrderApprovalFormsSuccess implements AixAction {
    readonly type = OrderApprovalFormsActionTypes.ORDER_APPROVAL_FORMS_SUCCESS;

    constructor(public payload: OrderApprovalForm[]) {}
}

export class LoadOrderApprovalFormsFailure implements AixAction {
    readonly type = OrderApprovalFormsActionTypes.ORDER_APPROVAL_FORMS_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export type OrderApprovalFormsActions =
    | LoadOrderApprovalForms
    | LoadOrderApprovalFormsSuccess
    | LoadOrderApprovalFormsFailure;
