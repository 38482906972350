import { Pipe, PipeTransform } from '@angular/core';

export const citizenValues: { [key: string]: string } = {
    Resident_alien: 'Resident Alien',
    Non_resident_alien: 'Non-Resident Alien',
    Citizen: 'U.S Citizen'
};

@Pipe({
    name: 'aixCitizenValue',
    standalone: true
})
export class AixCitizenValuePipe implements PipeTransform {
    transform(text: string): string {
        return citizenValues[text] ?? text;
    }
}
