import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class OrderDocumentUndeclineService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderDocumentUndecline(payload: {
        orderId: string;
        supplementalFileTypeId: string;
        contextMap: string;
        dismissedReason: string;
        firmId?: number;
        fundId?: number;
        holdingOptionId?: number;
    }): Observable<any> {
        return this.http.put<any>(
            `${this.environment.api}orders/${payload.orderId}/supplemental-files/update`,
            {
                supplementalFileTypeId: payload.supplementalFileTypeId,
                contextMap: payload.contextMap,
                dismissedReason: payload.dismissedReason,
                firmId: payload.firmId,
                fundId: payload.fundId,
                holdingOptionId: payload.holdingOptionId
            }
        );
    }
}
