@for (checkboxData of checkboxTree;track checkboxData) {

<aix-checkbox-item
    class="checkbox-custom-wrapper-full"
    (onCheckboxChange)="onClickCheckbox()"
    [checkboxData]="checkboxData"
    [isRequired]="hasRequiredError"
    [isValid]="isValid()"
    [isDirty]="isDirty"
>
</aix-checkbox-item>

}
