<aix-header-section [title]="config()?.headingText">
    @if (allowChanges() && config()?.enableRemoveButton !== false) {
    <aix-button
        [data-testing]="'remove-button'"
        [isDisabled]="isRemoveButtonDisabled()"
        (click)="removeSelectedRecords()"
        [icon]="'fa-trash-alt'"
        [buttonType]="removeButtonType"
        [buttonLabel]="'Remove'"
    >
    </aix-button>
    } @if (config()?.exportButtonConfig) {
    <aix-loading-button
        [data-testing]="'export-button'"
        class="btn-loading"
        [isLoading]="exportIsLoading()"
        [icon]="'fa-regular fa-upload'"
        [buttonStyle]="'tertiary'"
        [label]="config()?.exportButtonConfig?.label"
        (click)="onExport()"
    >
    </aix-loading-button>
    } @if (allowChanges() && config()?.enableAssignButton !== false) {
    <aix-button
        style="position: initial"
        class="aix-btn aix-btn--link br-link"
        [data-testing]="'assign-button'"
        [isDisabled]="isAssignBoxOpened"
        (click)="isAssignBoxOpened = true"
        [icon]="'fa-arrow-circle-right'"
        [buttonType]="assignLabelButtonType"
        [buttonLabel]="config()?.assignBoxConfig?.assignLabel"
    >
    </aix-button>
    }
</aix-header-section>

<ng-content></ng-content>

@if (isAssignBoxOpened) {
<div class="u-mb16 assign-box-container">
    <aix-assign-box
        [alreadyAddedItems]="stagedRecords()"
        [availableOptions]="allRecords()"
        [labelField]="config()?.assignBoxConfig?.labelField"
        [valueField]="config()?.assignBoxConfig?.valueField"
        [headingTitle]="config()?.assignBoxConfig?.headingTitle"
        [headingText]="config()?.assignBoxConfig?.headingText"
        [dropdownLabel]="config()?.assignBoxConfig?.dropdownLabel"
        [assignLabel]="config()?.assignBoxConfig?.assignLabel"
        [labelStrategy]="config()?.assignBoxConfig?.labelStrategy || defaultLabelStrategy"
        [isLoading]="isLoading()"
        (onHide)="isAssignBoxOpened = false"
        (onFilter)="onFilter($event)"
        (onStageItems)="stageSelectedRecords($event)"
    >
    </aix-assign-box>
</div>
}
<aix-notification #notificationRef [isOpen]="false" [classList]="['u-mb16']" status="alert">
    Changes made. Save the page to apply your changes.
</aix-notification>

<aix-search-box
    [hidden]="!stagedRecords() || stagedRecords().length === 0"
    (onSearch)="onSearch($event)"
    (onStartSearch)="onSearch($event)"
>
</aix-search-box>

<div class="flex-left-top flex-col">
    @if (gridOptions) {
    <aix-data-grid
        #grid
        [noHoverStyle]="noHoverStyle()"
        [domLayout]="'autoHeight'"
        [gridOptions]="gridOptions"
        [rowData]="stagedRecords()"
        [fitColumns]="fitColumns()"
        (cellClicked)="onCellClicked($event)"
        (gridReady)="onGridReady($event)"
    >
    </aix-data-grid>
    }
    <aix-pagination
        [records]="rowCountArray"
        [paginationPageSize]="paginationPageSize"
        [currentPage]="gridApi?.paginationGetCurrentPage() || 0"
        (goToFirstPage)="gridApi?.paginationGoToFirstPage()"
        (goToPreviousPage)="
            gridApi?.paginationGetCurrentPage() !== 0 ? gridApi?.paginationGoToPreviousPage() : null
        "
        (goToPage)="gridApi?.paginationGoToPage($event)"
        (goToNextPage)="gridApi?.paginationGoToNextPage()"
        (goToLastPage)="gridApi?.paginationGoToLastPage()"
    >
    </aix-pagination>
</div>
