import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderSigningMethodAction,
    OrderSigningMethodActionTypes,
    OrderSigningMethodFailureAction,
    OrderSigningMethodSuccessAction
} from './actions';
import { OrderSigningMethodService } from './service';

@Injectable()
export class OrderSigningMethodEffects {
    constructor(private actions$: Actions, private service: OrderSigningMethodService) {}

    orderSigningMethod$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderSigningMethodAction>(OrderSigningMethodActionTypes.ORDER_SIGNING_METHOD),
            switchMap(action =>
                this.service.orderSigningMethod(action.payload).pipe(
                    map(res => new OrderSigningMethodSuccessAction(res)),
                    catchHttpError(error => of(new OrderSigningMethodFailureAction({ error })))
                )
            )
        )
    );
}
