import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderStartReviewActionTypes {
    static readonly ORDER_START_REVIEW = type('[Order Start Review] Order Start Review');
    static readonly ORDER_START_REVIEW_SUCCESS = type(
        '[Order Start Review] Order Start Review Success'
    );
    static readonly ORDER_START_REVIEW_FAILURE = type(
        '[Order Start Review] Order Start Review Failure'
    );
}

export type OrderStartReviewActions =
    | OrderStartReview
    | OrderStartReviewSuccess
    | OrderStartReviewFailure;

export class OrderStartReview implements AixAction {
    readonly type = OrderStartReviewActionTypes.ORDER_START_REVIEW;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderStartReviewSuccess implements AixAction {
    readonly type = OrderStartReviewActionTypes.ORDER_START_REVIEW_SUCCESS;
}

export class OrderStartReviewFailure implements AixAction {
    readonly type = OrderStartReviewActionTypes.ORDER_START_REVIEW_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
