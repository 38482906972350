import { Pipe, PipeTransform } from '@angular/core';
import { ProductRecord } from '@trade-platform/ui-shared';

@Pipe({
    name: 'productsFilter',
    standalone: true
})
export class ProductsPipe implements PipeTransform {
    transform(products: ProductRecord[], filterText = '') {
        const filteredProducts = products;
        const lowercaseFilteredText = filterText.toLowerCase();

        if (!products) {
            return [];
        }

        if (lowercaseFilteredText && lowercaseFilteredText.length > 0) {
            // Searching on fund name, fund sponsor or fund structure
            return filteredProducts.filter(
                product =>
                    product.fundName.toLowerCase().indexOf(lowercaseFilteredText) > -1 ||
                    product.fundSponsor.name.toLowerCase().indexOf(lowercaseFilteredText) > -1 ||
                    (product.fundStructure as string).toLowerCase().indexOf(lowercaseFilteredText) >
                        -1
            );
        }

        // No search text - return all;
        return filteredProducts;
    }
}
