@if (isLoggingIn) {
<aix-loading></aix-loading>
} @if (!isLoggingIn) {
<div class="container u-mt80">
    <h2>Sorry the page you requested may have been moved or deleted.</h2>
    <p>
        <a class="br-link" href="javascript: history.back();"
            >Revisit the previous page
            <i class="fa-regular fa-angle-right" aria-hidden="true"></i>
        </a>
    </p>
    <p>
        <a class="br-link" href="/"
            >Go to the homepage
            <i class="fa-regular fa-angle-right" aria-hidden="true"></i>
        </a>
    </p>
</div>
}
