import { AixAction } from '../aix-action';
import { type } from '../models/type-cache';
import { SetFormDataActionParams } from '@trade-platform/form-fields';

// Load
export class SetFormDataActionTypes {
    static readonly SET_DATA = type('[Form Data] Set Data');
}

export class SetFormDataAction implements AixAction {
    readonly type = SetFormDataActionTypes.SET_DATA;

    constructor(public payload: SetFormDataActionParams) {}
}
