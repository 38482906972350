import { Component, effect, input, output, viewChild, ViewEncapsulation } from '@angular/core';
import {
    CellClickedEvent,
    ColDef,
    FilterChangedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IRowNode
} from 'ag-grid-community';
import { AixDataGridComponent } from '../aix-data-grid/aix-data-grid.component';
import { AixNotificationComponent } from '../aix-notification/aix-notification.component';
import { NotificationConfig } from '../aix-notification/config';
import { AixGridSelectCurrentPageHeaderComponent } from '../cell-renderers/grid-select-current-page-header.component';
import { AixButtonComponent, BUTTON_TYPE } from '../aix-button/aix-button.component';
import { AixPaginationComponent } from '../aix-pagination/aix-pagination.component';
import { AixSearchBoxComponent } from '../aix-search-box/aix-search-box.component';
import { AixAssignBoxComponent } from '../assign-box/assign-box.component';
import { AixLoadingButtonComponent } from '../aix-loading-button/aix-loading-button.component';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgIf } from '@angular/common';
import { AixHeaderSectionComponent } from '../aix-header-section/aix-header-section';

export interface AixAssignBoxConfig {
    labelField: string;
    valueField: string;
    headingTitle: string;
    headingText: string;
    dropdownLabel: string;
    dropdownValueField?: string;
    assignLabel: string;
    removeLabel?: string;
    labelStrategy?: (option: any) => string;

    // To use with AssignBoxCustodians and other special assign boxes
    customProperties?: any;
}

export interface AixTransientDataGridConfig {
    stagedNotificationMessage: string;
    unstagedNotificationMessage: string;
    headingText: string;
    overlayNoRowsTemplate: string;
    columnDefs: ColDef[];
    assignBoxConfig: AixAssignBoxConfig;
    exportButtonConfig?: {
        label: string;
    };
    enableRemoveButton?: boolean;
    enableAssignButton?: boolean;
    isRowSelectableFunction?: (node: IRowNode) => boolean;
}

@Component({
    selector: 'aix-transient-data-grid',
    templateUrl: 'aix-transient-data-grid.component.html',
    styleUrls: ['aix-transient-data-grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        NgIf,
        AixButtonComponent,
        AixDataTestingDirective,
        AixLoadingButtonComponent,
        AixAssignBoxComponent,
        AixNotificationComponent,
        AixSearchBoxComponent,
        AixDataGridComponent,
        AixPaginationComponent
    ]
})
export class AixTransientDataGridComponent {
    notificationRef = viewChild<AixNotificationComponent>('notificationRef');
    grid = viewChild<AixDataGridComponent>('grid');

    defaultLabelStrategy: (option: any) => string = (option: any) =>
        option[<string>this.config()?.assignBoxConfig.labelField];

    currentRecords = input<any[]>([]);
    stagedRecords = input<any[]>([]);
    allRecords = input<any[]>([]);
    config = input<AixTransientDataGridConfig>();
    isLoading = input(false);
    noHoverStyle = input(false);
    exportIsLoading = input(false);
    fitColumns = input(true);
    allowChanges = input(true);

    onFilterAllRecords = output<string>();
    onStageRecords = output<any>();
    onUnstageRecords = output<any>();
    onRecordClicked = output<string>();
    onExportClicked = output();

    gridApi: GridApi;
    gridOptions: GridOptions;
    rowHeight = 48;
    headerHeight = 56;
    rowCountArray: Array<void> = [];
    paginationPageSize = 10;

    isAssignBoxOpened = false;

    notificationConfig: NotificationConfig = {
        status: 'ok',
        message: ''
    };

    removeButtonType = BUTTON_TYPE.link;
    assignLabelButtonType = BUTTON_TYPE.link;

    constructor() {
        effect(() => {
            // Add a checkbox header and a checkbox to each row if changes are allowed;
            const colDefs: ColDef[] = [
                ...(this.allowChanges()
                    ? [
                          {
                              suppressMovable: true,
                              width: 42,
                              minWidth: 42,
                              checkboxSelection: true,
                              showDisabledCheckboxes: true,
                              headerComponent: AixGridSelectCurrentPageHeaderComponent,
                              cellClass: 'aix-checkbox-cell',
                              onCellClicked: (evt: CellClickedEvent) => {
                                  evt.node.setSelected(!evt.node.isSelected());
                              }
                          }
                      ]
                    : []),
                ...(<any>this.config()?.columnDefs)
            ];

            this.gridOptions = {
                columnDefs: colDefs,
                overlayNoRowsTemplate: this.config()?.overlayNoRowsTemplate ?? '',
                rowHeight: this.rowHeight,
                headerHeight: this.headerHeight,
                rowSelection: 'multiple',
                defaultColDef: {
                    resizable: false
                },
                isRowSelectable: this.config()?.isRowSelectableFunction
                    ? this.config()?.isRowSelectableFunction
                    : () => {
                          return true;
                      },
                paginationPageSize: this.paginationPageSize,
                onFilterChanged: this.onFilterChanged.bind(this)
            };

            if (this.gridApi) {
                // Columns being added or removed are not automatically rendered, so explicitly set column defs if we can
                this.gridApi.setGridOption('columnDefs', colDefs);
                // Resize to adjust for potential changes
                if (this.fitColumns()) {
                    setTimeout(() => {
                        this.gridApi?.sizeColumnsToFit();
                    });
                }
            }
        });

        effect(() => {
            this.rowCountArray = new Array(this.stagedRecords()?.length);
        });
    }

    //#region Grid Functions

    private onFilterChanged(event: FilterChangedEvent) {
        event.api.deselectAll();
        this.rowCountArray = new Array(event.api.getDisplayedRowCount());
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        if (this.fitColumns()) {
            setTimeout(() => {
                this.gridApi?.sizeColumnsToFit();
            });
        }
    }

    //#endregion

    //#region Record Functions

    isRemoveButtonDisabled() {
        if (this.gridApi) {
            return ((this.gridApi.getSelectedRows() as any[]) || []).length === 0;
        }
        return true;
    }

    removeSelectedRecords() {
        const records = this.gridApi.getSelectedRows() as any[];
        this.onUnstageRecords.emit(records);
        this.gridApi.deselectAll();
        this.notificationConfig.message = this.config()?.unstagedNotificationMessage as string;
        this.notificationRef()?.openNotification();
    }

    stageSelectedRecords(selectedRecords: any[]) {
        this.onStageRecords.emit(selectedRecords);
        this.notificationConfig.message = this.config()?.stagedNotificationMessage as string;
        this.notificationRef()?.openNotification();
    }

    onCellClicked(evt: CellClickedEvent) {
        if (!evt.colDef.onCellClicked) {
            this.onRecordClicked.emit(evt.data.id);
        }
    }

    onSearch(searchText: string) {
        this.grid()?.search(searchText);
    }

    onFilter(filter: string) {
        this.onFilterAllRecords.emit(filter);
    }

    onExport() {
        this.onExportClicked.emit();
    }

    //#endregion
}
