import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderSubmitActions, OrderSubmitActionTypes } from './actions';

export type OrderSubmitState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderSubmitReducer = (
    state: OrderSubmitState = initialState,
    action: OrderSubmitActions
): OrderSubmitState => {
    switch (action.type) {
        case OrderSubmitActionTypes.ORDER_SUBMIT:
            return inProgress();
        case OrderSubmitActionTypes.ORDER_SUBMIT_SUCCESS:
            return success(null);
        case OrderSubmitActionTypes.ORDER_SUBMIT_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
