import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderSignPayload } from '@trade-platform/ui-shared';
import { OrderSignActions, OrderSignActionTypes } from './actions';

export type OrderSignState = RemoteData<OrderSignPayload, ErrorWrapper>;
const initialState = notAsked<OrderSignPayload, ErrorWrapper>();

export const orderSignReducer = (
    state: OrderSignState = initialState,
    action: OrderSignActions
): OrderSignState => {
    switch (action.type) {
        case OrderSignActionTypes.ORDER_SIGN:
            return inProgress({} as OrderSignPayload);
        case OrderSignActionTypes.ORDER_SIGN_SUCCESS:
            return success(action.payload);
        case OrderSignActionTypes.ORDER_SIGN_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
