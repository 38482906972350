import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class ExternalDocusignRedirectGuard {
    constructor(@Inject(ENVIRONMENT) private readonly environment: IEnvironment) {}

    canActivate(
        r: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const param = r.paramMap.get('externalDocusignUrl');

        // Attempt to parse url param and redirect to it if it passes validation;
        try {
            const url = new URL(param as string);
            if (url.protocol === 'https:' && url.host === this.environment.docusignRedirectHost) {
                window.open(url.href, '_self');
            } else {
                return false;
            }
        } catch (error) {
            console.log(`Failed to construct docusign redirect url.`);
        }

        return false;
    }
}
