import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'aix-currency-as-of-cell',
    template: `
        <b>{{ params.value | currency : 'USD' : 'symbol' : '1.2-2' }}</b
        ><br />
        <p class="sublabel">(as of {{ params.data.recordedAt | date : 'MM/dd/yyyy' }})</p>
    `,
    standalone: true,
    imports: [CurrencyPipe, DatePipe]
})
export class CurrencyAsOfRendererComponent implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }
}
