import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { ProductMaterialActions, ProductMaterialActionTypes } from './actions';
import { ProductMaterial } from '../../models/product-materials/model';

export type ProductMaterialsState = RemoteData<ProductMaterial[], ErrorWrapper>;

export function productMaterialsReducer(
    state: ProductMaterialsState = notAsked(),
    action: ProductMaterialActions
): ProductMaterialsState {
    switch (action.type) {
        case ProductMaterialActionTypes.LOAD_PRODUCT_MATERIALS:
            return inProgress([]);
        case ProductMaterialActionTypes.LOAD_PRODUCT_MATERIALS_SUCCESS:
            return success(action.payload);
        case ProductMaterialActionTypes.LOAD_PRODUCT_MATERIALS_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}
