import { Component } from '@angular/core';
import { FieldDecoration } from '@trade-platform/dynamic-forms';

@Component({
    selector: 'aixIconFieldDecoration',
    template: ` <i [class]="params.icon"></i> `,
    standalone: true
})
export class IconFieldDecorationComponent implements FieldDecoration {
    params: { icon: string };
    hostObservables: FieldDecoration['hostObservables'];
    static readonly REF = 'IconFieldDecoration';
}
