import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    FundFirmActionTypes,
    LoadFirmsByFundAction,
    LoadFirmsByFundFailureAction,
    LoadFirmsByFundSuccessAction
} from './actions';
import { FundFirmsService } from './service';

@Injectable()
export class FundFirmsEffects {
    constructor(private actions$: Actions, private service: FundFirmsService) {}

    loadFirmsByFund$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadFirmsByFundAction>(FundFirmActionTypes.LOAD_FIRMS_BY_FUND),
            switchMap(action => {
                return this.service.getFirmsByFund(action.payload).pipe(
                    map(firms => {
                        return new LoadFirmsByFundSuccessAction({ firms });
                    }),
                    catchHttpError(error => of(new LoadFirmsByFundFailureAction({ error })))
                );
            })
        )
    );
}
