<ng-template #titleRef>
    <div class="u-fw600">{{ title() }}</div>
</ng-template>

<ng-template #contentRef>
    <div [style.height]="showMoreComponent.pixelsToShow()" [style.overflow]="'hidden'" #content>
        <aix-filter-list
            [filters]="filters()"
            [activeFilter]="activeFilter()"
            [showDivider]="showDivider()"
            [showCheckbox]="showCheckbox()"
            (onSelectFilter)="clickFilter($event)"
        >
        </aix-filter-list>
    </div>

    <aix-show-more
        #showMoreComponent
        [title]="title()"
        [activeFilter]="activeFilter()"
        [filters]="filters()"
        [elementView]="content"
    >
    </aix-show-more>
</ng-template>

<aix-expansion-panel
    [headerTemplate]="titleRef"
    [bodyTemplate]="contentRef"
    class="compact u-mt16"
    [arrowPosition]="'left'"
>
</aix-expansion-panel>
