import { JsonDecoder } from 'ts.data.json';
import { FundingInstructions } from '../../models/funding-instructions/model';

// Funding Instruction

export const fundingInstructionDecoder = JsonDecoder.object<FundingInstructions>(
    {
        fundingMethodValue: JsonDecoder.optional(JsonDecoder.string),
        achBankName: JsonDecoder.optional(JsonDecoder.string),
        achAccountType: JsonDecoder.optional(JsonDecoder.string),
        achRoutingNumber: JsonDecoder.optional(JsonDecoder.string),
        achBankAccount: JsonDecoder.optional(JsonDecoder.string)
    },
    'FundingInstruction'
);
