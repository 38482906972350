import { Validators } from '@angular/forms';
import {
    CalculatedFieldConfig,
    CalculatedFieldOptionsConfig,
    Checkboxgroup2FieldConfig,
    CheckboxgroupFieldConfig,
    ControlFieldConfig,
    DateFieldConfig,
    ExpandableTextFieldConfig,
    FieldConfig,
    FieldGroupConfig,
    FieldGroupLightConfig,
    FieldWhenAction,
    flattenFieldConfig,
    isCalculatedExpressionControl,
    isControlConfig,
    isFieldGroupConfig,
    isFieldGroupLightConfig,
    isFieldWhenAction,
    isRepeaterConfig,
    NotificationConfig,
    NumberFieldConfig,
    PercentageFieldConfig,
    RepeaterFieldConfig
} from '@trade-platform/form-fields';

import {
    emailValidator,
    exactLengthValidatorFactory,
    isNonEmptyString,
    objectHasValue,
    validateDateFactory,
    validateEINFactory,
    validateEqualDateFactory,
    validateIntlPhoneFactory,
    validateMaxDateFactory,
    validateMinDateFactory,
    validatePhoneFactory,
    validateSSNFactory,
    validateZipFactory
} from '@trade-platform/ui-utils';
import * as Lazy from 'lazy.js';
import {
    requiredChecksRegExp,
    requiredChecksValidatorFactory
} from './components/checkboxgroup/required-checks-validator';
import { generateRefId } from './dynamic-form.utils';

const validatorParser = (field: ControlFieldConfig) => {
    // Certain field types have default validators. We define them here.
    const validators = (field.validation as string[]) || [];

    if (field.type === 'date') {
        validators.push('date');
    } else if (field.type === 'ssn') {
        validators.push('ssn');
    } else if (field.type === 'ein') {
        validators.push('ein');
    } else if (field.type === 'telephone') {
        validators.push('telephone');
    } else if (field.type === 'intlPhone') {
        validators.push('intlPhone');
    } else if (field.type === 'zip') {
        validators.push('zip');
    }

    const minRegExp = () => /^min\.(\d+(?:\.\d+)?)$/;
    const maxRegExp = () => /^max\.(\d+(?:\.\d+)?)$/;
    const minLengthRegExp = () => /minLength\.(\d+)/;
    const maxLengthRegExp = () => /maxLength\.(\d+)/;
    const exactLengthRegExp = () => /exactLength\.(\d+)/;
    const minDate = () => /minDate\((\D+)?\)\.(.+)/;
    const maxDate = () => /maxDate\((\D+)?\)\.(.+)/;
    const equalDate = () => /equalDate\((\D+)?\)\.(.+)/;

    return validators.map(val => {
        if (val === 'required' && field.type !== 'checkbox') {
            return Validators.required;
        } else if (val === 'required' && field.type === 'checkbox') {
            return Validators.requiredTrue;
        } else if (val === 'date') {
            const dateFieldConfig = field as DateFieldConfig;
            return validateDateFactory(dateFieldConfig.format);
        } else if (val === 'email') {
            return emailValidator();
        } else if (val === 'ssn') {
            return validateSSNFactory();
        } else if (val === 'ein') {
            return validateEINFactory();
        } else if (val === 'telephone') {
            return validatePhoneFactory();
        } else if (val === 'intlPhone') {
            return validateIntlPhoneFactory();
        } else if (val === 'zip') {
            return validateZipFactory();
        } else if (minRegExp().test(val)) {
            const res = minRegExp().exec(val) as RegExpExecArray;
            const min = res[1];
            return Validators.min(parseFloat(min));
        } else if (maxRegExp().test(val)) {
            const res = maxRegExp().exec(val) as RegExpExecArray;
            const max = res[1];
            return Validators.max(parseFloat(max));
        } else if (minLengthRegExp().test(val)) {
            const res = minLengthRegExp().exec(val) as RegExpExecArray;
            const min = res[1];
            return Validators.minLength(parseInt(min, 10));
        } else if (maxLengthRegExp().test(val)) {
            const res = maxLengthRegExp().exec(val) as RegExpExecArray;
            const max = res[1];
            return Validators.maxLength(parseInt(max, 10));
        } else if (exactLengthRegExp().test(val)) {
            const res = exactLengthRegExp().exec(val) as RegExpExecArray;
            const len = res[1];
            return exactLengthValidatorFactory(parseInt(len, 10));
        } else if (requiredChecksRegExp().test(val)) {
            const res = requiredChecksRegExp().exec(val) as RegExpExecArray;
            const requiredChecks = res[1] as 'all' | number;
            return requiredChecksValidatorFactory(requiredChecks);
        } else if (val.indexOf('minDate') > -1) {
            const res = minDate().exec(val);
            const [format, rest] = res ? [res[1], res[2]] : ['', ''];
            return validateMinDateFactory(format, rest);
        } else if (val.indexOf('maxDate') > -1) {
            const res = maxDate().exec(val);
            const [format, rest] = res ? [res[1], res[2]] : ['', ''];
            return validateMaxDateFactory(format, rest);
        } else if (val.indexOf('equalDate') > -1) {
            const res = equalDate().exec(val);
            const [format, rest] = res ? [res[1], res[2]] : ['', ''];
            return validateEqualDateFactory(format, rest);
        }

        throw new Error(`Cannot parse validator "${val}"`);
    });
};

const controlParser = (field: ControlFieldConfig): ControlFieldConfig => ({
    ...field,
    classNames: {
        ...field.classNames,
        host: field.classNames ? field.classNames.host || {} : {}
    },
    validationNames: field.validation as string[],
    validation: validatorParser(field)
});

const dateParser = (field: DateFieldConfig): DateFieldConfig => {
    return {
        ...controlParser(field),
        format: isNonEmptyString(field.format) ? field.format : 'mmddyyyy'
    } as DateFieldConfig;
};

const numberParser = (field: NumberFieldConfig): NumberFieldConfig => {
    return {
        ...controlParser(field),
        allowDecimal: field.allowDecimal === true // defaults to false
    } as NumberFieldConfig;
};

const notificationParser = (field: NotificationConfig): NotificationConfig => {
    return {
        ...field,
        isClosable: field.isClosable !== undefined ? field.isClosable : true
    } as NotificationConfig;
};

const percentageParser = (field: PercentageFieldConfig): PercentageFieldConfig => {
    const maxValidatorRegExp = /max\.\d+$/;
    const hasMaxValidator = !!Lazy(field.validation as string[]).find(validatorName =>
        maxValidatorRegExp.test(validatorName)
    );
    if (!hasMaxValidator) {
        (field.validation as string[]).push('max.100');
    }
    return {
        ...controlParser(field),
        allowNegative: !!field.allowNegative, // defaults to false
        allowDecimal: field.allowDecimal !== false // defaults to true
    } as PercentageFieldConfig;
};

const repeaterParser = (field: RepeaterFieldConfig): RepeaterFieldConfig =>
    ({
        ...controlParser(field),
        limit: objectHasValue(field.limit) ? field.limit : 9999,
        maxVisibleItems: objectHasValue(field.maxVisibleItems) ? field.maxVisibleItems : 9999,
        itemsAreNotRemovableBeforeIndex: objectHasValue(field.itemsAreNotRemovableBeforeIndex)
            ? field.itemsAreNotRemovableBeforeIndex
            : 0,
        itemsAreInvisibleBeforeIndex: objectHasValue(field.itemsAreInvisibleBeforeIndex)
            ? field.itemsAreInvisibleBeforeIndex
            : 0
    } as RepeaterFieldConfig);

const fieldGroupParser = (field: FieldGroupConfig): FieldGroupConfig => ({
    ...field,
    classNames: {
        host: field.classNames ? field.classNames.host || {} : {},
        header: field.classNames ? field.classNames.header || {} : {},
        body: field.classNames ? field.classNames.body || {} : {}
    },
    children: _parseFieldConfig(field.children as FieldConfig[])
});

const fieldGroupLightParser = (field: FieldGroupLightConfig): FieldGroupLightConfig => ({
    ...field,
    children: _parseFieldConfig(field.children as FieldConfig[])
});

const expandableTextParser = (field: ExpandableTextFieldConfig): ExpandableTextFieldConfig => ({
    ...field,
    expandLabel: field.expandLabel ? field.expandLabel : 'Show More',
    collapseLabel: field.collapseLabel ? field.collapseLabel : 'Hide More'
});

const noParsing = (field: FieldGroupConfig): FieldGroupConfig => ({
    ...field,
    classNames: {
        host: field.classNames ? field.classNames.host || {} : {}
    }
});

const nonCtrlParsers = {
    group: fieldGroupParser,
    groupLight: fieldGroupLightParser,
    expandableText: expandableTextParser,
    freeText: noParsing
};

const otherParsers = {
    repeater: repeaterParser,
    percentage: percentageParser,
    date: dateParser,
    number: numberParser,
    notification: notificationParser // TODO: move this to nonCtrlParsers
};

type OtherParserTypes = keyof typeof otherParsers;
type NonCtrlParserTypes = keyof typeof nonCtrlParsers;
type AllParserTypes = OtherParserTypes | NonCtrlParserTypes | keyof typeof controlParser;

const findParser = (_type: AllParserTypes) => {
    if (_type in nonCtrlParsers) {
        return nonCtrlParsers[_type as NonCtrlParserTypes];
    }
    if (_type in otherParsers) {
        return otherParsers[_type as OtherParserTypes];
    }
    return controlParser;
};

function _parseFieldConfig(fields: FieldConfig[]): FieldConfig[] {
    const config = fields.map(field => {
        const parserFn = findParser(field.type as AllParserTypes);
        return parserFn(field as any);
    });
    return config as FieldConfig[];
}

/**
 * Misc
 */

/**
 * All fields must have a refId.
 * This function checks for that and generates a proper refIf in case it's missing
 */
const generateMissingRefIds = (fields: FieldConfig[]): FieldConfig[] => {
    const flattened = flattenFieldConfig(fields);
    flattened.forEach(field => {
        if (!field.refId) {
            field.refId = generateRefId();
        }
    });
    return fields;
};

/**
 * Error checks
 */

/**
 * Check for refIds that start with a non a-z A-Z character that are part of a calculated expression,
 * which is not permitted and throws runtime exceptions.
 */
const validFirstRefIdCharacters = 'abcdefghijklmnopqrstuvwxyz'.split('');
export const checkForInvalidRefIds = (field: FieldConfig, flattened: FieldConfig[]) => {
    const refId = field.refId as string;
    const calcExpThatReferencesThisRefId = Lazy(flattened).find(f => {
        if (
            isCalculatedExpressionControl(f as ControlFieldConfig) &&
            (f as CalculatedFieldConfig).calculated
        ) {
            return (
                (
                    (f as CalculatedFieldConfig).calculated as CalculatedFieldOptionsConfig
                ).calcExp.indexOf(` ${refId} `) > -1
            );
        }
        return false;
    }) as ControlFieldConfig & CalculatedFieldConfig;
    if (calcExpThatReferencesThisRefId) {
        return Lazy(validFirstRefIdCharacters).find(
            ch => ch === refId.charAt(0) || ch.toUpperCase() === refId.charAt(0)
        )
            ? undefined
            : `InvalidRefIdError: refId "${refId}" is invalid because it is part of the "${
                  calcExpThatReferencesThisRefId.refId
              }" calculated expression "${
                  (calcExpThatReferencesThisRefId.calculated as CalculatedFieldOptionsConfig)
                      .calcExp
              }", and refId's that are part of a calculated expression must start with an a-z or A-Z character`;
    }
    return undefined;
};

const checkForDuplicatedRefIds = (field: FieldConfig, flattened: FieldConfig[]) => {
    const refIds = flattened.filter(f => f.refId === field.refId);
    if (refIds.length > 1) {
        return `DuplicatedRefIdError: refId "${field.refId}" has ${refIds.length - 1} duplicates.`;
    }
    return undefined;
};

export const checkForControlsWithoutName = (field: FieldConfig) => {
    if (isControlConfig(field) && !field.name) {
        return `ControlWithoutNameError: Control "${JSON.stringify(
            field
        )}" doesn't have the "name" attribute  defined.`;
    }
    return undefined;
};

const checkForInvalidRelations = (field: FieldConfig, flattened: FieldConfig[]) => {
    if (!isFieldGroupLightConfig(field) && field.relations && field.relations.length > 0) {
        const errors: string[] = [];
        field.relations.forEach(rel => {
            Lazy(rel.when)
                .filter(isFieldWhenAction)
                .each(when_ => {
                    const when = when_ as FieldWhenAction; // this is needed becasue @types/lazy.js@0.5.3 types for filter don't suppport type guards.
                    if (Lazy(flattened).find(f => f.refId === when.refId) === undefined) {
                        const checkboxFields = Lazy(flattened).filter(
                            f => f.type === 'checkboxGroup' || f.type === 'checkboxGroup2'
                        );
                        let foundCheckboxAnyOf = false;
                        checkboxFields.each(checkboxField => {
                            const anyOf = (
                                checkboxField as
                                    | CheckboxgroupFieldConfig
                                    | Checkboxgroup2FieldConfig
                            ).anyOf;
                            if (anyOf && anyOf.some((option: any) => option.refId === when.refId)) {
                                foundCheckboxAnyOf = true;
                            }
                        });
                        if (!foundCheckboxAnyOf) {
                            errors.push(
                                `InvalidRelationError: Relation "${when.refId}" from field "${field.refId}" does not exist.`
                            );
                        }
                    }
                });
        });
        return errors;
    }
    return [undefined];
};

/**
 * Performs error and warning checks to all fields
 */
function doErrorChecks(fields: FieldConfig[], enableDebugging: boolean): FieldConfig[] {
    const { warns, errors } = captureErrors(fields, enableDebugging);
    Lazy(errors)
        .filter(err => err !== undefined)
        .each(err => {
            throw new Error(err);
        });
    if (enableDebugging) {
        Lazy(warns)
            .filter(warn => warn !== undefined)
            .each(warn => {
                console.warn(warn);
            });
    }
    return fields;
}

const emptyGroup = (children: FieldConfig[]): FieldGroupLightConfig => ({
    type: 'groupLight',
    children
});

/**
 * This function applies a few transformations to the field graph:
 * - desugars [] groups.
 * - adds a properly formatted refId to the repeater template group.
 * - applies these transformations recursively to groups.
 */
const fieldConfigTransformations = (fields: FieldConfig[]): FieldConfig[] => {
    return fields.map(field => {
        // It's a group created with `[]`
        if (Array.isArray(field)) {
            const children = field;
            return emptyGroup(fieldConfigTransformations(children));
        }
        // It is a group
        if (isFieldGroupConfig(field)) {
            field.children = fieldConfigTransformations(field.children as FieldConfig[]);
            return field;
        }
        // It is a group-light
        if (isFieldGroupLightConfig(field)) {
            field.children = fieldConfigTransformations(field.children as FieldConfig[]);
            return field;
        }
        // It is a repeater
        if (isRepeaterConfig(field)) {
            field.template.refId = `${field.refId}-row-@index@`;
            return field;
        }

        // It is a normal field
        return field;
    });
};

export function parseFieldConfig(fields: FieldConfig[], enableDebugging: boolean): FieldConfig[] {
    const fieldConfig = fieldConfigTransformations(fields);
    const allWithRefId = generateMissingRefIds(fieldConfig);
    const allWithoutErrors = doErrorChecks(allWithRefId, enableDebugging);
    return _parseFieldConfig(allWithoutErrors);
}

export function printErrorChecks(
    fields: FieldConfig[],
    enableDebugging: boolean
): { errors: (string | undefined)[]; warns: (string | undefined)[] } {
    const fieldConfig = fieldConfigTransformations(fields);
    const allWithRefId = generateMissingRefIds(fieldConfig);
    return captureErrors(allWithRefId, enableDebugging);
}

function captureErrors(
    fields: FieldConfig[],
    enableDebugging: boolean
): { errors: (string | undefined)[]; warns: (string | undefined)[] } {
    const flattened = flattenFieldConfig(fields);
    let warns: (string | undefined)[] = [];
    const errors: (string | undefined)[] = [];
    flattened.forEach(field => {
        errors.push(checkForDuplicatedRefIds(field, flattened));
        errors.push(checkForInvalidRefIds(field, flattened));
        errors.push(checkForControlsWithoutName(field));
        if (enableDebugging) {
            warns = warns.concat(checkForInvalidRelations(field, flattened));
        }
    });
    return { errors, warns };
}
