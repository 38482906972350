import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    input,
    OnInit,
    output,
    viewChild
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup
} from '@angular/forms';
import { objectHasValue } from '@trade-platform/ui-utils';
import { AixDropdownComponent } from '../button-dropdown/dropdown';
import { AixButtonComponent, BUTTON_TYPE } from '../aix-button/aix-button.component';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgIf, NgStyle } from '@angular/common';

type DropdownItem = any;

@Component({
    selector: 'aix-assign-box',
    templateUrl: `assign-box.component.html`,
    styleUrls: ['./assign-box.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        AixDropdownComponent,
        AixButtonComponent,
        AixDataTestingDirective
    ]
})
export class AixAssignBoxComponent implements OnInit {
    containerRef = viewChild<ElementRef<HTMLElement>>('containerRef');

    onHide = output();
    onFilter = output<string>();
    onStageItems = output<DropdownItem[]>();

    labelField = input<string>();
    valueField = input<string>();
    labelStrategy = input<(option: any) => string>();
    alreadyAddedItems = input<DropdownItem[]>();
    availableOptions = input<DropdownItem[]>([]);
    headingTitle = input<string>();
    headingText = input<string>();
    dropdownLabel = input<string>();
    // Use in case the element in the dropdown has a different property name than the element to compare
    dropdownValueField = input<string>();
    assignLabel = input<string>();
    isLoading = input(false);

    selectionForm = new UntypedFormGroup({ selectionDropdown: new UntypedFormControl() });
    // selectionForm = new FormGroup({ selectionDropdown: new FormControl() });

    isOpen = false;
    isClosing = false;
    styles: any = { height: '0px' };

    cancelButtonType = BUTTON_TYPE.link;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        setTimeout(() => (this.styles = { height: '235px', opacity: 1 }));
    }

    onTransitionEnds(event: TransitionEvent) {
        if (event.propertyName === 'height') {
            const h = (<ElementRef>this.containerRef()).nativeElement.getBoundingClientRect()
                .height;
            this.isOpen = h > 0;

            if (this.isOpen && !this.isClosing) {
                this.styles = { height: 'auto', opacity: 1, overflow: 'visible' };
            } else {
                this.isClosing = false;
                this.onHide.emit();
            }
            this.cd.detectChanges();
        }
    }

    isSelectionEmpty() {
        const selection = (this.selectionForm.get('selectionDropdown') as UntypedFormControl).value;
        return !Array.isArray(selection) || selection.length == 0;
    }

    disableStrategy = this.disableFunction.bind(this);

    private disableFunction(option: DropdownItem): boolean {
        if (this.selectionForm && option) {
            if (
                objectHasValue(this.alreadyAddedItems()) &&
                (<DropdownItem[]>this.alreadyAddedItems()).length > 0
            ) {
                const isDisabled =
                    this.alreadyAddedItems()?.find(
                        item =>
                            option[<string>this.valueField()] ===
                            item[this.dropdownValueField() ?? <string>this.valueField()]
                    ) !== undefined;
                return isDisabled;
            }
        }
        return false;
    }

    stageSelectedItems() {
        const selectionDropdown = this.selectionForm.get('selectionDropdown') as UntypedFormControl;
        const selectedItems: DropdownItem[] = selectionDropdown.value;
        this.onStageItems.emit(selectedItems);
        this.hide();
    }

    hide() {
        this.isClosing = true;

        // We can't do a transition from 'auto' to '0px', so we have to set first the current height and then move to 0
        const h = (<ElementRef>this.containerRef()).nativeElement.getBoundingClientRect().height;
        this.styles = { height: `${h}px`, opacity: 1 };
        setTimeout(() => (this.styles = { height: '0px', opacity: 0, overflow: 'hidden' }), 1);
        this.onFilterItems('');
    }

    onFilterItems(filter: string) {
        this.onFilter.emit(filter);
    }
}
