<div
    class="editor aix-form__group aix-form__group--textarea"
    [class.aix-form__control--invalid]="!isValid()"
    [class.aix-form__control--invalid-required]="isRequired() && value.length === 0"
    [class.aix-form__control--invalid-disabled]="isDisabled() && !isValid()"
>
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>

    <ngx-editor
        class="aix-form__control aix-form__control--rich-text"
        [disabled]="isDisabled()"
        [required]="isRequired()"
        [class.aix-disabled]="isDisabled()"
        [editor]="editor"
        [ngModel]="html"
        [placeholder]="''"
    ></ngx-editor>

    <label class="aix-form__label aix-label label">
        <ng-content></ng-content>
    </label>
</div>
