import { JsonDecoder } from 'ts.data.json';
import { Advisor, AdvisorAddress, AdvisorRecord } from '../../models/advisor/model';

// Advisor
export const advisorAdressDecoder = JsonDecoder.object<AdvisorAddress>(
    {
        addressStreet: JsonDecoder.optional(JsonDecoder.string),
        addressCity: JsonDecoder.optional(JsonDecoder.string),
        addressState: JsonDecoder.optional(JsonDecoder.string),
        addressZip: JsonDecoder.optional(JsonDecoder.string)
    },
    'AdvisorAddress'
);

export const advisorDecoder = JsonDecoder.object<Advisor>(
    {
        id: JsonDecoder.optional(JsonDecoder.string),
        fullName: JsonDecoder.optional(JsonDecoder.string),
        firstName: JsonDecoder.string,
        lastName: JsonDecoder.string,
        displayName: JsonDecoder.optional(JsonDecoder.string),
        email: JsonDecoder.string,
        crdNumber: JsonDecoder.optional(
            JsonDecoder.oneOf(
                [JsonDecoder.string, JsonDecoder.number.map(n => n.toString())],
                'string | number'
            )
        ),
        title: JsonDecoder.optional(JsonDecoder.string),
        addressStreet: JsonDecoder.optional(JsonDecoder.string),
        addressCity: JsonDecoder.optional(JsonDecoder.string),
        addressState: JsonDecoder.optional(JsonDecoder.string),
        addressZip: JsonDecoder.optional(JsonDecoder.string),
        phoneMobile: JsonDecoder.optional(
            JsonDecoder.oneOf(
                [JsonDecoder.string, JsonDecoder.number.map(phoneMobile => phoneMobile.toString())],
                'phoneMobile'
            )
        ),
        phoneWork: JsonDecoder.optional(JsonDecoder.string),
        phoneFax: JsonDecoder.optional(JsonDecoder.string),
        birthDate: JsonDecoder.optional(JsonDecoder.string),
        banned: JsonDecoder.optional(JsonDecoder.boolean),
        firmId: JsonDecoder.optional(
            JsonDecoder.oneOf(
                [JsonDecoder.string, JsonDecoder.number.map(n => n.toString())],
                'string | number'
            )
        ),
        firmAdmin: JsonDecoder.optional(JsonDecoder.boolean),
        firmName: JsonDecoder.optional(JsonDecoder.string),
        firmCRDNumber: JsonDecoder.optional(JsonDecoder.string),
        firmAddress: JsonDecoder.optional(advisorAdressDecoder),
        officeName: JsonDecoder.optional(JsonDecoder.string),
        officeId: JsonDecoder.optional(JsonDecoder.string),
        branchName: JsonDecoder.optional(JsonDecoder.string),
        branchNumber: JsonDecoder.optional(JsonDecoder.string),
        branchId: JsonDecoder.optional(JsonDecoder.string),
        branchAddress: JsonDecoder.optional(advisorAdressDecoder),
        hasAdditionalAdvisors: JsonDecoder.optional(JsonDecoder.boolean),
        additionalAdvisors: JsonDecoder.optional(
            JsonDecoder.array(JsonDecoder.succeed, 'AdditionalAdvisors')
        )
    },
    'Advisor'
);

export const advisorListDecoder = JsonDecoder.array<Advisor>(advisorDecoder, 'Advisor[]');

export const advisorRecordDecoder = JsonDecoder.object<AdvisorRecord>(
    {
        id: JsonDecoder.string,
        firstName: JsonDecoder.string,
        lastName: JsonDecoder.string
    },
    'AdvisorRecord'
);
