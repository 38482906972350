import { notAsked, RemoteData, success } from 'ngx-remotedata';
import { AuthActions, AuthActionTypes } from './actions';
import { Auth } from '../../models/auth/model';

export type AuthState = RemoteData<Auth>;
const initialState = notAsked<Auth>();

export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.SET_AUTH:
            return success(action.payload);
        case AuthActionTypes.UNLOAD_AUTH:
            return notAsked();
        default:
            return state;
    }
}
