import { JsonDecoder } from 'ts.data.json';
import { ExternalFirm } from './model';
import { usersByAdvisorDecoder } from '@trade-platform/ui-shared';

export const externalFirmDecoder = JsonDecoder.object<ExternalFirm>(
    {
        id: JsonDecoder.number,
        name: JsonDecoder.string,
        crdNumber: JsonDecoder.optional(JsonDecoder.string),
        type: JsonDecoder.string,
        addressStreet: JsonDecoder.optional(JsonDecoder.string),
        addressCity: JsonDecoder.optional(JsonDecoder.string),
        addressState: JsonDecoder.optional(JsonDecoder.string),
        addressZip: JsonDecoder.optional(JsonDecoder.string),
        users: JsonDecoder.optional(usersByAdvisorDecoder)
    },
    'ExternalFirm'
);

export const externalFirmsDecoder = JsonDecoder.array<ExternalFirm>(
    externalFirmDecoder,
    'ExternalFirms'
);
