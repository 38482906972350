@if (filters() && filters().length > 0) {

<div class="u-fw600 u-mt16" [class.u-pb8]="!!title()">{{ title() }}</div>

<div [style.height]="showMoreComponent.pixelsToShow()" [style.overflow]="'hidden'" #content>
    <aix-checkbox-list
        [filters]="filters()"
        [activeFilter]="activeFilter()"
        (onSelectFilter)="clickFilter($event)"
    >
    </aix-checkbox-list>
</div>

<aix-show-more
    #showMoreComponent
    [title]="title()"
    [activeFilter]="activeFilter()"
    [filters]="filters()"
    [elementView]="content"
>
</aix-show-more>
<div [class.group-divider]="showDivider()"></div>

}
