import { JsonDecoder } from 'ts.data.json';
import { filterListDecoder } from '../filters/decoders';
import { OrderQueueGroup } from '../../models/status/model';

export const orderQueueGroupDecoder = JsonDecoder.array(
    JsonDecoder.object<OrderQueueGroup>(
        {
            key: JsonDecoder.optional(JsonDecoder.string),
            label: JsonDecoder.string,
            queues: JsonDecoder.optional(filterListDecoder)
        },
        'OrderQueueGroup'
    ),
    'OrderQueueGroups[]'
);
