import { FileUploadActions, FileUploadActionTypes } from './actions';
import { FileUploadModel } from '../../models/file-upload/model';

const initialState = (): FileUploadModel => ({
    uploading: false
});

export function fileUploadReducer(
    state: FileUploadModel = initialState(),
    action: FileUploadActions
): FileUploadModel {
    switch (action.type) {
        case FileUploadActionTypes.UPLOAD_START:
            return { ...state, uploading: true };
        case FileUploadActionTypes.UPLOAD_SUCCESS:
        case FileUploadActionTypes.UPLOAD_FAILURE:
        case FileUploadActionTypes.UPLOAD_CANCEL:
            return { ...state, uploading: false };
        default:
            return state;
    }
}
