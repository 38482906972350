import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderDocumentUndeclineActions, OrderDocumentUndeclineActionTypes } from './actions';

export type OrderDocumentUndeclineState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderDocumentUndeclineReducer = (
    state: OrderDocumentUndeclineState = initialState,
    action: OrderDocumentUndeclineActions
): OrderDocumentUndeclineState => {
    switch (action.type) {
        case OrderDocumentUndeclineActionTypes.ORDER_DOCUMENT_UNDECLINE:
            return inProgress();
        case OrderDocumentUndeclineActionTypes.ORDER_DOCUMENT_UNDECLINE_SUCCESS:
            return success(null);
        case OrderDocumentUndeclineActionTypes.ORDER_DOCUMENT_UNDECLINE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
