import { JsonDecoder } from 'ts.data.json';
import { PrivacyPolicy } from '../../models/privacy-policy/model';

export const privacyPolicyDecoder = JsonDecoder.object<PrivacyPolicy>(
    {
        version: JsonDecoder.number,
        date: JsonDecoder.string,
        body: JsonDecoder.string
    },
    'PrivacyPolicy'
);
