import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { ApiFormError } from '@trade-platform/ui-utils';

export class UpdateActionTypes {
    static readonly UPDATE = type('[Generic] Update');
    static readonly UPDATE_SUCCESS = type('[Generic] Update Success');
    static readonly UPDATE_FAILURE = type('[Generic] Update Failure');
    static readonly RESET = type('[Generic] Reset Update');
}

export type UpdateActions<UpdatePayload, UpdatePayloadExtras, UpdateResult, UpdateSuffix> =
    | Update<UpdatePayload, UpdatePayloadExtras, UpdateSuffix>
    | UpdateSuccess<UpdateResult, UpdateSuffix>
    | UpdateFailure<UpdateSuffix>
    | ResetUpdate<UpdateSuffix>;

export class Update<UpdatePayload, UpdatePayloadExtras, UpdateSuffix> implements AixAction {
    readonly type = UpdateActionTypes.UPDATE;

    constructor(
        public payload: {
            updatePayload: UpdatePayload;
            updatePayloadExtras?: UpdatePayloadExtras;
            reducerSuffix: UpdateSuffix;
        }
    ) {}
}

export class UpdateSuccess<UpdateResult, UpdateSuffix> implements AixAction {
    readonly type = UpdateActionTypes.UPDATE_SUCCESS;

    constructor(public payload: { result: UpdateResult; reducerSuffix: UpdateSuffix }) {}
}

export class UpdateFailure<UpdateSuffix> implements AixAction {
    readonly type = UpdateActionTypes.UPDATE_FAILURE;

    constructor(
        public payload: { error: ErrorWrapper | ApiFormError; reducerSuffix: UpdateSuffix }
    ) {}
}

export class ResetUpdate<UpdateSuffix> implements AixAction {
    readonly type = UpdateActionTypes.RESET;

    constructor(public payload: { reducerSuffix: UpdateSuffix }) {}
}
