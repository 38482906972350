import { AixAuthService, ProfileStoreFacade } from '@advisor-ui/app-services';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AixModalComponent, AixUiComponentsModule } from '@trade-platform/ui-components';
import { AixEffectActions, AppState } from '@trade-platform/ui-shared';
import { isInProgress, RemoteData } from 'ngx-remotedata';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
    selector: 'aix-login',
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AixUiComponentsModule, AsyncPipe]
})
export class AixLoginComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    profileState$: Observable<RemoteData<any, any>>;
    isLoading$: Observable<boolean>;

    @ViewChild('logOutModal')
    logOutModal: AixModalComponent;

    constructor(
        private profileStore: ProfileStoreFacade,
        private store: Store<AppState>,
        private actions$: AixEffectActions,
        private authService: AixAuthService
    ) {
        this.profileState$ = this.store.pipe(select(state => state.profile));

        const terms$ = this.store.pipe(select(state => state.terms));
        const brand$ = this.store.pipe(select(state => (state as any).brand));
        this.isLoading$ = combineLatest([this.profileState$, terms$, brand$]).pipe(
            map(states => states.some(isInProgress))
        );
    }

    ngOnInit() {
        this.subscriptions.push(
            this.profileStore.actions.update.failure$.subscribe(() => {
                this.logOutModal.openModal();
            }),
            this.profileStore.actions.get.failure$.subscribe(() => {
                this.logOutModal.openModal();
            })
        );
    }

    logOut() {
        this.authService.callLogout();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
