<section>
    <a
        class="aix-btn aix-btn--link br-link"
        (click)="selectAllProducts()"
        [data-testing]="'clearFilters'"
    >
        Clear Filters
    </a>
    @for (queueGroup of queues(); track queueGroup.key; let last = $last; let index = $index) {
    <aix-checkbox-panel
        [activeFilter]="activeFilter()[queueGroup.key]"
        [title]="queueGroup.label"
        [length]="queues().length"
        [numberShowList]="numberShowList()"
        [filters]="queueGroup.queues || []"
        (onFilter)="setFilter($event, queueGroup.key)"
        [showDivider]="!last"
        [index]="index"
    >
    </aix-checkbox-panel>
    }
</section>
