import { JsonDecoder } from 'ts.data.json';
import {
    UserSharedRepCode,
    UserSharedRepCodeResponse
} from '../../models/shared-rep-codes-grid/model';
import { filterListDecoder } from '../filters/decoders';

export const userSharedRepCodeDecoder = JsonDecoder.object<UserSharedRepCode>(
    {
        id: JsonDecoder.string,
        firstName: JsonDecoder.string,
        lastName: JsonDecoder.string,
        email: JsonDecoder.string,
        status: JsonDecoder.string
    },
    'UserSharedRepCode'
);

export const userSharedRepCodeResponseDecoder = JsonDecoder.object<UserSharedRepCodeResponse>(
    {
        filters: filterListDecoder,
        results: JsonDecoder.dictionary(
            JsonDecoder.array<UserSharedRepCode>(userSharedRepCodeDecoder, 'UserSharedRepCode[]'),
            'Dict UserSharedRepCode[]'
        )
    },
    'UserSharedRepCodeResponse'
);
