import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderReviewCompleteActions, OrderReviewCompleteActionTypes } from './actions';

export type OrderReviewCompleteState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderReviewCompleteReducer = (
    state: OrderReviewCompleteState = initialState,
    action: OrderReviewCompleteActions
): OrderReviewCompleteState => {
    switch (action.type) {
        case OrderReviewCompleteActionTypes.ORDER_REVIEW_COMPLETE:
            return inProgress();
        case OrderReviewCompleteActionTypes.ORDER_REVIEW_COMPLETE_SUCCESS:
            return success(null);
        case OrderReviewCompleteActionTypes.ORDER_REVIEW_COMPLETE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
