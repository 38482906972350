import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, Order } from '@trade-platform/ui-shared';
import { UpdateSettlementDetailsActions, UpdateSettlementDetailsActionTypes } from './actions';

export type UpdateSettlementDetailsState = RemoteData<Order, ErrorWrapper>;
const initialState = notAsked<Order, ErrorWrapper>();

export const updateSettlementDetailsReducer = (
    state: UpdateSettlementDetailsState = initialState,
    action: UpdateSettlementDetailsActions
): UpdateSettlementDetailsState => {
    switch (action.type) {
        case UpdateSettlementDetailsActionTypes.UPDATE_SETTLEMENT_DETAILS:
            return inProgress();
        case UpdateSettlementDetailsActionTypes.UPDATE_SETTLEMENT_DETAILS_SUCCESS:
            return success(action.payload.order);
        case UpdateSettlementDetailsActionTypes.UPDATE_SETTLEMENT_DETAILS_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
