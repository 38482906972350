import { MachineConfig } from 'xstate';
import { LoginContext, LoginSchema } from './login-machine.schema';
import { LoginEvent } from './login-machine.events';

export const context: LoginContext = {
    errors: []
};

export const loginMachineConfig: MachineConfig<LoginContext, LoginSchema, LoginEvent> = {
    id: 'login',
    context,
    initial: 'boot',
    states: {
        boot: {
            on: {
                INIT: 'token',
                INIT_SSO: { target: 'boot', actions: ['loginSSO'] },
                REGISTER: { target: 'boot', actions: ['register'] },
                LOGOUT: [
                    { target: 'boot', cond: 'isLoggedInAuth0', actions: ['logout'] },
                    { target: 'boot', actions: ['login'] }
                ]
            }
        },
        error: {
            entry: 'printError',
            on: {
                LOGOUT: { target: 'boot', actions: ['logout'] }
            },
            exit: 'goToLoading'
        },
        token: {
            invoke: {
                id: 'login',
                src: 'fetchToken',
                onDone: 'outages',
                onError: 'outages'
            },
            on: {
                LOGOUT: { target: 'boot', actions: ['logout'] }
            }
        },
        outages: {
            invoke: {
                id: 'login',
                src: 'fetchOutages'
            },
            on: {
                IGNORE_OUTAGES: 'checkingLogin',
                HAS_NO_OUTAGES: 'checkingLogin',
                HAS_OUTAGES: 'outage',
                LOGOUT: { target: 'boot', actions: ['logout'] }
            }
        },
        outage: {
            type: 'final',
            entry: 'goToOutage'
        },
        checkingLogin: {
            on: {
                '': [
                    {
                        target: 'boot',
                        cond: 'isLoginFailure',
                        actions: ['onLoginFailure', 'login']
                    },
                    {
                        target: 'app',
                        cond: 'isLoggedInApp',
                        actions: ['onAlreadyLoggedIn']
                    },
                    {
                        target: 'profile',
                        cond: 'isLoginSuccessful',
                        actions: ['onLoginSuccessful']
                    },
                    { target: 'boot', actions: ['onNoLoginAttempt'] }
                ],
                LOGOUT: { target: 'boot', actions: ['logout'] }
            }
        },
        profile: {
            invoke: {
                id: 'login',
                src: 'fetchProfile'
            },
            on: {
                TERMS_ACCEPTED: 'redirect',
                TERMS_NOT_ACCEPTED: 'acceptingTerms',
                PROFILE_FAILURE: 'error',
                LOGOUT: { target: 'boot', actions: ['logout'] }
            }
        },
        acceptingTerms: {
            entry: 'goToTerms',
            on: {
                REDIRECT: 'profile',
                LOGOUT: { target: 'boot', actions: ['logout'] }
            }
        },
        redirect: {
            on: {
                '': { target: 'app', actions: ['redirectAfterLogin'] }
            }
        },
        app: {
            on: {
                LOGOUT: { target: 'boot', actions: ['logout'] },
                REDIRECT: 'redirect'
            }
        }
    }
};
