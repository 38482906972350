<div class="grid-columns">
    <a (click)="toggleColumList()">
        <i class="fa-regular fa-gear"></i>
    </a>
    @if (isColumnListVisible) {
    <div class="grid-columns__list">
        <ul>
            @for (column of columns; track column) {
            <li (click)="toggleColumVisibility(column)">
                <label>
                    <input type="checkbox" [checked]="column.isVisible()" />
                    {{ column.getColDef().headerName }}
                </label>
            </li>
            }
        </ul>
    </div>
    }
</div>
