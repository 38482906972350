import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'aixTruncate',
    standalone: true
})
export class AixTruncatePipe implements PipeTransform {
    static defaultEllipsys = '…';

    transform(
        text: string,
        maxLength: number,
        ellipsys: string = AixTruncatePipe.defaultEllipsys
    ): string {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + ellipsys;
    }
}
