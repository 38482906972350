import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderMarkNigoAction,
    OrderMarkNigoFailureAction,
    OrderMarkNigoSuccessAction,
    OrderNigoActionTypes
} from './actions';
import { OrderNigoService } from './service';

@Injectable()
export class OrderNigoEffects {
    constructor(private actions$: Actions, private orderNigoService: OrderNigoService) {}

    markOrderNigo$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderMarkNigoAction>(OrderNigoActionTypes.ORDER_MARK_NIGO),
            switchMap(action =>
                this.orderNigoService.markOrderNigo(action.payload.orderId).pipe(
                    map(res => new OrderMarkNigoSuccessAction(res)),
                    catchHttpError(error => of(new OrderMarkNigoFailureAction({ error })))
                )
            )
        )
    );
}
