import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { Observable } from 'rxjs';

@Injectable()
export class OrderMapSignaturesService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    mapSignatures(payload: { orderId: string }): Observable<any> {
        return this.http.post(
            `${this.environment.api}orders/${payload.orderId}/startMapSignatures`,
            null,
            { responseType: 'text' }
        );
    }
}
