import { Injectable } from '@angular/core';

@Injectable()
export class AixRedirectService {
    constructor() {}

    post(obj: any, url: string, target = '_self') {
        const form = document.createElement('form');
        form.target = target;
        form.method = 'POST'; // or 'post' if appropriate
        form.action = url;
        Object.keys(obj).forEach((param: string) => {
            const mapInput = document.createElement('input');
            mapInput.type = 'hidden';
            mapInput.name = param;
            mapInput.setAttribute('value', obj[param]);
            form.appendChild(mapInput);
        });
        document.body.appendChild(form);
        form.submit();
    }
}
