<div class="doc-preview">
    <aix-thumbnail
        [thumbnail]="data()?.material?.thumbnail"
        [id]="data()?.material?.id"
        [isSelected]="!!data()?.isSelected"
        (actionClick)="onActionClicked($event)"
    ></aix-thumbnail>

    <div class="doc-preview--actionbar flex-left-top">
        @if (selectable()) {
        <label class="checkbox-custom checkbox-custom-label-size u-mt12 u-mr0">
            <input type="checkbox" (change)="onCheck($event)" [checked]="data()?.isSelected" />
            <span class="checkbox-custom__indicator checkbox-custom__indicator-sm"></span>
        </label>
        }

        <div class="flex-col flex-left-top u-ml4 full-width">
            <div class="doc-preview__title-container full-width">
                <p class="doc-preview__title">
                    {{ data()?.material?.name }}
                </p>
                @if (tooltip()) {
                <span
                    class="doc-preview__title-tooltip"
                    aixTooltip
                    [aixTooltipContent]="tooltip()"
                ></span>
                }
            </div>
            @if (data().material.description) {
            <div class="doc-preview__description">{{ data().material.description }}</div>
            } @if (data().material.date) {
            <div class="doc-preview__date">
                {{ data().material.date | date : 'MM/dd/yyyy' }}
            </div>
            }
        </div>
    </div>
</div>
