import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
/**
 * Extract the last emitted value from a stream.
 * @param stream$ The stream you want to extract the value from.
 */
export const extractStreamValue = <T>(stream$: Observable<T>): T => {
    let value: T;
    value = undefined as any; // to make compiler happy
    stream$.pipe(take(1)).subscribe(res => (value = res));
    return value;
};

/**
 * If value is not an observable it is converted to observable, otherwise returns as is.
 */
export const toObservable = <T>(value: T): Observable<T> =>
    value instanceof Observable ? value : of(value);

/**
 * If value is an observable it is extraced and its value returned, otherwise returns as is.
 */
export const fromObservable = <T>(value: T | Observable<T>): T => {
    if (value instanceof Observable) {
        let res: T;
        res = undefined as any; // to make compiler happy
        value.subscribe(v => (res = v));
        return res;
    } else {
        return value;
    }
};
