import { WrappedOption } from './dropdown';

/**
 * Delegates the dropdown selection strategy to an external function.
 *
 * @param item The item we want to compare for selection. i.e. {id: 'us-citizen', name: 'U.S. Citizen'}
 * @param opt The actual WrappedOption ref to compare against
 */
export const selectByIdFunction = (item: any, opt: WrappedOption) => {
    if (item !== '' && item !== null && typeof item !== typeof opt.value) {
        throw new Error(
            `item (${typeof item}) and opt.value (${typeof opt.value}) types don't match`
        );
    }
    return item === opt.value;
};

/**
 * Delegates the dropdown value creation strategy to an external function.
 *
 * @param selection The WrappedOption(s) that need to be converted to a value.
 */
export const unwrapSelectByIdFunction = (selection: WrappedOption[]) => {
    return selection && selection.length > 0 ? selection[0].value : selection;
};
