import { isInProgress, filterSuccess, RemoteData, filterFailure } from 'ngx-remotedata';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiFormError } from '@trade-platform/ui-utils';

/**
 * Emits `true` when any of the provided streams is a `Loading` instance.
 * @param streams$ A comma separated list of RemoteData streams.
 */
export const anyIsLoading = (...streams$: RemoteData<any, any>[]): Observable<boolean> => {
    return combineLatest([streams$]).pipe(map(states => states.some(isInProgress)));
};

export const getDataFromState = <T, E>(state$: Observable<RemoteData<T, E>>): Observable<T> => {
    return state$.pipe(
        filterSuccess(),
        map(a => a.value)
    );
};

const isApiFormError = (err: any): err is ApiFormError => err instanceof ApiFormError;

export const getErrorFromState = <T, E>(state$: Observable<RemoteData<T, E>>): Observable<E> => {
    return state$.pipe(
        filterFailure(),
        filter(f => !isApiFormError(f.error)),
        map(f => f.error)
    );
};

export const getFormErrorFromState = <T, E>(
    state$: Observable<RemoteData<T, E>>
): Observable<E> => {
    return state$.pipe(
        filterFailure(),
        filter(f => isApiFormError(f.error)),
        map(f => f.error)
    );
};
