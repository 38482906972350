import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'aixMaskValue',
    standalone: true
})
export class AixMaskValuePipe implements PipeTransform {
    transform(text: string, maskString = '****'): string {
        const value = text.split('-').join('');
        const lastNumbers = value.slice(text.length - 4, text.length);

        return `${maskString}${lastNumbers}`;
    }
}
