import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderApprovalForm, orderApprovalFormsResponse } from '@trade-platform/ui-shared';

@Injectable()
export class OrderApprovalFormsService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getOrderApprovalForms(orderId: string): Observable<OrderApprovalForm[]> {
        return this.http
            .get<OrderApprovalForm[]>(`${this.environment.api}orders/${orderId}/approvals/forms`)
            .pipe(concatMap(p => fromPromise(orderApprovalFormsResponse.decodeToPromise(p))));
    }
}
