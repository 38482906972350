import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderReinstateResponse } from '@trade-platform/ui-shared';
import { OrderReinstateActions, OrderReinstateActionTypes } from './actions';

export type OrderReinstateState = RemoteData<OrderReinstateResponse, ErrorWrapper>;
const initialState = notAsked<OrderReinstateResponse, ErrorWrapper>();

export function orderReinstateReducer(
    state: OrderReinstateState = initialState,
    action: OrderReinstateActions
): OrderReinstateState {
    switch (action.type) {
        case OrderReinstateActionTypes.ORDER_REINSTATE:
            return inProgress({} as OrderReinstateResponse);
        case OrderReinstateActionTypes.ORDER_REINSTATE_SUCCESS:
            return success(action.payload);
        case OrderReinstateActionTypes.ORDER_REINSTATE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
