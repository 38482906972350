import { AixAction, AixNotification, type } from '@trade-platform/ui-shared';

export class StartOrderActionTypes {
    static readonly SET_TRAINING_MESSAGE = type('[StartOrder] Set Training Message');
    static readonly SET_WARNING_NOTIFICATION = type('[StartOrder] Set Warning Notification');
    static readonly SET_ERROR_NOTIFICATION = type('[StartOrder] Set Error Notification');
}

export type StartOrderActions = SetTrainingMessage | SetWarningNotification | SetErrorNotification;

export class SetTrainingMessage implements AixAction {
    readonly type = StartOrderActionTypes.SET_TRAINING_MESSAGE;

    constructor(public payload: string) {}
}

export class SetWarningNotification implements AixAction {
    readonly type = StartOrderActionTypes.SET_WARNING_NOTIFICATION;

    constructor(public payload: AixNotification) {}
}

export class SetErrorNotification implements AixAction {
    readonly type = StartOrderActionTypes.SET_ERROR_NOTIFICATION;

    constructor(public payload: AixNotification) {}
}
