import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DecimalPipe, CurrencyPipe } from '@angular/common';

@Component({
    selector: 'aix-currency-shares-cell',
    template: `
        <div>{{ params.value | currency : 'USD' : 'symbol' : '1.2-2' }}</div>
        <div class="-text--info">{{ params.data.units | number : '1.3-3' }} Shares</div>
    `,
    standalone: true,
    imports: [DecimalPipe, CurrencyPipe]
})
export class CurrencySharesRendererComponent implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }
}
