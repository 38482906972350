<div class="aix-viewer__document-wrapper" [ngStyle]="styles.pdfWrapper">
    <div class="aix-viewer__document">
        @if ((storeFacade.orderRemoteData$ | async | isInProgress) || (loadingImage | async) ||
        (pdf?.isLoading$ | async)) {
        <aix-loading></aix-loading>
        } @if (PDFJSloaded && pdfLoaded && pdfLoadError) {
        <div class="error-box">
            <div class="error-box__message">We apologize for the delay.</div>
            <div class="error-box__dev-message">
                A connectivity error has occurred. Please try again in a few minutes.
            </div>
        </div>
        } @if (isSpreadsheet) {
        <aix-notification [status]="'warning'" [isOpen]="true" [canClose]="false">
            Download in order to view file.
        </aix-notification>
        } @if (isPdf && !this.isSpreadsheet) {
        <aix-pdf-viewer
            [PDFJSready]="PDFJSloaded"
            [src]="pdfSource"
            [authToken]="authToken"
            (loadDocument)="onLoadDocument()"
            (loadDocumentError)="onLoadDocumentError($event)"
            (updatePage)="currentPage = $event + 1"
        >
        </aix-pdf-viewer>
        } @if (pdfSource?.url; as url) {

        <ng-container *ngrxLet="url | aixSecure as srcUrl">
            @if (!isPdf && !this.isSpreadsheet) {
            <aix-image-viewer [src]="srcUrl" (loadImage)="onLoadImage()"></aix-image-viewer>
            }
        </ng-container>

        }

        <div class="aix-viewer__controls-container" [ngStyle]="documentControls">
            <div class="aix-viewer__controls aix-viewer__controls-top">
                <div class="aix-viewer__controls-inner__full">
                    <div class="aix-viewer__icon-wrapper">
                        <a
                            class="aix-viewer__left-arrow"
                            title="Previous Document"
                            (click)="onClickPrevDocument()"
                        >
                            <i
                                class="fa-regular fa-arrow-left aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <span
                        class="aix-viewer__controls-label"
                        *ngrxLet="storeFacade.documentViewer$ as documentViewer"
                    >
                        Document
                        <span class="aix-viewer__pager-white"
                            >{{ getCurrentIndex(documentViewer.currentDocument) + 1 }} /
                        </span>
                        {{ documentViewer.filesToDisplay.length }}
                    </span>
                    <div class="aix-viewer__icon-wrapper__right">
                        <a
                            class="aix-viewer__right-arrow"
                            title="Next Document"
                            (click)="onClickNextDocument()"
                        >
                            <i
                                class="fa-regular fa-arrow-right aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="aix-viewer__controls">
                <div class="aix-viewer__controls-inner__left">
                    <div class="aix-viewer__icon-wrapper">
                        <a class="aix-viewer__zoom-out u-mt8" title="Zoom Out" (click)="zoomOut()">
                            <i
                                class="fa-regular fa-circle-minus aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="aix-viewer__icon-wrapper">
                        <a
                            class="aix-viewer__fullscreen-toggle"
                            title="Toggle Fullscreen"
                            [ngClass]="{ fullscreen: pdfFullscreen }"
                            (click)="onClickFullscreen()"
                        >
                            <i
                                class="fa-regular fa-maximize aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="aix-viewer__icon-wrapper">
                        <a class="aix-viewer__rotate" title="Rotate" (click)="onClickRotate()">
                            <i
                                class="fa-regular fa-rotate-right aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="aix-viewer__icon-wrapper">
                        <a class="aix-viewer__zoom-in" title="Zoom In" (click)="zoomIn()">
                            <i
                                class="fa-regular fa-circle-plus aix-viewer__icon"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                </div>
                @if (canDisplayPagination()) {
                <div class="aix-viewer__controls-inner__right">
                    <div class="aix-viewer__controls-label">
                        Page <span class="aix-viewer__pager-white">{{ currentPage }} / </span>
                        {{ pdf.totalPages }}
                    </div>
                </div>
                } @if (!canDisplayPagination()) {
                <div class="aix-viewer__controls-inner__right">
                    <div class="aix-viewer__controls-label">
                        Page <span class="aix-viewer__pager-white">1 / </span>1
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</div>
