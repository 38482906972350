import { Type } from '@angular/core';
import { FieldDecoration } from './field-decoration.directive';

export abstract class FieldDecorationMap {
    private static FIELD_DECORATION_MAP: Record<string, Type<FieldDecoration>> = {};

    static add(ref: string, type: Type<FieldDecoration>) {
        FieldDecorationMap.FIELD_DECORATION_MAP[ref] = type;
    }

    static get(ref: string) {
        if (!FieldDecorationMap.exists(ref)) {
            throw new Error(`FieldDecoration "${ref}" isn't present in the decorations map.`);
        }
        return FieldDecorationMap.FIELD_DECORATION_MAP[ref];
    }

    static exists(ref: string) {
        return FieldDecorationMap.FIELD_DECORATION_MAP[ref] !== undefined;
    }
}
