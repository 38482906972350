import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderDocumentDeclineActions, OrderDocumentDeclineActionTypes } from './actions';

export type OrderDocumentDeclineState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderDocumentDeclineReducer = (
    state: OrderDocumentDeclineState = initialState,
    action: OrderDocumentDeclineActions
): OrderDocumentDeclineState => {
    switch (action.type) {
        case OrderDocumentDeclineActionTypes.ORDER_DOCUMENT_DECLINE:
            return inProgress();
        case OrderDocumentDeclineActionTypes.ORDER_DOCUMENT_DECLINE_SUCCESS:
            return success(null);
        case OrderDocumentDeclineActionTypes.ORDER_DOCUMENT_DECLINE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
