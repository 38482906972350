import { Component, forwardRef, input, model, output } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    Validators
} from '@angular/forms';
import {
    AixCheckboxItemComponent,
    CheckboxRendererItem
} from '../aix-checkbox-item/aix-checkbox-item.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'aix-checkbox-renderer',
    templateUrl: 'aix-checkbox-render.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixCheckboxRendererComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixCheckboxRendererComponent
        }
    ],
    standalone: true,
    imports: [NgFor, AixCheckboxItemComponent]
})
export class AixCheckboxRendererComponent implements ControlValueAccessor {
    isRequired = input<boolean>(false);
    isValid = model<boolean>(false);

    checkboxTree: CheckboxRendererItem[] = [];
    hasRequiredError = false;
    isDirty = false;

    onCheckboxChange = output<string>();

    _propagateChanges: (value: any[]) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    onClickCheckbox() {
        this.isValid.set(this.checkboxTree?.some(item => item.checked));
        this.isDirty = true;
        this.onCheckboxChange.emit('dirty');
        this._propagateChanges(this.checkboxTree);
    }

    validate(ctrl: UntypedFormControl) {
        const isRequired = ctrl.hasValidator(Validators.required);
        this.hasRequiredError =
            !this.checkboxTree?.some(item => item.checked) && (this.isRequired() || isRequired);
        return this.hasRequiredError ? { required: true } : null;
    }

    writeValue(value: any) {
        this.checkboxTree = value;
    }

    registerOnChange(fn: (value: any[]) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
