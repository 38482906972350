import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';

export class ActionTypes {
    static readonly SET_BANNER_OFFSET = type('[Styles] Set Banner Offset');
    static readonly CLEAR_BANNER_OFFSET = type('[Styles] Clear Banner Offset');
}

export type StylesActions = SetBannerOffsetAction | ClearBannerOffsetAction;

export class SetBannerOffsetAction implements AixAction {
    readonly type = ActionTypes.SET_BANNER_OFFSET;

    constructor(public payload: { bannerOffsetPx: number }) {}
}

export class ClearBannerOffsetAction implements AixAction {
    readonly type = ActionTypes.CLEAR_BANNER_OFFSET;
}
