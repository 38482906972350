import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    LoadRepCodesByAdvisorAction,
    LoadRepCodesByAdvisorFailureAction,
    LoadRepCodesByAdvisorSuccessAction,
    RepCodesByAdvisorActionTypes
} from './actions';
import { RepCodessByAdvisorService } from './service';

@Injectable()
export class RepCodesByAdvisorEffects {
    constructor(private actions$: Actions, private service: RepCodessByAdvisorService) {}

    loadRepCodesByAdvisor$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadRepCodesByAdvisorAction>(
                RepCodesByAdvisorActionTypes.LOAD_REPCODES_BY_ADVISOR
            ),
            switchMap(action => {
                return this.service.getRepCodesByAdvisor(action.payload).pipe(
                    map(repcodes => {
                        return new LoadRepCodesByAdvisorSuccessAction(repcodes);
                    }),
                    catchHttpError(error => of(new LoadRepCodesByAdvisorFailureAction({ error })))
                );
            })
        )
    );
}
