import { Inject, Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

type WrappedAction = { timestamp: string; action: Action };

@Injectable()
export class LatestActionsService {
    readonly SENTRY_LATEST_ACTIONS_KEY = 'latest_actions';
    readonly NUM_ACTIONS_TO_SAVE = 7;

    private _actions: WrappedAction[] = [];
    get actions(): ReadonlyArray<WrappedAction> {
        return this._actions;
    }

    constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {}

    /**
     * Adds an Action to the beginning of the latest actions Array and removes the latest when length exceeds NUM_ACTIONS_TO_SAVE
     * @param action Action
     */
    addAction(action: Action) {
        this._actions = [
            {
                timestamp: new Date().toISOString(),
                action
            },
            ...this._actions
        ].slice(0, this.NUM_ACTIONS_TO_SAVE);
    }
    /**
     * Configures Sentry scope for the next reported error with the latest NUM_ACTIONS_TO_SAVE saved actions
     */
    setCurrentSentryContext() {
        if (this.environment.sentry.sendLatestActions) {
            Sentry.getCurrentScope().setExtra(
                this.SENTRY_LATEST_ACTIONS_KEY,
                JSON.stringify(this._actions)
            );
        }
    }
}
