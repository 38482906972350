<div class="u-mt16">
    <p class="u-mt6 u-mb16">Please label the documents you uploaded.</p>
    <form [formGroup]="relationForm">
        <div formArrayName="relations">
            @for (relation of relationsControls; track relation; let i = $index) {
            <div>
                @if (filterBy ? supplementalFiles[i]?.id === filterBy :
                !supplementalFiles[i].contextMap && !supplementalFiles[i]?.supplementalFileTypeId) {
                <div>
                    <p class="u-mt16 u-mb8" [data-testing]="'document-name'">
                        {{ supplementalFiles[i]?.name }}
                    </p>

                    <div class="aix-flex-grid aix-form__container" [formGroupName]="i">
                        <div class="aix-form__group aix-flex-grid__col--1of2">
                            <aix-dropdown
                                #documentTypeDropdownRef
                                formControlName="supplementalFileTypeId"
                                labelField="name"
                                valueField="id"
                                placeholder="Select Document Type"
                                class="aix-form__control--required"
                                [data-testing]="'document-type-dropdown'"
                                [class.aix-form__control--invalid]="
                                    (relationForm?.value?.relations[i].supplementalFileTypeId?.length >= 0 ?
                                    !relationForm?.value?.relations[i].supplementalFileTypeId?.length :
                                    !relationForm?.value?.relations[i].supplementalFileTypeId)
                                "
                                [class.aix-form__control--invalid-required]="
                                    (relationForm?.value?.relations[i].supplementalFileTypeId?.length >= 0 ?
                                    !relationForm?.value?.relations[i].supplementalFileTypeId?.length :
                                    !relationForm?.value?.relations[i].supplementalFileTypeId)
                                "
                                [options]="documentTypes"
                            >
                            </aix-dropdown>
                            <label class="aix-form__label aix-label">Document type</label>
                        </div>

                        <div class="aix-form__group aix-flex-grid__col--1of2">
                            <aix-dropdown
                                #investorDropdownRef
                                formControlName="owner"
                                labelField="fullName"
                                valueField="contextMap"
                                placeholder="Select Investor"
                                [data-testing]="'investor-dropdown'"
                                class="aix-form__control--required"
                                [class.aix-form__control--invalid]="
                                    (relationForm?.value?.relations[i].owner?.length >= 0 ?
                                    !relationForm?.value?.relations[i].owner?.length :
                                    !relationForm?.value?.relations[i].owner)
                                "
                                [class.aix-form__control--invalid-required]="
                                    (relationForm?.value?.relations[i].owner?.length >= 0 ?
                                    !relationForm?.value?.relations[i].owner?.length :
                                    !relationForm?.value?.relations[i].owner)
                                "
                                [options]="owners"
                            >
                            </aix-dropdown>
                            <label class="aix-form__label aix-label">Associated with</label>
                        </div>

                        @if (enablePrivate && !supplementalFiles[i].isRequired) {
                        <div class="aix-form__group aix-flex-grid__col--1of2">
                            <aix-checkbox
                                formControlName="isPrivate"
                                [data-testing]="'isPrivateCheckbox'"
                                [isValid]="true"
                                [isRequired]="false"
                            >
                                <span>Mark as Private</span>
                                <span class="checkbox-custom__sublabel"
                                    >Private documents are only visible to members within your
                                    organization with the same Reviewer / Compliance role.</span
                                >
                            </aix-checkbox>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </form>
</div>

<div class="aix-toolbar u-mt24">
    <aix-button
        [buttonType]="cancelButtonType"
        [buttonLabel]="'Cancel'"
        class="offline--view-pdf"
        (click)="cancelFileUpload()"
    >
    </aix-button>
    <aix-button
        [buttonType]="saveButtonType"
        [data-testing]="'save-button'"
        [isDisabled]="formIsInvalid || (store.orderUpdateFilesRemoteData$ | async | isInProgress)"
        (click)="clickSaveRelations()"
        [buttonLabel]="'Save'"
    >
    </aix-button>
</div>
