import { AixAction } from '../aix-action';
import { type } from '../models/type-cache';

export class ResetStoreActionTypes {
    static readonly RESET_STORE_STATES = type('[Reset Store] Rest Store States');
}

export class ResetStoreStatesAction implements AixAction {
    readonly type = ResetStoreActionTypes.RESET_STORE_STATES;

    constructor() {}
}

export type ResetStoreActions = ResetStoreStatesAction;
