<div class="tooltip-container">
    <span class="aix-pill tag tooltip-trigger" [class]="color()" [style.color]="labelColor()">
        @if (icon()) {
            <i [class]="icon()" class="u-mr4"></i>
        }
        <span>{{label()}}</span>
    </span>
    @if (tooltip()) {
        <div class="tooltip aixTooltip__tooltip">{{tooltip()}}</div>
    }
</div>
