import {
    AixAction,
    ErrorWrapper,
    OrderMarkNeedsClarificationRequest,
    OrderMarkNeedsClarificationResponse,
    type
} from '@trade-platform/ui-shared';

export class OrderNeedsClarificationActionTypes {
    static readonly ORDER_MARK_NEEDS_CLARIFICATION = type(
        '[OrderNeedsAction] Mark Order Needs Clarification'
    );
    static readonly ORDER_MARK_NEEDS_CLARIFICATION_SUCCESS = type(
        '[OrderNeedsAction] Mark Order Needs Clarification Success'
    );
    static readonly ORDER_MARK_NEEDS_CLARIFICATION_FAILURE = type(
        '[OrderNeedsAction] Mark Order Needs Clarification Failure'
    );
}

export type OrderNeedsClarificationActions =
    | OrderMarkNeedsClarificationAction
    | OrderMarkNeedsClarificationSuccessAction
    | OrderMarkNeedsClarificationFailureAction;

export class OrderMarkNeedsClarificationAction implements AixAction {
    readonly type = OrderNeedsClarificationActionTypes.ORDER_MARK_NEEDS_CLARIFICATION;

    constructor(public payload: OrderMarkNeedsClarificationRequest) {}
}
export class OrderMarkNeedsClarificationSuccessAction implements AixAction {
    readonly type = OrderNeedsClarificationActionTypes.ORDER_MARK_NEEDS_CLARIFICATION_SUCCESS;

    constructor(public payload: OrderMarkNeedsClarificationResponse) {}
}
export class OrderMarkNeedsClarificationFailureAction implements AixAction {
    readonly type = OrderNeedsClarificationActionTypes.ORDER_MARK_NEEDS_CLARIFICATION_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
