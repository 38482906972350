import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { PrivacyPolicy } from '../../models/privacy-policy/model';

export class PrivacyPolicyActionTypes {
    static readonly CLEAR_PRIVACY_POLICY = type('[PrivacyPolicy] Clear Privacy Policy');

    static readonly LOAD_PRIVACY_POLICY = type('[PrivacyPolicy] Load Privacy Policy');
    static readonly LOAD_PRIVACY_POLICY_SUCCESS = type(
        '[PrivacyPolicy] Load Privacy Policy Success'
    );
    static readonly LOAD_PRIVACY_POLICY_FAIL = type('[PrivacyPolicy] Load Privacy Policy Failure');

    static readonly LOAD_PRIVACY_POLICY_FROM_LOCAL_STORAGE = type(
        '[PrivacyPolicy] Load Privacy Policy From Local Storage'
    );
    static readonly SET_PRIVACY_POLICY = type('[PrivacyPolicy] Set Privacy Policy');
}

export type PrivacyPolicyActions =
    | ClearPrivacyPolicyAction
    | LoadPrivacyPolicyAction
    | LoadPrivacyPolicySuccessAction
    | LoadPrivacyPolicyFailureAction
    | LoadPrivacyPolicyFromStorageAction
    | SetPrivacyPolicyAction;

export class ClearPrivacyPolicyAction implements AixAction {
    readonly type = PrivacyPolicyActionTypes.CLEAR_PRIVACY_POLICY;

    constructor() {}
}
export class LoadPrivacyPolicyAction implements AixAction {
    readonly type = PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY;
    payload?: any;

    constructor() {}
}
export class LoadPrivacyPolicySuccessAction implements AixAction {
    readonly type = PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY_SUCCESS;

    constructor(public payload: PrivacyPolicy) {}
}
export class LoadPrivacyPolicyFailureAction implements AixAction {
    readonly type = PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}
export class LoadPrivacyPolicyFromStorageAction implements AixAction {
    readonly type = PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY_FROM_LOCAL_STORAGE;

    constructor() {}
}
export class SetPrivacyPolicyAction implements AixAction {
    readonly type = PrivacyPolicyActionTypes.SET_PRIVACY_POLICY;

    constructor(public payload: PrivacyPolicy) {}
}
