<button
    type="button"
    class="aix-btn"
    [class.aix-btn--tertiary-inverted]="solid"
    [class.br-btn]="solid"
    [class.aix-btn--link]="!solid"
    [class.br-link]="!solid"
    [data-testing]="config.refId!"
    (click)="onClickButton()"
>
    @if (config.icon) {
    <span [class]="config.icon"></span>
    }
    <span [class.u-ml6]="config.icon" [class.u-fw600]="bold">{{ config.label }}</span>
</button>
