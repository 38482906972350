<section>
    <aix-header-section [title]="'Status Log'"></aix-header-section>

    @if ((store.orderStatusHistorySuccess$ | async | aixStatusHistory)?.length > 0) {
    <aix-data-grid
        [domLayout]="'autoHeight'"
        [gridOptions]="gridOptions"
        [rowData]="store.orderStatusHistorySuccess$ | async | aixStatusHistory"
        (gridReady)="onGridReady($event)"
    >
    </aix-data-grid>
    } @if (!(store.orderStatusHistoryRemoteData$ | async | isInProgress) &&
    (store.orderStatusHistorySuccess$ | async | aixStatusHistory)?.length === 0) {
    <div [data-testing]="'no-log-message'">
        Unable to show status log, this order was created before status logs were made available.
    </div>
    } @if ((store.orderStatusHistorySuccess$ | async | aixStatusHistory)?.length >
    paginationPageSize) {
    <div class="u-mt16 hide-if-print">
        <aix-pagination
            [records]="store.orderStatusHistorySuccess$ | async | aixStatusHistory"
            [paginationPageSize]="paginationPageSize"
            [currentPage]="gridApi?.paginationGetCurrentPage()"
            (goToFirstPage)="gridApi?.paginationGoToFirstPage()"
            (goToPreviousPage)="gridApi?.paginationGoToPreviousPage()"
            (goToPage)="gridApi?.paginationGoToPage($event)"
            (goToNextPage)="gridApi?.paginationGoToNextPage()"
            (goToLastPage)="gridApi?.paginationGoToLastPage()"
        >
        </aix-pagination>
    </div>
    }
</section>
