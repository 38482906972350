import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges,
    viewChildren,
    ViewEncapsulation
} from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'aix-pagination',
    templateUrl: './aix-pagination.component.html',
    styleUrls: ['./aix-pagination.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, AixDataTestingDirective]
})
export class AixPaginationComponent implements OnChanges, AfterViewInit {
    paginationPageSize = input(9);
    currentPage = input(0);
    records = input<any[]>([]);
    maxPages = input(10);

    calculatedMaxPages = model(this.maxPages());

    goToFirstPage = output<void>();
    goToPreviousPage = output<void>();
    goToPage = output<number>();
    goToNextPage = output<void>();
    goToLastPage = output<void>();

    pages: {
        num: number;
        label: string;
    }[] = [];

    buttons = viewChildren<ElementRef>('buttons');

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['maxPages']) {
            this.calculatedMaxPages.set(this.maxPages());
        }
        if (changes['records'] && changes['records'].currentValue) {
            this.updatePages(changes['records'].currentValue);
        }
        if (changes['currentPage'] && changes['currentPage'].currentValue >= 0) {
            if (this.records() && this.records().length) {
                this.updatePages(this.records());
            }
        }
        if (changes['paginationPageSize']) {
            if (this.records() && this.records().length) {
                this.updatePages(this.records());
            }
        }
    }

    updatePages(records: any[]) {
        if (records && records.length > this.paginationPageSize()) {
            const totalPages = Math.ceil(records.length / this.paginationPageSize());
            const maxPages = Math.min(totalPages, this.calculatedMaxPages());
            const shownPages = new Array(maxPages);
            let firstPage = Math.max(
                Math.ceil(this.currentPage() - this.paginationPageSize() / 2),
                0
            );

            // We are showing the last page, so we can't show lastPage+1
            if (firstPage + this.calculatedMaxPages() >= totalPages) {
                firstPage = Math.max(totalPages - this.calculatedMaxPages(), 0);
            }

            if (maxPages > totalPages) {
                if (firstPage + maxPages > totalPages) {
                    firstPage = totalPages - this.paginationPageSize() - 1;
                }
            }

            this.pages = shownPages.fill(firstPage).map((start, index) => {
                return { num: start + index, label: (start + index + 1).toString(10) };
            });
            this.changeDetector.detectChanges();
            this.recalculateMaxPages();
        }
    }

    private amountOfWrappedButtons() {
        if (this.buttons() && this.buttons()[0]) {
            const firstOffsetTop = this.buttons()[0].nativeElement.offsetTop;
            const firstWrappedIndex = this.buttons().findIndex(button => {
                return button.nativeElement.offsetTop !== firstOffsetTop;
            });
            return firstWrappedIndex > -1 ? this.buttons().length - firstWrappedIndex : 0;
        }
        return 0;
    }

    private recalculateMaxPages() {
        this.calculatedMaxPages.set(this.maxPages());
        if (this.amountOfWrappedButtons() > 0) {
            this.calculatedMaxPages.set(this.maxPages() - this.amountOfWrappedButtons());
            this.updatePages(this.records());
        }
    }

    ngAfterViewInit() {
        this.recalculateMaxPages();
    }
}
