import {
    AixAction,
    ErrorWrapper,
    OrderSigningMethodPayload,
    OrderSigningMethodResponse,
    type
} from '@trade-platform/ui-shared';

export class OrderSigningMethodActionTypes {
    static readonly ORDER_SIGNING_METHOD = type('[OrderSigningMethod] Signing Method');
    static readonly ORDER_SIGNING_METHOD_SUCCESS = type(
        '[OrderSigningMethod] Signing Method Success'
    );
    static readonly ORDER_SIGNING_METHOD_FAILURE = type(
        '[OrderSigningMethod] Signing Method Failure'
    );
}

export type OrderSigningMethodActions =
    | OrderSigningMethodAction
    | OrderSigningMethodSuccessAction
    | OrderSigningMethodFailureAction;

export class OrderSigningMethodAction implements AixAction {
    readonly type = OrderSigningMethodActionTypes.ORDER_SIGNING_METHOD;

    constructor(public payload: OrderSigningMethodPayload) {}
}
export class OrderSigningMethodSuccessAction implements AixAction {
    readonly type = OrderSigningMethodActionTypes.ORDER_SIGNING_METHOD_SUCCESS;

    constructor(public payload: OrderSigningMethodResponse | undefined) {}
}
export class OrderSigningMethodFailureAction implements AixAction {
    readonly type = OrderSigningMethodActionTypes.ORDER_SIGNING_METHOD_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
