import {
    currencyFormatter,
    currencyFormatterOrEmDash,
    orderNumberFormatter,
    dateFormatter,
    sharesFormatter
} from './formatters';

export class GridUtils {
    static formatters = {
        currencyFormatter,
        currencyFormatterOrEmDash,
        orderNumberFormatter,
        dateFormatter,
        sharesFormatter
    };
}
