import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import { SettlementDetailsService } from './service';
import {
    UpdateSettlementDetailsAction,
    UpdateSettlementDetailsActionTypes,
    UpdateSettlementDetailsFailureAction,
    UpdateSettlementDetailsSuccessAction
} from './update/actions';

@Injectable()
export class SettlementDetailsEffects {
    constructor(private actions$: Actions, private service: SettlementDetailsService) {}

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateSettlementDetailsAction>(
                UpdateSettlementDetailsActionTypes.UPDATE_SETTLEMENT_DETAILS
            ),
            switchMap(action =>
                this.service.update(action.payload.orderId, action.payload.details).pipe(
                    map(order => new UpdateSettlementDetailsSuccessAction({ order })),
                    catchHttpError(error =>
                        of(
                            new UpdateSettlementDetailsFailureAction({
                                error
                            })
                        )
                    )
                )
            )
        )
    );
}
