import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'aix-ticker-name-cell',
    template: `
        <div class="br-primary__color u-fw600">{{ params.value }}</div>
        <div class="-text--info">{{ params.data.displayName }}</div>
    `,
    standalone: true
})
export class TickerNameRendererComponent implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }
}
