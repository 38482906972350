import { Inject, Injectable } from '@angular/core';
import {
    AbstractLogger,
    DateFormat,
    DateFormatEnum,
    LFService,
    LogFormat,
    Logger,
    LoggerFactory,
    LoggerFactoryOptions,
    LoggerType,
    LogGroupRule,
    LogLevel,
    LogMessage
} from 'typescript-logging';
import { ENVIRONMENT, IEnvironment } from './injection-tokens';
import { getFromStorage } from './storage';

class EmptyLogger extends AbstractLogger {
    constructor(name: string, settings: any) {
        super(name, settings);
    }

    protected doLog(msg: LogMessage): void {
        // silence
    }
}

@Injectable()
export class LogService {
    private logger: LoggerFactory;

    constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {
        const options = new LoggerFactoryOptions().addLogGroupRule(
            new LogGroupRule(
                new RegExp('.+'),
                LogLevel.Debug,
                new LogFormat(new DateFormat(DateFormatEnum.Default, '/'), false)
            )
        );

        const emptyLoggerOptions = new LoggerFactoryOptions().addLogGroupRule(
            new LogGroupRule(
                new RegExp('.+'),
                LogLevel.Debug,
                new LogFormat(),
                LoggerType.Custom,
                (name: string, logGroupRule: any) => new EmptyLogger(name, logGroupRule)
            )
        );

        const enableDebugging =
            this.environment.enableDebugging || getFromStorage('enableDebugging', false);

        this.logger = LFService.createLoggerFactory(enableDebugging ? options : emptyLoggerOptions);
    }

    getLogger(name: string): Logger {
        return this.logger.getLogger(name);
    }
}
