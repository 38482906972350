import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderForm } from '@trade-platform/ui-shared';
import { LoadOrderFormActions, LoadOrderFormActionTypes } from './actions';

export type LoadOrderFormState = RemoteData<OrderForm, ErrorWrapper>;
const initialState = notAsked<OrderForm, ErrorWrapper>();

export const loadOrderFormReducer = (
    state: LoadOrderFormState = initialState,
    action: LoadOrderFormActions
): LoadOrderFormState => {
    switch (action.type) {
        case LoadOrderFormActionTypes.LOAD_ORDER_FORM:
            return inProgress({} as OrderForm);
        case LoadOrderFormActionTypes.LOAD_ORDER_FORM_SUCCESS:
            return success(action.payload.form);
        case LoadOrderFormActionTypes.LOAD_ORDER_FORM_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
