import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    input,
    viewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    Validator
} from '@angular/forms';
import { keyboardEventSwitch } from '@trade-platform/ui-utils';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'aix-multiple-text',
    templateUrl: './multiple.component.html',
    styleUrls: ['./multiple.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixMultipleTextComponent)
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AixMultipleTextComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgIf, AixDataTestingDirective]
})
export class AixMultipleTextComponent implements ControlValueAccessor, Validator {
    multipleFieldRef = viewChild<ElementRef<HTMLInputElement>>('multipleFieldRef');

    label = input<string>();
    isDisabled = input<boolean>();
    placeholder = input('');
    validation = input<RegExp>();
    valueField = input<string>();

    _selection: string[] = [];
    _propagateChanges: (value: any[]) => void = () => ({});
    _propagateTouches: () => void = () => ({});
    _onValidationChange: any = () => {};

    toggleBehaviour = true;

    private onEnterItem = () => {
        const newSelection = [...this._selection];
        const value = (<ElementRef>this.multipleFieldRef()).nativeElement.value;
        if (value !== '' && !newSelection.includes(value)) {
            newSelection.push(value);
        }

        this._selection = newSelection;

        this._propagateChanges(this._selection);
        this._propagateTouches();

        setTimeout(() => ((<ElementRef>this.multipleFieldRef()).nativeElement.value = ''));
    };

    private onDeleteItem = () => {
        // If the user is not entering a new item, remove the last entered item;
        if ((<ElementRef>this.multipleFieldRef()).nativeElement.value === '') {
            const lastChip = this._selection[this._selection.length - 1];
            if (lastChip) {
                this.removeChip(lastChip);
            }
        }
    };

    private onKeyDownKeyMatcher = keyboardEventSwitch({
        Enter: this.onEnterItem,
        Spacebar: this.onEnterItem,
        Delete: this.onDeleteItem,
        Backspace: this.onDeleteItem,
        other: {
            key_comma: {
                matcherFn: (event: KeyboardEvent) => {
                    return event.keyCode === 188 || event.key === ',';
                },
                onMatch: () => {
                    this.onEnterItem();
                }
            }
        }
    });

    constructor() {}

    onKeyDown(event: KeyboardEvent) {
        event.stopPropagation();
        this.onKeyDownKeyMatcher.withEvent(event);
    }

    onBlur(event: Event) {
        this.onEnterItem();
    }

    removeChip(option: string) {
        this._selection = [...this._selection].filter((item: string) => item !== option);
        this._propagateChanges(this._selection);
        this._propagateTouches();
    }

    // @override
    writeValue(value: any) {
        if (value === this._selection) {
            return;
        }

        if (Array.isArray(value)) {
            this._selection = value;
        } else {
            this._selection = value ? value.split(',') : [];
        }
    }

    validateItem(item: string) {
        return !this.validation()?.test(item);
    }

    validate({ value }: UntypedFormControl) {
        const isInvalid = this._selection.some(item => this.validateItem(item));
        return isInvalid ? { invalid: isInvalid } : null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidationChange = fn;
    }

    registerOnChange(fn: (value: any[]) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
