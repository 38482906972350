import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    DocumentFile,
    DocumentViewerDownloadService,
    LoadOrderSuffix,
    OrderFormComment,
    removeFileExtension
} from '@trade-platform/ui-shared';
import { AixModalComponent } from '@trade-platform/ui-components';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { fileType } from '@trade-platform/lib-enums';
import { OrderFormCommentsService } from './order-comments/order-form-comments.helper';
import { AixRequiredFilesMenuOption } from './file-upload/filelist/filelist';
import { AixUploadDocumentComponent, ReplacePayload } from '@advisor-ui/app-components';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from './base.orders.store.facade';

export type FileModalType = 'upload' | 'replace' | 'delete' | 'ignore' | 'relations';

@Component({
    template: '',
    standalone: true
})
export abstract class AixAbstractUploadComponent implements OnInit, OnDestroy {
    @ViewChild('uploadDocumentsRef')
    uploadDocumentsRef: AixUploadDocumentComponent;
    @ViewChild('replaceDocumentsRef')
    replaceDocumentsRef: AixUploadDocumentComponent;

    @Input() acceptedFormIds: string[] = [];
    @Input() orderFileComments: OrderFormComment[] = [];
    @Input() displayComponentHeader = true;
    @Input() hideFilelist = false;

    @ViewChild('uploadModal', { static: true })
    uploadModal: AixModalComponent;
    @ViewChild('replaceModal', { static: true })
    replaceModal: AixModalComponent;
    @ViewChild('deleteModal', { static: true })
    deleteModal: AixModalComponent;
    @ViewChild('ignoreModal', { static: true })
    ignoreModal: AixModalComponent;
    @ViewChild('ignoreRequiredModal', { static: true })
    ignoreRequiredModal: AixModalComponent;

    subscriptions: Subscription[] = [];

    selectedFiles: File[] = [];
    replacePayload: ReplacePayload;
    unresolvedFileComments: OrderFormComment[] = [];
    fileToRemove: DocumentFile;
    fileToIgnore: any;
    fileSizeLimit =
        this.environment.fileUploadMaxSize && !isNaN(Number(this.environment.fileUploadMaxSize))
            ? Number(this.environment.fileUploadMaxSize)
            : 25000000;
    uploadCompleted = false;
    uploadStarted = false;
    fileUploaded = false;
    fileUploadError = false;
    fileUploadOpen = false;
    replaceUploadOpen = false;
    acknowledgeIgnoreRequired = false;
    uploadUrl: string;

    readonly reducerSuffix = this.storeFacade.type;
    readonly updateOrderReducerSuffix = 'orderFilesUpdate';
    readonly fileType = fileType;

    constructor(
        @Inject(ENVIRONMENT) public environment: IEnvironment,
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public docDownloader: DocumentViewerDownloadService,
        public commentsService: OrderFormCommentsService,
        public cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.storeFacade.orderSuccess$.subscribe(order => {
                this.uploadUrl = `${this.storeFacade.apiUrl}/${order.id}/files`;
            }),
            this.storeFacade.actions.declineDocument.success$.subscribe(action => {
                this.loadOrder(this.reducerSuffix);
            }),
            this.storeFacade.actions.undeclineDocument.success$.subscribe(action => {
                this.loadOrder(this.reducerSuffix);
            }),
            this.storeFacade.actions.removeDocument.success$.subscribe(action => {
                this.loadOrder(this.reducerSuffix);
            }),
            this.storeFacade.actions.updateOrderFiles.success$
                .pipe(
                    filter(action => action.payload.reducerSuffix === this.updateOrderReducerSuffix)
                )
                .subscribe(action => {
                    this.loadOrder(this.reducerSuffix);
                }),
            this.storeFacade.actions.cancelUpload.success$.subscribe(action => {
                // for cases where the user is still able to try to cancel but the file has already gone through,
                // give the file a chance to appear on the order
                setTimeout(() => {
                    this.loadOrder(this.reducerSuffix);
                }, 300);
            })
        );
    }

    loadOrder(reducerSuffix: LoadOrderSuffix) {
        const order = this.storeFacade.order;
        if (order) {
            this.storeFacade.actions.getOrder.dispatch({ orderId: order.id, reducerSuffix });
        }
    }

    resetUpload() {
        this.fileUploadError = false;
        this.fileUploaded = false;
        this.uploadStarted = false;
        this.uploadCompleted = false;
    }

    setRelations() {
        this.fileUploaded = false;
        this.uploadStarted = false;
        this.fileUploadOpen = false;
    }

    onUploadDocuments() {
        this.selectedFiles = [];
        this.resetUpload();
        this.fileUploadOpen = true;
    }

    getReplaceModalTitle() {
        return this.unresolvedFileComments && this.unresolvedFileComments.length > 0
            ? 'Resolve Document Comments'
            : 'Replace Documents';
    }

    getOrderFileCommentsText() {
        return `Please re-upload or remove this document in order to resolve the comment${
            this.unresolvedFileComments.length > 1 ? 's' : ''
        }`;
    }

    replaceDocuments() {
        this.selectedFiles = [];
        this.resetUpload();
        this.replaceUploadOpen = true;
    }

    onReplaceUpload(file: DocumentFile) {
        this.unresolvedFileComments = this.commentsService.getComments(file.formId || file.id);
        this.cd.detectChanges();

        this.replacePayload = {
            id: file.id,
            supplementalFileTypeId: file.supplementalFileTypeId,
            contextMap: file.contextMap,
            formId: file.formId
        };
        this.replaceDocuments();
    }

    removeFile(file: DocumentFile) {
        this.fileToRemove = file;
        this.deleteModal.openModal();
    }

    ignoreFile(item: AixRequiredFilesMenuOption) {
        if (item.file.required) {
            this.ignoreRequiredModal.openModal();
        } else {
            this.ignoreModal.openModal();
        }
        this.fileToIgnore = item;
    }

    unignoreFile(item: AixRequiredFilesMenuOption) {
        const order = this.storeFacade.order;
        const { id, contextMap, firmId, fundId, holdingOptionId } = item.file;
        this.storeFacade.actions.undeclineDocument.dispatch({
            orderId: order.id,
            supplementalFileTypeId: id,
            contextMap: contextMap as string,
            dismissedReason: '',
            firmId,
            fundId,
            holdingOptionId
        });
    }

    onFilesSelected(files: File[]) {
        Array.from(files).forEach(file => {
            this.selectedFiles.push(file);
        });
        this.clickStartUpload();
        this.cd.detectChanges();
    }

    onFilesUploaded() {
        this.selectedFiles = [];
        this.uploadCompleted = true;
        this.fileUploadOpen = true;
        this.loadOrder(this.reducerSuffix);
    }

    onCancelUpload() {
        this.selectedFiles = [];
        this.uploadCompleted = true;
        this.loadOrder(this.reducerSuffix);
    }

    onReplaceFilesSelected(files: File[]) {
        Array.from(files).forEach(file => {
            this.selectedFiles.push(file);
        });
        this.clickStartReplace();
        this.cd.detectChanges();
    }

    onReplaceFilesUploaded() {
        this.selectedFiles = [];
        this.replaceUploadOpen = false;
        this.loadOrder(this.reducerSuffix);
    }

    onFileUploadError() {
        this.fileUploadError = true;
    }

    onRelationsSaved() {
        this.loadOrder(this.reducerSuffix);
        this.uploadCompleted = false;
        this.fileUploadOpen = false;
    }

    onDownloadFile(file: DocumentFile) {
        const order = this.storeFacade.order;
        this.docDownloader.getDocument({
            href: `${this.storeFacade.apiUrl}/${order.id}/download/${file.id}`,
            name: removeFileExtension(file.name)
        });
    }

    onRemoveUploadOptionSelected(event: string) {
        const order = this.storeFacade.order;

        switch (event) {
            case 'Yes, continue':
                this.storeFacade.actions.removeDocument.dispatch({
                    orderId: order.id,
                    fileToRemove: this.fileToRemove
                });
                break;
            case 'Cancel':
                this.deleteModal.closeModal();
                break;
        }
    }

    onCloseIgnoreUploadModal() {
        this.acknowledgeIgnoreRequired = false;
        this.ignoreModal.closeModal();
    }

    onIgnoreUploadOptionSelected(event: string) {
        const order = this.storeFacade.order;

        switch (event) {
            case 'Yes, continue':
                this.acknowledgeIgnoreRequired = false;
                const { id, contextMap, firmId, fundId, holdingOptionId } = this.fileToIgnore.file;
                this.storeFacade.actions.declineDocument.dispatch({
                    orderId: order.id,
                    supplementalFileTypeId: id,
                    contextMap,
                    dismissedReason: 'ignore',
                    firmId,
                    fundId,
                    holdingOptionId
                });
                break;
            case 'Cancel':
                this.onCloseIgnoreUploadModal();
                break;
        }
    }

    returnToUploader() {
        this.storeFacade.actions.returnToUploader.dispatch();
    }

    removeFileSelected(fileToRemove: File) {
        const index = this.selectedFiles.findIndex(item => item === fileToRemove);
        this.selectedFiles.splice(index, 1);
    }

    clickCancelUpload() {
        this.selectedFiles = [];
        this.uploadModal.closeModal();
    }

    clickStartUpload() {
        this.uploadStarted = true;
    }

    clickContinueUpload() {
        this.fileUploaded = false;
        this.uploadModal.closeModal();
    }

    clickCancelReplace() {
        this.selectedFiles = [];
        this.replaceModal.closeModal();
    }

    clickStartReplace() {
        this.uploadStarted = true;
    }

    clickContinueReplace() {
        this.fileUploaded = false;
        this.replaceModal.closeModal();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public openModal(type: FileModalType) {
        switch (type) {
            case 'upload':
                this.uploadModal.openModal();
                break;
            case 'replace':
                this.replaceModal.openModal();
                break;
            case 'delete':
                this.deleteModal.openModal();
                break;
            case 'ignore':
                this.ignoreModal.openModal();
                break;
            default:
                break;
        }
        this.cd.markForCheck();
    }

    public uploadDocuments() {
        this.onUploadDocuments();
        this.cd.markForCheck();
    }

    public replaceUpload(file: DocumentFile) {
        this.onReplaceUpload(file);
        this.cd.markForCheck();
    }
}
