import { Pipe, PipeTransform } from '@angular/core';
import { isJust, isNothing, Maybe, withDefault } from 'ts.data.maybe';

@Pipe({
    name: 'withDefault',
    standalone: true
})
export class WithDefaultPipe implements PipeTransform {
    transform<T>(maybe: Maybe<T> | null | undefined, defaultValue: T): T {
        if (maybe === null || maybe === undefined) {
            return defaultValue;
        }
        return withDefault(maybe, defaultValue);
    }
}

@Pipe({
    name: 'isNothing',
    standalone: true
})
export class IsNothingPipe implements PipeTransform {
    transform<T>(maybe: Maybe<T> | null | undefined): boolean {
        return maybe === null || maybe === undefined || isNothing(maybe);
    }
}

@Pipe({
    name: 'isJust',
    standalone: true
})
export class IsJustPipe implements PipeTransform {
    transform<T>(maybe: Maybe<T> | null | undefined): boolean {
        return maybe !== null && maybe !== undefined && isJust(maybe);
    }
}
