import {
    ChangeDetectionStrategy,
    Component,
    input,
    model,
    OnDestroy,
    output,
    ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AixFilterItem, AixFilterListComponent } from '../aix-filter-list/aix-filter-list';
import { AixExpansionPanelComponent } from '../aix-expansion-panel/expansion-panel';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { AixShowMoreComponent } from '../aix-show-more/aix-show-more';

@Component({
    selector: 'aix-filter-panel',
    templateUrl: './aix-filter-panel.html',
    styleUrls: ['./aix-filter-panel.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixFilterListComponent,
        AixExpansionPanelComponent,
        AixDataTestingDirective,
        AixShowMoreComponent
    ]
})
export class AixFilterPanelComponent implements OnDestroy {
    title = input<string>();
    filters = input.required<AixFilterItem[]>();
    showDivider = input(true);
    showCheckbox = input(false);

    activeFilter = model.required<AixFilterItem[]>();

    onFilter = output<AixFilterItem | AixFilterItem[]>();

    subscriptions: Subscription[] = [];

    constructor() {}

    clickFilter(item: AixFilterItem | AixFilterItem[]) {
        this.onFilter.emit(item);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
