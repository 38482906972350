import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AixAuthService,
    AixChangesService,
    AixRouteService,
    LoginMachine,
    Logout
} from '@advisor-ui/app-services';
import { Subscription } from 'rxjs';
import { AixCancelChangesModalComponent } from '@advisor-ui/app-components';
import { AixUiComponentsModule } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-logout',
    templateUrl: './logout.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixUiComponentsModule, AixCancelChangesModalComponent]
})
export class AixLogoutComponent implements OnInit, OnDestroy {
    @ViewChild('cancelChangesModal', { static: true })
    cancelChangesModal: AixCancelChangesModalComponent;

    subscriptions: Subscription[] = [];
    usePreviousUrl = false;

    constructor(
        public changesService: AixChangesService,
        public routeService: AixRouteService,
        public authService: AixAuthService,
        public router: Router,
        public route: ActivatedRoute,
        private loginMachine: LoginMachine
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.queryParams.subscribe(params => {
                this.usePreviousUrl = params.usePreviousUrl
                    ? JSON.parse(params.usePreviousUrl)
                    : false;

                if (this.changesService.changes) {
                    this.cancelChangesModal.openModal();
                } else {
                    this.logOut();
                }
            })
        );
    }

    logOut() {
        this.loginMachine.send(
            new Logout(
                this.usePreviousUrl && !/logout/.test(this.routeService.previousRoute as string)
                    ? this.routeService.previousRoute
                    : null
            )
        );
    }

    onCancelChangesModal(e: string) {
        switch (e) {
            case 'Yes, continue':
                this.logOut();
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
