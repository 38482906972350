import { Pipe, PipeTransform } from '@angular/core';
import { ApiFormError } from '@trade-platform/ui-utils';
import { isFailure, RemoteData } from 'ngx-remotedata';

@Pipe({
    name: 'isFormFailure',
    standalone: true
})
export class IsFormFailurePipe implements PipeTransform {
    transform(value: RemoteData<any, any>): boolean {
        return isFailure(value) && value.error instanceof ApiFormError;
    }
}
