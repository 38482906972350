import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderFormCommentActionTypes,
    OrderFormCommentCreateAction,
    OrderFormCommentCreateFailureAction,
    OrderFormCommentCreateSuccessAction,
    OrderFormCommentDeleteAction,
    OrderFormCommentDeleteFailureAction,
    OrderFormCommentDeleteSuccessAction,
    OrderFormCommentEditAction,
    OrderFormCommentEditSuccessAction,
    OrderFormCommentGetAction,
    OrderFormCommentGetFailureAction,
    OrderFormCommentGetSuccessAction,
    OrderFormCommentListAction,
    OrderFormCommentListFailureAction,
    OrderFormCommentListSuccessAction,
    OrderFormCommentResolveAction,
    OrderFormCommentResolveFailureAction,
    OrderFormCommentResolveSuccessAction,
    OrderFormCommentsResolveAction,
    OrderFormCommentsResolveFailureAction,
    OrderFormCommentsResolveSuccessAction,
    OrderFormCommentUpdateAction,
    OrderFormCommentUpdateFailureAction,
    OrderFormCommentUpdateSuccessAction
} from './actions';
import { OrderFormCommentService } from './service';

@Injectable()
export class OrderFormCommentEffects {
    constructor(
        private actions$: Actions,
        private orderFormCommentService: OrderFormCommentService
    ) {}

    getOrderFormComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentGetAction>(OrderFormCommentActionTypes.ORDER_FORM_COMMENT_GET),
            switchMap(action =>
                this.orderFormCommentService.getOrderFormComment(action.payload).pipe(
                    map(res => new OrderFormCommentGetSuccessAction(res)),
                    catchHttpError(error => of(new OrderFormCommentGetFailureAction({ error })))
                )
            )
        )
    );

    listOrderFormComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentListAction>(OrderFormCommentActionTypes.ORDER_FORM_COMMENT_LIST),
            switchMap(action =>
                this.orderFormCommentService.getOrderFormComments(action.payload).pipe(
                    map(res => new OrderFormCommentListSuccessAction(res)),
                    catchHttpError(error => of(new OrderFormCommentListFailureAction({ error })))
                )
            )
        )
    );

    createOrderFormComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentCreateAction>(
                OrderFormCommentActionTypes.ORDER_FORM_COMMENT_CREATE
            ),
            switchMap(action =>
                this.orderFormCommentService.addOrderFormComment(action.payload).pipe(
                    map(res => new OrderFormCommentCreateSuccessAction(res)),
                    catchHttpError(error => of(new OrderFormCommentCreateFailureAction({ error })))
                )
            )
        )
    );

    editOrderFormComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentEditAction>(OrderFormCommentActionTypes.ORDER_FORM_COMMENT_EDIT),
            map(action => {
                return new OrderFormCommentEditSuccessAction(action.payload);
            })
        )
    );

    deleteOrderFormComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentDeleteAction>(
                OrderFormCommentActionTypes.ORDER_FORM_COMMENT_DELETE
            ),
            switchMap(action =>
                this.orderFormCommentService.deleteOrderFormComment(action.payload).pipe(
                    map(res => new OrderFormCommentDeleteSuccessAction(res)),
                    catchHttpError(error => of(new OrderFormCommentDeleteFailureAction({ error })))
                )
            )
        )
    );

    updateOrderFormComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentUpdateAction>(
                OrderFormCommentActionTypes.ORDER_FORM_COMMENT_UPDATE
            ),
            switchMap(action =>
                this.orderFormCommentService.updateOrderFormComment(action.payload).pipe(
                    map(res => new OrderFormCommentUpdateSuccessAction(res)),
                    catchHttpError(error => of(new OrderFormCommentUpdateFailureAction({ error })))
                )
            )
        )
    );

    resolveOrderFormComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentResolveAction>(
                OrderFormCommentActionTypes.ORDER_FORM_COMMENT_RESOLVE
            ),
            switchMap(action =>
                this.orderFormCommentService.resolveOrderComment(action.payload).pipe(
                    map(res => new OrderFormCommentResolveSuccessAction(res)),
                    catchHttpError(error =>
                        of(
                            new OrderFormCommentResolveFailureAction({
                                error: error,
                                data: action.payload.data
                            })
                        )
                    )
                )
            )
        )
    );

    resolveOrderFormComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormCommentsResolveAction>(
                OrderFormCommentActionTypes.ORDER_FORM_COMMENTS_RESOLVE
            ),
            switchMap(action => {
                if (action.payload.commentIds && action.payload.commentIds.length > 0) {
                    const requests = action.payload.commentIds.map(id => {
                        return this.orderFormCommentService.resolveOrderComment({
                            data: {
                                orderId: action.payload.data.orderId,
                                documentId: action.payload.data.documentId,
                                id: id
                            },
                            isResolved: true
                        });
                    });

                    return forkJoin(requests).pipe(
                        map(
                            aggregateResponse =>
                                new OrderFormCommentsResolveSuccessAction({
                                    comments: aggregateResponse,
                                    reducerSuffix: action.payload.reducerSuffix
                                })
                        ),
                        catchHttpError(error =>
                            of(new OrderFormCommentsResolveFailureAction({ error: error }))
                        )
                    );
                } else {
                    return of(
                        new OrderFormCommentsResolveSuccessAction({
                            comments: [],
                            reducerSuffix: action.payload.reducerSuffix
                        })
                    );
                }
            })
        )
    );
}
