import { Component, input } from '@angular/core';

export type AixTagColor = 'success' | 'alert' | 'warn' | 'error' | 'note' | 'ok' | 'brand' | 'aix';

@Component({
    selector: 'aix-tag',
    templateUrl: './aix-tag.component.html',
    styleUrls: ['./aix-tag.component.scss'],
    standalone: true
})
export class AixTagComponent {
    icon = input<string | null>(null);
    tooltip = input<string | null>(null);
    label = input.required<string>();
    labelColor = input<string>('#263238');
    color = input<AixTagColor>('note');
}
