import { Directive, ElementRef, HostBinding, input, OnDestroy, OnInit } from '@angular/core';
import { AixPendingFieldsManagerService } from '../../services/pending-fields-manager.service';
import { AbstractControl } from '@angular/forms';
import { v4 as uuid } from 'uuid';

export interface NextPendingField {
    control: AbstractControl;
    margin?: number;
}

@Directive({
    selector: '[aixPendingField]',
    standalone: true
})
export class AixNextPendingFieldDirective implements OnInit, OnDestroy {
    field = input<NextPendingField | undefined>(undefined, { alias: 'aixPendingField' });
    @HostBinding('attr.aixPendingFieldId') readonly id = uuid();

    constructor(
        private elem: ElementRef,
        public pendingFieldsManager: AixPendingFieldsManagerService
    ) {}

    ngOnInit() {
        setTimeout(() => {
            if (this.field()) {
                this.pendingFieldsManager.addField(this.id as string, {
                    control: (<NextPendingField>this.field()).control,
                    elem: this.elem.nativeElement,
                    margin: (<NextPendingField>this.field()).margin
                        ? <number>(<NextPendingField>this.field()).margin
                        : 0
                });
            }
        }, 0);
    }

    ngOnDestroy() {
        if (this.field()) {
            setTimeout(() => this.pendingFieldsManager.removeField(this.id as string), 0);
        }
    }
}
