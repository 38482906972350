import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderAdminActions, OrderAdminActionTypes } from './actions';

export type OrderNigoToNeedsClarificationState = RemoteData<null, ErrorWrapper>;

export function orderNigoToNeedsClarificationReducer(
    state: OrderNigoToNeedsClarificationState = notAsked(),
    action: OrderAdminActions
): OrderNigoToNeedsClarificationState {
    switch (action.type) {
        case OrderAdminActionTypes.ORDER_NIGO_TO_NEEDS_CLARIFICATION:
            return inProgress();
        case OrderAdminActionTypes.ORDER_NIGO_TO_NEEDS_CLARIFICATION_SUCCESS:
            return success(null);
        case OrderAdminActionTypes.ORDER_NIGO_TO_NEEDS_CLARIFICATION_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type OrderToWetsignState = RemoteData<null, ErrorWrapper>;

export function orderToWetsignReducer(
    state: OrderToWetsignState = notAsked(),
    action: OrderAdminActions
): OrderToWetsignState {
    switch (action.type) {
        case OrderAdminActionTypes.ORDER_TO_WETSIGN:
            return inProgress();
        case OrderAdminActionTypes.ORDER_TO_WETSIGN_SUCCESS:
            return success(null);
        case OrderAdminActionTypes.ORDER_TO_WETSIGN_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type AdminMarkOrderCompleteState = RemoteData<null, ErrorWrapper>;

export function adminMarkOrderCompleteReducer(
    state: AdminMarkOrderCompleteState = notAsked(),
    action: OrderAdminActions
): AdminMarkOrderCompleteState {
    switch (action.type) {
        case OrderAdminActionTypes.ORDER_MARK_COMPLETE:
            return inProgress();
        case OrderAdminActionTypes.ORDER_MARK_COMPLETE_SUCCESS:
            return success(null);
        case OrderAdminActionTypes.ORDER_MARK_COMPLETE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type AdminResubmitOrderState = RemoteData<null, ErrorWrapper>;

export function adminResubmitOrderReducer(
    state: AdminResubmitOrderState = notAsked(),
    action: OrderAdminActions
): AdminResubmitOrderState {
    switch (action.type) {
        case OrderAdminActionTypes.ORDER_RESUBMIT:
            return inProgress();
        case OrderAdminActionTypes.ORDER_RESUBMIT_SUCCESS:
            return success(null);
        case OrderAdminActionTypes.ORDER_RESUBMIT_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type AdminPushUpdatedFormToOrderState = RemoteData<null, ErrorWrapper>;

export function adminPushUpdatedFormToOrderReducer(
    state: AdminPushUpdatedFormToOrderState = notAsked(),
    action: OrderAdminActions
): AdminPushUpdatedFormToOrderState {
    switch (action.type) {
        case OrderAdminActionTypes.ORDER_PUSH_UPDATED_FORM:
            return inProgress();
        case OrderAdminActionTypes.ORDER_PUSH_UPDATED_FORM_SUCCESS:
            return success(null);
        case OrderAdminActionTypes.ORDER_PUSH_UPDATED_FORM_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type AdminMoveOrderBackInWorkflowState = RemoteData<null, ErrorWrapper>;

export function adminMoveOrderBackInWorkflowReducer(
    state: AdminMoveOrderBackInWorkflowState = notAsked(),
    action: OrderAdminActions
): AdminMoveOrderBackInWorkflowState {
    switch (action.type) {
        case OrderAdminActionTypes.ORDER_MOVE_BACK_IN_WORKFLOW:
            return inProgress();
        case OrderAdminActionTypes.ORDER_MOVE_BACK_IN_WORKFLOW_SUCCESS:
            return success(null);
        case OrderAdminActionTypes.ORDER_MOVE_BACK_IN_WORKFLOW_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type AdminMoveOrderForwardInWorkflowState = RemoteData<null, ErrorWrapper>;

export function adminMoveOrderForwardInWorkflowReducer(
    state: AdminMoveOrderForwardInWorkflowState = notAsked(),
    action: OrderAdminActions
): AdminMoveOrderForwardInWorkflowState {
    switch (action.type) {
        case OrderAdminActionTypes.ORDER_MOVE_FORWARD_IN_WORKFLOW:
            return inProgress();
        case OrderAdminActionTypes.ORDER_MOVE_FORWARD_IN_WORKFLOW_SUCCESS:
            return success(null);
        case OrderAdminActionTypes.ORDER_MOVE_FORWARD_IN_WORKFLOW_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
