/**
 * Wrapper around localStorage to make null errors more explicit, specially on Firefox.
 * @see https://www.fxsitecompat.dev/en-CA/docs/2019/window-localstorage-no-longer-throws-securityerror-when-blocked-due-to-privacy-settings/
 */
export const withStorage = <T>(fn: (storage: Storage) => T): T => {
    if (window.localStorage) {
        return fn(window.localStorage);
    } else {
        throw new Error(
            'localStorage is not available, likely because you have cookies disabled in your browser privacy settings.'
        );
    }
};

/**
 * Gets a JSON value from local storage parsed as a JS object
 * @param key The stored value key.
 * @param defaultValue The default value in case the specified key does not exists.
 */
export const getFromStorage = <T>(key: string, defaultValue?: T): T | undefined => {
    return withStorage(storage => {
        const value = storage.getItem(key);
        if (value === null) {
            return defaultValue;
        }

        try {
            return JSON.parse(value) as T;
        } catch (error) {
            // discard
        }
        return (value as unknown) as T;
    });
};

/**
 * Sets a JSON value to local storage parsed as a JS object
 * @param key The key where the value will be stored.
 * @param value The value we want to save. (The value will be stringified before being added to the localStorage)
 */
export const setToStorage = (key: string, value: any) => {
    withStorage(storage => {
        storage.setItem(key, JSON.stringify(value));
    });
};
