import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { OrderApprovalFormsActions, OrderApprovalFormsActionTypes } from './actions';
import { ErrorWrapper, OrderApprovalForm } from '@trade-platform/ui-shared';

export type OrderApprovalFormsState = RemoteData<OrderApprovalForm[], ErrorWrapper>;
const initialState = notAsked<OrderApprovalForm[], ErrorWrapper>();

export const orderApprovalFormsReducer = (
    state: OrderApprovalFormsState = initialState,
    action: OrderApprovalFormsActions
): OrderApprovalFormsState => {
    switch (action.type) {
        case OrderApprovalFormsActionTypes.ORDER_APPROVAL_FORMS:
            return inProgress([]);
        case OrderApprovalFormsActionTypes.ORDER_APPROVAL_FORMS_SUCCESS:
            return success(action.payload);
        case OrderApprovalFormsActionTypes.ORDER_APPROVAL_FORMS_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
