import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { OrderReducerSuffixPayload } from '../../models/order/model';
import { Investor } from '../../models/investor/model';

export class InvestorActionTypes {
    // Investor
    static readonly LOAD_INVESTOR_BY_TAX_ID = type('[Investor] Load Investor by Tax Id');
    static readonly LOAD_INVESTOR_BY_TAX_ID_SUCCESS = type(
        '[Investor] Load Investor by Tax Id Success'
    );
    static readonly LOAD_INVESTOR_BY_TAX_ID_FAILURE = type(
        '[Investor] Load Investor by Tax Id Failure'
    );
    static readonly CLEAR_INVESTOR = type('[Investor] Clear Investor');
}

export type InvestorActions =
    | LoadInvestorByTaxIdAction
    | LoadInvestorByTaxIdSuccessAction
    | LoadInvestorByTaxIdFailureAction
    | ClearInvestorAction;

// Load Investor by Tax Id
export class LoadInvestorByTaxIdAction implements AixAction {
    readonly type = InvestorActionTypes.LOAD_INVESTOR_BY_TAX_ID;

    constructor(
        public payload: {
            taxId: string;
            personTypeFilter: 'corporation' | 'human';
            reducerSuffix: string;
        }
    ) {}
}
export class LoadInvestorByTaxIdSuccessAction implements AixAction {
    readonly type = InvestorActionTypes.LOAD_INVESTOR_BY_TAX_ID_SUCCESS;

    constructor(public payload: { investor: Investor; reducerSuffix: string }) {}
}
export class LoadInvestorByTaxIdFailureAction implements AixAction {
    readonly type = InvestorActionTypes.LOAD_INVESTOR_BY_TAX_ID_FAILURE;

    constructor(public payload: { error: ErrorWrapper; reducerSuffix: string }) {}
}
export class ClearInvestorAction implements AixAction {
    readonly type = InvestorActionTypes.CLEAR_INVESTOR;
    constructor(public payload: OrderReducerSuffixPayload) {}
}
