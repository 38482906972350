<form class="u-mt40" [formGroup]="form">
    <div class="header--section">
        <div class="-heading">
            <h3>Advisor Information</h3>
        </div>
    </div>
    <aix-radio-group
        [data-testing]="'firmType'"
        formControlName="firmType"
        [aixPendingField]="{ control: form.get('firmType') }"
        [options]="advisorTypes"
        [title]="'Please note how you will be submitting this business.'"
        [labelField]="'title'"
        [valueField]="'value'"
        [isValid]="!!form.get('firmType')?.valid"
        [isRequired]="true"
    >
    </aix-radio-group>

    @if (form.get('firmType')?.valid) {
    <div class="aix-flex-grid__col">
        <div class="aix-form__group u-mt24">
            <aix-dropdown
                #advisorDropdownRef
                class="aix-form__control--required"
                [class.aix-form__control--dirty]="advisorDropdownRef.isDirty"
                [class.aix-form__control--invalid-required]="(form.value.repCode?.length >= 0 ?
                        !form.value.repCode?.length :
                        !form.value.repCode)"
                [class.aix-form__control--invalid]="(form.value.repCode?.length >= 0 ?
                        !form.value.repCode?.length :
                        !form.value.repCode)"
                [aixPendingField]="{ control: form.get('repCode') }"
                [formControlName]="'repCode'"
                labelField="listName"
                valueField="id"
                placeholder=""
                [data-testing]="'advisor'"
                [options]="startOrder?.repCodes"
                (ngModelChange)="selectRepCode($event[0])"
            >
            </aix-dropdown>
            <label class="aix-form__label aix-label">Choose a Rep ID</label>
        </div>
    </div>
    } @if (form.get('repCode')?.valid) {
    <div class="aix-flex-grid__col">
        <div class="aix-form__group u-mt24">
            <aix-dropdown
                #solicitingAdvisorRef
                [class.aix-form__control--dirty]="solicitingAdvisorRef.isDirty"
                class="aix-form__control--required"
                [class.aix-form__control--invalid-required]="(form.value.advisor?.length >= 0 ?
                        !form.value.advisor?.length :
                        !form.value.advisor)"
                [class.aix-form__control--invalid]="(form.value.advisor?.length >= 0 ?
                        !form.value.advisor?.length :
                        !form.value.advisor)"
                [aixPendingField]="{ control: form.get('advisor') }"
                [formControlName]="'advisor'"
                labelField="displayName"
                valueField="id"
                placeholder=""
                [data-testing]="'solicitingAdvisor'"
                [options]="advisors"
                (ngModelChange)="selectAdvisor($event[0])"
            >
            </aix-dropdown>
            <label class="aix-form__label aix-label">Choose Soliciting Advisor</label>
        </div>
    </div>
    }
</form>
