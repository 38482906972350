import { SetOrderPathValueActionParams } from '@trade-platform/form-fields';
import { Investor } from '../../models/investor/model';
import {
    BaseOrder,
    DocumentFile,
    LoadOrderPayload,
    LoadOrderSuffix,
    OrderId,
    UpdateOrderFilesSuffix,
    UpdateOrderSuffix
} from '../../models/order/model';
import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { Update, UpdateFailure, UpdateSuccess } from '../update/actions';

// Load
export class LoadOrderActionTypes {
    static readonly LOAD_ORDER = type('[Order] Load Order');
    static readonly LOAD_ORDER_SUCCESS = type('[Order] Load Order Success');
    static readonly LOAD_ORDER_FAILURE = type('[Order] Load Order Failure');
    static readonly RESET_ORDER = type('[Order] Reset Order');
    static readonly REMOVE_INVESTOR_FROM_ORDER = type('[Order] Remove Investor From Order');
    static readonly SET_ORDER_PATH_VALUE = type('[Order] Set Order Path Value');
}

export type LoadOrderActions =
    | LoadOrder
    | LoadOrderSuccess
    | LoadOrderFailure
    | ResetOrder
    | RemoveInvestorFromOrder
    | SetOrderPathValueAction;

export class LoadOrder implements AixAction {
    readonly type = LoadOrderActionTypes.LOAD_ORDER;

    constructor(public payload: LoadOrderPayload) {}
}

export class LoadOrderSuccess implements AixAction {
    readonly type = LoadOrderActionTypes.LOAD_ORDER_SUCCESS;

    constructor(public payload: { order: BaseOrder; reducerSuffix: LoadOrderSuffix }) {}
}

export class LoadOrderFailure implements AixAction {
    readonly type = LoadOrderActionTypes.LOAD_ORDER_FAILURE;

    constructor(public payload: { error: ErrorWrapper; reducerSuffix: LoadOrderSuffix }) {}
}

export class ResetOrder implements AixAction {
    readonly type = LoadOrderActionTypes.RESET_ORDER;

    constructor(public payload: { reducerSuffix: LoadOrderSuffix }) {}
}

export class RemoveInvestorFromOrder implements AixAction {
    readonly type = LoadOrderActionTypes.REMOVE_INVESTOR_FROM_ORDER;

    constructor(public payload: { investor: Investor; reducerSuffix: LoadOrderSuffix }) {}
}

export class SetOrderPathValueAction implements AixAction {
    readonly type = LoadOrderActionTypes.SET_ORDER_PATH_VALUE;

    constructor(
        public payload: {
            value: any;
            params: SetOrderPathValueActionParams;
            reducerSuffix: LoadOrderSuffix;
        }
    ) {}
}

// Update
export type UpdateOrder = Update<BaseOrder, { formCompleted: boolean }, UpdateOrderSuffix>;
export type UpdateOrderSuccess = UpdateSuccess<OrderId, UpdateOrderSuffix>;
export type UpdateOrderFailure = UpdateFailure<UpdateOrderSuffix>;

// Update files
export type UpdateOrderFiles = Update<DocumentFile[], { orderId: string }, UpdateOrderFilesSuffix>;
export type UpdateOrderFilesSuccess = UpdateSuccess<OrderId, UpdateOrderFilesSuffix>;
export type UpdateOrderFilesFailure = UpdateFailure<UpdateOrderFilesSuffix>;

//Patch Local Ui Forms
export class PatchLocalUiDataActionTypes {
    static readonly PATCH_LOCAL_UI_DATA = type('[LocalUiData] Patch Data');
}

export type PatchLocalUiDataActions = PatchLocalUiData;

export class PatchLocalUiData implements AixAction {
    readonly type = PatchLocalUiDataActionTypes.PATCH_LOCAL_UI_DATA;

    constructor(public payload: { order: BaseOrder }) {}
}
