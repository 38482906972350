import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderDocumentRemove,
    OrderDocumentRemoveActionTypes,
    OrderDocumentRemoveFailure,
    OrderDocumentRemoveSuccess
} from './actions';
import { OrderDocumentRemoveService } from './service';

@Injectable()
export class OrderDocumentRemoveEffects {
    constructor(
        private actions$: Actions,
        private orderDocumentRemoveService: OrderDocumentRemoveService
    ) {}

    orderDocumentRemove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderDocumentRemove>(OrderDocumentRemoveActionTypes.ORDER_DOCUMENT_REMOVE),
            mergeMap(action =>
                this.orderDocumentRemoveService.orderDocumentRemove(action.payload).pipe(
                    map(result => new OrderDocumentRemoveSuccess()),
                    catchHttpError(error =>
                        of(
                            new OrderDocumentRemoveFailure({
                                error
                            })
                        )
                    )
                )
            )
        )
    );
}
