import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { ProfileRepCode, repCodesDecoder } from '@trade-platform/ui-shared';

@Injectable()
export class RepCodessByAdvisorService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getRepCodesByAdvisor(advisor: string): Observable<ProfileRepCode[]> {
        return this.http
            .get<ProfileRepCode[]>(`${this.environment.api}users/${advisor}/repcodes`)
            .pipe(concatMap(p => fromPromise(repCodesDecoder.decodeToPromise(p))));
    }
}
