import {
    AixAction,
    ErrorWrapper,
    OrderDocumentRemovePayload,
    type
} from '@trade-platform/ui-shared';

// Remove file
export class OrderDocumentRemoveActionTypes {
    static readonly ORDER_DOCUMENT_REMOVE = type('[Order Document Remove] Remove Order Document');
    static readonly ORDER_DOCUMENT_REMOVE_SUCCESS = type(
        '[Order Document Remove] Remove Order Document Success'
    );
    static readonly ORDER_DOCUMENT_REMOVE_FAILURE = type(
        '[Order Document Remove] Remove Order Document Failure'
    );
}

export type OrderDocumentRemoveActions =
    | OrderDocumentRemove
    | OrderDocumentRemoveSuccess
    | OrderDocumentRemoveFailure;

export class OrderDocumentRemove implements AixAction {
    readonly type = OrderDocumentRemoveActionTypes.ORDER_DOCUMENT_REMOVE;

    constructor(public payload: OrderDocumentRemovePayload) {}
}

export class OrderDocumentRemoveSuccess implements AixAction {
    readonly type = OrderDocumentRemoveActionTypes.ORDER_DOCUMENT_REMOVE_SUCCESS;
}

export class OrderDocumentRemoveFailure implements AixAction {
    readonly type = OrderDocumentRemoveActionTypes.ORDER_DOCUMENT_REMOVE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
