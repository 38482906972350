<div class="content-container">
    @for (card of (cards | productsFilter:productSearchText);track card) {
    <div class="product-container">
        <aix-product-card
            class="product-box"
            [attr.data-testing]="'product-item-id-' + card.id"
            [productCard]="card"
            [language]="productCardLanguage"
            [productOverviewEnabled]="true"
            (cardSelected)="productCardSelect($event)"
            (cardFavorite)="favoriteCard($event)"
        >
        </aix-product-card>
    </div>
    }
    <!-- Empty product-container divs are needed to support the flex layout and keep the product cards from stretching to fill extra space (ie, keeps all product cards the same width) -->
    <div class="product-container"></div>
    <div class="product-container"></div>
    <div class="product-container"></div>
</div>
