/**
 * Orders higher order reducer
 */

import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { OrdersActions, OrdersActionTypes } from './actions';
import { ErrorWrapper, OrderRecord, OrdersSuffix, Page } from '@trade-platform/ui-shared';

export type OrdersState = RemoteData<Page<OrderRecord>, ErrorWrapper>;

export function ordersReducerFactory(reducerSuffix: OrdersSuffix) {
    const initialState = notAsked<Page<OrderRecord>, ErrorWrapper>();

    const reducer = (state: OrdersState = initialState, action: OrdersActions): OrdersState => {
        switch (action.type) {
            case OrdersActionTypes.LOAD_ORDERS:
                return inProgress({} as Page<OrderRecord>);
            case OrdersActionTypes.LOAD_ORDERS_SUCCESS:
                return success(action.payload.ordersPage);
            case OrdersActionTypes.LOAD_ORDERS_FAILURE:
                return failure(action.payload.error);
            default:
                return state;
        }
    };

    return (state: OrdersState = initialState, action: OrdersActions): OrdersState => {
        if (action.payload) {
            switch (action.payload.reducerSuffix) {
                case reducerSuffix:
                    return [reducer].reduce((acc, fn) => fn(acc, action), state);
                default:
                    return state;
            }
        } else {
            return state;
        }
    };
}
