import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { AccountRegistrationsActions, AccountRegistrationsActionTypes } from './actions';
import {
    Account,
    AccountRegistration,
    ErrorWrapper,
    Page,
    RegistrationWithPositions
} from '@trade-platform/ui-shared';

/**
 * Account Registrations
 */

export type AccountRegistrationsState = RemoteData<Page<AccountRegistration>, ErrorWrapper>;
const initialState = notAsked<Page<AccountRegistration>, ErrorWrapper>();

export function accountRegistrationsReducer(
    state: AccountRegistrationsState = initialState,
    action: AccountRegistrationsActions
): AccountRegistrationsState {
    switch (action.type) {
        case AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS:
            return inProgress({} as Page<AccountRegistration>);
        case AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_SUCCESS:
            return success(action.payload);
        case AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

/**
 * Account Registrations By Advisor
 */

export type AccountRegistrationsByAdvisorState = RemoteData<Account[], ErrorWrapper>;
const initialState2 = notAsked<Account[], ErrorWrapper>();

export function accountRegistrationsByAdvisorReducer(
    state: AccountRegistrationsByAdvisorState = initialState2,
    action: AccountRegistrationsActions
): AccountRegistrationsByAdvisorState {
    switch (action.type) {
        case AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR:
            return inProgress({} as Account[]);
        case AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR_SUCCESS:
            return success(action.payload.registrations);
        case AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

/**
 * Registration With Positions
 */

export type RegistrationWithPositionsState = RemoteData<RegistrationWithPositions, ErrorWrapper>;
const initialState3 = notAsked<RegistrationWithPositions, ErrorWrapper>();

export function registrationWithPositionsReducer(
    state: RegistrationWithPositionsState = initialState3,
    action: AccountRegistrationsActions
): RegistrationWithPositionsState {
    switch (action.type) {
        case AccountRegistrationsActionTypes.LOAD_REGISTRATION_WITH_POSITIONS:
            return inProgress({} as RegistrationWithPositions);
        case AccountRegistrationsActionTypes.LOAD_REGISTRATION_WITH_POSITIONS_SUCCESS:
            return success(action.payload);
        case AccountRegistrationsActionTypes.LOAD_REGISTRATION_WITH_POSITIONS_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
