import { JsonDecoder } from 'ts.data.json';
import { ProductMaterial } from '../../models/product-materials/model';

export const productMaterialDecoder = JsonDecoder.object<ProductMaterial>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.string,
        fundId: JsonDecoder.number,
        isPdf: JsonDecoder.optional(JsonDecoder.boolean),
        fileName: JsonDecoder.string,
        documentType: JsonDecoder.string, // enum: productMaterialsTypes
        documentSubType: JsonDecoder.optional(JsonDecoder.string),
        documentDate: JsonDecoder.optional(JsonDecoder.string),
        requiresStamping: JsonDecoder.boolean,
        thumbnail: JsonDecoder.optional(JsonDecoder.string)
    },
    'ProductMaterial'
);

export const productMaterialsDecoder = JsonDecoder.array<ProductMaterial>(
    productMaterialDecoder,
    'ProductMaterial[]'
);
