import { Pipe, PipeTransform } from '@angular/core';
import { WrappedOption } from './dropdown';

@Pipe({
    name: 'aixDropdownFilter'
})
export class AixDropdownFilterPipe implements PipeTransform {
    transform(items: Array<WrappedOption>, term: string, omit = false): Array<WrappedOption> {
        return !omit
            ? items.filter(
                  item => item && item.label.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
            : items;
    }
}
