export const pdfTitles = {
    'ready to send': 'Please send documents for eSignature via email.',
    preview: 'A preview of the required documents has been generated',
    preview2: 'Please select a signing method to continue.',
    'send for wetsign': 'All forms have been signed. Please continue.',
    'send for esign': 'All eSignatures have been collected. Please continue.',
    'pending uploads': 'Please download and upload signed forms.',
    'wetsign only': 'This order does not meet the criteria for eSign, please continue.',
    'choose wetsign only': 'This order does not meet the criteria for eSign, please continue.',
    'pending signatures':
        'Sent for eSign. Please wait to continue until all signatures are collected.',
    'pending approval process': 'Your Order Has Been Signed',
    'ready to submit': 'Your Order Has Been Signed',
    submitted: 'Your Order is Signed and Ready for Submission',
    smsAuthentication:
        'This order requires SMS authentication for eSigning. Please send documents for eSign.'
};

export const pdfSubtitles = {
    'ready to send': 'Please choose a method for collecting signatures.',
    wetSignOnlyNotOnboarded:
        'This order requires a wet signature and will have to be downloaded, signed, and reuploaded.',
    'pending signatures': `You'll be able to proceed once all electronic signatures have been collected.`,
    'pending approval process': '',
    'ready to submit': '',
    submitted: 'Please review or submit your signed order.',
    changeSignerDetails: 'To change signer details, '
};

export const firmTypes = {
    ria: 'ria',
    ibd: 'ibd'
};
