import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

export interface DocuSignForm {
    orderId?: string;
    emailSubject: string;
    emailBody: string;
}

@Injectable()
export class OrderDocusignSendService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderDocusignSend(form: DocuSignForm): Observable<any> {
        return this.http.post<any>(`${this.environment.api}orders/${form.orderId}/send`, {
            emailSubject: form.emailSubject,
            emailBody: form.emailBody
        });
    }
}
