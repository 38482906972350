import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
    private _currentTitle: string;
    get currentTitle(): string {
        return this._currentTitle;
    }

    constructor(private titleService: Title) {}

    suffix = 'AIX Platform';

    setTitle(title: string) {
        this._currentTitle = `${title} - ${this.suffix}`;
        this.titleService.setTitle(this._currentTitle);
    }
}
