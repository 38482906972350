import {
    Component,
    contentChild,
    input,
    model,
    output,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import { NgClass, NgFor, NgTemplateOutlet } from '@angular/common';

export interface ButtonBarOption {
    label: string;
    [key: string]: any;
}

@Component({
    selector: 'aix-button-bar',
    templateUrl: './aix-button-bar.component.html',
    styleUrls: ['./aix-button-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgClass, NgTemplateOutlet]
})
export class AixButtonBarComponent {
    options = input<ButtonBarOption[]>();
    selectedOption = model<ButtonBarOption>();
    ulClasses = input('buttonbar__menu');
    liClasses = input('buttonbar__menu-item');

    onChange = output<ButtonBarOption>();

    optionTemplate = contentChild<TemplateRef<any>>(TemplateRef);

    onClickOption(option: ButtonBarOption): void {
        this.selectedOption.set(option);
        this.onChange.emit(option);
    }
}
