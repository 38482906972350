import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

export interface PendingFieldControl {
    control: AbstractControl;
    elem: HTMLElement;
    margin: number;
}

@Injectable()
export class AixPendingFieldsManagerService {
    currentField: string;
    controls: { [key: string]: PendingFieldControl } = {};
    form: FormGroup;

    constructor() {}

    setForm(form: FormGroup) {
        this.form = form;
    }

    addField(id: string, field: PendingFieldControl) {
        this.controls[id] = field;
    }

    removeField(id: string) {
        delete this.controls[id];
    }

    nextField() {
        // Stop searching next field if the form is valid
        if (this.form.valid) {
            return;
        }

        // Get dynamic form controls in order by DOM tree
        const controls: { [key: string]: PendingFieldControl } = {};
        document.querySelectorAll('[aixPendingFieldId]').forEach(item => {
            const refId = item.getAttribute('aixPendingFieldId') as string;
            controls[refId] = this.controls[refId];
        });

        const keys = Object.keys(controls);
        let nextIndex = keys.indexOf(this.currentField) + 1;

        if (nextIndex >= keys.length) {
            nextIndex = 0;
        }

        this.currentField = keys[nextIndex];

        const control = this.controls[this.currentField].control;

        if (control?.valid) {
            this.nextField();
        } else {
            this.scrollToField(this.currentField);
        }
    }

    scrollToField(id: string) {
        if (id) {
            const elem = this.controls[id].elem;
            const input = elem.nodeName === 'INPUT' ? elem : elem.querySelector('input');
            if (elem) {
                elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
                this.setAnimation(elem);

                setTimeout(() => this.afterAnimation(elem), 3100);

                if (input) {
                    // Wrapped in a setTimeout because otherwise the smooth scrollIntoView
                    // animation breaks
                    setTimeout(() => this.focusElement(input), 500);
                }
            }
        }
    }

    afterAnimation(elem: HTMLElement) {
        if (elem.querySelector('.aix-blink')) {
            elem.querySelector('.aix-blink')?.classList.remove('aix-blink-animation');
        } else {
            this.afterAnimation(elem.parentElement as HTMLElement);
        }
    }

    setAnimation(elem: HTMLElement) {
        if (elem.querySelector('.aix-blink')) {
            elem.querySelector('.aix-blink')?.classList.add('aix-blink-animation');
        } else {
            this.setAnimation(elem.parentElement as HTMLElement);
        }
    }

    focusElement(elem: HTMLElement) {
        if (elem.getAttribute('type') === 'text') {
            elem.focus();
        }
    }
}
