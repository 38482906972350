import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getFromStorage, setToStorage, withStorage } from '@trade-platform/ui-utils';
import { map, switchMap, tap } from 'rxjs/operators';
import {
    LoadRegisterFromStorageAction,
    PingRegisterAction,
    PingRegisterFailureAction,
    PingRegisterSuccessAction,
    RegisterActionTypes,
    SendRegisterAction,
    SendRegisterFailureAction,
    SendRegisterSuccessAction,
    SetRegisterAction,
    UnloadRegisterAction
} from './actions';
import { RegisterToken } from '../../models/register/model';
import { catchHttpError } from '@trade-platform/ui-shared';
import { of } from 'rxjs';
import { AixRegisterService } from './service';

@Injectable()
export class RegisterEffects {
    constructor(private actions$: Actions, private service: AixRegisterService) {}

    loadRegisterFromStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadRegisterFromStorageAction>(RegisterActionTypes.LOAD_REGISTER_FROM_STORAGE),
            map(() => {
                const profile = getFromStorage('register') as RegisterToken;
                return new SetRegisterAction(profile);
            })
        )
    );

    setRegister$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetRegisterAction>(RegisterActionTypes.SET_REGISTER),
                tap((action: SetRegisterAction) => {
                    setToStorage('register', action.payload);
                })
            ),
        { dispatch: false }
    );

    unloadRegister$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<UnloadRegisterAction>(RegisterActionTypes.UNLOAD_REGISTER),
                tap(() => {
                    withStorage(storage => storage.removeItem('register'));
                })
            ),
        { dispatch: false }
    );

    pingRegisterInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PingRegisterAction>(RegisterActionTypes.PING_REGISTER),
            switchMap(action =>
                this.service.sendRegisterInformation(action.jwt).pipe(
                    map(() => {
                        return new PingRegisterSuccessAction();
                    }),
                    catchHttpError(error => {
                        return of(new PingRegisterFailureAction({ error }));
                    })
                )
            )
        )
    );

    sendRegisterInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SendRegisterAction>(RegisterActionTypes.SEND_REGISTER),
            switchMap(action =>
                this.service.sendRegisterInformation(action.jwt, action.payload).pipe(
                    map(() => {
                        return new SendRegisterSuccessAction();
                    }),
                    catchHttpError(error => {
                        return of(new SendRegisterFailureAction({ error }));
                    })
                )
            )
        )
    );
}
