import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { fromObservable } from '../utils/observable-utils';
import { stringIsNotEmpty } from '../utils/string';

function prettyPrintDateFormat(format: string): string {
    switch (format) {
        case 'yyyy':
            return 'yyyy';
        case 'mmyyyy':
            return 'mm/yyyy';
        case 'mmddyyyy':
            return 'mm/dd/yyyy';
        default:
            return format;
    }
}

// validation function
export function validateDateFactory(format?: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        // Only a few values are allowed as a format. Since this error message appears on screen, we'll fall back to mmddyyyy since that's the field config default
        const errorMessage = `Please use the ${prettyPrintDateFormat(
            format || 'mmddyyyy'
        )} format.`;
        const ISODateRegExp = () =>
            /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/;
        // sometimes value comes from a @[a.b.c] binding, so it's an observable
        const value = fromObservable(c.value);
        if (!stringIsNotEmpty(value)) {
            return null;
        } else if (!isNaN(value)) {
            return { date: errorMessage };
        } else if (value.indexOf('T') > 0 && ISODateRegExp().test(value.split('T')[0])) {
            //check if ISOString format
            const millis = Date.parse(value);
            if (isNaN(millis)) {
                return { date: errorMessage };
            }

            return null;
        } else {
            const splitDate: string[] = value.split('/');

            // It's a value from the server
            if (value.indexOf('-') > 0) {
                return null;
            }

            switch (format) {
                case 'yyyy':
                    if (splitDate[0]?.length !== 4) {
                        return { date: errorMessage };
                    }
                    return null;
                case 'mmdd':
                    if (splitDate[0]?.length !== 2 || splitDate[1]?.length !== 2) {
                        return { date: errorMessage };
                    }
                    return null;
                case 'mmyyyy':
                    if (splitDate[0]?.length !== 2 || splitDate[1]?.length !== 4) {
                        return { date: errorMessage };
                    }
                    return null;
                case 'mmddyyyy':
                default:
                    if (
                        splitDate[0]?.length !== 2 ||
                        splitDate[1]?.length !== 2 ||
                        splitDate[2]?.length !== 4
                    ) {
                        return { date: errorMessage };
                    }
                    return null;
            }
        }
    };
}
