import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    getRequiredFilesToUpload,
    getTotalSupplementalUploads,
    getUploadedSupplementalFiles
} from '../../process/overview/utils/order-utils';
import { AixFileUploadComponent } from '../../file-upload/file-upload';
import {
    AixRequiredFilesMenuOption,
    AixUploadedFilesMenuOption
} from '../../file-upload/filelist/filelist';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { CommonModule, NgIf } from '@angular/common';

@Component({
    selector: 'aix-order-details-upload-documents',
    templateUrl: './upload-documents.html',
    styleUrls: ['./upload-documents.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        AixHeaderSectionComponent,
        AixButtonComponent,
        AixDataTestingDirective,
        NgIf,
        AixFileUploadComponent
    ]
})
export class AixOrdersDetailUploadDocumentsComponent implements OnInit, OnDestroy {
    readonly reducerSuffix = this.store.type;

    @Input() isReadOnly = false;
    @Input() sectionClasses: any;

    orderId: string;
    subscriptions: Subscription[] = [];

    hasUploadedFiles = true;
    uploadedFilesCount = 0;
    totalUploads = 0;
    finishedUploads = 0;
    arrayFiles: AixUploadedFilesMenuOption[] = [];
    arrayRequiredFiles?: AixRequiredFilesMenuOption[] = [];
    uploadButtonType = BUTTON_TYPE.link;

    @ViewChild('orderFileUpload')
    orderFileUpload: AixFileUploadComponent;

    constructor(
        private ref: ChangeDetectorRef,
        public route: ActivatedRoute,
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            (this.route.parent as ActivatedRoute).params.subscribe(params => {
                if (params['orderId']) {
                    this.orderId = params['orderId'];
                    this.loadOrder();
                }
            }),
            this.store.orderSuccess$.subscribe(order => {
                const uploadedSupplementalFiles = getUploadedSupplementalFiles(order);
                const totalSupplementalUploads = getTotalSupplementalUploads(order);

                this.uploadedFilesCount = uploadedSupplementalFiles.length;
                this.hasUploadedFiles = totalSupplementalUploads > 0;

                const files = order.files;
                const requiredFiles = order.requiredSupplementalFiles;
                if (files) {
                    this.arrayFiles = uploadedSupplementalFiles;
                }
                if (requiredFiles) {
                    this.arrayRequiredFiles = getRequiredFilesToUpload(order);
                }
                this.ref.detectChanges();
            })
        );
    }

    loadOrder() {
        this.store.actions.getOrder.dispatch({
            orderId: this.orderId,
            reducerSuffix: this.reducerSuffix
        });
    }

    uploadDocuments() {
        this.orderFileUpload.uploadDocuments();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
