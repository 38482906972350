<h5>Need help?</h5>
<div class="card-content">
    <p class="large-text u-mt8">Please contact our help desk.</p>
    <p class="u-mt8">
        <span class="u-fw600">Email: </span>
        <a class="br-link" href="mailto:{{supportEmail}}">{{ supportEmail }}</a>
    </p>
    <p class="u-mt4">
        <span class="u-fw600">Phone: </span>
        <a class="br-link" href="tel:{{supportPhone}}">{{ supportPhone }}</a>
        @if ((profile$ | async)?.organization?.extension) {
        <span> ext. {{ (profile$ | async)?.organization?.extension }} </span>
        }
    </p>
    <p class="u-mt4"><span class="u-fw600">Hours:</span> Mon.-Fri. 9 AM to 6 PM ET</p>
</div>
