import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    AccountRegistrationsActionTypes,
    LoadAccountRegistrations,
    LoadAccountRegistrationsByAdvisorAction,
    LoadAccountRegistrationsByAdvisorFailureAction,
    LoadAccountRegistrationsByAdvisorSuccessAction,
    LoadAccountRegistrationsFailure,
    LoadAccountRegistrationsSuccess,
    LoadRegistrationWithPositions,
    LoadRegistrationWithPositionsFailure,
    LoadRegistrationWithPositionsSuccess
} from './actions';
import { AccountRegistrationsService } from './service';

@Injectable()
export class AccountRegistrationsEffects {
    constructor(private actions$: Actions, private service: AccountRegistrationsService) {}

    loadAccountRegistrations$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadAccountRegistrations>(
                AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS
            ),
            switchMap(action => {
                return this.service.getAccountRegistrations(action.payload).pipe(
                    map(registrations => new LoadAccountRegistrationsSuccess(registrations)),
                    catchHttpError(error => of(new LoadAccountRegistrationsFailure({ error })))
                );
            })
        )
    );

    loadAccountRegistrationsByAdvisor$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadAccountRegistrationsByAdvisorAction>(
                AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR
            ),
            switchMap(action => {
                return this.service.getAccountRegistrationsByAdvisor(action.payload).pipe(
                    map(registrations => {
                        return new LoadAccountRegistrationsByAdvisorSuccessAction({
                            registrations,
                            reducerSuffix: action.payload.reducerSuffix
                        });
                    }),
                    catchHttpError(error =>
                        of(
                            new LoadAccountRegistrationsByAdvisorFailureAction({
                                error,
                                reducerSuffix: action.payload.reducerSuffix
                            })
                        )
                    )
                );
            })
        )
    );

    loadRegistrationWithPositions$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadRegistrationWithPositions>(
                AccountRegistrationsActionTypes.LOAD_REGISTRATION_WITH_POSITIONS
            ),
            switchMap(action => {
                return this.service.getRegistrationWithPositions(action.payload).pipe(
                    map(registration => new LoadRegistrationWithPositionsSuccess(registration)),
                    catchHttpError(error => of(new LoadRegistrationWithPositionsFailure({ error })))
                );
            })
        )
    );
}
