import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { OrderStep } from '../store/order-steps/types';
import { BaseOrder } from '../models/order/model';

@Injectable()
export abstract class BaseOrderStepsService {
    /**
     * Angular router NavigationExtras;
     * Pass `replaceUrl: true` to each `navigate()` call to replace possible intermediate or non-allowed urls in browser history;
     * Ex: `/orders/process/:orderId` will be replaced in the browser history with the current step url (ex: `/orders/process/:orderId/complete-forms`);
     */
    protected readonly navigationExtras: NavigationExtras = {
        replaceUrl: true
    };

    abstract getActiveStep(): OrderStep;
    abstract processActiveStep(order: BaseOrder, activeStep: OrderStep): void;
}
