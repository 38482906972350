import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { FundFirmActions, FundFirmActionTypes } from './actions';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { ExternalFirm } from './model';

/**
 * External Firms By Fund
 */

export type FirmsByFundState = RemoteData<ExternalFirm[], ErrorWrapper>;
const initialState = notAsked<ExternalFirm[], ErrorWrapper>();

export function firmsByFundReducer(
    state: FirmsByFundState = initialState,
    action: FundFirmActions
): FirmsByFundState {
    switch (action.type) {
        case FundFirmActionTypes.LOAD_FIRMS_BY_FUND:
            return inProgress({} as ExternalFirm[]);
        case FundFirmActionTypes.LOAD_FIRMS_BY_FUND_SUCCESS:
            return success(action.payload.firms);
        case FundFirmActionTypes.LOAD_FIRMS_BY_FUND_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
