import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    LoadProductMaterialsAction,
    LoadProductMaterialsFailureAction,
    LoadProductMaterialsSuccessAction,
    ProductMaterialActionTypes
} from './actions';
import { ProductMaterialsService } from './service';
import { catchHttpError } from '@trade-platform/ui-shared';

@Injectable()
export class ProductMaterialsEffects {
    constructor(private actions$: Actions, private service: ProductMaterialsService) {}

    loadProductMaterials$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductMaterialsAction>(ProductMaterialActionTypes.LOAD_PRODUCT_MATERIALS),
            switchMap(action =>
                this.service.loadProductMaterials(action.payload).pipe(
                    map(materials => new LoadProductMaterialsSuccessAction(materials)),
                    catchHttpError(error => of(new LoadProductMaterialsFailureAction({ error })))
                )
            )
        )
    );
}
