import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderStartReviewActions, OrderStartReviewActionTypes } from './actions';

export type OrderStartReviewState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderStartReviewReducer = (
    state: OrderStartReviewState = initialState,
    action: OrderStartReviewActions
): OrderStartReviewState => {
    switch (action.type) {
        case OrderStartReviewActionTypes.ORDER_START_REVIEW:
            return inProgress();
        case OrderStartReviewActionTypes.ORDER_START_REVIEW_SUCCESS:
            return success(null);
        case OrderStartReviewActionTypes.ORDER_START_REVIEW_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
