import { ApiFormErrorItem } from '@trade-platform/ui-utils';

export const CONFIRM_ORDER_CANCELLATION_TITLE = 'Cancel order?';
export const CONFIRM_ORDER_CANCELLATION_MSG =
    'Canceled orders can be reinstated for 5 calendar days.';
export const CONFIRM_ORDER_CANCELLATION_MSG_SUB =
    'After 5 calendar days canceled orders are moved to Archived.';
export const ERROR_CANCEL_ORDER_MESSAGE = 'Error. We were unable to cancel the order.';
export const ERROR_REINSTATE_ORDER_MESSAGE = 'Error. We were unable to reinstate the order.';
export const ERROR_CONTACT =
    'Please contact <a href="mailto:support@aixplatform.com">support@aixplatform.com</a>.';
export const ERROR_CREATE_ACCOUNT_DEFAULT = {
    // default text -- this should be updated on order create failure with account specifics
    lineOne: 'Error. Our records indicate an account already exists with this data.',
    lineTwo:
        'Please search for this account in the "Choose Investor Account" field. If you still do not see the account, please reach out to your system administrator.'
};
export const getCreateAccountError = (error: ApiFormErrorItem) => {
    const accountErrorText = ERROR_CREATE_ACCOUNT_DEFAULT;

    if (error.accountNumber && error.accountNumber !== '') {
        if (error.custodianName && error.custodianName !== '') {
            if (error.custodianName === 'No Custodian/Clearing Firm') {
                accountErrorText.lineOne = `Error. Our records indicate a directly held account already exists with account number ${error.accountNumber}.`;
            } else {
                accountErrorText.lineOne = `Error. Our records indicate an account already exists with account number ${error.accountNumber} at ${error.custodianName}.`;
            }
        } else {
            accountErrorText.lineOne = `Error. Our records indicate an account already exists with account number ${error.accountNumber}.`;
        }
    }

    return accountErrorText;
};
