import { JsonDecoder } from 'ts.data.json';
import { AcceptTermResponse, TermsAndConditions } from '../../models/terms/model';

export const termDecoder = JsonDecoder.object<TermsAndConditions>(
    {
        version: JsonDecoder.number,
        date: JsonDecoder.string,
        body: JsonDecoder.string,
        accepted: JsonDecoder.boolean,
        acceptedAt: JsonDecoder.string
    },
    'TermsAndConditions'
);

export const acceptTermResponseDecoder = JsonDecoder.object<AcceptTermResponse>(
    {
        acceptedTermsVersion: JsonDecoder.number,
        acceptedTermsAt: JsonDecoder.string
    },
    'AcceptTermResponse'
);
