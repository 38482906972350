import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class OrderStartReviewService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderStartReview(orderId: string, skipReview: boolean): Observable<any> {
        return this.http.post<any>(
            `${this.environment.api}orders/${orderId}/startReview?skipReview=${skipReview}`,
            {
                id: orderId
            }
        );
    }
}
