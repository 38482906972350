<footer
    [ngStyle]="styles.brandingStyle"
    class="aix-footer br-navigation--secondary__background-color"
>
    @if (!isOutages) {
    <div class="container">
        <div class="flex-grid">
            <div>
                Powered by AIX&trade; @if (isProfile) {
                <span
                    >|
                    <a
                        href="/#/privacy-policy"
                        target="_blank"
                        class="br-link br-link-white"
                        (mouseover)="onHoverLink($event.target)"
                        (mouseleave)="onLeaveLink($event.target)"
                    >
                        Privacy Policy
                    </a></span
                >
                }
            </div>
            <div class="hide-if-print">
                Contact Us:
                <a
                    href="mailto:{{ supportEmail }}"
                    class="br-link br-link-white"
                    (mouseover)="onHoverLink($event.target)"
                    (mouseleave)="onLeaveLink($event.target)"
                >
                    {{ supportEmail }} </a
                >,
                <a
                    href="tel:{{supportPhone}}"
                    class="br-link br-link-white"
                    (mouseover)="onHoverLink($event.target)"
                    (mouseleave)="onLeaveLink($event.target)"
                >
                    {{ supportPhone | aixPhone }}
                </a>
                @if ((profile$ | async)?.organization?.extension) {
                <span> ext. {{ (profile$ | async)?.organization?.extension }} </span>
                }
            </div>
        </div>
    </div>
    }
</footer>
