import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    UserByAdvisor,
    usersByAdvisorDecoder,
    UsersTrainingBody,
    usersTrainingDecoder,
    UsersTrainingResponse
} from '@trade-platform/ui-shared';

@Injectable()
export class UsersByAdvisorService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getUsersByAdvisor(repCode: string): Observable<UserByAdvisor[]> {
        return this.http
            .get<UserByAdvisor[]>(`${this.environment.api}users/repcodes/${repCode}`)
            .pipe(concatMap(p => fromPromise(usersByAdvisorDecoder.decodeToPromise(p))));
    }

    getUsersTraining(body: UsersTrainingBody): Observable<UsersTrainingResponse> {
        return this.http
            .post<UsersTrainingResponse>(`${this.environment.api}users/repcodes/training`, body)
            .pipe(concatMap(p => fromPromise(usersTrainingDecoder.decodeToPromise(p))));
    }

    getAdvisorsByFirmUser(): Observable<UserByAdvisor[]> {
        return this.http
            .get<UserByAdvisor[]>(`${this.environment.api}users/solicitingAdvisors`)
            .pipe(concatMap(p => fromPromise(usersByAdvisorDecoder.decodeToPromise(p))));
    }
}
