// Repeats `\\d` n times
const d = (num: number) => `\\d`.repeat(num);
const yyyy = d(4);
const mm = d(2);
const dd = d(2);
const __ = `(?:-|/)`;

const isoDateRegExp = () => new RegExp(`^${yyyy}-${mm}-${dd}T${d(2)}:${d(2)}:${d(2)}.${d(3)}Z$`);
const yyyymmddRegExp = () => new RegExp(`^(${yyyy})${__}(${mm})${__}(${dd})$`);
const mmddyyyyRegExp = () => new RegExp(`^(${mm})${__}(${dd})${__}(${yyyy})$`);

/**
 * Given a string returns a date or null.
 * It returns null when the `dateString` is falsy.
 *
 * @param dateString A date in either yyyy-mm-dd, mm/dd/yyyy or ISO format
 */
export const parseFormsDate = (dateString: string | null | undefined): Date | null => {
    return ([
        { filterFn: str => !str, mapFn: () => null },
        { filterFn: str => (str ? isoDateRegExp().test(str) : false), mapFn: str => new Date(str) },
        {
            filterFn: str => (str ? yyyymmddRegExp().test(str) : false),
            mapFn: str => {
                const match = str.match(yyyymmddRegExp()) as RegExpMatchArray;
                const [, Y, M, D] = match.map(n => parseInt(n, 10));
                return new Date(Y, M - 1, D);
            }
        },
        {
            filterFn: str => (str ? mmddyyyyRegExp().test(str) : false),
            mapFn: str => {
                const match = str.match(mmddyyyyRegExp()) as RegExpMatchArray;
                const [, M, D, Y] = match.map(n => parseInt(n, 10));
                return new Date(Y, M - 1, D);
            }
        }
    ] as {
        filterFn: (str: string | undefined | null) => boolean;
        mapFn: (str: string) => Date | null;
    }[])
        .filter(item => item.filterFn(dateString))
        .map(item => item.mapFn(dateString as string))
        .pop() as Date | null;
};
