import { JsonDecoder } from 'ts.data.json';
import { Transaction } from '../../models/transactions/model';

export const transactionDecoder = JsonDecoder.object<Transaction>(
    {
        id: JsonDecoder.number,
        displayName: JsonDecoder.string,
        name: JsonDecoder.optional(JsonDecoder.string),
        description: JsonDecoder.optional(JsonDecoder.string),
        tradeDate: JsonDecoder.optional(JsonDecoder.string),
        settlementDate: JsonDecoder.optional(JsonDecoder.string),
        marketValue: JsonDecoder.number,
        units: JsonDecoder.optional(JsonDecoder.number),
        unitPrice: JsonDecoder.optional(JsonDecoder.number),
        currencyCode: JsonDecoder.string,
        readonly: JsonDecoder.optional(JsonDecoder.boolean),
        transactionTypeName: JsonDecoder.string
    },
    'Transaction'
);

export const transactionsDecoder = JsonDecoder.array<Transaction>(
    transactionDecoder,
    'Transaction[]'
);
