import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';

export type SortByType = 'asc' | 'desc';

@Pipe({
    name: 'sortBy',
    standalone: true
})
export class SortByPipe implements PipeTransform {
    transform(value: any[], order: SortByType = 'asc', column = ''): any[] {
        if (!value) {
            return value;
        }
        if (value.length <= 1) {
            return value;
        }
        if (!column || column === '') {
            if (order === 'asc') {
                return value.sort();
            } else {
                return value.sort().reverse();
            }
        }
        return orderBy(value, [column], [order]);
    }
}
