import {
    AixAction,
    ErrorWrapper,
    OrderIdPayload,
    OrderSignPayload,
    type
} from '@trade-platform/ui-shared';

export class OrderFinishMapSignaturesActionTypes {
    static readonly ORDER_FINISH_MAP_SIGNATURES = type(
        '[Order Finish Map Signatures] Order Finish Map Signatures'
    );
    static readonly ORDER_FINISH_MAP_SIGNATURES_SUCCESS = type(
        '[Order Finish Map Signatures] Order Finish Map Signatures Success'
    );
    static readonly ORDER_FINISH_MAP_SIGNATURES_FAILURE = type(
        '[Order Finish Map Signatures] Order Finish Map Signatures Failure'
    );
}

export type OrderFinishMapSignaturesActions =
    | OrderFinishMapSignatures
    | OrderFinishMapSignaturesSuccess
    | OrderFinishMapSignaturesFailure;

export class OrderFinishMapSignatures implements AixAction {
    readonly type = OrderFinishMapSignaturesActionTypes.ORDER_FINISH_MAP_SIGNATURES;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderFinishMapSignaturesSuccess implements AixAction {
    readonly type = OrderFinishMapSignaturesActionTypes.ORDER_FINISH_MAP_SIGNATURES_SUCCESS;

    constructor(public payload: OrderSignPayload) {}
}

export class OrderFinishMapSignaturesFailure implements AixAction {
    readonly type = OrderFinishMapSignaturesActionTypes.ORDER_FINISH_MAP_SIGNATURES_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
