<div [formGroup]="form">
    <h5>{{ this.labelType === 'recordkeeper' ? 'Recordkeeper' : 'Custodial Arrangement' }}</h5>
    <p class="u-mt8">
        Select where the investment {{ this.labelType === 'maintenance' ? 'is currently' : 'will be'
        }} held.
    </p>

    <div class="aix-flex-grid__col u-mt16">
        <div class="aix-form__group" [data-testing]="'holding-option'">
            <aix-dropdown
                #holdingOptionRef
                [data-testing]="'holding-option-dropdown'"
                class="aix-form__control--required"
                [aixPendingField]="{ control: form.get('holdingOption') }"
                [class.aix-form__control--dirty]="holdingOptionRef.isDirty"
                [class.aix-form__control--invalid]="!holdingOption"
                [class.aix-form__control--invalid-required]="!holdingOption"
                [formControlName]="'holdingOption'"
                [options]="holdingOptions"
                labelField="name"
                valueField="id"
                placeholder=""
                [itemMessage]="itemMessage"
                (ngModelChange)="holdingOption = $event && $event.length > 0 ? $event[0].id : null"
                [aixDisabled]="form.get('holdingOption')?.disabled"
            >
            </aix-dropdown>
            <label [data-testing]="'title'" class="aix-form__label aix-label"
                >{{ this.labelType === 'recordkeeper' ? 'Recordkeeper' : 'Holding Option' }}</label
            >
        </div>
    </div>
</div>
