/**
 * Order higher order reducer
 */

import { failure, fold, inProgress, map, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import { LoadOrderActions, LoadOrderActionTypes } from './actions';
import { BaseOrder, LoadOrderSuffix } from '../../models/order/model';
import { set as _set } from 'lodash-es';

export type LoadOrderState = RemoteData<BaseOrder, ErrorWrapper>;

export function orderReducerFactory(reducerSuffixes: LoadOrderSuffix[]) {
    const reducer = (state: LoadOrderState, action: LoadOrderActions): LoadOrderState => {
        switch (action.type) {
            case LoadOrderActionTypes.LOAD_ORDER: {
                return inProgress({} as BaseOrder);
            }
            case LoadOrderActionTypes.LOAD_ORDER_SUCCESS: {
                return success(action.payload.order);
            }
            case LoadOrderActionTypes.LOAD_ORDER_FAILURE: {
                return failure(action.payload.error);
            }
            case LoadOrderActionTypes.RESET_ORDER: {
                return notAsked();
            }
            case LoadOrderActionTypes.REMOVE_INVESTOR_FROM_ORDER: {
                return map(order => {
                    if (order.account) {
                        order.account.investors = order.account.investors.filter(
                            inv => inv.id !== action.payload.investor.id
                        );
                    }
                    return order;
                }, state);
            }
            case LoadOrderActionTypes.SET_ORDER_PATH_VALUE: {
                const errorMsg = (status: string) =>
                    `The Order is in the "${status}" state and you are trying to set a value. You can only set values to an Order when it's in the "Success" state.`;
                return success(
                    fold(
                        () => {
                            throw new Error(errorMsg('Not Asked'));
                        },
                        () => {
                            throw new Error(errorMsg('In Progress'));
                        },
                        () => {
                            throw new Error(errorMsg('Failure'));
                        },
                        order => {
                            const params = action.payload.params;
                            // Mutate val
                            if (params.actionValue === 'passthrough') {
                                _set(order, params.storePath, action.payload.value);
                            } else {
                                const valueToSet = params.actionValue[action.payload.value];
                                if (valueToSet !== undefined) {
                                    _set(order, params.storePath, valueToSet);
                                }
                            }
                            return order;
                        },
                        state
                    )
                );
            }
            default: {
                return state;
            }
        }
    };

    return (state: LoadOrderState = notAsked(), action: LoadOrderActions): LoadOrderState => {
        if (action.payload) {
            if (reducerSuffixes.indexOf(action.payload.reducerSuffix) > -1) {
                return [reducer].reduce((acc, fn) => fn(acc, action), state);
            } else {
                return state;
            }
        } else {
            return state;
        }
    };
}
