import { Pipe, PipeTransform } from '@angular/core';
import { createSeparatorMask, unMaskSeparatorValue } from '@trade-platform/ui-utils';

@Pipe({
    name: 'aixPhone',
    standalone: true
})
export class AixPhonePipe implements PipeTransform {
    private readonly PHONE_MAX_LENGTH = 10;
    private mask = createSeparatorMask([3, 6], this.PHONE_MAX_LENGTH, '-');

    transform(phone: string) {
        return this.mask(unMaskSeparatorValue(phone) as string);
    }
}
