import { JsonDecoder } from 'ts.data.json';
import { firmDecoder } from '../firm/decoder';
import {
    ExternalFirm,
    Feature,
    Profile,
    ProfileHoldingOption,
    ProfileOrganization,
    ProfileRepCode,
    ProfileSubTenant,
    RepCodeUsers,
    Role,
    SharedExternalFirm,
    UserEventHistory,
    UserTenantDataTypes
} from '../../models/profile/model';
import { fundSponsorDecoder } from '../fund-sponsor/decoder';
import { addressDecoder } from '../user/decoder';

export const repCodeUsersDecoder = JsonDecoder.object<RepCodeUsers>(
    {
        id: JsonDecoder.optional(JsonDecoder.string),
        firstName: JsonDecoder.optional(JsonDecoder.string),
        lastName: JsonDecoder.optional(JsonDecoder.string),
        fullName: JsonDecoder.optional(JsonDecoder.string),
        email: JsonDecoder.optional(JsonDecoder.string),
        birthDate: JsonDecoder.optional(JsonDecoder.string),
        banned: JsonDecoder.optional(JsonDecoder.boolean),
        firmAdmin: JsonDecoder.optional(JsonDecoder.boolean),
        tenantCode: JsonDecoder.optional(JsonDecoder.string)
    },
    'RepCodeUsers'
);

export const profileRepCodeDecoder = JsonDecoder.object<ProfileRepCode>(
    {
        id: JsonDecoder.string,
        fullName: JsonDecoder.optional(JsonDecoder.string),
        repCode: JsonDecoder.optional(JsonDecoder.string),
        listName: JsonDecoder.optional(JsonDecoder.string),
        custodianName: JsonDecoder.optional(JsonDecoder.string),
        repCodeSubstring: JsonDecoder.optional(JsonDecoder.string),
        firmCodeSubstring: JsonDecoder.optional(JsonDecoder.string),
        branchCodeSubstring: JsonDecoder.optional(JsonDecoder.string),
        users: JsonDecoder.optional(JsonDecoder.array(repCodeUsersDecoder, 'repCodeUsers[]'))
    },
    'ProfileRepCode'
);

export const repCodesDecoder = JsonDecoder.array<ProfileRepCode>(
    profileRepCodeDecoder,
    'ProfileRepCodes[]'
);

export const roleDecoder = JsonDecoder.object<Role>(
    {
        name: JsonDecoder.string
    },
    'Role'
);

export const featureDecoder = JsonDecoder.object<Feature>(
    {
        name: JsonDecoder.string
    },
    'Feature'
);

export const profileHoldingOptionDecoder = JsonDecoder.object<ProfileHoldingOption>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.string,
        shortCode: JsonDecoder.string,
        zip: JsonDecoder.optional(JsonDecoder.string),
        city: JsonDecoder.optional(JsonDecoder.string),
        phone: JsonDecoder.optional(JsonDecoder.string),
        state: JsonDecoder.optional(JsonDecoder.string),
        addressLine1: JsonDecoder.optional(JsonDecoder.string),
        addressLine2: JsonDecoder.optional(JsonDecoder.string),
        accountPrefix: JsonDecoder.optional(JsonDecoder.string),
        accountPostfix: JsonDecoder.optional(JsonDecoder.string),
        externalCustodianId: JsonDecoder.optional(JsonDecoder.string),
        custodianTaxId: JsonDecoder.optional(JsonDecoder.string)
    },
    'ProfileHoldingOption'
);

export const userTenantDataTypesDecoder = JsonDecoder.oneOf<UserTenantDataTypes>(
    [
        JsonDecoder.isExactly<'firm'>('firm'),
        JsonDecoder.isExactly<'holdingOption'>('holdingOption'),
        JsonDecoder.isExactly<'fundSponsor'>('fundSponsor'),
        JsonDecoder.isExactly<'transferAgent'>('transferAgent')
    ],
    'firm | holdingOption | fundSponsor | transferAgent'
);

export const subTenantDecoder = JsonDecoder.object<ProfileSubTenant>(
    {
        entityId: JsonDecoder.number,
        type: userTenantDataTypesDecoder
    },
    'ProfileSubTenant'
);

export const profileOrganizationDecoder = JsonDecoder.object<ProfileOrganization>(
    {
        id: JsonDecoder.optional(JsonDecoder.number),
        tenantIdentifier: JsonDecoder.string,
        entityId: JsonDecoder.number,
        type: userTenantDataTypesDecoder,
        extension: JsonDecoder.optional(JsonDecoder.string),
        subTenants: JsonDecoder.optional(JsonDecoder.array(subTenantDecoder, 'SubTenants[]')),
        supportEmail: JsonDecoder.optional(JsonDecoder.string),
        supportPhoneNumber: JsonDecoder.optional(JsonDecoder.string),
        languageOptions: JsonDecoder.optional(JsonDecoder.succeed),
        routing: JsonDecoder.optional(JsonDecoder.succeed)
    },
    'ProfileOrganization'
);

export const profileExternalFirmDecoder = JsonDecoder.object<ExternalFirm>(
    {
        id: JsonDecoder.number,
        name: JsonDecoder.string,
        type: JsonDecoder.string,
        crdNumber: JsonDecoder.optional(JsonDecoder.string),
        addressStreet: JsonDecoder.optional(JsonDecoder.string),
        addressCity: JsonDecoder.optional(JsonDecoder.string),
        addressState: JsonDecoder.optional(JsonDecoder.string),
        addressZip: JsonDecoder.optional(JsonDecoder.string)
    },
    'ExternalFirm'
);

export const profileExternalFirmsDecoder = JsonDecoder.array<ExternalFirm>(
    profileExternalFirmDecoder,
    'ExternalFirms[]'
);

export const userEventHistory = JsonDecoder.object<UserEventHistory>(
    {
        createdAt: JsonDecoder.string,
        updatedBy: JsonDecoder.number,
        passwordResetDate: JsonDecoder.optional(JsonDecoder.string),
        accountSetupDate: JsonDecoder.optional(JsonDecoder.string),
        editDate: JsonDecoder.optional(JsonDecoder.string),
        resetMFADate: JsonDecoder.optional(JsonDecoder.string),
        isPasswordResetExpired: JsonDecoder.optional(JsonDecoder.boolean),
        isAccountSetupExpired: JsonDecoder.optional(JsonDecoder.boolean),
        passwordResetExpirationDate: JsonDecoder.optional(JsonDecoder.string),
        accountSetupExpirationDate: JsonDecoder.optional(JsonDecoder.string)
    },
    'userEventHistory'
);

export const profileDecoder = JsonDecoder.object<Profile>(
    {
        crdNumber: JsonDecoder.optional(JsonDecoder.string),
        fullName: JsonDecoder.string,
        features: JsonDecoder.array(featureDecoder, 'Features[]'),
        firstName: JsonDecoder.string,
        lastName: JsonDecoder.string,
        email: JsonDecoder.string,
        repCodes: JsonDecoder.array(profileRepCodeDecoder, 'ProfileRepCode[]'),
        firm: JsonDecoder.optional(firmDecoder),
        holdingOption: JsonDecoder.optional(profileHoldingOptionDecoder),
        fundSponsor: JsonDecoder.optional(fundSponsorDecoder),
        roles: JsonDecoder.array(roleDecoder, 'Role[]'),
        acceptedTerms: JsonDecoder.boolean,
        identityProviderId: JsonDecoder.number,
        firmName: JsonDecoder.optional(JsonDecoder.string),
        firmCRDNumber: JsonDecoder.optional(JsonDecoder.string),
        firmAddress: JsonDecoder.optional(addressDecoder),
        branchName: JsonDecoder.optional(JsonDecoder.string),
        branchNumber: JsonDecoder.optional(JsonDecoder.string),
        branchId: JsonDecoder.optional(JsonDecoder.string),
        branchAddress: JsonDecoder.optional(addressDecoder),
        officeName: JsonDecoder.optional(JsonDecoder.string),
        officeId: JsonDecoder.optional(JsonDecoder.string),
        addressStreet: JsonDecoder.optional(JsonDecoder.string),
        addressCity: JsonDecoder.optional(JsonDecoder.string),
        addressState: JsonDecoder.optional(JsonDecoder.string),
        addressZip: JsonDecoder.optional(JsonDecoder.string),
        phoneMobile: JsonDecoder.optional(JsonDecoder.string),
        organization: JsonDecoder.optional(profileOrganizationDecoder),
        firms: JsonDecoder.optional(profileExternalFirmsDecoder),
        eventHistory: JsonDecoder.optional(userEventHistory)
    },
    'Profile'
);

export const resetMFADecoder = JsonDecoder.succeed;

export const sharedExternalFirmDecoder = JsonDecoder.object<SharedExternalFirm>(
    {
        externalFirmId: JsonDecoder.string,
        name: JsonDecoder.string
    },
    'SharedExternalFirm'
);

export const sharedExternalFirmsDecoder = JsonDecoder.array<SharedExternalFirm>(
    sharedExternalFirmDecoder,
    'SharedExternalFirms[]'
);
