import {
    AixAction,
    ErrorWrapper,
    OrderFormComment,
    OrderFormCommentCreatePayload,
    OrderFormCommentListPayload,
    OrderFormCommentRequestPayload,
    OrderFormCommentResolvePayload,
    OrderFormCommentsResolvePayload,
    OrderFormCommentsResolveResponse,
    OrderFormCommentUpdatePayload,
    type
} from '@trade-platform/ui-shared';

export class OrderFormCommentActionTypes {
    static readonly ORDER_FORM_COMMENT_GET = type('[OrderFormComment] Get Comment');
    static readonly ORDER_FORM_COMMENT_GET_SUCCESS = type('[OrderFormComment] Get Comment Success');
    static readonly ORDER_FORM_COMMENT_GET_FAILURE = type('[OrderFormComment] Get Comment Failure');

    static readonly ORDER_FORM_COMMENT_LIST = type('[OrderFormComment] List Comments');
    static readonly ORDER_FORM_COMMENT_LIST_SUCCESS = type(
        '[OrderFormComment] List Comments Success'
    );
    static readonly ORDER_FORM_COMMENT_LIST_FAILURE = type(
        '[OrderFormComment] List Comments Failure'
    );

    static readonly ORDER_FORM_COMMENT_CREATE = type('[OrderFormComment] Create Comment');
    static readonly ORDER_FORM_COMMENT_CREATE_SUCCESS = type(
        '[OrderFormComment] Create Comment Success'
    );
    static readonly ORDER_FORM_COMMENT_CREATE_FAILURE = type(
        '[OrderFormComment] Create Comment Failure'
    );

    static readonly ORDER_FORM_COMMENT_EDIT = type('[OrderFormComment] Edit Comment');
    static readonly ORDER_FORM_COMMENT_EDIT_SUCCESS = type(
        '[OrderFormComment] Edit Comment Success'
    );

    static readonly ORDER_FORM_COMMENT_DELETE = type('[OrderFormComment] Delete Comment');
    static readonly ORDER_FORM_COMMENT_DELETE_SUCCESS = type(
        '[OrderFormComment] Delete Comment Success'
    );
    static readonly ORDER_FORM_COMMENT_DELETE_FAILURE = type(
        '[OrderFormComment] Delete Comment Failure'
    );

    static readonly ORDER_FORM_COMMENT_UPDATE = type('[OrderFormComment] Update Comment');
    static readonly ORDER_FORM_COMMENT_UPDATE_SUCCESS = type(
        '[OrderFormComment] Update Comment Success'
    );
    static readonly ORDER_FORM_COMMENT_UPDATE_FAILURE = type(
        '[OrderFormComment] Update Comment Failure'
    );

    static readonly ORDER_FORM_COMMENT_RESOLVE = type('[OrderFormComment] Resolve Comment');
    static readonly ORDER_FORM_COMMENT_RESOLVE_SUCCESS = type(
        '[OrderFormComment] Resolve Comment Success'
    );
    static readonly ORDER_FORM_COMMENT_RESOLVE_FAILURE = type(
        '[OrderFormComment] Resolve Comment Failure'
    );

    static readonly ORDER_FORM_COMMENTS_RESOLVE = type('[OrderFormComment] Resolve Comments');
    static readonly ORDER_FORM_COMMENTS_RESOLVE_SUCCESS = type(
        '[OrderFormComment] Resolve Comments Success'
    );
    static readonly ORDER_FORM_COMMENTS_RESOLVE_FAILURE = type(
        '[OrderFormComment] Resolve Comments Failure'
    );
}

export type OrderFormCommentActions =
    | OrderFormCommentGetAction
    | OrderFormCommentGetSuccessAction
    | OrderFormCommentGetFailureAction
    | OrderFormCommentListAction
    | OrderFormCommentListSuccessAction
    | OrderFormCommentListFailureAction
    | OrderFormCommentCreateAction
    | OrderFormCommentCreateSuccessAction
    | OrderFormCommentCreateFailureAction
    | OrderFormCommentEditAction
    | OrderFormCommentEditSuccessAction
    | OrderFormCommentDeleteAction
    | OrderFormCommentDeleteSuccessAction
    | OrderFormCommentDeleteFailureAction
    | OrderFormCommentUpdateAction
    | OrderFormCommentUpdateSuccessAction
    | OrderFormCommentUpdateFailureAction
    | OrderFormCommentResolveAction
    | OrderFormCommentResolveSuccessAction
    | OrderFormCommentResolveFailureAction
    | OrderFormCommentsResolveAction
    | OrderFormCommentsResolveSuccessAction
    | OrderFormCommentsResolveFailureAction;

// GET;
export class OrderFormCommentGetAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_GET;

    constructor(public payload: OrderFormCommentRequestPayload) {}
}
export class OrderFormCommentGetSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_GET_SUCCESS;

    constructor(public payload: OrderFormComment) {}
}
export class OrderFormCommentGetFailureAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_GET_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// LIST;
export class OrderFormCommentListAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_LIST;

    constructor(public payload: OrderFormCommentListPayload) {}
}
export class OrderFormCommentListSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_LIST_SUCCESS;

    constructor(public payload: OrderFormComment[]) {}
}
export class OrderFormCommentListFailureAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_LIST_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// CREATE;
export class OrderFormCommentCreateAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_CREATE;

    constructor(public payload: OrderFormCommentCreatePayload) {}
}
export class OrderFormCommentCreateSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_CREATE_SUCCESS;

    constructor(public payload: OrderFormComment) {}
}
export class OrderFormCommentCreateFailureAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_CREATE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// EDIT;
export class OrderFormCommentEditAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_EDIT;

    constructor(public payload: OrderFormComment) {}
}
export class OrderFormCommentEditSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_EDIT_SUCCESS;

    constructor(public payload: OrderFormComment) {}
}

// DELETE;
export class OrderFormCommentDeleteAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_DELETE;

    constructor(public payload: OrderFormCommentRequestPayload) {}
}
export class OrderFormCommentDeleteSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_DELETE_SUCCESS;

    constructor(public payload: any) {}
}
export class OrderFormCommentDeleteFailureAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_DELETE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// UPDATE;
export class OrderFormCommentUpdateAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_UPDATE;

    constructor(public payload: OrderFormCommentUpdatePayload) {}
}
export class OrderFormCommentUpdateSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_UPDATE_SUCCESS;

    constructor(public payload: OrderFormComment) {}
}
export class OrderFormCommentUpdateFailureAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_UPDATE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// RESOLVE;
export class OrderFormCommentResolveAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_RESOLVE;

    constructor(public payload: OrderFormCommentResolvePayload) {}
}
export class OrderFormCommentResolveSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_RESOLVE_SUCCESS;

    constructor(public payload: OrderFormComment) {}
}
export class OrderFormCommentResolveFailureAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENT_RESOLVE_FAILURE;

    constructor(public payload: { error: ErrorWrapper; data: OrderFormCommentRequestPayload }) {}
}

// RESOLVE COMMENTS;
export class OrderFormCommentsResolveAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENTS_RESOLVE;

    constructor(public payload: OrderFormCommentsResolvePayload) {}
}
export class OrderFormCommentsResolveSuccessAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENTS_RESOLVE_SUCCESS;

    constructor(public payload: OrderFormCommentsResolveResponse) {}
}
export class OrderFormCommentsResolveFailureAction implements AixAction {
    readonly type = OrderFormCommentActionTypes.ORDER_FORM_COMMENTS_RESOLVE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
