import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import { InvestorActions, InvestorActionTypes } from './actions';
import { Investor } from '../../models/investor/model';

export type InvestorState = RemoteData<Investor, ErrorWrapper>;

export function investorReducer(reducerSuffix: string) {
    const initialState = notAsked<Investor, ErrorWrapper>();

    const reducer = (state = initialState, action: InvestorActions): InvestorState => {
        switch (action.type) {
            case InvestorActionTypes.CLEAR_INVESTOR:
                return notAsked();
            case InvestorActionTypes.LOAD_INVESTOR_BY_TAX_ID:
                return inProgress({} as Investor);
            case InvestorActionTypes.LOAD_INVESTOR_BY_TAX_ID_SUCCESS:
                return success(action.payload.investor);
            case InvestorActionTypes.LOAD_INVESTOR_BY_TAX_ID_FAILURE:
                return failure(action.payload.error);
            default:
                return state;
        }
    };

    return (state = initialState, action: InvestorActions) => {
        if (action.payload) {
            switch (action.payload.reducerSuffix) {
                case reducerSuffix:
                    return reducer(state, action);
                default:
                    return state;
            }
        } else {
            return state;
        }
    };
}
