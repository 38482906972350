import {
    AixAction,
    ErrorWrapper,
    Order,
    OrderCompletePayload,
    type
} from '@trade-platform/ui-shared';

export class UpdateSettlementDetailsActionTypes {
    static readonly UPDATE_SETTLEMENT_DETAILS = type('[Update Settlement Details] Update');
    static readonly UPDATE_SETTLEMENT_DETAILS_SUCCESS = type('[Update Settlement Details] Success');
    static readonly UPDATE_SETTLEMENT_DETAILS_FAILURE = type('[Update Settlement Details] Failure');
}

export type UpdateSettlementDetailsActions =
    | UpdateSettlementDetailsAction
    | UpdateSettlementDetailsSuccessAction
    | UpdateSettlementDetailsFailureAction;

export class UpdateSettlementDetailsAction implements AixAction {
    readonly type = UpdateSettlementDetailsActionTypes.UPDATE_SETTLEMENT_DETAILS;

    constructor(public payload: OrderCompletePayload) {}
}

export class UpdateSettlementDetailsSuccessAction implements AixAction {
    readonly type = UpdateSettlementDetailsActionTypes.UPDATE_SETTLEMENT_DETAILS_SUCCESS;

    constructor(public payload: { order: Order }) {}
}

export class UpdateSettlementDetailsFailureAction implements AixAction {
    readonly type = UpdateSettlementDetailsActionTypes.UPDATE_SETTLEMENT_DETAILS_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
