import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    OrderSigningMethodPayload,
    OrderSigningMethodResponse,
    orderSigningMethodResponseDecoder
} from '@trade-platform/ui-shared';

@Injectable()
export class OrderSigningMethodService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderSigningMethod(
        payload: OrderSigningMethodPayload
    ): Observable<OrderSigningMethodResponse | undefined> {
        return this.http
            .post<OrderSigningMethodResponse>(
                `${this.environment.api}orders/${payload.orderId}/signingMethod`,
                {
                    signingMethod: payload.signingMethod
                }
            )
            .pipe(
                concatMap(p => {
                    return fromPromise(orderSigningMethodResponseDecoder.decodeToPromise(p));
                })
            );
    }
}
