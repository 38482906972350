import { AixAction, ErrorWrapper, type } from '@trade-platform/ui-shared';
import { ExternalFirm } from './model';

export class FundFirmActionTypes {
    static readonly LOAD_FIRMS_BY_FUND = type('[External Firms] Load External Firms By Fund');
    static readonly LOAD_FIRMS_BY_FUND_SUCCESS = type(
        '[External Firms] Load External Firms By Fund Success'
    );
    static readonly LOAD_FIRMS_BY_FUND_FAILURE = type(
        '[External Firms] Load External Firms By Fund Failure'
    );
}

export type FundFirmActions =
    | LoadFirmsByFundAction
    | LoadFirmsByFundSuccessAction
    | LoadFirmsByFundFailureAction;

// Load External Firms by Fund
export class LoadFirmsByFundAction implements AixAction {
    readonly type = FundFirmActionTypes.LOAD_FIRMS_BY_FUND;

    constructor(public payload: number) {}
}

export class LoadFirmsByFundSuccessAction implements AixAction {
    readonly type = FundFirmActionTypes.LOAD_FIRMS_BY_FUND_SUCCESS;

    constructor(
        public payload: {
            firms: ExternalFirm[];
        }
    ) {}
}

export class LoadFirmsByFundFailureAction implements AixAction {
    readonly type = FundFirmActionTypes.LOAD_FIRMS_BY_FUND_FAILURE;

    constructor(
        public payload: {
            error: ErrorWrapper;
        }
    ) {}
}
