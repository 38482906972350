import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    LoadAdvisorsByFirmUserAction,
    LoadAdvisorsByFirmUserFailureAction,
    LoadAdvisorsByFirmUserSuccessAction,
    LoadUsersByAdvisorAction,
    LoadUsersByAdvisorFailureAction,
    LoadUsersByAdvisorSuccessAction,
    LoadUsersTrainingAction,
    LoadUsersTrainingFailureAction,
    LoadUsersTrainingSuccessAction,
    UsersByAdvisorActionTypes
} from './actions';
import { UsersByAdvisorService } from './service';

@Injectable()
export class UsersByAdvisorEffects {
    constructor(private actions$: Actions, private service: UsersByAdvisorService) {}

    loadUsersByAdvisor$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadUsersByAdvisorAction>(UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR),
            switchMap(action => {
                return this.service.getUsersByAdvisor(action.payload).pipe(
                    map(users => {
                        return new LoadUsersByAdvisorSuccessAction(users);
                    }),
                    catchHttpError(error => of(new LoadUsersByAdvisorFailureAction({ error })))
                );
            })
        )
    );

    loadUsersTraining$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadUsersTrainingAction>(UsersByAdvisorActionTypes.LOAD_USERS_TRAINING),
            switchMap(action => {
                return this.service.getUsersTraining(action.payload).pipe(
                    map(training => {
                        return new LoadUsersTrainingSuccessAction(training);
                    }),
                    catchHttpError(error => of(new LoadUsersTrainingFailureAction({ error })))
                );
            })
        )
    );

    loadAdvisorsByFirmUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadAdvisorsByFirmUserAction>(
                UsersByAdvisorActionTypes.LOAD_ADVISORS_BY_FIRM_USER
            ),
            switchMap(action => {
                return this.service.getAdvisorsByFirmUser().pipe(
                    map(users => {
                        return new LoadAdvisorsByFirmUserSuccessAction(users);
                    }),
                    catchHttpError(error => of(new LoadAdvisorsByFirmUserFailureAction({ error })))
                );
            })
        )
    );
}
