<div>
    <div class="aix-btn__icon-wrapper u-mr8">
        @if (isLoading()) {
        <aix-loading
            class="btn-loading"
            [style]="styles.loading"
            [size]="14"
            [relative]="true"
            [transparent]="true"
            [negative]="buttonStyle() === 'primary'"
        >
        </aix-loading>
        } @if (icon() && !isLoading()) {
        <i [ngClass]="icon()"></i>
        }
    </div>
    <span>{{ label() }}</span>
</div>
