import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderSigningCompleteActionTypes {
    static readonly ORDER_SIGNING_COMPLETE = type(
        '[Order Signing Complete] Order Signing Complete'
    );
    static readonly ORDER_SIGNING_COMPLETE_SUCCESS = type(
        '[Order Signing Complete] Order Signing Complete Success'
    );
    static readonly ORDER_SIGNING_COMPLETE_FAILURE = type(
        '[Order Signing Complete] Order Signing Complete Failure'
    );
}

export class OrderSigningComplete implements AixAction {
    readonly type = OrderSigningCompleteActionTypes.ORDER_SIGNING_COMPLETE;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderSigningCompleteSuccess implements AixAction {
    readonly type = OrderSigningCompleteActionTypes.ORDER_SIGNING_COMPLETE_SUCCESS;
}

export class OrderSigningCompleteFailure implements AixAction {
    readonly type = OrderSigningCompleteActionTypes.ORDER_SIGNING_COMPLETE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export type OrderSigningCompleteActions =
    | OrderSigningComplete
    | OrderSigningCompleteSuccess
    | OrderSigningCompleteFailure;
