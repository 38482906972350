import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';

export class FileUploadActionTypes {
    static readonly UPLOAD_START = type('[FileUpload] Start Upload');
    static readonly UPLOAD_SUCCESS = type('[FileUpload] Upload Success');
    static readonly UPLOAD_FAILURE = type('[FileUpload] Upload Failure');
    static readonly UPLOAD_CANCEL = type('[FileUpload] Cancel Upload');
}

export type FileUploadActions =
    | FileUploadStartAction
    | FileUploadSuccessAction
    | FileUploadFailureAction
    | FileUploadCancelAction;

export class FileUploadStartAction implements AixAction {
    readonly type = FileUploadActionTypes.UPLOAD_START;

    constructor() {}
}

export class FileUploadSuccessAction implements AixAction {
    readonly type = FileUploadActionTypes.UPLOAD_SUCCESS;

    constructor() {}
}

export class FileUploadFailureAction implements AixAction {
    readonly type = FileUploadActionTypes.UPLOAD_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class FileUploadCancelAction implements AixAction {
    readonly type = FileUploadActionTypes.UPLOAD_CANCEL;

    constructor() {}
}
