import {
    AixAction,
    ErrorWrapper,
    OrderIdPayload,
    OrderReinstateResponse,
    type
} from '@trade-platform/ui-shared';

export class OrderReinstateActionTypes {
    static readonly ORDER_REINSTATE = type('[OrderReinstate] Reinstate Order');
    static readonly ORDER_REINSTATE_SUCCESS = type('[OrderReinstate] Reinstate Order Success');
    static readonly ORDER_REINSTATE_FAILURE = type('[OrderReinstate] Reinstate Order Failure');
}

export type OrderReinstateActions =
    | OrderReinstateAction
    | OrderReinstateSuccessAction
    | OrderReinstateFailureAction;

export class OrderReinstateAction implements AixAction {
    readonly type = OrderReinstateActionTypes.ORDER_REINSTATE;

    constructor(public payload: OrderIdPayload) {}
}
export class OrderReinstateSuccessAction implements AixAction {
    readonly type = OrderReinstateActionTypes.ORDER_REINSTATE_SUCCESS;

    constructor(public payload: OrderReinstateResponse) {}
}
export class OrderReinstateFailureAction implements AixAction {
    readonly type = OrderReinstateActionTypes.ORDER_REINSTATE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
