import { JsonDecoder } from 'ts.data.json';
import { Brand } from '../../models/branding/model';

export const brandDecoder = JsonDecoder.object<Brand>(
    {
        logo: JsonDecoder.optional(JsonDecoder.string),
        loginLogo: JsonDecoder.optional(JsonDecoder.string),
        favicon: JsonDecoder.optional(JsonDecoder.string),
        docusignEmail: JsonDecoder.optional(JsonDecoder.string),
        docusignPrimary: JsonDecoder.optional(JsonDecoder.string),
        primaryColor: JsonDecoder.optional(JsonDecoder.string),
        primaryColorDark: JsonDecoder.optional(JsonDecoder.string),
        primaryColorLight: JsonDecoder.optional(JsonDecoder.string),
        secondaryColor: JsonDecoder.optional(JsonDecoder.string),
        secondaryColorDark: JsonDecoder.optional(JsonDecoder.string),
        secondaryColorLight: JsonDecoder.optional(JsonDecoder.string),
        navbarBgColor: JsonDecoder.optional(JsonDecoder.string),
        navbarTextColor: JsonDecoder.optional(JsonDecoder.string),
        navbarHoverColor: JsonDecoder.optional(JsonDecoder.string),
        css: JsonDecoder.optional(JsonDecoder.string),
        subdomain: JsonDecoder.optional(JsonDecoder.string)
    },
    'Brand'
);
