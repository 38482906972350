import { JsonDecoder } from 'ts.data.json';
import { FundCusips } from '../../models/cusips/model';

export const cusipDecoder = JsonDecoder.object<FundCusips>(
    {
        displayName: JsonDecoder.string,
        cusips: JsonDecoder.array<string>(JsonDecoder.string, 'string[]')
    },
    'FundCusips'
);

export const cusipsDecoder = JsonDecoder.array<FundCusips>(cusipDecoder, 'FundCusips[]');
