import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { AixLoadingComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-page-not-found',
    templateUrl: './page-not-found.html',
    styleUrls: ['./page-not-found.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AixLoadingComponent]
})
export class AixPageNotFoundComponent implements OnInit {
    isLoggingIn = true;
    constructor(public route: ActivatedRoute) {}

    ngOnInit() {
        this.isLoggingIn = this.route.snapshot.url[0].path.includes('access_token');
    }
}
