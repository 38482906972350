import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, ProfileRepCode } from '@trade-platform/ui-shared';
import { RepCodesByAdvisorActions, RepCodesByAdvisorActionTypes } from './actions';

/**
 * Advisor RepCodes
 */

export type RepCodesByAdvisorState = RemoteData<ProfileRepCode[], ErrorWrapper>;
const usersInitialState = notAsked<ProfileRepCode[], ErrorWrapper>();

export function repCodesByAdvisorReducer(
    state: RepCodesByAdvisorState = usersInitialState,
    action: RepCodesByAdvisorActions
): RepCodesByAdvisorState {
    switch (action.type) {
        case RepCodesByAdvisorActionTypes.LOAD_REPCODES_BY_ADVISOR:
            return inProgress([] as ProfileRepCode[]);
        case RepCodesByAdvisorActionTypes.LOAD_REPCODES_BY_ADVISOR_SUCCESS:
            return success(action.payload);
        case RepCodesByAdvisorActionTypes.LOAD_REPCODES_BY_ADVISOR_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
