import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ProductFavoritesActions, ProductFavoritesActionTypes } from './actions';
import { ErrorWrapper } from '../models/common';
import { ProductFavorite } from '../../models/product-favorites/model';

export type ProductFavoritesState = RemoteData<ProductFavorite, ErrorWrapper>;

export function productFavoritesReducer(
    state = notAsked<ProductFavorite, ErrorWrapper>(),
    action: ProductFavoritesActions
): ProductFavoritesState {
    switch (action.type) {
        // Update
        case ProductFavoritesActionTypes.UPDATE_PRODUCT_FAVORITES:
            return inProgress({} as ProductFavorite);
        case ProductFavoritesActionTypes.UPDATE_PRODUCT_FAVORITES_SUCCESS:
            return success(action.payload);
        case ProductFavoritesActionTypes.UPDATE_PRODUCT_FAVORITES_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
