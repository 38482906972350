import { JsonDecoder } from 'ts.data.json';
import { ProductFavorite } from '../../models/product-favorites/model';

export const productFavoriteDecoder = JsonDecoder.object<ProductFavorite>(
    {
        id: JsonDecoder.number,
        type: JsonDecoder.string,
        value: JsonDecoder.array<string>(JsonDecoder.string, 'value[]')
    },
    'RepCodeUsers'
);
