<div class="canvas" [ngStyle]="canvasHeight">
    <div class="item-container">
        <div class="item-container__image" [ngStyle]="containerStyles.styles">
            <img
                [hidden]="!src()"
                [width]="rowWidth"
                [height]="rowHeight"
                [ngStyle]="imageStyles"
                [attr.src]="src()"
                (load)="onLoadImage($event)"
            />
        </div>
    </div>
</div>
