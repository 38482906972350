import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    Account,
    accountListDecoder,
    AccountRegistration,
    AccountRegistrationByAdvisorPayload,
    accountRegistrationDecoder,
    AccountRegistrationsPayload,
    createPaginationHttpParams,
    Page,
    paginationDecoder,
    RegistrationWithPositions,
    registrationWithPositionsDecoder
} from '@trade-platform/ui-shared';

@Injectable()
export class AccountRegistrationsService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getAccountRegistrations(
        options: AccountRegistrationsPayload
    ): Observable<Page<AccountRegistration>> {
        return this.http
            .get<Page<AccountRegistration>>(`${this.environment.api}registrations`, {
                params: createPaginationHttpParams(options.paginationOptions),
                observe: 'response'
            })
            .pipe(
                concatMap(response => {
                    return fromPromise(
                        paginationDecoder<AccountRegistration>(
                            accountRegistrationDecoder,
                            parseInt(response.headers.get('x-total-count') as string, 10),
                            parseInt(response.headers.get('x-total-pages') as string, 10),
                            'AccountRegistration'
                        ).decodeToPromise(response)
                    );
                })
            );
    }

    getAccountRegistrationsByAdvisor(
        options: AccountRegistrationByAdvisorPayload
    ): Observable<Account[]> {
        return this.http
            .get<AccountRegistration[]>(`${this.environment.api}accounts`, {
                params: createPaginationHttpParams(options.paginationOptions)
            })
            .pipe(concatMap(p => fromPromise(accountListDecoder.decodeToPromise(p))));
    }

    getRegistrationWithPositions(id: string): Observable<RegistrationWithPositions> {
        return this.http
            .get<RegistrationWithPositions>(`${this.environment.api}registrations/${id}`)
            .pipe(concatMap(p => fromPromise(registrationWithPositionsDecoder.decodeToPromise(p))));
    }
}
