import { ProductAccountType } from '@trade-platform/ui-shared';
import { groupBy, sortBy } from 'lodash-es';

export const getAccountTypes = (accounts: ProductAccountType[] | undefined): any[] => {
    const result = [];
    if (accounts) {
        const sortedGroups = sortBy(groupBy(accounts, 'headerName'), group => group[0].sequence);
        for (let i = 0; i < sortedGroups.length; i++) {
            const group = [];
            const accountTypes = sortedGroups[i];
            group.push({
                type: 'title',
                label: accountTypes[0].headerName || '',
                isTitle: true
            });
            for (let j = 0; j < accountTypes.length; j++) {
                group.push({
                    label: accountTypes[j].displayName,
                    value: accountTypes[j].accountType,
                    refId: accountTypes[j].accountType,
                    disabled: accountTypes[j].disabled,
                    ownershipType: accountTypes[j].ownershipType,
                    tooltip: accountTypes[j].tooltip
                });
            }
            result.push(group);
        }
    }

    return result;
};

export interface radioGroupSection {
    type?: string;
    label: string;
    isTitle: boolean;
    children: any[];
    columnNumber?: number;
}

export const getAccountTypesWithSections = (accounts: ProductAccountType[] | undefined): any[] => {
    const result = [];
    if (accounts) {
        const sortedSections = sortBy(groupBy(accounts, 'sectionName'), group => group[0].sequence);
        for (const sectionAccountTypes of sortedSections) {
            const section: radioGroupSection = {
                type: 'section',
                label: sectionAccountTypes[0].sectionName || '',
                isTitle: true,
                children: []
            };
            const columnOne = [];
            const columnTwo = [];
            const sortedGroups = sortBy(
                groupBy(sectionAccountTypes, 'headerName'),
                group => group[0].sequence
            );
            for (const groupAccountTypes of sortedGroups) {
                const group: radioGroupSection = {
                    type: 'group',
                    label: groupAccountTypes[0].headerName || '',
                    isTitle: true,
                    columnNumber: groupAccountTypes[0].columnNumber || 1,
                    children: []
                };
                for (const accountType of groupAccountTypes) {
                    group.children.push({
                        label: accountType.displayName,
                        value: accountType.accountType,
                        refId: accountType.accountType,
                        disabled: accountType.disabled,
                        ownershipType: accountType.ownershipType,
                        tooltip: accountType.tooltip
                    });
                }
                if (!groupAccountTypes[0].columnNumber || groupAccountTypes[0].columnNumber === 1) {
                    columnOne.push(group);
                } else {
                    columnTwo.push(group);
                }
            }
            section.children = [columnOne, columnTwo];
            result.push(section);
        }
    }

    return result;
};
