import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class OrderSubmitService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderSubmit(orderId: string): Observable<null> {
        return this.http.post<null>(`${this.environment.api}orders/${orderId}/submit`, {
            id: orderId
        });
    }
}
