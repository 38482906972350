export const removeFileExtension = (file: string) => {
    const fileParts = file.split('.');
    fileParts.pop();
    return fileParts.join('.');
};

export const getFileExtension = (filetype: string) => {
    if (filetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return 'xlsx';
    }
    return filetype.split('/').pop();
};
