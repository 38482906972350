import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { extractStreamValue, objectHasValue } from '@trade-platform/ui-utils';
import { v4 as uuid } from 'uuid';
import { clone as _clone, get as _get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { just, Maybe, nothing } from 'ts.data.maybe';
import { applyRefIdToTemplate } from '../components/repeater/repeater-utils';
import { parseFieldConfig } from '../dynamic-form.parser';
import { computeDerivedValues, ObservableMapFuncts } from '../dynamic-form.utils';
import { CheckboxGroupControlState, DynamicFormControlState, DynamicFormState } from './model';
import {
    AnyOfFieldConfig,
    BaseFieldConfig,
    ControlFieldConfig,
    ExpandableTextFieldConfig,
    FieldConfig,
    FieldGroupConfig,
    flattenFieldConfig,
    FreeTextFieldConfig,
    isAnyOfConfig,
    isControlConfig,
    isExpandableTextConfig,
    isFreeTextConfig,
    isNotificationConfig,
    isOneOfConfig,
    NotificationConfig,
    OneOfFieldConfig,
    OptionFieldConfig,
    RepeaterFieldConfig
} from '@trade-platform/form-fields';
import * as Lazy from 'lazy.js';

export const getControlDataBinding = (
    store: Store<Record<string, DynamicFormState>>,
    fieldConfig:
        | ControlFieldConfig
        | AnyOfFieldConfig
        | OneOfFieldConfig
        | FreeTextFieldConfig
        | ExpandableTextFieldConfig
        | NotificationConfig,
    observableMapFuncts: ObservableMapFuncts
) => {
    let value$: Maybe<BehaviorSubject<any>> = nothing();
    let anyOf$: Maybe<BehaviorSubject<any>> = nothing();
    let oneOf$: Maybe<BehaviorSubject<any>> = nothing();
    const config = computeDerivedValues(
        [fieldConfig as FieldConfig],
        store,
        observableMapFuncts
    )[0] as BaseFieldConfig;
    if (isControlConfig(config) && config.value && config.value.subscribe) {
        value$ = just(config.value);
    }
    if (
        (isFreeTextConfig(config) ||
            isExpandableTextConfig(config) ||
            isNotificationConfig(config)) &&
        config.text &&
        config.text.subscribe
    ) {
        value$ = just(config.text);
    }
    if (isAnyOfConfig(config) && config.anyOf && (config.anyOf as any).subscribe) {
        anyOf$ = just(config.anyOf as any);
    }
    if (isOneOfConfig(config) && config.oneOf && (config.oneOf as any).subscribe) {
        oneOf$ = just(config.oneOf as any);
    }

    return { value$, anyOf$, oneOf$ };
};

export const getDataPathByFieldConfig = (fieldConfig: ControlFieldConfig) =>
    fieldConfig.group ? `${fieldConfig.group}.${fieldConfig.name}` : fieldConfig.name;

export const getFormControlStateByRefId = (
    store$: Store<Record<string, DynamicFormState>>,
    formUID: FormUID,
    refId: string
): DynamicFormControlState => {
    const control = extractStreamValue(
        store$.pipe(
            select(state => state[formUID.value].controls),
            map(controls => controls[refId])
        )
    );

    if (control) {
        return control;
    }

    // ------------------------------------------------------
    //
    //  Related to https://github.com/trade-platform/trade-platform/issues/5136
    //  The following is relations.ts specific logic.
    //
    //  We only get here when we are searching for refIds
    //  within CheckboxGroups' options.
    //
    // ------------------------------------------------------

    const matchingOptions = store$.pipe(
        select(state => state[formUID.value].controls),
        map((controls: Record<string, DynamicFormControlState>): OptionFieldConfig[] => {
            const extractedControls = Object.values(controls);
            return Lazy(extractedControls)
                .filter(isCheckboxGroupFormControlState)
                .reduce((acc, control) => {
                    return acc.concat((control as CheckboxGroupControlState).extra.options || []);
                }, [] as OptionFieldConfig[])
                .filter((opt: OptionFieldConfig): boolean => opt.refId === refId);
        })
    );
    const opts = extractStreamValue(matchingOptions);
    if (opts && opts.length > 0) {
        return {
            fieldConfig: opts[0]
        } as unknown as DynamicFormControlState;
    }

    return undefined as any;
};

export const getDataByPath = <T>(
    store$: Store<Record<string, DynamicFormState>>,
    formUID: FormUID,
    path: string
) =>
    extractStreamValue<T>(
        store$.pipe(
            select(state => state[formUID.value].data),
            map(data => _get(data, path))
        )
    );

export const getControlsByDataPath = (controls: DynamicFormState['controls'], path: string) => {
    return Lazy(Object.keys(controls))
        .map(key => controls[key])
        .filter(control => getDataPathByFieldConfig(control.fieldConfig) === path)
        .toArray();
};

export const getStoreControlsByDataPath = (
    store$: Store<Record<string, DynamicFormState>>,
    formUID: FormUID,
    path: string
) => {
    return extractStreamValue(
        store$.pipe(
            select(state => state[formUID.value].controls),
            map(controls => getControlsByDataPath(controls, path))
        )
    );
};

export const createOptionGroupFromHostFieldConfig = (fieldConfig: {
    group: string;
    name: string;
}) => (fieldConfig.group ? `${fieldConfig.group}.${fieldConfig.name}` : fieldConfig.name);

/**
 * Gets Store controls
 */
export const getStoreControls = (
    store$: Store<Record<string, DynamicFormState>>,
    formUID: FormUID
) => {
    return extractStreamValue(store$.pipe(select(state => state[formUID.value].controls)));
};

/**
 * Gets all repeater fields
 */
export const getRepeaterFields = (
    fieldConfig: Readonly<RepeaterFieldConfig>,
    compiledTemplate: FieldConfig[]
) => {
    const isRepeaterField = (f: FieldConfig) => {
        return Object.keys(fieldConfig.dataTemplate.internal).some(key => {
            const refIdMatcher = fieldConfig.dataTemplate.internal[key].refId.replace(
                /@index@/g,
                `\\d+`
            );
            return new RegExp(refIdMatcher).test(f.refId as string);
        });
    };
    return flattenFieldConfig(compiledTemplate).filter(isRepeaterField);
};

export const getRepeaterControls = (
    repeaterFieldConfig: Readonly<RepeaterFieldConfig>,
    compiledTemplate: FieldConfig[],
    controls: DynamicFormState['controls']
) => {
    const fields = getRepeaterFields(repeaterFieldConfig, compiledTemplate);
    return Lazy(fields)
        .filter(f => !!controls[f.refId as string])
        .map(f => controls[f.refId as string])
        .toArray();
};

export interface FormUID {
    readonly tag: 'formUID';
    readonly value: string;
}

@Injectable()
export class FormUIDGenerator {
    generateFormUID(): FormUID {
        return { tag: 'formUID', value: uuid() };
    }
}

// -- -- -- -- -- --
//
//  Repeater utils
//
// -- -- -- -- -- --

const generateRepeaterItem = (
    fieldConfig: RepeaterFieldConfig,
    dataTemplate: { [key: string]: { refId: string; group?: string } },
    itemValue: Record<string, any>,
    index: number,
    hidden: boolean,
    enableDebugging: boolean
) => {
    let jsonStringTemplate = JSON.stringify(fieldConfig.template);

    jsonStringTemplate = jsonStringTemplate.replace(/@index@/g, index.toString());

    for (const fieldName in dataTemplate) {
        if (dataTemplate.hasOwnProperty(fieldName)) {
            const refId = dataTemplate[fieldName].refId.replace(/@index@/g, index.toString());
            jsonStringTemplate = applyRefIdToTemplate(jsonStringTemplate, fieldName, refId);
        }
    }

    const fieldConfigTemplate = JSON.parse(jsonStringTemplate);
    const parsedFieldConfig = parseFieldConfig([fieldConfigTemplate], enableDebugging);
    const flattened: FieldConfig[] = flattenFieldConfig(parsedFieldConfig);
    flattened.forEach(config => {
        if (isControlConfig(config)) {
            // mutation by reference
            config.hidden = config.hidden || hidden;
            config.disabled = fieldConfig.disabled || config.disabled;
            const value = itemValue[config.name];
            config.value = objectHasValue(value) ? value : config.value;
        }
    });

    return parsedFieldConfig[0] as FieldGroupConfig;
};

export const compileRepeaterTemplate = (
    fieldConfig: RepeaterFieldConfig,
    value: Record<string, any>[],
    enableDebugging: boolean
) => {
    const itemsAreInvisibleBeforeIndex = fieldConfig.itemsAreInvisibleBeforeIndex ?? 0;
    const lastVisibleIndex = (fieldConfig.maxVisibleItems as number) + itemsAreInvisibleBeforeIndex;
    return value.map((itemValue, index) => {
        const hidden = index < itemsAreInvisibleBeforeIndex || index >= lastVisibleIndex;
        return {
            ...generateRepeaterItem(
                fieldConfig,
                { ...fieldConfig.dataTemplate.internal, ...fieldConfig.dataTemplate.external },
                _clone(itemValue),
                index,
                hidden,
                enableDebugging
            ),
            hidden
        };
    });
};

// -- -- -- -- -- -- -- -- -- -- -- -- --
//
//  DynamicFormControlState Type guards
//
// -- -- -- -- -- -- -- -- -- -- -- -- --

export const isCheckboxGroupFormControlState = (
    ctrlState: DynamicFormControlState
): ctrlState is CheckboxGroupControlState =>
    ctrlState.fieldConfig.type === 'checkboxGroup' ||
    ctrlState.fieldConfig.type === 'checkboxGroup2';

export const generateRefIdMappings = (formId: string, mappings: any) => {
    for (const prop in mappings) {
        const path = mappings[prop].path;

        if (mappings[prop].path === 'REF_ID_VALUE') {
            // Look for REF_ID_VALUE to add the radioGroup/dropdown mappings
            mappings[prop].path = `order.forms.${formId}.fields.${prop}`;
        } else if (path.indexOf('REF_ID_FIELD_VALUE') > -1) {
            // Look for REF_ID_FIELD_VALUE to add the radioGroup/dropdown mappings by refIdValue
            const refId = path.split('=')[1];
            mappings[prop].path = `order.forms.${formId}.fields.${refId}`;
        }
    }

    return mappings;
};
