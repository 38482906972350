import {
    Component,
    effect,
    ElementRef,
    HostListener,
    input,
    model,
    OnInit,
    output,
    signal,
    viewChild
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
    AixButtonComponent,
    AixCheckboxListComponent,
    AixDateComponent,
    AixFilterItem,
    AixRadioGroupComponent,
    AixSearchBoxComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-select-checkbox-filter',
    templateUrl: 'aix-select-checkbox-filter.component.html',
    styleUrls: ['aix-select-checkbox-filter.component.scss'],
    imports: [
        NgClass,
        AixButtonComponent,
        AixDateComponent,
        AixRadioGroupComponent,
        FormsModule,
        ReactiveFormsModule,
        AixDataTestingDirective,
        AixCheckboxListComponent,
        AixSearchBoxComponent
    ],
    standalone: true
})
export class AixSelectCheckboxFilterComponent implements OnInit {
    searchBoxComponent = viewChild<AixSearchBoxComponent>('searchBoxComponent');

    @HostListener('document:click', ['$event'])
    onClickOutside($event: MouseEvent) {
        if (!this.ref.nativeElement.contains($event.target)) {
            if (this.currentFilters.length !== this.activeFilter().length) {
                this.activeFilter.set(this.currentFilters);
                this.filterLength = this.filterList().filter(
                    (item: any) => item.id !== 'all'
                ).length;
            }
            this.closeDropDown();
        }
    }

    name = input.required<string>();
    activeFilter = model.required<any[]>();
    filterList = model.required<AixFilterItem[]>();
    applyFilterButtonEnabled = signal(false);

    applyFilters = output<any>();

    filteredList: AixFilterItem[] = [];

    isFiltering = false;
    isClearingFilter = false;
    isDropdownOpen = false;
    currentFilters: any[] = [];
    filterLength = 0;
    filterSelectedLength = 0;

    setFilterButtonType = BUTTON_TYPE.primary;
    clearFilterButtonType = BUTTON_TYPE.link;

    constructor(private ref: ElementRef) {
        effect(() => {
            this.currentFilters = this.activeFilter();
        });
    }

    ngOnInit() {
        this.isFiltering = this.activeFilter().length !== this.filterList().length;
        this.filterLength = this.activeFilter().filter(f => f.id !== 'all').length;
        this.filterSelectedLength = this.activeFilter().filter(
            (item: any) => item.id !== 'all'
        ).length;
    }

    toggleOpenDropdown() {
        if (this.isDropdownOpen) {
            this.closeDropDown();
        } else {
            this.openDropdown();
        }
    }

    closeDropDown() {
        this.isDropdownOpen = false;
    }

    openDropdown() {
        if (!this.isClearingFilter) {
            this.isDropdownOpen = true;
        }
        this.isClearingFilter = false;
        // set current filters
        this.currentFilters = this.activeFilter();
    }

    onSelectFilter(filters: any) {
        if (filters.some((filter: any) => filter.id === 'all')) {
            if (
                this.activeFilter().some(filter => filter.id === 'all') &&
                filters.length < this.activeFilter().length
            ) {
                filters = filters.filter((filter: any) => filter.id !== 'all');
            } else {
                filters = this.filterList();
            }
        } else {
            if (this.activeFilter().some(filter => filter.id === 'all')) {
                filters = [];
            } else if (filters.length === this.filterList().length - 1) {
                filters = this.filterList();
            }
        }

        this.filterSelectedLength = filters.filter((item: any) => item.id !== 'all').length;
        this.applyFilterButtonEnabled.set(this.filterSelectedLength > 0);

        this.activeFilter.set(filters);
    }

    updateFilters() {
        if (this.searchBoxComponent()) {
            const searchText = this.searchBoxComponent()?.searchText.trim();
            if (searchText) {
                this.filteredList = this.filterList().filter(filter =>
                    filter.label.toLowerCase().includes(searchText.toLowerCase())
                );
            } else {
                this.filteredList = this.filterList();
            }
        } else {
            this.filteredList = this.filterList();
        }
    }

    clearFilter(isClearingFilter = false) {
        this.isClearingFilter = isClearingFilter;
        this.activeFilter.set(this.filterList());
        this.currentFilters = this.filterList();
        this.filterLength = this.filterList().filter((item: any) => item.id !== 'all').length;
        this.filterSelectedLength = this.filterLength;
        if (this.searchBoxComponent()) {
            this.searchBoxComponent()?.clear();
        }
        if (isClearingFilter) {
            this.setFilter();
        }
    }

    setFilter() {
        this.applyFilters.emit(this.activeFilter());
        this.currentFilters = this.activeFilter();
        this.filterLength = this.currentFilters.filter(f => f.id !== 'all').length;
        this.isFiltering = this.currentFilters.length !== this.filterList().length;
        this.isClearingFilter = false;
    }
}
