import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    LoadOrderApprovalForms,
    LoadOrderApprovalFormsFailure,
    LoadOrderApprovalFormsSuccess,
    OrderApprovalFormsActionTypes
} from './actions';
import { OrderApprovalFormsService } from './service';
import { catchHttpError } from '@trade-platform/ui-shared';

@Injectable()
export class OrderApprovalFormsEffects {
    constructor(
        private actions$: Actions,
        private orderApprovalFormsService: OrderApprovalFormsService
    ) {}

    orderApprovalForms$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadOrderApprovalForms>(OrderApprovalFormsActionTypes.ORDER_APPROVAL_FORMS),
            switchMap(action =>
                this.orderApprovalFormsService.getOrderApprovalForms(action.payload.orderId).pipe(
                    map(res => new LoadOrderApprovalFormsSuccess(res)),
                    catchHttpError(error => of(new LoadOrderApprovalFormsFailure({ error })))
                )
            )
        )
    );
}
