import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { CanLoadByRole } from './routing/can-load-by-role';
import { FeatureGuard } from './routing/guards/feature.guard';
import { ProfileGuard } from './routing/guards/profile.guard';
import { LogoutGuard } from './routing/guards/logout.guard';
import { ExternalDocusignRedirectGuard } from './routing/guards/external.docusign.redirect.guard';
import { AixAuthService } from './services/auth-service';
import { SentryErrorHandler } from './services/sentry';
import { AixChangesService } from './services/changes-service';
import { InactivityInterceptor } from './services/inactivity-interceptor';
import { InactivityService } from './services/inactivity-service';
import { KeepAliveService } from './services/keep-alive-service';
import { AixLoginApiService } from './services/login-api-service';
import { AixRedirectService } from './services/redirect-service';
import { AixRouteService } from './services/route-service';
import { UnauthorizedInterceptor } from './services/unauthorized-interceptor';

@NgModule({
    imports: [CommonModule],
    exports: []
})
export class AdvisorUiAppServicesModule {
    static forRoot(config: {
        environment: IEnvironment;
    }): ModuleWithProviders<AdvisorUiAppServicesModule> {
        return {
            ngModule: AdvisorUiAppServicesModule,
            providers: [
                { provide: ENVIRONMENT, useValue: config.environment },
                AixAuthService,
                CanLoadByRole,
                FeatureGuard,
                ProfileGuard,
                LogoutGuard,
                ExternalDocusignRedirectGuard,
                SentryErrorHandler,
                AixChangesService,
                InactivityInterceptor,
                InactivityService,
                KeepAliveService,
                AixLoginApiService,
                AixRedirectService,
                AixRouteService,
                UnauthorizedInterceptor
            ]
        };
    }
}
