import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderCancelReview,
    OrderCancelReviewActionTypes,
    OrderCancelReviewFailure,
    OrderCancelReviewSuccess
} from './actions';
import { OrderCancelReviewService } from './service';

@Injectable()
export class OrderCancelReviewEffects {
    constructor(
        private actions$: Actions,
        private orderCancelReviewService: OrderCancelReviewService
    ) {}

    orderCancelReview$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderCancelReview>(OrderCancelReviewActionTypes.ORDER_CANCEL_REVIEW),
            switchMap(action =>
                this.orderCancelReviewService.orderCancelReview(action.payload.orderId).pipe(
                    map(_ => new OrderCancelReviewSuccess()),
                    catchHttpError(error => of(new OrderCancelReviewFailure({ error })))
                )
            )
        )
    );
}
