import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderFinalizeActionTypes {
    static readonly ORDER_FINALIZE = type('[Order Finalize] Order Finalize');
    static readonly ORDER_FINALIZE_RESET = type('[Order Finalize] Order Finalize Reset');
    static readonly ORDER_FINALIZE_SUCCESS = type('[Order Finalize] Order Finalize Success');
    static readonly ORDER_FINALIZE_FAILURE = type('[Order Finalize] Order Finalize Failure');
}

export type OrderFinalizeActions =
    | OrderFinalize
    | OrderFinalizeSuccess
    | OrderFinalizeFailure
    | OrderFinalizeReset;

export class OrderFinalize implements AixAction {
    readonly type = OrderFinalizeActionTypes.ORDER_FINALIZE;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderFinalizeReset implements AixAction {
    readonly type = OrderFinalizeActionTypes.ORDER_FINALIZE_RESET;
}

export class OrderFinalizeSuccess implements AixAction {
    readonly type = OrderFinalizeActionTypes.ORDER_FINALIZE_SUCCESS;
}

export class OrderFinalizeFailure implements AixAction {
    readonly type = OrderFinalizeActionTypes.ORDER_FINALIZE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
