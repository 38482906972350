import { JsonDecoder } from 'ts.data.json';
import {
    AIILinkPostSSO,
    TrainingStatus,
    UserByAdvisor,
    UsersTrainingResponse
} from '../../models/users/model';

export const trainingStatusDecoder = JsonDecoder.object<TrainingStatus>(
    {
        email: JsonDecoder.string,
        trainingCompleted: JsonDecoder.boolean
    },
    'TrainingStatus'
);

export const aiiLinkPostSSODecoder = JsonDecoder.object<AIILinkPostSSO>(
    {
        ssoURLLink: JsonDecoder.string,
        ssoUserId: JsonDecoder.string,
        ssoARCCompanyId: JsonDecoder.string,
        seccik: JsonDecoder.optional(JsonDecoder.string)
    },
    'AIILinkPostSSO'
);

export const usersTrainingDecoder = JsonDecoder.object<UsersTrainingResponse>(
    {
        AIILink: JsonDecoder.string,
        AIILinkPostSSO: JsonDecoder.optional(aiiLinkPostSSODecoder),
        trainingStatus: JsonDecoder.array<TrainingStatus>(trainingStatusDecoder, 'TrainingStatus[]')
    },
    'UsersTraining'
);

export const userByAdvisorDecoder = JsonDecoder.object<UserByAdvisor>(
    {
        id: JsonDecoder.string,
        firstName: JsonDecoder.string,
        lastName: JsonDecoder.string,
        displayName: JsonDecoder.string,
        email: JsonDecoder.string
    },
    'UserByAdvisor'
);

export const usersByAdvisorDecoder = JsonDecoder.array<UserByAdvisor>(
    userByAdvisorDecoder,
    'UserByAdvisor[]'
);
