import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentViewerDownloadService } from '@trade-platform/ui-shared';
import { OrderFormCommentsService } from '../order-comments/order-form-comments.helper';
import { AixAbstractUploadComponent } from '../abstract-upload';
import {
    getFilteredFormIds,
    getUserUploadedFormFiles
} from '../process/overview/utils/order-utils';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixLoadingComponent,
    AixModalComponent,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../base.orders.store.facade';
import { RemoteDataModule } from 'ngx-remotedata';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { AixFormFilelistComponent } from './form-filelist/form-filelist';
import { AixUploadDocumentComponent } from '@advisor-ui/app-components';

@Component({
    selector: 'aix-form-upload',
    templateUrl: './form-upload.html',
    styleUrls: ['./form-upload.scss'],
    standalone: true,
    imports: [
        NgIf,
        AixLoadingComponent,
        AixFormFilelistComponent,
        AixModalComponent,
        AixUploadDocumentComponent,
        NgFor,
        AixNotificationComponent,
        AixButtonComponent,
        AixDataTestingDirective,
        AsyncPipe,
        RemoteDataModule
    ]
})
export class AixFormUploadComponent
    extends AixAbstractUploadComponent
    implements OnInit, OnDestroy
{
    @Input() isReadOnly = false;

    cancelButtonType = BUTTON_TYPE.link;
    continueButtonType = BUTTON_TYPE.primary;
    closeUploaderButtonType = BUTTON_TYPE.primary;

    constructor(
        @Inject(ENVIRONMENT) public environment: IEnvironment,
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public docDownloader: DocumentViewerDownloadService,
        public commentsService: OrderFormCommentsService,
        public cd: ChangeDetectorRef
    ) {
        super(environment, storeFacade, docDownloader, commentsService, cd);
    }

    isStartUploadDisabled(): boolean {
        return this.selectedFiles.length === 0 || this.hasExtraFormUploads();
    }

    hasExtraFormUploads(): boolean {
        return this.getExtraUploadsCount() > 0;
    }

    getExtraUploadsCount(): number {
        const order = this.storeFacade.order;
        if (!order) {
            return 0;
        }
        return (
            this.selectedFiles.length -
            (getFilteredFormIds(order, this.acceptedFormIds).length -
                getUserUploadedFormFiles(order, this.acceptedFormIds).length)
        );
    }
}
