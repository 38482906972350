<aix-modal
    #sentModal
    [buttonOptions]="['', 'Close']"
    [modalTitle]="'eSign Sent'"
    modalType="confirmation"
    (optionSelected)="sentModalSelected($event)"
>
    <ng-template>
        <div><p>We have sent the Order to your email inbox. Please review and sign</p></div>
    </ng-template>
</aix-modal>

<aix-modal
    #needsActionModal
    [modalTitle]="'Mark as needs action?'"
    modalType="confirmation"
    (optionSelected)="needsActionSelected($event)"
>
    <ng-template>
        <div>
            <p class="modal-title u-mt16">This order will be sent for clarification or changes.</p>
        </div>
    </ng-template>
</aix-modal>

<aix-modal
    #returnTradePackageModal
    [modalTitle]="'Return order?'"
    modalType="confirmation"
    (optionSelected)="returnTradePackageSelected($event)"
>
    <ng-template>
        <div>
            <p class="modal-title u-mt16">
                All comments have been reviewed and additional clarification has been provided.
            </p>
        </div>
    </ng-template>
</aix-modal>

<aix-modal
    #authorizeModal
    [modalTitle]="'Authorize order?'"
    (optionSelected)="authorizeModalSelected($event)"
    modalType="confirmation"
>
    <ng-template>
        <div>
            <p class="modal-title u-mt16">This order will move to the next queue.</p>
        </div>
    </ng-template>
</aix-modal>

<aix-modal
    #signFlowSelectionModal
    [buttonOptions]="['Wet Sign', 'eSign']"
    [modalTitle]="'How would you like to sign the order?'"
    (optionSelected)="signFlowSelected($event)"
    modalType="confirmation"
>
    <ng-template>
        <div>
            <p class="modal-title u-mt16">
                Please select whether you want to eSign or Wet Sign. Selection Wet Sign will require
                you to download forms, sign them and upload them manually.
            </p>
        </div>
    </ng-template>
</aix-modal>

<aix-make-changes
    #aixMakeChangesAndCancelEnvelopeModal
    [redirectUrl]="makeChangesRedirectUrl"
></aix-make-changes>

@if (storeFacade.documentViewer.showModal) {
<section class="aix-viewer__download-and-sign">
    <div class="aix-viewer__download-and-sign--wrapper">
        <div class="flex-between-center header--section u-mb16">
            <h3 class="-heading" [data-testing]="'download-and-sign-title'">Download & Sign</h3>
            <span class="fa-regular fa-xmark" (click)="displayModal(false)"></span>
        </div>
        <aix-form-upload #orderFileUpload [acceptedFormIds]="acceptedFormIds"></aix-form-upload>

        <div class="aix-toolbar u-mt24">
            <aix-button
                [buttonType]="cancelButtonType"
                [buttonLabel]="'Cancel'"
                [isDisabled]="isReadOnly"
                [data-testing]="'cancel-button'"
                (click)="displayModal(false)"
            >
            </aix-button>
            <aix-button
                [data-testing]="'mark-as-signed-button'"
                (click)="markAsSigned()"
                [isDisabled]="hasUnfinishedFormUploads || isReadOnly"
                [buttonType]="markAsSignedButtonType"
                [buttonLabel]="'Mark as Signed'"
            >
            </aix-button>
        </div>
    </div>
</section>
}

<div class="aix-viewer__action-bar aix-toolbar" [data-testing]="'action-bar'">
    <div class="aix-viewer__action-bar__container--left">
        @if ((storeFacade.documentViewer$ | async)!.hasPermission) {
        <div>
            @if ((storeFacade.documentViewer$ | async)!.action === 'approve') {
            <aix-button
                [data-testing]="'authorize-order-button'"
                (click)="onClickApprove()"
                [buttonType]="authorizeOrderButtonType"
                [buttonLabel]="'Authorize Order'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            } @if ((storeFacade.documentViewer$ | async)!.action === 'wetSign' &&
            (storeFacade.orderSuccess$ | async).fund?.reviewerCanESign) {
            <aix-button
                [data-testing]="'select-e-sign-flow-button'"
                (click)="selectSignFlow()"
                [buttonType]="downloadAndSignButtonType"
                [buttonLabel]="'Sign'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            } @if ((storeFacade.documentViewer$ | async)!.action === 'wetSign' &&
            !(storeFacade.orderSuccess$ | async).fund?.reviewerCanESign) {
            <aix-button
                [data-testing]="'download-and-sign-button'"
                (click)="displayModal(true)"
                [buttonType]="downloadAndSignButtonType"
                [buttonLabel]="'Download & Sign'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            } @if ((storeFacade.documentViewer$ | async)!.action === 'sign') {
            <aix-button
                [data-testing]="'e-sign-button'"
                (click)="sign()"
                [buttonType]="eSignButtonType"
                [buttonLabel]="'eSign'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            } @if ((storeFacade.documentViewer$ | async)!.action === 'resolve' &&
            (storeFacade.orderSuccess$ | async)!.status === orderStatus.needsClarification) {
            <aix-button
                [data-testing]="'return-order-button'"
                (click)="onReturnTradePackage()"
                [buttonType]="returnOrderButtonType"
                [buttonLabel]="'Return Order'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            } @if ((((storeFacade.documentViewer$ | async)!.action === 'resolve' &&
            (storeFacade.orderSuccess$ | async)!.status !== orderStatus.needsClarification) ||
            (storeFacade.documentViewer$ | async)!.action === 'signed' ||
            (storeFacade.documentViewer$ | async)!.action === 'none') || (storeFacade.orderSuccess$
            | async)!.status !== orderStatus.offline && !isPending()) {
            <aix-button
                [data-testing]="'edit-order-button'"
                (click)="makeChanges()"
                [buttonType]="(storeFacade.orderSuccess$ | async)!.status === orderStatus.nigo ? editOrderButtonType : editOrderNeedsClarificationButtonType"
                [buttonLabel]="'Edit Order'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            } @if ((storeFacade.orderSuccess$ | async)!.status === orderStatus.needsClarification ||
            ((storeFacade.orderSuccess$ | async)!.status === orderStatus.nigo &&
            hasNeedsClarificationComments)) {
            <aix-button
                [data-testing]="'needs-action-button'"
                (click)="uploadDocumentRedirect()"
                [buttonType]="editOrderNeedsClarificationButtonType"
                [buttonLabel]="'Upload Document'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            } @if ((storeFacade.documentViewer$ | async)!.action !== 'none' &&
            (storeFacade.documentViewer$ | async)!.action !== 'signed' &&
            (storeFacade.documentViewer$ | async)!.action !== 'resolve') {
            <aix-button
                [data-testing]="'needs-action-button'"
                (click)="onMarkOrderNeedsAction()"
                [buttonType]="needsActionButtonType"
                [buttonLabel]="'Needs Action'"
                [isDisabled]="isReadOnly"
            >
            </aix-button>
            }
        </div>
        } @if ((storeFacade.documentViewer$ | async)!.action === 'approve' &&
        !(storeFacade.documentViewer$ | async)!.hasPermission) {
        <div class="aix-viewer__action-bar__text" [data-testing]="'awaiting-approval'">
            Awaiting approval
        </div>
        } @if (((storeFacade.documentViewer$ | async)!.action === 'sign' ||
        (storeFacade.documentViewer$ | async)!.action === 'wetSign') &&
        !(storeFacade.documentViewer$ | async)!.hasPermission) {
        <div class="aix-viewer__action-bar__text" [data-testing]="'awaiting-signature'">
            Awaiting signature
        </div>
        }
    </div>

    <div
        class="aix-viewer__action-bar__container--right"
        [ngClass]="{ 'u-text-right': (storeFacade.documentViewer$ | async)!.offline }"
    >
        @if (isSigner !== null && !(isSigner && (storeFacade.documentViewer$ | async)!.action ===
        'wetSign')) {
        <aix-loading-button
            [data-testing]="'download-button'"
            [buttonStyle]="'tertiary'"
            [icon]="'fa-regular fa-download'"
            [label]="downloadAll ? 'Download All' : 'Download'"
            [isLoading]="downloadFlag && (storeFacade.documentViewer$ | async)!.downloading[storeFacade.order.title]"
            (click)="onDownload()"
        ></aix-loading-button>
        }
        <div
            class="aix-btn aix-btn--link br-link"
            [data-testing]="'close-button'"
            (click)="clickClose.emit()"
        >
            <i class="fa-regular fa-xmark u-mr8"></i> <span>Close</span>
        </div>
    </div>
</div>
