import { OrderStatusFilter } from '../models/order/model';

/**
 * Constants
 */
export const constants = {
    statusFilterGroups: [1, 2], // Rename these to something more semantic when we know the roles
    statusFilters: (): OrderStatusFilter[] => [
        {
            filter: '',
            label: 'All Orders',
            description: 'All orders I have access to.',
            display: true,
            group: 1,
            count: 0
        },
        /**
         * Hide 'My orders' form F&F investor
         * { filter: 'myOrders', label: 'My orders' },
         */

        {
            filter: 'draft',
            label: 'Draft',
            description: 'Orders that have been started but not sent for signature.',
            display: true,
            group: 1,
            count: 0
        },
        {
            filter: 'completed',
            label: 'Completed',
            description: 'Order is ready to be signed.',
            display: true,
            group: 1,
            count: 0
        },
        {
            filter: 'sent',
            label: 'Sent',
            description: 'Order has been sent for signature.',
            display: true,
            group: 1,
            count: 0
        },
        {
            filter: 'investor signed',
            label: 'Signed',
            description: 'Order has been signed and is ready to submit.',
            display: true,
            group: 1,
            count: 0
        },
        {
            filter: 'pending firm reviewer',
            label: 'Needs approval',
            description: 'Order is waiting to be signed by firm back office signatory.',
            relatedFilters: ['pending custodian reviewer'],
            display: true,
            group: 2,
            count: 0
        },
        {
            filter: 'pending firm signer',
            label: 'Needs signature',
            description: '',
            relatedFilters: ['pending custodian signer'],
            display: true,
            group: 2,
            count: 0
        },
        {
            filter: 'signed',
            label: 'Ready to submit',
            description: 'Order has been signed and is ready to submit.',
            display: true,
            group: 2,
            count: 0
        },
        {
            filter: 'rejected',
            label: 'Rejected',
            description: '',
            display: true,
            group: 2,
            count: 0
        },
        {
            filter: 'offline',
            label: 'Offline',
            description: '',
            display: true,
            group: 2,
            count: 0
        },
        {
            filter: 'submitted',
            label: 'Submitted',
            description:
                'Transaction has been finalized. Final price and number of shares have been updated.',
            display: true,
            group: 2,
            count: 0
        }
    ],
    profileTypes: {
        ACTIVE_INVESTOR: 'activeInvestor',
        ADMIN: 'admin',
        ADVISOR: 'advisor',
        ADVISOR_READ_ONLY: 'advisorView',
        ASSISTANT: 'assistant',
        AUTHORIZER: 'authorizer',
        REVIEWER: 'reviewer',
        SIGNER: 'signer',
        SUBMITTER: 'submitter',
        SYSADMIN: 'sysAdmin',
        PLATFORM_ADMIN: 'platformAdmin',
        ORDER_ADMIN: 'orderAdmin'
    },

    orderStatusOrder: {
        default: 0,
        draft: 1,
        completed: 2,
        sent: 3,
        'investor signed': 4,
        'pending firm reviewer': 5,
        'pending custodian reviewer': 5,
        'pending firm signer': 6,
        'pending custodian signer': 6,
        rejected: 7, // TODO: Determine where this should really be in the process - JH;
        offline: 7,
        signed: 8,
        submitted: 9
    }
};

export const emDash = '—';

// Account type mappings for start order forms
export interface OneOfAccountTypeModel {
    refId?: string;
    id?: string;
    type?: string;
    name: string;
}

export const groupedAccountTypes: OneOfAccountTypeModel[] = [
    {
        type: 'title',
        name: 'Single Owner'
    },
    {
        refId: 'individual',
        id: 'individual',
        name: 'Individual'
    },
    {
        refId: 'transferOnDeath',
        id: 'transfer_on_death',
        name: 'Transfer on Death - Individual'
    },
    {
        type: 'title',
        name: 'Minor Account'
    },
    {
        refId: 'ugmaAccount',
        id: 'ugma',
        name: 'UGMA'
    },
    {
        refId: 'utmaAccount',
        id: 'utma',
        name: 'UTMA'
    },
    {
        type: 'title',
        name: 'Multiple Owner'
    },
    {
        refId: 'communityProperty',
        id: 'community_property',
        name: 'Community property'
    },
    {
        refId: 'joint',
        id: 'joint',
        name: 'Joint Tenants'
    },
    {
        refId: 'jointWithSurvivorship',
        id: 'joint_with_survivorship',
        name: 'Joint Tenants with Rights of Survivorship'
    },
    {
        refId: 'transferOnDeathJtwros',
        id: 'transfer_on_death_jtwros',
        name: 'Transfer on Death - Joint Tenants with Rights of Survivorship'
    },
    {
        refId: 'transferOnDeathTenantsEntirety',
        id: 'transfer_on_death_tenants_entirety',
        name: 'Transfer on Death - Tenants by Entirety'
    },
    {
        refId: 'tenantsInCommon',
        id: 'tenants_in_common',
        name: 'Tenants in Common'
    },
    {
        refId: 'tenantsByEntirety',
        id: 'tenants_by_entirety',
        name: 'Tenants by Entirety'
    },
    {
        type: 'title',
        name: 'Trust'
    },
    {
        refId: 'trust',
        id: 'personal_trust',
        name: 'Trust'
    },
    {
        refId: 'charitableRemainderTrust',
        id: 'charitable_remainder_trust',
        name: 'Charitable Remainder Trust'
    },
    {
        refId: 'institutionalTrust',
        id: 'institutional_trust',
        name: 'Institutional Trust Account'
    },
    {
        refId: 'revocableTrust',
        id: 'revocable_trust',
        name: 'Revocable Trust'
    },
    {
        refId: 'testamentaryTrust',
        id: 'testamentary_trust',
        name: 'Testamentary Trust'
    },
    {
        refId: 'irrevocableLivingTrust',
        id: 'irrevocable_living_trust',
        name: 'Irrevocable Living Trust'
    },
    {
        refId: 'businessTrust',
        id: 'business_trust',
        name: 'Business Trust'
    },
    {
        type: 'title',
        name: 'Other account'
    },
    {
        refId: 'partnership',
        id: 'partnership',
        name: 'Partnership'
    },
    {
        refId: 'soleProprietor',
        id: 'sole_proprietor',
        name: 'Sole Proprietorship'
    },
    {
        refId: 'exemptOrganization',
        id: '501c2',
        name: 'Exempt Organization'
    },
    {
        refId: 'nominee',
        id: 'nominee',
        name: 'Nominee'
    },
    {
        refId: '529Plan',
        id: '529_plan',
        name: '529 Plan'
    },
    {
        refId: 'profitSharing',
        id: 'profit_sharing',
        name: 'Profit Sharing Plan'
    },
    {
        refId: 'estateAccount',
        id: 'estate',
        name: 'Estate Account'
    },
    {
        refId: 'government',
        id: 'government',
        name: 'Government Entities'
    },
    {
        refId: 'educationInstitution',
        id: 'school',
        name: 'Education Institution'
    },
    {
        refId: 'nonProfit',
        id: 'non_profit',
        name: 'Non-Profit Organization'
    },
    {
        refId: 'endowment',
        id: 'endowment',
        name: 'Endowment'
    },
    {
        refId: 'foundation',
        id: 'foundation',
        name: 'Foundation'
    },
    {
        refId: 'religious',
        id: 'religious',
        name: 'Religious Organization'
    },
    {
        refId: 'investmentCompany',
        id: 'investment_company',
        name: 'Investment Company'
    },
    {
        refId: 'insuranceCompany',
        id: 'insurance_company',
        name: 'Insurance Company'
    },
    {
        refId: 'fiduciaryAccount',
        id: 'fiduciary_account',
        name: 'Individual Fiduciary'
    },
    {
        refId: 'investmentClub',
        id: 'investment_club',
        name: 'Investment Club'
    },
    {
        refId: 'union',
        id: 'union',
        name: 'Union'
    },
    {
        refId: 'unincorporatedAssociation',
        id: 'unincorporated_association',
        name: 'Unincorporated Association'
    },
    {
        refId: 'hospital',
        id: 'hospital',
        name: 'Hospitals & Medical'
    },
    {
        refId: 'charity',
        id: 'charity',
        name: 'Charity'
    },
    {
        refId: 'miscellaneousOrganization',
        id: 'miscellaneous_organization',
        name: 'Miscellaneous Organization'
    },
    {
        refId: 'other',
        id: 'other',
        name: 'Other'
    },
    {
        type: 'title',
        name: 'Retirement Account'
    },
    {
        refId: 'traditionalIRA',
        id: 'ira',
        name: 'Traditional IRA'
    },
    {
        refId: 'rolloverIRA',
        id: 'ira_rollover',
        name: 'Rollover IRA'
    },
    {
        refId: 'sepIRA',
        id: 'sep_ira',
        name: 'SEP IRA'
    },
    {
        refId: 'rothIRA',
        id: 'roth_ira',
        name: 'Roth IRA/Roth Conversion'
    },
    {
        refId: 'simpleIRA',
        id: 'simple_ira',
        name: 'Simple IRA'
    },
    {
        refId: 'sarsep',
        id: 'sarsep',
        name: 'SARSEP'
    },
    {
        refId: 'educationalIRA',
        id: 'educational_savings',
        name: 'Educational IRA'
    },
    {
        refId: '403b',
        id: '403b',
        name: '403B'
    },
    {
        refId: 'taftHartleyPensionPlan',
        id: 'pension_taft_hartley',
        name: 'Taft Hartley Pension Plan'
    },
    {
        refId: '401k',
        id: '401k',
        name: '401K Plan'
    },
    {
        refId: 'moneyPurchasePensionPlan',
        id: 'money_purchase_pension_plan',
        name: 'Money Purchase Pension Plan'
    },
    {
        refId: 'singleK',
        id: 'single_k',
        name: 'Single K (non-funded)'
    },
    {
        refId: 'pension',
        id: 'pension',
        name: 'Pension Plan'
    },
    {
        refId: 'keogh',
        id: 'keogh',
        name: 'Keogh'
    },
    {
        refId: 'iraBeneficiary',
        id: 'ira_beneficiary',
        name: 'IRA Beneficiary Distribution Account'
    },
    {
        refId: 'rothIraBeneficiary',
        id: 'roth_ira_beneficiary',
        name: 'Roth IRA Beneficiary Distribution Account'
    },
    {
        type: 'title',
        name: 'Corporation'
    },
    {
        refId: 'cCorp',
        id: 'c_corp',
        name: 'C Corporation'
    },
    {
        refId: 'sCorp',
        id: 's_corp',
        name: 'S Corporation'
    },
    {
        refId: 'llcCCorp',
        id: 'llc_c_corp_treatment',
        name: 'LLC - C Corporation'
    },
    {
        refId: 'llcSCorp',
        id: 'llc_s_corp_treatment',
        name: 'LLC - S Corporation'
    },
    {
        refId: 'llcPartnership',
        id: 'llc_partnership_treatment',
        name: 'LLC - Partnership'
    },
    {
        refId: 'llc',
        id: 'llc',
        name: 'LLC'
    }
];

export interface WhenAccountTypeModel {
    refId: string;
    value: string;
}

export const primaryOwnershipTypes: WhenAccountTypeModel[] = [
    // Ownership: Single Owner
    {
        refId: 'ownership',
        value: 'individual'
    },
    {
        refId: 'ownership',
        value: 'transfer_on_death'
    },
    // Ownership: Multiple Owner
    {
        refId: 'ownership',
        value: 'community_property'
    },
    {
        refId: 'ownership',
        value: 'joint'
    },
    {
        refId: 'ownership',
        value: 'joint_with_survivorship'
    },
    {
        refId: 'ownership',
        value: 'transfer_on_death_jtwros'
    },
    {
        refId: 'ownership',
        value: 'transfer_on_death_tenants_entirety'
    },
    {
        refId: 'ownership',
        value: 'tenants_in_common'
    },
    {
        refId: 'ownership',
        value: 'tenants_by_entirety'
    },
    // Ownership: Minor Account
    {
        refId: 'ownership',
        value: 'ugma'
    },
    {
        refId: 'ownership',
        value: 'utma'
    },
    // Ownership: Qualified Plan Account
    {
        refId: 'ownership',
        value: 'non_custodial_ira'
    },
    {
        refId: 'ownership',
        value: 'ira'
    },
    {
        refId: 'ownership',
        value: 'ira_rollover'
    },
    {
        refId: 'ownership',
        value: 'sep_ira'
    },
    {
        refId: 'ownership',
        value: 'roth_ira'
    },
    {
        refId: 'ownership',
        value: 'simple_ira'
    },
    {
        refId: 'ownership',
        value: 'educational_savings'
    },
    {
        refId: 'ownership',
        value: 'ira_beneficiary'
    },
    {
        refId: 'ownership',
        value: 'roth_ira_beneficiary'
    },
    // Other Individual #6037
    {
        refId: 'ownership',
        value: 'sarsep'
    },
    {
        refId: 'ownership',
        value: '403b'
    },
    {
        refId: 'ownership',
        value: 'pension_taft_hartley'
    },
    {
        refId: 'ownership',
        value: '401k'
    },
    {
        refId: 'ownership',
        value: 'money_purchase_pension_plan'
    },
    {
        refId: 'ownership',
        value: 'single_k'
    },
    {
        refId: 'ownership',
        value: 'pension'
    },
    {
        refId: 'ownership',
        value: 'keogh'
    },
    {
        refId: 'ownership',
        value: '529_plan'
    },
    {
        refId: 'ownership',
        value: 'profit_sharing'
    },
    {
        refId: 'ownership',
        value: 'sole_proprietor'
    }
];

export const jointOwnershipTypes: WhenAccountTypeModel[] = [
    // Ownership: Multiple Owner
    {
        refId: 'ownership',
        value: 'community_property'
    },
    {
        refId: 'ownership',
        value: 'joint'
    },
    {
        refId: 'ownership',
        value: 'joint_with_survivorship'
    },
    {
        refId: 'ownership',
        value: 'transfer_on_death_jtwros'
    },
    {
        refId: 'ownership',
        value: 'transfer_on_death_tenants_entirety'
    },
    {
        refId: 'ownership',
        value: 'tenants_in_common'
    },
    {
        refId: 'ownership',
        value: 'tenants_by_entirety'
    },
    // Ownership: Minor Account
    {
        refId: 'ownership',
        value: 'ugma'
    },
    {
        refId: 'ownership',
        value: 'utma'
    }
];

export const entityOwnershipTypes: WhenAccountTypeModel[] = [
    {
        refId: 'ownership',
        value: 'personal_trust'
    },
    {
        refId: 'ownership',
        value: 'charitable_remainder_trust'
    },
    {
        refId: 'ownership',
        value: 'institutional_trust'
    },
    {
        refId: 'ownership',
        value: 'revocable_trust'
    },
    {
        refId: 'ownership',
        value: 'testamentary_trust'
    },
    {
        refId: 'ownership',
        value: 'irrevocable_living_trust'
    },
    {
        refId: 'ownership',
        value: 'business_trust'
    },
    {
        refId: 'ownership',
        value: 'c_corp'
    },
    {
        refId: 'ownership',
        value: 's_corp'
    },
    {
        refId: 'ownership',
        value: 'llc_c_corp_treatment'
    },
    {
        refId: 'ownership',
        value: 'llc_s_corp_treatment'
    },
    {
        refId: 'ownership',
        value: 'llc_partnership_treatment'
    },
    {
        refId: 'ownership',
        value: 'llc'
    },
    {
        refId: 'ownership',
        value: 'partnership'
    },
    {
        refId: 'ownership',
        value: '501c2'
    },
    {
        refId: 'ownership',
        value: 'nominee'
    },
    {
        refId: 'ownership',
        value: 'estate'
    },
    {
        refId: 'ownership',
        value: 'government'
    },
    {
        refId: 'ownership',
        value: 'school'
    },
    {
        refId: 'ownership',
        value: 'non_profit'
    },
    {
        refId: 'ownership',
        value: 'endowment'
    },
    {
        refId: 'ownership',
        value: 'foundation'
    },
    {
        refId: 'ownership',
        value: 'religious'
    },
    {
        refId: 'ownership',
        value: 'investment_company'
    },
    {
        refId: 'ownership',
        value: 'insurance_company'
    },
    {
        refId: 'ownership',
        value: 'fiduciary_account'
    },
    {
        refId: 'ownership',
        value: 'investment_club'
    },
    {
        refId: 'ownership',
        value: 'union'
    },
    {
        refId: 'ownership',
        value: 'unincorporated_association'
    },
    {
        refId: 'ownership',
        value: 'miscellaneous_organization'
    },
    {
        refId: 'ownership',
        value: 'other'
    },
    {
        refId: 'ownership',
        value: 'hospital'
    },
    {
        refId: 'ownership',
        value: 'charity'
    }
];
