import { ChangeDetectionStrategy, Component, Inject, Input, TemplateRef } from '@angular/core';
import { commentType } from '@trade-platform/lib-enums';
import { OrderFormComment } from '@trade-platform/ui-shared';
import { isInProgress } from 'ngx-remotedata';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderFormCommentsMode } from '../order-form-comments/order-form-comments';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { OrderFormCommentsPipe } from '../order-form-comments-pipe';
import { NgIf, NgFor, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { AixDataTestingDirective, AixLoadingComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-order-form-comments-list',
    templateUrl: 'order-form-comments-list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        AixLoadingComponent,
        NgFor,
        AixDataTestingDirective,
        NgClass,
        NgTemplateOutlet,
        AsyncPipe,
        OrderFormCommentsPipe
    ]
})
export class AixOrderFormCommentsListComponent {
    @Input() commentRef: TemplateRef<any>;
    @Input() comments: OrderFormComment[];
    @Input() showSystemUpdates = true;
    @Input() mode: OrderFormCommentsMode;

    isLoading$: Observable<boolean>;

    commentType = commentType;

    constructor(@Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade) {
        this.isLoading$ = combineLatest([
            store.orderFormCommentCreateRemoteData$,
            store.orderFormCommentUpdateRemoteData$,
            store.orderFormCommentDeleteRemoteData$,
            store.orderFormCommentsRemoteData$
        ]).pipe(map(states => states.some(isInProgress)));
    }
}
