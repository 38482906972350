import { Component } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'aix-date-header',
    template: ` {{ params.data.label }} {{ params.data.date | date : 'MM/dd/yyyy' }} `,
    styles: [':host {font-weight: 500; font-size: 12px}'],
    standalone: true,
    imports: [DatePipe]
})
export class DateRendererHeaderComponent implements IHeaderGroupAngularComp {
    public params: any;

    constructor() {}

    agInit(params: any): void {
        this.params = params;

        // NOTE: needs data passed explicitly, since it's a header component;
        if (!params.data) {
            throw new Error('Missing data parameter for DateRendererHeaderComponent');
        }
    }
}
