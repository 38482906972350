import { Pipe, PipeTransform } from '@angular/core';
import { OrderFormComment } from '@trade-platform/ui-shared';
import { commentStatus, commentType } from '@trade-platform/lib-enums';

@Pipe({
    name: 'orderFormCommentsFilter',
    standalone: true
})
export class OrderFormCommentsPipe implements PipeTransform {
    transform(
        comments: OrderFormComment[] | null,
        showSystemComments = true,
        allowResolved = true,
        documentIds: string[] | null = null,
        commentTagType: string | null = null
    ) {
        let filteredComments = comments || [];

        if (!comments) {
            return filteredComments;
        }

        if (!showSystemComments) {
            filteredComments = filteredComments.filter(
                comment => comment.commentType !== commentType.systemGenerated
            );
        }

        if (!allowResolved) {
            filteredComments = filteredComments.filter(
                comment => comment.status === commentStatus.unresolved
            );
        }

        if (documentIds) {
            // A 'SystemGenerated' comment will never have a documentId
            filteredComments = filteredComments.filter(
                comment =>
                    documentIds.findIndex((id: string) => comment.documentId === id) > -1 ||
                    comment.commentType === commentType.systemGenerated ||
                    comment.commentType === commentType.external
            );
        }

        if (commentTagType) {
            filteredComments = filteredComments.filter(
                comment => comment.commentTag === commentTagType
            );
        }

        return filteredComments;
    }
}
