import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    OrderCancelEnvelopeAction,
    OrderCancelEnvelopeActionTypes,
    OrderCancelEnvelopeFailureAction,
    OrderCancelEnvelopeSuccessAction
} from './actions';
import { OrderCancelEnvelopeService } from './service';
import { catchHttpError } from '@trade-platform/ui-shared';

@Injectable()
export class OrderCancelEnvelopeEffects {
    constructor(private actions$: Actions, private service: OrderCancelEnvelopeService) {}

    orderCancelEnvelope$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderCancelEnvelopeAction>(OrderCancelEnvelopeActionTypes.ORDER_CANCEL_ENVELOPE),
            switchMap(action =>
                this.service.orderCancelEnvelopeService(action.payload).pipe(
                    map(res => new OrderCancelEnvelopeSuccessAction(res)),
                    catchHttpError(error => of(new OrderCancelEnvelopeFailureAction({ error })))
                )
            )
        )
    );
}
