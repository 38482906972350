export const getPropertyClass = () => {
    return {
        marginTop: 'u-mt',
        marginBottom: 'u-mb',
        bordered: 'aix-form__grid--bordered',
        size: 'checkbox-custom__label',
        flex: 'aix-flex-grid',
        grid: 'aix-form__grid',
        columns: 'aix-flex-grid__col',
        group: 'aix-form__group',
        disabled: 'aix-disabled',
        componentClass: 'checkbox-custom-wrapper',
        clearfix: 'aix-flex-grid__clearfix',
        container: 'aix-form__container',
        customClass: ''
    };
};

export const getHostPropertyClass = () => {
    return {
        checkbox: 'checkbox-custom-wrapper',
        hidden: 'aix-hidden'
    };
};
