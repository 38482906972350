import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { outagesDecoder } from '../../decoders/outages/decoder';
import { Outage } from '../../models/outages/model';

@Injectable()
export class OutagesService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getOutages(): Observable<Outage[]> {
        return this.http
            .get<Outage[]>(`${this.environment.outagesApi}outages/current`)
            .pipe(concatMap(p => fromPromise(outagesDecoder.decodeToPromise(p))));
    }
}
