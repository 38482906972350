<span class="aix-form__group">
    <span
        #textContainerRef
        class="aix-form__group-message free-text__text"
        [data-testing]="config.refId!"
        [innerHTML]="(valueToRender$ | async)! | aixSanitize"
    ></span>
    @if (config.infoTooltip) {
    <span
        class="free-text-tooltip"
        aixTooltip
        [aixTooltipContent]="config.infoTooltip"
        [data-testing]="config.refId! + '__infotooltip'"
    ></span>
    }
</span>
