import { FlashMessage } from '../../models/flash-messages/model';
import { FlashMessagesActions, ActionTypes } from './actions';

export interface FlashMessagesState {
    messages: FlashMessage[];
}

const initialState: FlashMessagesState = {
    messages: []
};

export function flashMessagesReducer(
    state: FlashMessagesState = initialState,
    action: FlashMessagesActions
): FlashMessagesState {
    switch (action.type) {
        case ActionTypes.ADD_FLASH_MESSAGE:
            return {
                messages: [...state.messages, action.payload.message]
            };
        case ActionTypes.REMOVE_FLASH_MESSAGE:
            return {
                messages: state.messages.filter(msg => msg.uid !== action.payload.uid)
            };
        case ActionTypes.CLEAR_FLASH_MESSAGES:
            return {
                messages: []
            };
        default:
            return state;
    }
}
