import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderReviewCompleteActionTypes {
    static readonly ORDER_REVIEW_COMPLETE = type('[Order Review Complete] Order Review Complete');
    static readonly ORDER_REVIEW_COMPLETE_SUCCESS = type(
        '[Order Review Complete] Order Review Complete Success'
    );
    static readonly ORDER_REVIEW_COMPLETE_FAILURE = type(
        '[Order Review Complete] Order Review Complete Failure'
    );
}

export type OrderReviewCompleteActions =
    | OrderReviewComplete
    | OrderReviewCompleteSuccess
    | OrderReviewCompleteFailure;

export class OrderReviewComplete implements AixAction {
    readonly type = OrderReviewCompleteActionTypes.ORDER_REVIEW_COMPLETE;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderReviewCompleteSuccess implements AixAction {
    readonly type = OrderReviewCompleteActionTypes.ORDER_REVIEW_COMPLETE_SUCCESS;
}

export class OrderReviewCompleteFailure implements AixAction {
    readonly type = OrderReviewCompleteActionTypes.ORDER_REVIEW_COMPLETE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
