import { Component, contentChild, input, OnInit, output, TemplateRef } from '@angular/core';
import { AixButtonComponent, BUTTON_TYPE } from '../aix-button/aix-button.component';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'aix-modal',
    templateUrl: 'aix-modal.component.html',
    styleUrls: ['aix-modal.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, AixDataTestingDirective, NgTemplateOutlet, NgFor, AixButtonComponent]
})
export class AixModalComponent implements OnInit {
    modalTemplate = contentChild<TemplateRef<any>>(TemplateRef);
    buttonOptions = input<string[]>(['Cancel', 'Yes, continue']);
    modalTitle = input<string>();
    closeable = input(true);
    modalZIndex = input(1010);
    modalType = input<'confirmation' | 'interactive'>('confirmation');

    private _defaultButtonDisableFunction = () => false;
    buttonDisableFunction = input<(label: string) => boolean>(this._defaultButtonDisableFunction);

    optionSelected = output<string>();
    onCloseModal = output<boolean>();
    onOpenModal = output<boolean>();

    modalShow = false;
    data: any;
    modalWidth = '480px';

    primaryButtonType = BUTTON_TYPE.primary;
    linkButtonType = BUTTON_TYPE.link;

    constructor() {}

    ngOnInit() {
        if (this.modalType() !== 'confirmation' && this.modalType() !== 'interactive') {
            console.error('Modal has no modalType set');
        }
        this.modalWidth = this.modalType() === 'confirmation' ? '480px' : '780px';
    }

    public closeModal() {
        this.modalShow = false;
        this.onCloseModal.emit(true);
    }

    public openModal() {
        this.modalShow = true;
        this.onOpenModal.emit(true);
    }

    public setData(data: any) {
        this.data = data;
    }

    buttonClicked(label: string) {
        if (!this.buttonDisableFunction()(label)) {
            this.optionSelected.emit(label);
            this.closeModal();
        }
    }

    buttonClasses(button: string) {
        if (this.buttonOptions().indexOf(button) + 1 === this.buttonOptions().length) {
            // The last button in the list of buttonOptions is the "primary action",
            // and should look like a button
            return true;
        }

        // Otherwise it looks like a link
        return false;
    }
}
