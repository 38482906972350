import { Component } from '@angular/core';

type RendererParams = {
    // The Font Awesome icon classes i.e. `fa-regular fa-trash-alt`.
    myIcon: string;
    // The function that will be executed when the icon is clicked.
    myAction: (rowData: { [key: string]: string }) => void;
    // The whole row data. This is provided by ag-grid automatically.
    data: { [key: string]: any };
};

@Component({
    selector: 'aix-action-icon-cell-renderer',
    template: `
        <div class="text-center">
            <a (click)="onClick($event)">
                <i class="{{ params.myIcon }}" aria-hidden="true"></i>
            </a>
        </div>
    `,
    standalone: true
})
export class AixActionIconCellRendererComponent {
    params: RendererParams;

    constructor() {}

    agInit(params: RendererParams) {
        this.params = params;
        if (!params.myAction) {
            throw new Error('Missing action parameter for ActionIconCellRendererComponent');
        }
    }

    onClick(e: MouseEvent): void {
        this.params.myAction(this.params.data);
        e.stopPropagation();
    }
}
