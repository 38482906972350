import { JsonDecoder } from 'ts.data.json';
import { stringDocusignDateDecoder } from '../../../store/models/common';
import {
    DocuSignEnvelopeInformation,
    EnvelopeStatus,
    RecipientStatus,
    RecipientStatuses,
    SigningStatus
} from '../../../models/docusign/signing-status/model';

export const signingStatusDecoder = JsonDecoder.object<SigningStatus>(
    {
        DocuSignEnvelopeInformation: JsonDecoder.object<DocuSignEnvelopeInformation>(
            {
                EnvelopeStatus: JsonDecoder.object<EnvelopeStatus>(
                    {
                        Sent: JsonDecoder.succeed,
                        Email: JsonDecoder.succeed,
                        Status: JsonDecoder.succeed,
                        Created: JsonDecoder.succeed,
                        Subject: JsonDecoder.succeed,
                        ACHolder: JsonDecoder.succeed,
                        ACStatus: JsonDecoder.succeed,
                        UserName: JsonDecoder.succeed,
                        EnvelopeID: JsonDecoder.succeed,
                        ACStatusDate: JsonDecoder.succeed,
                        CustomFields: JsonDecoder.succeed,
                        ACHolderEmail: JsonDecoder.succeed,
                        TimeGenerated: JsonDecoder.succeed,
                        AutoNavigation: JsonDecoder.succeed,
                        EnvelopePDFHash: JsonDecoder.succeed,
                        SenderIPAddress: JsonDecoder.succeed,
                        SigningLocation: JsonDecoder.succeed,
                        ACHolderLocation: JsonDecoder.succeed,
                        DocumentStatuses: JsonDecoder.succeed,
                        AuthoritativeCopy: JsonDecoder.succeed,
                        RecipientStatuses: JsonDecoder.object<RecipientStatuses>(
                            {
                                RecipientStatus: JsonDecoder.array<RecipientStatus>(
                                    JsonDecoder.object<RecipientStatus>(
                                        {
                                            Signed: JsonDecoder.optional(stringDocusignDateDecoder),
                                            Sent: JsonDecoder.optional(stringDocusignDateDecoder),
                                            Delivered:
                                                JsonDecoder.optional(stringDocusignDateDecoder),
                                            Type: JsonDecoder.succeed,
                                            Email: JsonDecoder.string,
                                            Status: JsonDecoder.oneOf<
                                                | 'AutoResponded'
                                                | 'Completed'
                                                | 'Sent'
                                                | 'Created'
                                                | 'Delivered'
                                                | 'Declined'
                                            >(
                                                [
                                                    JsonDecoder.isExactly<'AutoResponded'>(
                                                        'AutoResponded'
                                                    ),
                                                    JsonDecoder.isExactly<'Completed'>('Completed'),
                                                    JsonDecoder.isExactly<'Sent'>('Sent'),
                                                    JsonDecoder.isExactly<'Created'>('Created'),
                                                    JsonDecoder.isExactly<'Delivered'>('Delivered'),
                                                    JsonDecoder.isExactly<'Declined'>('Declined')
                                                ],
                                                'AutoResponded | Completed | Sent | Created | Delivered | Declined'
                                            ),
                                            UserName: JsonDecoder.succeed,
                                            RecipientId: JsonDecoder.succeed,
                                            TabStatuses: JsonDecoder.succeed,
                                            CustomFields: JsonDecoder.succeed,
                                            RoutingOrder: JsonDecoder.succeed,
                                            AccountStatus: JsonDecoder.succeed,
                                            AutoRespondedReason: JsonDecoder.succeed,
                                            DeclineReason: JsonDecoder.succeed,
                                            Declined:
                                                JsonDecoder.optional(stringDocusignDateDecoder),
                                            RecipientIPAddress: JsonDecoder.succeed,
                                            FormData: JsonDecoder.succeed,
                                            RecipientAttachment: JsonDecoder.succeed
                                        },
                                        'RecipientStatus'
                                    ),
                                    'RecipientStatus[]'
                                )
                            },
                            'RecipientStatuses'
                        ),
                        EnvelopeIdStamping: JsonDecoder.succeed
                    },
                    'EnvelopeStatus'
                )
            },
            'DocuSignEnvelopeInformation'
        )
    },
    'signingStatus'
);
