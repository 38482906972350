import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { OrderApproveActions, OrderApproveActionTypes } from './actions';
import { ErrorWrapper, OrderApprovalResponse } from '@trade-platform/ui-shared';

export type OrderApproveState = RemoteData<OrderApprovalResponse, ErrorWrapper>;
const initialState = notAsked<OrderApprovalResponse, ErrorWrapper>();

export const orderApproveReducer = (
    state: OrderApproveState = initialState,
    action: OrderApproveActions
): OrderApproveState => {
    switch (action.type) {
        case OrderApproveActionTypes.ORDER_APPROVE_START:
            return inProgress({} as OrderApprovalResponse);
        case OrderApproveActionTypes.ORDER_APPROVE_START_SUCCESS:
            return success({} as OrderApprovalResponse);
        case OrderApproveActionTypes.ORDER_APPROVE_START_FAILURE:
            return failure(action.payload.error);
        case OrderApproveActionTypes.ORDER_APPROVE_START_RESET:
            return notAsked();

        case OrderApproveActionTypes.ORDER_APPROVE_RESUME:
            return inProgress({} as OrderApprovalResponse);
        case OrderApproveActionTypes.ORDER_APPROVE_RESUME_SUCCESS:
            return success({} as OrderApprovalResponse);
        case OrderApproveActionTypes.ORDER_APPROVE_RESUME_FAILURE:
            return failure(action.payload.error);

        case OrderApproveActionTypes.ORDER_APPROVE:
            return inProgress({} as OrderApprovalResponse);
        case OrderApproveActionTypes.ORDER_APPROVE_SUCCESS:
            return success(action.payload);
        case OrderApproveActionTypes.ORDER_APPROVE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
