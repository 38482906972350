import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// validation function
export function validateDomainUrlFactory(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        let result = null;

        const regExp =
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/;

        const isValid = c.value === null || c.value === '' || regExp.exec(c.value.trim());

        if (!isValid) {
            result = {
                domainUrl: {
                    valid: false
                }
            };
        }

        return result;
    };
}
