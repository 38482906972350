import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { FieldConfig, FieldGroupConfig, NotificationConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { DynamicFormState } from '../../dynamic-form-store/model';
import { DynamicFormStore } from '../../dynamic-form-store';
import { DynamicFormRelations } from '../../dynamic-form-store/relations';
import { showRelationsHeuristic } from '../../dynamic-form.utils';
import { Store } from '@ngrx/store';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { getFormControlStateByRefId } from '../../dynamic-form-store/utils';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { AixDataTestingDirective, AixNotificationComponent } from '@trade-platform/ui-components';
import { AixSanitizePipe } from 'libs/ui-utils/src/lib/utils/pipes/sanitize';
import { DynamicFieldDirective } from '../dynamic-field.directive';

export interface GroupNotification {
    refId: NotificationConfig['refId'];
    type: 'fieldChangeNotification' | 'serverValidationErrorNotification';
    text: NotificationConfig['text'];
    status: NotificationConfig['status'];
}

@Component({
    selector: 'aix-dynamic-group',
    styleUrls: ['./group.component.scss'],
    templateUrl: './group.component.html',
    standalone: true,
    imports: [
        NgClass,
        AixDataTestingDirective,
        NgIf,
        NgFor,
        AixNotificationComponent,
        AixSanitizePipe,
        forwardRef(() => DynamicFieldDirective),
        DynamicFieldDirective
    ]
})
export class AixDynamicGroupComponent implements Field, OnInit, OnDestroy {
    // Static
    static HOST_CLASS = 'aix-flex-grid aix-form__container';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicGroupComponent.HOST_CLASS;

    @HostBinding('id')
    attrId = '';

    // Other
    config: FieldGroupConfig<FieldConfig[]>;
    headerClassNames: string[];
    bodyClassNames: string[];
    subscriptions: Subscription[] = [];
    notifications: GroupNotification[] = [];
    private removeServerValidationErrorListener: ReturnType<Renderer2['listen']>;
    private notificationsShown: { [key: string]: boolean } = {};

    constructor(
        private helper: DynamicFormHelper,
        private cd: ChangeDetectorRef,
        private elemRef: ElementRef,
        private renderer: Renderer2,
        private formStore: DynamicFormStore,
        private store: Store<Record<string, DynamicFormState>>,
        public relationsManager: DynamicFormRelations
    ) {
        this.cd.detach();
    }

    ngOnInit() {
        this.calculateClassNames();
        this.attrId = this.config.refId as string;

        // Control initialization
        this.formStore.addNonControl(this.config);

        this.subscriptions.push(
            showRelationsHeuristic(this.store, this.formStore.formUID, this.cd)
        );

        // Server Validation Notifications
        this.removeServerValidationErrorListener = this.renderer.listen(
            this.elemRef.nativeElement,
            FieldEvent.SERVER_VALIDATION_ERROR,
            event => this.onServerValidationError(event)
        );

        this.cd.detectChanges();
    }

    private onServerValidationError(event: CustomEvent<{ refId: string; message: string }>) {
        const controlRefId = event.detail.refId + '_serverValidationError';

        const isHidden =
            getFormControlStateByRefId(this.store, this.formStore.formUID, event.detail.refId)
                .fieldConfig.hidden ?? false;

        if (!this.notificationsShown[controlRefId] && !isHidden) {
            this.notificationsShown[controlRefId] = true;
            this.notifications.push({
                refId: controlRefId,
                type: 'fieldChangeNotification',
                text: event.detail.message,
                status: 'warning'
            });
        }
        this.cd.detectChanges();

        event.stopImmediatePropagation();
    }

    onNotificationClosed(index: number) {
        const refId = this.notifications[index].refId as string;
        delete this.notificationsShown[refId];
        this.notifications.splice(index, 1);
        this.cd.detectChanges();
    }

    calculateClassNames() {
        const classNames = this.config.classNames
            ? [
                  AixDynamicGroupComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicGroupComponent.HOST_CLASS;
        this.classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;
        if (this.config && this.config.classNames && this.config.classNames.header) {
            this.headerClassNames = this.helper.parseHeaderProperties(
                this.config.classNames.header
            );
        }
        if (this.config && this.config.classNames && this.config.classNames.body) {
            this.bodyClassNames = this.helper.parseHeaderProperties(this.config.classNames.body);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.formStore.removeControl(this.config);
        this.removeServerValidationErrorListener();
    }
}
