import { Component, input, output } from '@angular/core';
import { BUTTON_TYPE } from '../aix-button/aix-button.component';
import { AixLoadingButtonComponent } from '../aix-loading-button/aix-loading-button.component';
import { AixInlineDropdownComponent } from '../aix-inline-dropdown/aix-inline-dropdown.component';
import { DatePipe, NgIf } from '@angular/common';
import { AixThumbnailComponent } from '../aix-thumbnail/aix-thumbnail.component';

export interface AccountDocument {
    id: string;
    accountId: string;
    type: string;
    subtype: string;
    displayName: string;
    filename: string;
    url: string;
    version: string;
    documentDate: string;
    securityName?: string;
    contactName?: string;
    thumbnail?: string;
    download?: boolean;
    name?: string;
    userUploaded?: boolean;
}

@Component({
    selector: 'aix-account-document-preview',
    templateUrl: './aix-account-document-preview.component.html',
    styleUrls: ['./aix-account-document-preview.component.scss'],
    standalone: true,
    imports: [
        AixThumbnailComponent,
        NgIf,
        AixInlineDropdownComponent,
        AixLoadingButtonComponent,
        DatePipe
    ]
})
export class AixAccountDocumentPreviewComponent {
    data = input<AccountDocument>();
    downloading = input(false);
    isAdmin = input(false);
    actionClick = output<string>();
    clickDownload = output<string>();
    clickDelete = output<string>();

    buttonLabelButtonType = BUTTON_TYPE.primary;

    onActionClicked(id: string) {
        this.actionClick.emit(id);
    }

    onClickDownload(documentId: string | undefined) {
        this.clickDownload.emit(<string>documentId);
    }

    onOptionSelected(option: string, id: string | undefined) {
        switch (option) {
            case 'View':
                this.onActionClicked(<string>id);
                break;
            case 'Download':
                this.onClickDownload(<string>id);
                break;
            case 'Remove':
                this.clickDelete.emit(<string>id);
                break;
        }
    }
}
