import { BaseOrder, OrderForm } from '@trade-platform/ui-shared';
import { formStatus } from '@trade-platform/lib-enums';

export const FINISHED_FORM_ID = 'finish';
export const calculateNextForm = (order: BaseOrder, formId: string): OrderForm => {
    const currentForm = order.forms[formId];
    const pristineForms = Object.keys(order.forms)
        .map(key => order.forms[key])
        .sort((a, b) => a.index - b.index)
        .filter(form => form.status !== formStatus.completed && form.isOnboarded !== false)
        .reduce(
            (acc, form) => {
                if (form.index < currentForm.index) {
                    acc.prevForms.push(form);
                } else {
                    acc.nextForms.push(form);
                }
                return acc;
            },
            { prevForms: [] as OrderForm[], nextForms: [] as OrderForm[] }
        );

    const nextForms = pristineForms.nextForms.concat(pristineForms.prevForms);
    const currentFormIndex = nextForms.findIndex(form => form.id === currentForm.id);
    const nextForm = nextForms[currentFormIndex + 1];
    return (
        nextForm ||
        ({
            id: FINISHED_FORM_ID,
            name: 'Return to Order Overview',
            status: '',
            index: 0,
            fields: {},
            map: {}
        } as OrderForm)
    );
};
