import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { AcceptTermResponse, TermsAndConditions } from '../../models/terms/model';

export class TermsActionTypes {
    static readonly CLEAR_TERMS = type('[Terms] Clear Terms');

    static readonly LOAD_TERMS = type('[Terms] Load Terms');
    static readonly LOAD_TERMS_SUCCESS = type('[Terms] Load Terms Success');
    static readonly LOAD_TERMS_FAIL = type('[Terms] Load Terms Failure');

    static readonly LOAD_TERMS_FROM_LOCAL_STORAGE = type('[Terms] Load Terms From Local Storage');
    static readonly SET_TERMS = type('[Terms] Set Terms');
}

export class AcceptTermsActionTypes {
    static readonly ACCEPT_TERMS = type('[Terms] Accept Terms');
    static readonly ACCEPT_TERMS_SUCCESS = type('[Terms] Accept Terms Success');
    static readonly ACCEPT_TERMS_FAIL = type('[Terms] Accept Terms Failure');
}

export type TermsActions =
    | ClearTermsAction
    | LoadTermsAction
    | LoadTermsSuccessAction
    | LoadTermsFailureAction
    | LoadTermsFromStorageAction
    | SetTermsAction;

export type AcceptTermsActions =
    | AcceptTermsAction
    | AcceptTermsSuccessAction
    | AcceptTermsFailureAction;

export class ClearTermsAction implements AixAction {
    readonly type = TermsActionTypes.CLEAR_TERMS;

    constructor() {}
}
export class LoadTermsAction implements AixAction {
    readonly type = TermsActionTypes.LOAD_TERMS;
    payload?: any;

    constructor() {}
}
export class LoadTermsSuccessAction implements AixAction {
    readonly type = TermsActionTypes.LOAD_TERMS_SUCCESS;

    constructor(public payload: TermsAndConditions) {}
}
export class LoadTermsFailureAction implements AixAction {
    readonly type = TermsActionTypes.LOAD_TERMS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}
export class LoadTermsFromStorageAction implements AixAction {
    readonly type = TermsActionTypes.LOAD_TERMS_FROM_LOCAL_STORAGE;

    constructor() {}
}
export class SetTermsAction implements AixAction {
    readonly type = TermsActionTypes.SET_TERMS;

    constructor(public payload: TermsAndConditions) {}
}

export class AcceptTermsAction implements AixAction {
    readonly type = AcceptTermsActionTypes.ACCEPT_TERMS;

    constructor() {}
}
export class AcceptTermsSuccessAction implements AixAction {
    readonly type = AcceptTermsActionTypes.ACCEPT_TERMS_SUCCESS;

    constructor(public payload: AcceptTermResponse) {}
}
export class AcceptTermsFailureAction implements AixAction {
    readonly type = AcceptTermsActionTypes.ACCEPT_TERMS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}
