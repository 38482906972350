import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderCancelReviewActions, OrderCancelReviewActionTypes } from './actions';

export type OrderCancelReviewState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderCancelReviewReducer = (
    state: OrderCancelReviewState = initialState,
    action: OrderCancelReviewActions
): OrderCancelReviewState => {
    switch (action.type) {
        case OrderCancelReviewActionTypes.ORDER_CANCEL_REVIEW:
            return inProgress();
        case OrderCancelReviewActionTypes.ORDER_CANCEL_REVIEW_SUCCESS:
            return success(null);
        case OrderCancelReviewActionTypes.ORDER_CANCEL_REVIEW_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
