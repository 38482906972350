import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { DocumentFile } from '@trade-platform/ui-shared';

@Injectable()
export class OrderDocumentRemoveService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderDocumentRemove(payload: { orderId: string; fileToRemove: DocumentFile }): Observable<any> {
        return this.http.delete(
            `${this.environment.api}orders/${payload.orderId}/files/${payload.fileToRemove.id}`
        );
    }
}
