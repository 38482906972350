<!-- blink container -->
<span class="aix-blink" [class.radio-custom-container__invalid]="templateData.ctrlIsInvalid">
    @if ((config.label && !config.columns) || config.columns! <= 1) {
    <p [data-testing]="config.refId! + '__label'">
        {{ config.label }}<span>&nbsp;</span
        ><span>{{ templateData.fieldIsRequired ? '*' : '' }}</span>
        @if (config.infoTooltip) {
        <span
            aixTooltip
            [aixTooltipContent]="config.infoTooltip"
            [data-testing]="config.refId! + '__infotooltip'"
        ></span>
        }
    </p>
    } @if (config.description) {
    <p class="aix-form__group-message u-mt14">
        {{ config.description }}
    </p>
    }

    <!-- 1 column -->
    @if (!config.columns || config.columns! <= 1) {
    <div
        class="radio-custom-container"
        [ngClass]="bodyClassNames ? bodyClassNames : ''"
        [class.radio-custom-container__invalid]="templateData.ctrlIsInvalid"
        #inputField
    >
        @for (option of templateData.options; track trackByOptionFn($index, option)) {
        <div class="aix-flex-grid aix-form__grid">
            <div class="aix-form__group">
                @if (option.isTitle) {
                <h3 class="aix-form__label aix-label u-mt20">
                    {{ option.label }}
                </h3>
                } @if (!option.isTitle) {
                <div class="radio-custom-wrapper-full">
                    <label
                        class="radio-custom radio-custom-label-size"
                        [data-testing]="'radio-group-item'"
                        [class.aix-disabled]="templateData.fieldIsDisabled || option.disabled"
                    >
                        <input
                            type="radio"
                            [data-testing]="'input'"
                            [aixDynamicNextPendingField]="{
                                config: config,
                                margin: templateData.options.length * 35
                            }"
                            [name]="controlName"
                            [value]="option.value"
                            [class.aix-disabled]="templateData.fieldIsDisabled || option.disabled"
                            [disabled]="templateData.fieldIsDisabled || option.disabled"
                            (change)="userInputEmitter$.next(option)"
                            [data-testing]="config.refId! + '_' + option.value"
                            [attr.tabindex]="
                                templateData.fieldIsDisabled || option.disabled ? '-1' : ''
                            "
                            [checked]="option.value === templateData.selectedOption?.value"
                        />
                        <span
                            class="radio-custom__indicator"
                            [class.aix-form__control--invalid]="templateData.ctrlIsInvalid"
                            [class.aix-form__control--invalid-required]="
                                templateData.ctrlHasRequiredError
                            "
                            [data-testing]="
                                config.refId! + '_' + option.value + '__required-styles'
                            "
                        ></span>
                        <span
                            class="radio-custom__label radio-custom__label--xl"
                            [data-testing]="'label'"
                        >
                            {{ option.label }}
                            @if (option.subLabel) {
                            <span class="radio-custom__sublabel">
                                {{ option.subLabel }}
                            </span>
                            }
                        </span>
                        @if (option.tooltip) {
                        <span
                            aixTooltip
                            [aixTooltipContent]="option.tooltip"
                            [data-testing]="config.refId! + '_' + option.value + '__tooltip'"
                        ></span>
                        }
                    </label>
                </div>
                }
            </div>
        </div>
        }
    </div>
    }

    <!-- More than 1 column -->
    <ng-template #headerTemplate>
        <span class="-text--large u-pl16" [data-testing]="config.refId! + '__label'">
            <span>{{ config.label }}{{ templateData.selectedOptionLabel }}</span
            ><span>&nbsp;</span><span>{{ templateData.fieldIsRequired ? '*' : '' }}</span>
        </span>
    </ng-template>

    <ng-template #bodyTemplate>
        <div
            class="radio-custom-expansion-panel-content"
            #inputField
            [ngClass]="bodyClassNames ? bodyClassNames : ''"
        >
            @for (columnOfOptions of templateData.optionsInColumns; track
            trackByOptionArrayFn($index, columnOfOptions)) {
            <div
                class="radio-custom-column"
                [ngClass]="{
                    'radio-custom-column__1of2': config.columns === 2,
                    'radio-custom-column__1of3': config.columns === 3
                }"
            >
                @for (option of columnOfOptions; track trackByOptionFn(i, option); let i = $index) {
                <div class="aix-flex-grid aix-form__grid">
                    <div class="aix-form__group">
                        @if (option.isTitle) {
                        <h5 class="radio-custom-title">
                            {{ option.label }}
                        </h5>
                        } @if (!option.isTitle) {
                        <div class="radio-custom-wrapper-full">
                            <label
                                class="radio-custom radio-custom-label-size"
                                [class.aix-disabled]="
                                    templateData.fieldIsDisabled || option.disabled
                                "
                            >
                                <input
                                    type="radio"
                                    [aixDynamicNextPendingField]="{
                                        config: config!,
                                        margin: ceil(i / config.columns!) * 35
                                    }"
                                    [name]="controlName"
                                    [value]="option.value"
                                    [class.aix-disabled]="
                                        templateData.fieldIsDisabled || option.disabled
                                    "
                                    [disabled]="templateData.fieldIsDisabled || option.disabled"
                                    (change)="userInputEmitter$.next(option)"
                                    [data-testing]="config.refId! + '_' + option.value"
                                    [checked]="option.value === templateData.selectedOption?.value"
                                    [attr.tabindex]="
                                        templateData.fieldIsDisabled || option.disabled ? '-1' : ''
                                    "
                                />
                                <span class="radio-custom__indicator"></span>
                                <span class="radio-custom__label radio-custom__label--xl">
                                    {{ option.label }}
                                    @if (option.subLabel) {
                                    <span class="radio-custom__sublabel">
                                        {{ option.subLabel }}
                                    </span>
                                    }
                                </span>
                                @if (option.tooltip) {
                                <span
                                    aixTooltip
                                    [aixTooltipContent]="option.tooltip"
                                    [data-testing]="
                                        config.refId! + '_' + option.value + '__tooltip'
                                    "
                                ></span>
                                }
                            </label>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </ng-template>
</span>

@if (config.columns && config.columns! > 1) {
<aix-expansion-panel
    #expansionPanelRef
    [data-testing]="config.refId! + '__expansion-panel'"
    [headerNgClass]="{
        'aix-form__control--dirty': templateData.fieldIsDirty,
        'aix-form__control--invalid': templateData.ctrlIsInvalid,
        'aix-form__control--invalid-required': templateData.ctrlHasRequiredError
    }"
    [headerTemplate]="headerTemplate"
    [bodyTemplate]="bodyTemplate"
    [arrowPosition]="'right'"
    (expand)="onOpenOrClose()"
    (collapse)="onOpenOrClose()"
>
</aix-expansion-panel>
}
