import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    OrderCancel,
    OrderCancelActionTypes,
    OrderCancelFailure,
    OrderCancelSuccess
} from './actions';
import { OrderCancelService } from './service';
import { catchHttpError } from '@trade-platform/ui-shared';

@Injectable()
export class OrderCancelEffects {
    constructor(private actions$: Actions, private orderCancelService: OrderCancelService) {}

    orderCancel$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderCancel>(OrderCancelActionTypes.ORDER_CANCEL),
            switchMap(action =>
                this.orderCancelService.orderCancel(action.payload.orderId).pipe(
                    map(_ => new OrderCancelSuccess()),
                    catchHttpError(error => of(new OrderCancelFailure({ error })))
                )
            )
        )
    );
}
