import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    DefaultUrlSerializer,
    Navigation,
    NavigationEnd,
    ResolveEnd,
    Router,
    RouterOutlet,
    UrlTree
} from '@angular/router';
import { Store } from '@ngrx/store';
import {
    AixEffectActions,
    ApplyBrandingAction,
    ApplyBrandingSuccessAction,
    AppState,
    Brand,
    IconFieldDecorationComponent,
    LoadOrderFailure,
    PingRegisterAction,
    RegisterToken,
    RemoteDataInProgressFieldDecorationComponent,
    SetRegisterAction
} from '@trade-platform/ui-shared';
import { FieldDecorationMap } from '@trade-platform/dynamic-forms';
import { objectHasValue } from '@trade-platform/ui-utils';
import {
    AixAuthService,
    AixRouteService,
    Init,
    InitSSO,
    LoginMachine,
    Register,
    routeConstants
} from '@advisor-ui/app-services';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { StateValue } from 'xstate/lib/types';
import qs from 'qs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { NgIf } from '@angular/common';
import { AixUiComponentsModule, BannerStylesDirective } from '@trade-platform/ui-components';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import {
    AixFooterComponent,
    AixInactivityModalComponent,
    AixNavBarComponent,
    FlashMessagesComponent
} from '@advisor-ui/app-components';

@Component({
    selector: 'aix-trade-platform',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        AixUiComponentsModule,
        RouterOutlet,
        FlashMessagesComponent,
        AixInactivityModalComponent,
        AixNavBarComponent,
        AixFooterComponent
    ],
    hostDirectives: [BannerStylesDirective]
})
export class TradePlatformWebAppComponent implements OnInit, OnDestroy, AfterViewChecked {
    isAdminUrl = false;
    isLoggingIn = true;
    subscriptions: Subscription[] = [];

    private readonly BRAND_STYLE_ID = 'brandingStyle';

    private currentLoginState: StateValue | null = null;

    constructor(
        public routeService: AixRouteService,
        public authService: AixAuthService,
        public actions$: AixEffectActions,
        public store: Store<AppState>,
        public router: Router,
        public route: ActivatedRoute,
        private gtag: Angulartics2GoogleTagManager,
        private loginMachine: LoginMachine,
        private cd: ChangeDetectorRef,
        private jwtService: JwtHelperService
    ) {
        console.log(`AIX Platform ${environment.id}.${environment.appVersion}`);
        this.gtag.startTracking();
    }

    ngOnInit() {
        const path = qs.parse(window.location.pathname);
        if (path && '/sso' in path) {
            this.loginMachine.send(new InitSSO());
        } else if (path && '/register' in path) {
            const accessToken = window.location.search.split('?token=')[1];
            const jwt = this.jwtService.decodeToken(accessToken) as RegisterToken;
            jwt.token = accessToken;

            this.store.dispatch(new SetRegisterAction(jwt));
            this.store.dispatch(new PingRegisterAction(accessToken));
            this.loginMachine.send(new Register());
        } else {
            this.loginMachine.send(new Init());
        }

        this.initializeDynamicFormsMaps();

        this.subscriptions.push(
            this.router.events.subscribe(evt => {
                if (evt instanceof NavigationEnd || evt instanceof ResolveEnd) {
                    let doScroll = true;

                    this.isAdminUrl =
                        objectHasValue(evt) &&
                        objectHasValue(evt.url) &&
                        evt.url.indexOf('/admin/') > -1;

                    const urlSerializer = new DefaultUrlSerializer();
                    if ((this.router.getCurrentNavigation() as Navigation).previousNavigation) {
                        const currUrl = urlSerializer.serialize(
                            (this.router.getCurrentNavigation() as Navigation).finalUrl as UrlTree
                        );
                        const prevUrl = urlSerializer.serialize(
                            (
                                (this.router.getCurrentNavigation() as Navigation)
                                    .previousNavigation as Navigation
                            ).finalUrl as UrlTree
                        );

                        // Never scroll to top if the user is navigating in the same page
                        if (prevUrl.split(';')[0] === currUrl.split(';')[0]) {
                            doScroll = false;
                        }
                    }

                    if (doScroll) {
                        setTimeout(function () {
                            window.scrollTo({
                                top: 0,
                                left: 0
                            });
                        }, 1);
                    }
                }
            }),
            this.actions$
                .ofClass<ApplyBrandingSuccessAction>(ApplyBrandingSuccessAction)
                .subscribe(action => {
                    if (action.payload && action.payload.css && action.payload.css.length > 0) {
                        this.applyBrand(action.payload);
                    }
                }),
            this.actions$.ofClass<LoadOrderFailure>(LoadOrderFailure).subscribe(action => {
                // If order is not found, redirect the user back to the orders list;
                const error = action.payload.error;
                if (error && error.options && error.options.status === 404) {
                    this.router.navigate(routeConstants.routes.purchase.status());
                }
            }),
            this.loginMachine.loginState$.subscribe(state => {
                this.isLoggingIn = false;
                this.currentLoginState = state.value;

                // The state machine will not redirect if we are already logged in, so we need to do it manually here;
                const currentRoute = this.routeService.currentRoute;
                const previousRoute = this.routeService.previousRoute;
                if (
                    this.currentLoginState === 'app' &&
                    (currentRoute === '/' || currentRoute === '') &&
                    !/access_token/.test(previousRoute as string)
                ) {
                    this.authService.redirectOnLogin();
                }
            })
        );

        if (this.authService.isLoggedInAuth0()) {
            this.store.dispatch(new ApplyBrandingAction());
        }
    }

    initializeDynamicFormsMaps() {
        if (!FieldDecorationMap.exists(RemoteDataInProgressFieldDecorationComponent.REF)) {
            FieldDecorationMap.add(
                RemoteDataInProgressFieldDecorationComponent.REF,
                RemoteDataInProgressFieldDecorationComponent
            );
        }
        if (!FieldDecorationMap.exists(IconFieldDecorationComponent.REF)) {
            FieldDecorationMap.add(IconFieldDecorationComponent.REF, IconFieldDecorationComponent);
        }
    }

    ngAfterViewChecked() {
        // Is this still needed??? - JMH;
        this.logoutWhenNotLoggedIn();
        this.cd.detectChanges();
    }

    applyBrand(brand: Brand) {
        const head = document.getElementsByTagName('body')[0];
        const brandingStyleElement = document.getElementById(this.BRAND_STYLE_ID);

        // First add new styling link;
        const link = document.createElement('link');
        link.id = this.BRAND_STYLE_ID;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = brand.css as string;
        link.media = 'all';
        link.addEventListener('load', () => {
            // Second remove old styling link (if exists);
            if (brandingStyleElement) {
                head.removeChild(brandingStyleElement);
            }
        });
        head.appendChild(link);
    }

    logout() {
        this.authService.callLogout(true);
    }

    logoutWhenNotLoggedIn() {
        if (this.currentLoginState === 'app' && !this.authService.isLoggedInAuth0()) {
            this.logout();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd || evt instanceof ResolveEnd) {
                this.isAdminUrl =
                    objectHasValue(evt) &&
                    objectHasValue(evt.url) &&
                    evt.url.indexOf('/admin/') > -1;
                // scroll to top on route change
                setTimeout(function () {
                    window.scrollTo(0, 0);
                }, 1);
            }
        });
    }
}
