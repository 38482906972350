import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// validation function
export function emailValidator(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        let result = null;

        const regExp = /^\b[A-Za-z0-9._%+!-]+@[A-Za-z0-9_%+-]+(\.[A-Za-z0-9]+)+\b$/;

        const isValid =
            c.value === undefined ||
            c.value === null ||
            c.value === '' ||
            regExp.exec(c.value.trim());

        if (!isValid) {
            result = {
                email: {
                    valid: false
                }
            };
        }

        return result;
    };
}
