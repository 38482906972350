import { Pipe, PipeTransform } from '@angular/core';
import { formatUTCDateString } from '@trade-platform/ui-utils';

@Pipe({
    name: 'aixUtcDate',
    standalone: true
})
export class UtcDatePipe implements PipeTransform {
    transform(dateString: string): string {
        return formatUTCDateString(dateString, 'mmddyyyy');
    }
}
