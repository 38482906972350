import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[aixTemplate]',
    standalone: true
})
export class AixTemplateDirective {
    @Input('aixTemplate') name: string;

    constructor(public template: TemplateRef<any>) {}
}
