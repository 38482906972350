import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderMapSignaturesActions, OrderMapSignaturesActionTypes } from './actions';

export type OrderMapSignaturesState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderMapSignaturesReducer = (
    state: OrderMapSignaturesState = initialState,
    action: OrderMapSignaturesActions
): OrderMapSignaturesState => {
    switch (action.type) {
        case OrderMapSignaturesActionTypes.ORDER_MAP_SIGNATURES:
            return inProgress();
        case OrderMapSignaturesActionTypes.ORDER_MAP_SIGNATURES_SUCCESS:
            return success(null);
        case OrderMapSignaturesActionTypes.ORDER_MAP_SIGNATURES_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
