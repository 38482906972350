import { Directive, ElementRef, Inject, input, OnInit, Renderer2 } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Directive({
    selector: '[data-testing]',
    standalone: true
})
export class AixDataTestingDirective implements OnInit {
    dataTestingValue = input<string | undefined>(undefined, { alias: 'data-testing' });

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(ENVIRONMENT) private environment: IEnvironment
    ) {}

    ngOnInit() {
        if (this.environment.environment === 'production') {
            this.renderer.removeAttribute(this.el.nativeElement, 'data-testing');
        } else {
            if (this.dataTestingValue()) {
                this.renderer.setAttribute(
                    this.el.nativeElement,
                    'data-testing',
                    <string>this.dataTestingValue()
                );
            }
        }
    }
}
