import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';

export class UploadDocumentActionTypes {
    static readonly RETURN_TO_UPLOADER = type('[UploadDocument] Return To Uploader');
}

export type UploadDocumentActions = ReturnToUploaderAction;

export class ReturnToUploaderAction implements AixAction {
    readonly type = UploadDocumentActionTypes.RETURN_TO_UPLOADER;

    constructor() {}
}
