import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderFormsComplete,
    OrderFormsCompleteActionTypes,
    OrderFormsCompleteFailure,
    OrderFormsCompleteSuccess
} from './actions';
import { OrderFormsCompleteService } from './service';

@Injectable()
export class OrderFormsCompleteEffects {
    constructor(
        private actions$: Actions,
        private orderFormsCompleteService: OrderFormsCompleteService
    ) {}

    orderFormsComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFormsComplete>(OrderFormsCompleteActionTypes.ORDER_FORMS_COMPLETE),
            switchMap(action =>
                this.orderFormsCompleteService.orderFormsComplete(action.payload.orderId).pipe(
                    map(res => new OrderFormsCompleteSuccess()),
                    catchHttpError(error =>
                        of(
                            new OrderFormsCompleteFailure({
                                error
                            })
                        )
                    )
                )
            )
        )
    );
}
