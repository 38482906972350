import { DocumentViewerActions, DocumentViewerActionTypes } from './actions';
import { DocumentViewerModel } from '../../models/document-viewer/model';

const initialState = (): DocumentViewerModel => ({
    tab: null,
    currentDocument: '',
    currentFile: null,
    currentForm: null,
    filesToDisplay: [],
    offline: false,
    action: null,
    hasPermission: false,
    downloading: {},
    downloadError: false,
    notification: null,
    initialized: false,
    showModal: false
});

export function documentViewerReducer(
    state: DocumentViewerModel = initialState(),
    action: DocumentViewerActions
): DocumentViewerModel {
    switch (action.type) {
        case DocumentViewerActionTypes.RESET:
            return initialState();
        case DocumentViewerActionTypes.SET_ACTIVE_TAB:
            return { ...state, tab: action.payload.tab };
        case DocumentViewerActionTypes.SET_ACTIVE_FILE:
            return { ...state, currentFile: action.payload.currentFile };
        case DocumentViewerActionTypes.SET_ACTIVE_FORM:
            return { ...state, currentForm: action.payload.currentForm };
        case DocumentViewerActionTypes.SET_FILES_TO_DISPLAY:
            return { ...state, filesToDisplay: action.payload.filesToDisplay };
        case DocumentViewerActionTypes.SET_ACTIVE_DOCUMENT:
            return { ...state, currentDocument: action.payload.currentDocument };
        case DocumentViewerActionTypes.SET_OFFLINE:
            return { ...state, offline: action.payload.offline };
        case DocumentViewerActionTypes.SET_ACTION:
            return { ...state, action: action.payload.action };
        case DocumentViewerActionTypes.SET_PERMISSIONS:
            return { ...state, hasPermission: action.payload.permissions };
        case DocumentViewerActionTypes.DOWNLOAD:
            return { ...state, downloading: { ...action.payload.downloading } };
        case DocumentViewerActionTypes.DOWNLOAD_ERROR:
            return { ...state, downloadError: action.payload.downloadError };
        case DocumentViewerActionTypes.SHOW_NOTIFICATION:
            return { ...state, notification: action.payload.notification };
        case DocumentViewerActionTypes.HIDE_NOTIFICATION:
            return { ...state, notification: null };
        case DocumentViewerActionTypes.SET_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case DocumentViewerActionTypes.SHOW_MODAL:
            return { ...state, showModal: action.payload.showModal };
        default:
            return state;
    }
}
