import { AixAction, ErrorWrapper, SupplementalFile, type } from '@trade-platform/ui-shared';

export class DocumentTypeActionTypes {
    static readonly LOAD_DOCUMENT_TYPES = type('[DocumentTypes] Load DocumentTypes');
    static readonly LOAD_DOCUMENT_TYPES_SUCCESS = type(
        '[DocumentTypes] Load DocumentTypes Success'
    );
    static readonly LOAD_DOCUMENT_TYPES_FAILURE = type(
        '[DocumentTypes] Load DocumentTypes Failure'
    );
}

export type DocumentTypesActions =
    | LoadDocumentTypes
    | LoadDocumentTypesSuccess
    | LoadDocumentTypesFailure;

// Load

export class LoadDocumentTypes implements AixAction {
    readonly type = DocumentTypeActionTypes.LOAD_DOCUMENT_TYPES;

    constructor(public payload: string) {}
}

export class LoadDocumentTypesSuccess implements AixAction {
    readonly type = DocumentTypeActionTypes.LOAD_DOCUMENT_TYPES_SUCCESS;

    constructor(public payload: SupplementalFile[]) {}
}

export class LoadDocumentTypesFailure implements AixAction {
    readonly type = DocumentTypeActionTypes.LOAD_DOCUMENT_TYPES_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
