import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import {
    LoadFirmsActions,
    LoadFirmsActionTypes,
    ProfileActions,
    ProfileActionTypes,
    ProfilePasswordResetActions,
    ProfilePasswordResetTypes,
    ResetMFAActions,
    ResetMFATypes
} from './actions';
import { Profile, SharedExternalFirm } from '../../models/profile/model';

export type ProfileState = RemoteData<Profile, ErrorWrapper>;

export function profileReducer(
    state: ProfileState = notAsked(),
    action: ProfileActions
): ProfileState {
    switch (action.type) {
        case ProfileActionTypes.LOAD_PROFILE:
            return inProgress({} as Profile);
        case ProfileActionTypes.LOAD_PROFILE_SUCCESS:
            return success(action.payload);
        case ProfileActionTypes.LOAD_PROFILE_FAIL:
            return failure(action.payload.error);
        case ProfileActionTypes.SET_PROFILE:
            return success(action.payload);
        case ProfileActionTypes.UNLOAD_PROFILE:
            return notAsked();
        case ProfileActionTypes.UPDATE_PROFILE:
            return inProgress({} as Profile);
        case ProfileActionTypes.UPDATE_PROFILE_SUCCESS:
            return success(action.payload);
        case ProfileActionTypes.UPDATE_PROFILE_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

/**
 * Password Reset;
 */
export type ProfilePasswordResetState = RemoteData<boolean, ErrorWrapper>;

export function profilePasswordResetReducer(
    state: ProfilePasswordResetState = notAsked(),
    action: ProfilePasswordResetActions
): ProfilePasswordResetState {
    switch (action.type) {
        case ProfilePasswordResetTypes.PASSWORD_RESET:
            return inProgress(false);
        case ProfilePasswordResetTypes.PASSWORD_RESET_SUCCESS:
            return success(action.payload);
        case ProfilePasswordResetTypes.PASSWORD_RESET_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

/**
 * Reset MFA
 */

export type ResetMFAState = RemoteData<boolean, ErrorWrapper>;

export function resetMFAReducer(
    state: ResetMFAState = notAsked(),
    action: ResetMFAActions
): ResetMFAState {
    switch (action.type) {
        case ResetMFATypes.RESET_MFA:
            return inProgress(false);
        case ResetMFATypes.RESET_MFA_SUCCESS:
            return success(action.payload);
        case ResetMFATypes.RESET_MFA_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}

/**
 * Shared Firms
 */
export type SharedFirmsState = RemoteData<SharedExternalFirm[], ErrorWrapper>;

export function sharedFirmsReducer(
    state: SharedFirmsState = notAsked(),
    action: LoadFirmsActions
): SharedFirmsState {
    switch (action.type) {
        case LoadFirmsActionTypes.LOAD_FIRMS:
            return inProgress([]);
        case LoadFirmsActionTypes.LOAD_FIRMS_SUCCESS:
            return success(action.payload);
        case LoadFirmsActionTypes.LOAD_FIRMS_FAIL:
            return failure(action.payload.error);
        default:
            return state;
    }
}
