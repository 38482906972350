import { AixAction, ErrorWrapper, type } from '@trade-platform/ui-shared';
import { ProductMaterial, ProductMaterialPayload } from '../../models/product-materials/model';

export class ProductMaterialActionTypes {
    static readonly LOAD_PRODUCT_MATERIALS = type('[Product Materials] Load Product Materials');
    static readonly LOAD_PRODUCT_MATERIALS_SUCCESS = type(
        '[Product Materials] Load Product Materials Success'
    );
    static readonly LOAD_PRODUCT_MATERIALS_FAIL = type(
        '[Product Materials] Load Product Materials Failure'
    );
}

export type ProductMaterialActions =
    | LoadProductMaterialsAction
    | LoadProductMaterialsSuccessAction
    | LoadProductMaterialsFailureAction;

export class LoadProductMaterialsAction implements AixAction {
    readonly type = ProductMaterialActionTypes.LOAD_PRODUCT_MATERIALS;

    constructor(public payload: ProductMaterialPayload) {}
}

export class LoadProductMaterialsSuccessAction implements AixAction {
    readonly type = ProductMaterialActionTypes.LOAD_PRODUCT_MATERIALS_SUCCESS;

    constructor(public payload: ProductMaterial[]) {}
}

export class LoadProductMaterialsFailureAction implements AixAction {
    readonly type = ProductMaterialActionTypes.LOAD_PRODUCT_MATERIALS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}
