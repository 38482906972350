/**
 * Detects if an object has cyclical references
 * @param obj
 */
export const isObjectRecursive = (obj: Record<string, unknown>) => {
    const seen: any[] = [];

    const detect = (object: any) => {
        if (object && typeof object === 'object') {
            if (seen.indexOf(object) !== -1) {
                return true;
            }
            seen.push(object);
            for (const key in object) {
                if (object.hasOwnProperty(key) && detect(object[key])) {
                    return true;
                }
            }
        }
        return false;
    };

    return detect(obj);
};

/**
 * Copies an object recursively and returns new references for all nested objects
 * @param obj
 */
export const cloneObject = (object: Record<string, unknown>) => {
    if (isObjectRecursive(object)) {
        throw new Error('The object is recursive');
    }

    const clone = (obj: any) => {
        const copy: Record<string, unknown> = {};
        for (const i in obj) {
            if (obj[i] != null && typeof obj[i] === 'object') {
                copy[i] = clone(obj[i]);
            } else {
                copy[i] = obj[i];
            }
        }
        return copy;
    };

    return clone(object);
};

/**
 * Returns true when an object is not null nor undefined
 * @param obj
 */
export const objectHasValue = <T>(obj: T): obj is NonNullable<T> =>
    obj !== null && obj !== undefined;

/**
 * Returns true when an object is complex. i.e. {}, {a: 1}, etc...
 * @param obj
 */
export const isComplexObject = (obj: any) => obj !== null && typeof obj === 'object';

/**
 * Returns the recursive merge of target and source
 * @param target
 * @param source
 */
export const mergeObject = (target: Record<string, any>, source: Record<string, any>) => {
    for (const key of Object.keys(source)) {
        if (typeof source[key] === 'object') {
            Object.assign(source[key], mergeObject(target[key] || {}, source[key]));
        }
    }

    return Object.assign(target, source);
};

/**
 * Remove empty literal objects i.e `{}` from Arrays recursively.
 */
export const removeEmptyObjectsFromArray = (obj: any): { [key: string]: any } => {
    if (Array.isArray(obj)) {
        return obj.filter(emptyObjectFilter).map(removeEmptyObjectsFromArray);
    } else if (isComplexObject(obj)) {
        const obj2: any = {};
        Object.keys(obj).forEach(key => {
            obj2[key] = removeEmptyObjectsFromArray(obj[key]);
        });
        return obj2;
    } else {
        return obj;
    }
};
function emptyObjectFilter(obj: any) {
    return !isComplexObject(obj) || (isComplexObject(obj) && Object.keys(obj).length > 0);
}
