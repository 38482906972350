import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SupplementalFile } from '@trade-platform/ui-shared';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { from as fromPromise } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { DocumentTypesDecoder } from './decoder';

@Injectable()
export class DocumentTypesService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getDocumentTypes(orderId: string) {
        return this.http
            .get<SupplementalFile[]>(`${this.environment.api}orders/${orderId}/types`)
            .pipe(concatMap(p => fromPromise(DocumentTypesDecoder.decodeToPromise(p))));
    }
}
