import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderSignPayload } from '@trade-platform/ui-shared';

@Injectable()
export class OrderSignService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderSign(orderId: string): Observable<OrderSignPayload> {
        return this.http.put<OrderSignPayload>(
            `${this.environment.api}orders/${orderId}/recipient`,
            {
                id: orderId
            }
        );
    }
}
