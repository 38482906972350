export class TransientDataManager<T extends { id: any }> {
    public isLoading = false;

    private _options: T[] = [];
    get options(): T[] {
        return this._options;
    }

    private _originalSelection: T[] = [];
    get originalSelection(): T[] {
        return this._originalSelection;
    }

    private _currentSelection: T[] = [];
    get currentSelection(): T[] {
        return this._currentSelection;
    }

    setOptions(opts: T[]) {
        this._options = opts;
    }

    setOriginalSelection(selected: T[]) {
        this._originalSelection = selected;
        this._currentSelection = selected;
    }

    addToCurrentSelection(items: T[]) {
        this._currentSelection = [...items, ...this._currentSelection];
    }

    removeFromCurrentSelection(items: T[]) {
        this._currentSelection = this._currentSelection.filter(
            cur => items.find(item => cur.id === item.id) === undefined
        );
    }

    getAddedToOriginalSelection() {
        return this._currentSelection?.filter(
            cur => this._originalSelection.find(item => cur.id === item.id) === undefined
        );
    }

    getRemovedFromOriginalSelection() {
        return this._originalSelection?.filter(
            item => this._currentSelection.find(cur => cur.id === item.id) === undefined
        );
    }

    hasChanges() {
        return (
            this.getAddedToOriginalSelection()?.length > 0 ||
            this.getRemovedFromOriginalSelection()?.length > 0
        );
    }

    reset() {
        this._originalSelection = [];
        this._currentSelection = [];
    }
}
