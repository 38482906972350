import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { Profile, SharedExternalFirm, UpdateProfilePayload } from '../../models/profile/model';

/**
 * Action Types
 */
export class ProfileActionTypes {
    static readonly LOAD_PROFILE = type('[Profile] Load Profile');
    static readonly LOAD_PROFILE_SUCCESS = type('[Profile] Load Profile Success');
    static readonly LOAD_PROFILE_FAIL = type('[Profile] Load Profile Failure');

    static readonly UNLOAD_PROFILE = type('[Profile] Unload Profile');
    static readonly SET_PROFILE = type('[Profile] Set Profile');
    static readonly LOAD_PROFILE_FROM_STORAGE = type('[Profile] Load Profile From Storage');

    static readonly UPDATE_PROFILE = type('[Profile] Update Profile');
    static readonly UPDATE_PROFILE_SUCCESS = type('[Profile] Update Profile Success');
    static readonly UPDATE_PROFILE_FAIL = type('[Profile] Update Profile Failure');
}

/**
 * Available Action Types
 */
export type ProfileActions =
    | LoadProfileAction
    | LoadProfileSuccessAction
    | LoadProfileFailAction
    | UpdateProfileAction
    | UpdateProfileSuccessAction
    | UpdateProfileFailAction
    | UnloadProfileAction
    | SetProfileAction
    | LoadProfileFromStorageAction;

/**
 * Advisor Profile
 */
export class LoadProfileAction implements AixAction {
    readonly type = ProfileActionTypes.LOAD_PROFILE;
}

export class LoadProfileSuccessAction implements AixAction {
    readonly type = ProfileActionTypes.LOAD_PROFILE_SUCCESS;

    constructor(public payload: Profile) {}
}

export class LoadProfileFailAction implements AixAction {
    readonly type = ProfileActionTypes.LOAD_PROFILE_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class UpdateProfileAction implements AixAction {
    readonly type = ProfileActionTypes.UPDATE_PROFILE;

    constructor(public payload: UpdateProfilePayload) {}
}

export class UpdateProfileSuccessAction implements AixAction {
    readonly type = ProfileActionTypes.UPDATE_PROFILE_SUCCESS;

    constructor(public payload: Profile) {}
}

export class UpdateProfileFailAction implements AixAction {
    readonly type = ProfileActionTypes.UPDATE_PROFILE_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

/**
 * Unload Profile
 */
export class UnloadProfileAction implements AixAction {
    readonly type = ProfileActionTypes.UNLOAD_PROFILE;

    constructor() {}
}

/**
 * Set Profile
 */
export class SetProfileAction implements AixAction {
    readonly type = ProfileActionTypes.SET_PROFILE;

    constructor(public payload: Profile) {}
}

/**
 * Load From Storage
 */
export class LoadProfileFromStorageAction implements AixAction {
    readonly type = ProfileActionTypes.LOAD_PROFILE_FROM_STORAGE;
}

/**
 * Password Reset;
 */
export class ProfilePasswordResetTypes {
    static readonly PASSWORD_RESET = type('[Profile] PasswordReset');
    static readonly PASSWORD_RESET_SUCCESS = type('[Profile] PasswordReset Success');
    static readonly PASSWORD_RESET_FAIL = type('[Profile] PasswordReset Failure');
}
export type ProfilePasswordResetActions =
    | ProfilePasswordReset
    | ProfilePasswordResetSuccess
    | ProfilePasswordResetFailure;

export class ProfilePasswordReset implements AixAction {
    readonly type = ProfilePasswordResetTypes.PASSWORD_RESET;

    constructor() {}
}
export class ProfilePasswordResetSuccess implements AixAction {
    readonly type = ProfilePasswordResetTypes.PASSWORD_RESET_SUCCESS;

    constructor(public payload: boolean) {}
}
export class ProfilePasswordResetFailure implements AixAction {
    readonly type = ProfilePasswordResetTypes.PASSWORD_RESET_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

/**
 * MFA Reset
 */
export class ResetMFATypes {
    static readonly RESET_MFA = type('[Profile] ResetMFA');
    static readonly RESET_MFA_SUCCESS = type('[Profile] ResetMFA Success');
    static readonly RESET_MFA_FAIL = type('[Profile] ResetMFA Failure');
}
export type ResetMFAActions = ResetMFA | ResetMFASuccess | ResetMFAFailure;

export class ResetMFA implements AixAction {
    readonly type = ResetMFATypes.RESET_MFA;

    constructor() {}
}
export class ResetMFASuccess implements AixAction {
    readonly type = ResetMFATypes.RESET_MFA_SUCCESS;

    constructor(public payload: boolean) {}
}
export class ResetMFAFailure implements AixAction {
    readonly type = ResetMFATypes.RESET_MFA_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}

/**
 * Load Firms (including shared)
 */
export class LoadFirmsActionTypes {
    static readonly LOAD_FIRMS = type('[Profile] Load Firms');
    static readonly LOAD_FIRMS_SUCCESS = type('[Profile] Load Firms Success');
    static readonly LOAD_FIRMS_FAIL = type('[Profile] Load Firms Failure');
}

export type LoadFirmsActions = LoadFirmsAction | LoadFirmsSuccessAction | LoadFirmsFailAction;

export class LoadFirmsAction implements AixAction {
    readonly type = LoadFirmsActionTypes.LOAD_FIRMS;

    constructor() {}
}

export class LoadFirmsSuccessAction implements AixAction {
    readonly type = LoadFirmsActionTypes.LOAD_FIRMS_SUCCESS;

    constructor(public payload: SharedExternalFirm[]) {}
}

export class LoadFirmsFailAction implements AixAction {
    readonly type = LoadFirmsActionTypes.LOAD_FIRMS_FAIL;

    constructor(public payload: { error: ErrorWrapper }) {}
}
