import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { OrderCancelEnvelopeActions, OrderCancelEnvelopeActionTypes } from './actions';
import { ErrorWrapper } from '@trade-platform/ui-shared';

export type OrderCancelEnvelopeState = RemoteData<boolean, ErrorWrapper>;

export const orderCancelEnvelopeReducer = (
    state: OrderCancelEnvelopeState = notAsked(),
    action: OrderCancelEnvelopeActions
): OrderCancelEnvelopeState => {
    switch (action.type) {
        case OrderCancelEnvelopeActionTypes.ORDER_CANCEL_ENVELOPE:
            return inProgress(false);
        case OrderCancelEnvelopeActionTypes.ORDER_CANCEL_ENVELOPE_SUCCESS:
            return success(action.payload);
        case OrderCancelEnvelopeActionTypes.ORDER_CANCEL_ENVELOPER_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
