import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-progress',
    templateUrl: './progress.html',
    styleUrls: ['./progress.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgStyle, AixDataTestingDirective]
})
export class AixProgressComponent implements OnInit {
    progressN = 0;
    @Input()
    set progress(progress: number) {
        this.progressN = Math.max(Math.floor(progress), 0);
        this.updateProgress();
    }
    @Input()
    type: 'linear' | 'circular' = 'linear';
    @Input()
    sqSize = 120;
    @Input()
    strokeLinecap: 'round' | 'butt' = 'round';
    @Input()
    strokeWidth = 8;
    @Input()
    fontSize = 24;
    @Input()
    subtext = true;

    innerStrokeWidth: number;
    radius: number;
    viewBox: string;
    dashArray: number;
    dashOffset: number;
    subtextPosition: string;

    constructor() {}

    ngOnInit() {
        this.updateProgress();
    }

    updateProgress() {
        this.innerStrokeWidth = this.strokeWidth;
        this.radius = (this.sqSize - this.strokeWidth) / 2;
        this.viewBox = `0 0 ${this.sqSize} ${this.sqSize}`;
        this.dashArray = this.radius * Math.PI * 2;
        this.dashOffset = this.dashArray - (this.dashArray * this.progressN) / 100;
        this.subtextPosition = this.subtext
            ? String(Math.ceil(this.sqSize / 2 + this.fontSize / 4.1667))
            : String(Math.ceil(this.sqSize / 2 + this.fontSize / 4.1667) + this.fontSize);
    }
}
