import { AixAction, ErrorWrapper, ProfileRepCode, type } from '@trade-platform/ui-shared';

export class RepCodesByAdvisorActionTypes {
    static readonly LOAD_REPCODES_BY_ADVISOR = type(
        '[Account Registrations] Load RepCodes By Advisor'
    );
    static readonly LOAD_REPCODES_BY_ADVISOR_SUCCESS = type(
        '[Account Registrations] Load RepCodes By Advisor Success'
    );
    static readonly LOAD_REPCODES_BY_ADVISOR_FAILURE = type(
        '[Account Registrations] Load RepCodes By Advisor Failure'
    );
}

export type RepCodesByAdvisorActions =
    | LoadRepCodesByAdvisorAction
    | LoadRepCodesByAdvisorSuccessAction
    | LoadRepCodesByAdvisorFailureAction;

// Load RepCodes by Advisor
export class LoadRepCodesByAdvisorAction implements AixAction {
    readonly type = RepCodesByAdvisorActionTypes.LOAD_REPCODES_BY_ADVISOR;

    constructor(public payload: string) {}
}

export class LoadRepCodesByAdvisorSuccessAction implements AixAction {
    readonly type = RepCodesByAdvisorActionTypes.LOAD_REPCODES_BY_ADVISOR_SUCCESS;

    constructor(public payload: ProfileRepCode[]) {}
}

export class LoadRepCodesByAdvisorFailureAction implements AixAction {
    readonly type = RepCodesByAdvisorActionTypes.LOAD_REPCODES_BY_ADVISOR_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
