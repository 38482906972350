import { Pipe, PipeTransform } from '@angular/core';
import { OrdersStatusHistory } from '@trade-platform/ui-shared';

@Pipe({
    name: 'aixStatusHistory',
    standalone: true
})
export class AixStatusHistoryPipe implements PipeTransform {
    // Status history data is saved as an audit log after successful updates, while the status history screen is supposed to show when an order leaves queues
    // This transform turns data points of when an order enters a queue into data points of when an order left a queue by shifting the event timestamp but leaving the status/role
    transform(data: OrdersStatusHistory[]): any {
        if (!data) {
            return [];
        }
        return data.map(statusHistory => {
            let updatedByType = 'N/A';
            if (statusHistory.updatedByType === 'firm') {
                updatedByType = 'Firm';
            } else if (statusHistory.updatedByType === 'holdingOption') {
                updatedByType = 'Custodian';
            } else if (statusHistory.updatedByType === 'fundSponsor') {
                updatedByType = 'Fund Sponsor';
            }
            return {
                ...statusHistory,
                updatedByType
            };
        });
    }
}
