import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { Observable } from 'rxjs';

@Injectable()
export class LogoutService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    logout(): Observable<any> {
        return this.http.post<any>(`${this.environment.api}me/logout`, null);
    }
}
