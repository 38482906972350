import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// validation function
export function validateEINFactory(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        let result = null;
        const einRegExp = /(^(\d|\*){2}-?(\d|\*){7}$|^XX-XXXXXXX$)/g;
        const isValid = c.value === null || c.value === '' || einRegExp.exec(c.value);

        if (!isValid) {
            result = {
                aixEIN: {
                    valid: false
                }
            };
        }

        return result;
    };
}
