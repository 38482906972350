import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { LatestActionsService } from '@trade-platform/ui-shared';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    defaultHandler: ErrorHandler;

    constructor(
        public latestActionsService: LatestActionsService,
        @Inject(ENVIRONMENT) private readonly environment: IEnvironment
    ) {
        if (this.environment.sentry.reportToSentry) {
            Sentry.init({
                dsn: `${this.environment.sentry.dsn}`,
                environment: `${this.environment.sentry.environment}`,
                release: this.environment.appVersion,
                ignoreErrors: ['ResizeObserver loop limit exceeded'],
                integrations: [Sentry.rewriteFramesIntegration()] as any[],
                beforeSend(event: Sentry.ErrorEvent) {
                    if (event.request?.data) delete event.request.data;
                    if (event.extra) delete event.extra;
                    if (event.user) delete event.user;
                    return event;
                }
            });
        }
        this.defaultHandler = new ErrorHandler();
    }

    handleError(error: any): void {
        if (!this.environment.sentry.swallowExceptions) {
            this.defaultHandler.handleError(error);
        }
        if (this.environment.sentry.reportToSentry) {
            this.latestActionsService.setCurrentSentryContext();
            Sentry.captureException(
                error && error.hasOwnProperty('originalError') ? error.originalError : error
            );
        }
    }
}
