import { AixAction, ErrorWrapper, OrderIdPayload, type } from '@trade-platform/ui-shared';

export class OrderSubmitActionTypes {
    static readonly ORDER_SUBMIT = type('[Order Submit] Order Submit');
    static readonly ORDER_SUBMIT_SUCCESS = type('[Order Submit] Order Submit Success');
    static readonly ORDER_SUBMIT_FAILURE = type('[Order Submit] Order Submit Failure');
}

export type OrderSubmitActions = OrderSubmit | OrderSubmitSuccess | OrderSubmitFailure;

export class OrderSubmit implements AixAction {
    readonly type = OrderSubmitActionTypes.ORDER_SUBMIT;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderSubmitSuccess implements AixAction {
    readonly type = OrderSubmitActionTypes.ORDER_SUBMIT_SUCCESS;
}

export class OrderSubmitFailure implements AixAction {
    readonly type = OrderSubmitActionTypes.ORDER_SUBMIT_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
