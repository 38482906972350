import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, UserByAdvisor, UsersTrainingResponse } from '@trade-platform/ui-shared';
import { UsersByAdvisorActions, UsersByAdvisorActionTypes } from './actions';

/**
 * Account Registrations
 */

export type UsersByAdvisorState = RemoteData<UserByAdvisor[], ErrorWrapper>;
const usersInitialState = notAsked<UserByAdvisor[], ErrorWrapper>();

export function usersByAdvisorReducer(
    state: UsersByAdvisorState = usersInitialState,
    action: UsersByAdvisorActions
): UsersByAdvisorState {
    switch (action.type) {
        case UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR:
            return inProgress([] as UserByAdvisor[]);
        case UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR_SUCCESS:
            return success(action.payload);
        case UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type UsersTrainingState = RemoteData<UsersTrainingResponse, ErrorWrapper>;
const trainingInitialState = notAsked<UsersTrainingResponse, ErrorWrapper>();

export function usersTrainingReducer(
    state: UsersTrainingState = trainingInitialState,
    action: UsersByAdvisorActions
): UsersTrainingState {
    switch (action.type) {
        case UsersByAdvisorActionTypes.LOAD_USERS_TRAINING:
            return inProgress({} as UsersTrainingResponse);
        case UsersByAdvisorActionTypes.LOAD_USERS_TRAINING_SUCCESS:
            return success(action.payload);
        case UsersByAdvisorActionTypes.LOAD_USERS_TRAINING_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export type AdvisorsByFirmUsersState = RemoteData<UserByAdvisor[], ErrorWrapper>;
const advisorsInitialState = notAsked<UserByAdvisor[], ErrorWrapper>();

export function advisorsByFirmUserReducer(
    state: AdvisorsByFirmUsersState = advisorsInitialState,
    action: UsersByAdvisorActions
): AdvisorsByFirmUsersState {
    switch (action.type) {
        case UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR:
            return inProgress([] as UserByAdvisor[]);
        case UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR_SUCCESS:
            return success(action.payload);
        case UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
