import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// validation function
export function validatePhoneFactory(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        let result = null;

        if (c.value && c.value.length > 0) {
            const phone = c.value.replace(/[^0-9]/g, '');
            if (phone.length !== 10) {
                result = {
                    validatePhone: {
                        valid: false
                    }
                };
            }
        }

        return result;
    };
}
