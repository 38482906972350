import { ChangeDetectorRef, Directive, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@trade-platform/ui-shared';
import { Observable } from 'rxjs';

@Directive({
    standalone: true,
    selector: '[bannerStyles]'
})
export class BannerStylesDirective {
    @HostBinding('style.padding-top') paddingTop: string;
    @HostBinding('style.transition') transition = 'padding-top 0.2s ease-in-out';

    bannerOffsetPx$: Observable<number>;
    baseOffsetPx = 0;

    constructor(private store: Store<AppState>, public ref: ChangeDetectorRef) {
        this.bannerOffsetPx$ = this.store.select(state => state.styles.bannerOffsetPx);

        this.bannerOffsetPx$.subscribe((px: number) => {
            this.paddingTop = `${this.baseOffsetPx + px}px`;
            ref.markForCheck();
        });
    }
}
