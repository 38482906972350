import { JsonDecoder } from 'ts.data.json';
import {
    OrderApproval,
    OrderApprovalConfig,
    OrderApprovalResponse,
    OrderApprovalStartResponse,
    OrderCurrentApproval
} from '../../models/order-approve/model';

export const orderApprovalConfigDecoder = JsonDecoder.object<OrderApprovalConfig>(
    {
        id: JsonDecoder.number,
        name: JsonDecoder.string,
        sequence: JsonDecoder.number,
        role: JsonDecoder.string,
        firmId: JsonDecoder.optional(JsonDecoder.number),
        custodianId: JsonDecoder.optional(JsonDecoder.number),
        fundSponsorId: JsonDecoder.optional(JsonDecoder.number)
    },
    'OrderApprovalConfig'
);

export const orderApprovalDecoder = JsonDecoder.object<OrderApproval>(
    {
        id: JsonDecoder.number,
        approved: JsonDecoder.optional(JsonDecoder.boolean),
        userId: JsonDecoder.optional(JsonDecoder.string),
        timestamp: JsonDecoder.optional(JsonDecoder.string),
        config: orderApprovalConfigDecoder
    },
    'OrderApproval'
);

export const orderApprovalsDecoder = JsonDecoder.array<OrderApproval>(
    orderApprovalDecoder,
    'OrderApproval[]'
);

export const orderCurrentApprovalDecoder = JsonDecoder.object<OrderCurrentApproval>(
    {
        id: JsonDecoder.number,
        type: JsonDecoder.string,
        roles: JsonDecoder.array<string>(JsonDecoder.string, 'string[]'),
        actingEntityType: JsonDecoder.string,
        actingEntityId: JsonDecoder.number,
        additionalData: JsonDecoder.optional(JsonDecoder.succeed)
    },
    'OrderCurrentApproval'
);

export const orderApprovalResponseDecoder = JsonDecoder.object<OrderApprovalResponse>(
    {
        id: JsonDecoder.optional(JsonDecoder.number),
        approved: JsonDecoder.optional(JsonDecoder.boolean),
        completedAt: JsonDecoder.optional(JsonDecoder.string),
        configId: JsonDecoder.optional(JsonDecoder.number)
    },
    'OrderApprovalResponse'
);

export const orderApprovalStartResponseDecoder = JsonDecoder.optional(
    JsonDecoder.object<OrderApprovalStartResponse>(
        {
            id: JsonDecoder.number,
            orderId: JsonDecoder.string,
            configId: JsonDecoder.number,
            updatedAt: JsonDecoder.string,
            createdAt: JsonDecoder.string,
            approved: JsonDecoder.optional(JsonDecoder.boolean),
            userId: JsonDecoder.optional(JsonDecoder.string),
            completedAt: JsonDecoder.optional(JsonDecoder.string),
            deletedAt: JsonDecoder.optional(JsonDecoder.string)
        },
        'OrderApprovalStartResponse'
    )
);
