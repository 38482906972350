import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import { RegisterActions, RegisterActionTypes } from './actions';
import { RegisterToken } from '../../models/register/model';

export type RegisterTokenState = RemoteData<RegisterToken, ErrorWrapper>;

export function registerTokenReducer(
    state: RegisterTokenState = notAsked(),
    action: RegisterActions
): RegisterTokenState {
    switch (action.type) {
        case RegisterActionTypes.SET_REGISTER:
            return success(action.payload);
        case RegisterActionTypes.UNLOAD_REGISTER:
            return notAsked();
        default:
            return state;
    }
}

export type RegisterState = RemoteData<null, ErrorWrapper>;

export function registerReducer(
    state: RegisterState = notAsked(),
    action: RegisterActions
): RegisterState {
    switch (action.type) {
        case RegisterActionTypes.PING_REGISTER:
            return inProgress(null);
        case RegisterActionTypes.PING_REGISTER_SUCCESS:
            return success(null);
        case RegisterActionTypes.PING_REGISTER_FAILURE:
            return failure(action.payload.error);
        case RegisterActionTypes.SEND_REGISTER:
            return inProgress(null);
        case RegisterActionTypes.SEND_REGISTER_SUCCESS:
            return success(null);
        case RegisterActionTypes.SEND_REGISTER_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
