import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { NgStyle } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

export interface AixMargins {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

@Component({
    selector: 'aix-loading',
    templateUrl: './aix-loading.component.html',
    styleUrls: ['./aix-loading.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixDataTestingDirective, NgStyle]
})
export class AixLoadingComponent {
    relative = input(false);
    transparent = input(false);
    size = input(40);
    style = input<Record<string, unknown>>({});
    margin = input<AixMargins>();
    negative = input(false);

    constructor() {}
}
