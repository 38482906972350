@if ((isLoading$ | async)) {
<aix-loading></aix-loading>
}

<aix-modal
    #logOutModal
    [buttonOptions]="['Log Out']"
    [closeable]="false"
    [modalTitle]="'Account Setup Incomplete'"
    (optionSelected)="logOut()"
>
    <ng-template>
        <p class="u-mb16">
            Please contact your system administrator in order to complete your account setup.
        </p>
    </ng-template>
</aix-modal>
