import {
    Account,
    AccountRegistration,
    AccountRegistrationByAdvisorPayload,
    AccountRegistrationByAdvisorReducerSuffix,
    AccountRegistrationsPayload,
    AixAction,
    ErrorWrapper,
    Page,
    RegistrationWithPositions,
    type
} from '@trade-platform/ui-shared';

export class AccountRegistrationsActionTypes {
    static readonly LOAD_ACCOUNT_REGISTRATIONS = type(
        '[Account Registrations] Load Account Registrations'
    );
    static readonly LOAD_ACCOUNT_REGISTRATIONS_SUCCESS = type(
        '[Account Registrations] Load Account Registrations Success'
    );
    static readonly LOAD_ACCOUNT_REGISTRATIONS_FAILURE = type(
        '[Account Registrations] Load Account Registrations Failure'
    );

    static readonly LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR = type(
        '[Account Registrations] Load Account Registrations By Advisor'
    );
    static readonly LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR_SUCCESS = type(
        '[Account Registrations] Load Account Registrations By Advisor Success'
    );
    static readonly LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR_FAILURE = type(
        '[Account Registrations] Load Account Registrations By Advisor Failure'
    );

    static readonly LOAD_REGISTRATION_WITH_POSITIONS = type(
        '[Account Registrations] Load Registration With Positions'
    );
    static readonly LOAD_REGISTRATION_WITH_POSITIONS_SUCCESS = type(
        '[Account Registrations] Load Registration With Positions Success'
    );
    static readonly LOAD_REGISTRATION_WITH_POSITIONS_FAILURE = type(
        '[Account Registrations] Load Registration With Positions Failure'
    );
}

export type AccountRegistrationsActions =
    | LoadAccountRegistrations
    | LoadAccountRegistrationsSuccess
    | LoadAccountRegistrationsFailure
    | LoadRegistrationWithPositions
    | LoadAccountRegistrationsByAdvisorAction
    | LoadAccountRegistrationsByAdvisorSuccessAction
    | LoadAccountRegistrationsByAdvisorFailureAction
    | LoadRegistrationWithPositionsSuccess
    | LoadRegistrationWithPositionsFailure;

// Load Account Overview Accounts
export class LoadAccountRegistrations implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS;

    constructor(public payload: AccountRegistrationsPayload) {}
}

export class LoadAccountRegistrationsSuccess implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_SUCCESS;

    constructor(public payload: Page<AccountRegistration>) {}
}

export class LoadAccountRegistrationsFailure implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// Load Account Registrations by Advisor
export class LoadAccountRegistrationsByAdvisorAction implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR;

    constructor(public payload: AccountRegistrationByAdvisorPayload) {}
}

export class LoadAccountRegistrationsByAdvisorSuccessAction implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR_SUCCESS;

    constructor(
        public payload: {
            registrations: Account[];
            reducerSuffix: AccountRegistrationByAdvisorReducerSuffix;
        }
    ) {}
}

export class LoadAccountRegistrationsByAdvisorFailureAction implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_ACCOUNT_REGISTRATIONS_BY_ADVISOR_FAILURE;

    constructor(
        public payload: {
            error: ErrorWrapper;
            reducerSuffix: AccountRegistrationByAdvisorReducerSuffix;
        }
    ) {}
}

// Load Account Registration With Positions by ID
export class LoadRegistrationWithPositions implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_REGISTRATION_WITH_POSITIONS;

    constructor(public payload: string) {}
}

export class LoadRegistrationWithPositionsSuccess implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_REGISTRATION_WITH_POSITIONS_SUCCESS;

    constructor(public payload: RegistrationWithPositions) {}
}

export class LoadRegistrationWithPositionsFailure implements AixAction {
    readonly type = AccountRegistrationsActionTypes.LOAD_REGISTRATION_WITH_POSITIONS_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
