import {
    AixAction,
    ErrorWrapper,
    OrderDocumentDeclinePayload,
    type
} from '@trade-platform/ui-shared';

export class OrderDocumentUndeclineActionTypes {
    static readonly ORDER_DOCUMENT_UNDECLINE = type(
        '[Order Document Undecline] Order Document Undecline'
    );
    static readonly ORDER_DOCUMENT_UNDECLINE_SUCCESS = type(
        '[Order Document Undecline] Order Document Undecline Success'
    );
    static readonly ORDER_DOCUMENT_UNDECLINE_FAILURE = type(
        '[Order Document Undecline] Order Document Undecline Failure'
    );
}

export type OrderDocumentUndeclineActions =
    | OrderDocumentUndecline
    | OrderDocumentUndeclineSuccess
    | OrderDocumentUndeclineFailure;

export class OrderDocumentUndecline implements AixAction {
    readonly type = OrderDocumentUndeclineActionTypes.ORDER_DOCUMENT_UNDECLINE;

    constructor(public payload: OrderDocumentDeclinePayload) {}
}

export class OrderDocumentUndeclineSuccess implements AixAction {
    readonly type = OrderDocumentUndeclineActionTypes.ORDER_DOCUMENT_UNDECLINE_SUCCESS;
}

export class OrderDocumentUndeclineFailure implements AixAction {
    readonly type = OrderDocumentUndeclineActionTypes.ORDER_DOCUMENT_UNDECLINE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
