import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogService } from './utils/log-service';
import { AixConsolePipe } from './utils/pipes/console';
import { AixMaskSSNPipe } from './utils/pipes/mask-ssn';
import { IsJustPipe, IsNothingPipe, WithDefaultPipe } from './utils/pipes/maybe.pipes';
import { AixRemoveTrailingZeroesPipe } from './utils/pipes/remove-trailing-zeroes';
import { AixSanitizePipe } from './utils/pipes/sanitize';
import { AixTruncatePipe } from './utils/pipes/truncate';
import { AixPluralizePipe } from './utils/pipes/pluralize';
import { SortByPipe } from './utils/pipes/sortBy.pipe';
import { AixKebabCasePipe } from './utils/pipes/kebab-case-pipe';
import { AixMaskValuePipe } from './utils/pipes/mask-value.pipe';
import { AixCitizenValuePipe } from './utils/pipes/citizen-value.pipe';

const LIB_PROVIDERS = [
    AixConsolePipe,
    AixMaskSSNPipe,
    AixRemoveTrailingZeroesPipe,
    AixSanitizePipe,
    AixTruncatePipe,
    LogService,
    WithDefaultPipe,
    IsJustPipe,
    IsNothingPipe,
    AixPluralizePipe,
    AixKebabCasePipe,
    AixMaskValuePipe,
    AixCitizenValuePipe
];

const DIRECTIVES: NgModule['exports'] = [
    // Add directives
];

const PIPES = [
    // Add pipes here
    AixConsolePipe,
    AixMaskSSNPipe,
    AixRemoveTrailingZeroesPipe,
    AixSanitizePipe,
    AixTruncatePipe,
    WithDefaultPipe,
    IsJustPipe,
    IsNothingPipe,
    SortByPipe,
    AixKebabCasePipe,
    AixMaskValuePipe,
    AixCitizenValuePipe
];

const COMPONENTS: NgModule['exports'] = [
    // Add components
];

@NgModule({
    imports: [CommonModule, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
    exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    providers: LIB_PROVIDERS
})
export class AixUiUtilsModule {}
