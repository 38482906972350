import { ActionReducer } from '@ngrx/store';
import { isRemoteData, notAsked } from 'ngx-remotedata';
import { ResetStoreActionTypes } from './actions';

// List of states that should not be reset;
const whitelistedStates = ['profile', 'router'];

/**
 * Meta reducer that will listen for a reset store states action;
 * When it catches a reset store states action, it will reset all states to NotAsked (except for the whitelisted states);
 * @param reducer {ActionReducer<any>}
 */
export function resetReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        // Reset all states to NotAsked;
        if (action.type === ResetStoreActionTypes.RESET_STORE_STATES) {
            for (const prop in state) {
                if (isRemoteData(state[prop]) && whitelistedStates.indexOf(prop) === -1) {
                    state[prop] = notAsked();
                }
            }
        }
        return reducer(state, action);
    };
}
