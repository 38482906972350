<div class="container footnotes">
    @for (note of (footnoteService.items$ | async);track note) {
    <div id="aix-footnote-content-{{ note.id }}" class="aix-footnote-content">
        <sup class="aix-footnote br-link" (click)="returnToFootnote(note.id)">{{ note.id }}</sup>
        <span>
            {{ note.content }}
            <a class="hide-if-print br-link" (click)="returnToFootnote(note.id)">Return</a>
        </span>
    </div>
    }
</div>
