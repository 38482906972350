import {
    BaseOrder,
    DocumentFile,
    DocumentViewerActionStringTypes,
    isFeatureEnabled
} from '@trade-platform/ui-shared';
import {
    approvalConfigType,
    approvalStatusLabel,
    fileType,
    orderStatus
} from '@trade-platform/lib-enums';
import { get } from 'lodash-es';

export const getAction = (order: BaseOrder): DocumentViewerActionStringTypes => {
    switch (order.status) {
        case orderStatus.pendingFirmApproval:
        case orderStatus.pendingCustodianApproval:
        case orderStatus.pendingFundSponsorApproval:
            if (
                order.currentApproval &&
                order.currentApproval.type === approvalConfigType.signature
            ) {
                return order.wetSign ? 'wetSign' : 'sign';
            } else if (
                order.currentApproval &&
                (order.currentApproval.type === approvalConfigType.authorization ||
                    order.currentApproval.type === approvalConfigType.integration)
            ) {
                return 'approve';
            } else {
                return 'resolve';
            }
        case orderStatus.draft:
        case orderStatus.formsComplete:
            return 'none';
        case orderStatus.readyToSend:
        case orderStatus.pendingSignatures:
        case orderStatus.pendingApprovalProcess:
        case orderStatus.pendingSubmissionToTransferAgent:
            return 'signed';
        case orderStatus.pendingPresignatureReview:
            return 'approve';
        case orderStatus.submitted:
            return isFeatureEnabled('editSubmittedOrders') ? 'resolve' : 'none';
        case orderStatus.nigo:
        case orderStatus.needsClarification:
        case orderStatus.offline:
        default:
            return 'resolve';
    }
};

export const getApprovalId = (order: BaseOrder): number | null => {
    let id = null;
    if (order && order.approvals && order.approvals.length > 0) {
        order.approvals.forEach(approval => {
            // complete === null = pre-created approval;
            if (approval && !approval.approved) {
                id = approval.id;
            }
        });
    }

    return id;
};

export const filterFiles = (order: BaseOrder) => {
    order.files.forEach(file => {
        const orderForms = order.forms[file.formId as string];

        if (orderForms) {
            // Get screen names from forms
            file.screenName = orderForms.name;
            file.index = orderForms.index;
        } else if (file.supplementalFileTypeId && file.supplementalFileType) {
            if (file.contextMap) {
                const assigneeName = get(order, file.contextMap);
                if (assigneeName) {
                    file.screenName = `${file.supplementalFileType.name} for ${assigneeName}`;
                } else {
                    file.screenName = file.supplementalFileType.name;
                }
            } else {
                file.screenName = file.supplementalFileType.name;
            }
            // supporting documents do not have a form
            file.index = 9999999;
        } else {
            file.screenName = file.name;
            file.index = 9999999;
        }
    });

    return order.files.sort((a, b): number => (a.index as number) - (b.index as number));
};

export const getFilesToDisplay = (order: BaseOrder) => {
    let filesToDisplay: DocumentFile[] = [];

    if (order && order.files) {
        filesToDisplay = filterFiles(order);
    }

    // Only display supplemental file uploads when on order step 2 (forms complete);
    if (order && order.status === orderStatus.formsComplete) {
        filesToDisplay = filesToDisplay.filter(
            (file: DocumentFile) =>
                file.type === fileType.supplemental || file.type === fileType.unassignedSupplemental
        );
    }

    const formFiles: DocumentFile[] = filesToDisplay.filter(
        (file: DocumentFile) => file.type === fileType.form
    );
    let supplementalFiles: DocumentFile[] = filesToDisplay.filter(
        (file: DocumentFile) => file.type !== fileType.form
    );

    supplementalFiles = supplementalFiles.sort((a, b) => {
        let order;
        const aName = a?.supplementalFileType?.name || a?.screenName || a?.name;
        const bName = b?.supplementalFileType?.name || b?.screenName || b?.name;

        // First order by file type then by file name
        if (a.type !== b.type) {
            order = b.type === fileType.supplemental ? 1 : -1;
        } else if (aName && bName) {
            order = aName > bName ? 1 : -1;
        } else {
            order = aName > bName ? -1 : 1;
        }

        return order;
    });
    return [...formFiles, ...supplementalFiles];
};

export const isApproval = (statusLabel: string): boolean => {
    return statusLabel === approvalStatusLabel.needApproval;
};

export const isSignature = (statusLabel: string): boolean => {
    return statusLabel === approvalStatusLabel.needSignature;
};
