import { keyCode } from './navigator';

/**
 * Dictionary of Keyboard `keyCode` and `key` values
 */
export namespace Keyboard {
    export enum KeyCode {
        Alt = 18,
        Control = 17,
        Shift = 16,
        Enter = 13,
        Tab = 9,
        ArrowDown = 40,
        ArrowLeft = 37,
        ArrowRight = 39,
        ArrowUp = 38,
        Backspace = 8,
        Delete = 46,
        Spacebar = 32,
        Escape = 27,
        OSXMetaLeft = 91,
        OSXMetaRight = 93,
        Negative = 189,
        // Row Numbers 0-9
        ROW_0 = 48,
        ROW_1 = 49,
        ROW_2 = 50,
        ROW_3 = 51,
        ROW_4 = 52,
        ROW_5 = 53,
        ROW_6 = 54,
        ROW_7 = 55,
        ROW_8 = 56,
        ROW_9 = 57,
        // Numpad numbers 0-9
        PAD_0 = 96,
        PAD_1 = 97,
        PAD_2 = 98,
        PAD_3 = 99,
        PAD_4 = 100,
        PAD_5 = 101,
        PAD_6 = 102,
        PAD_7 = 103,
        PAD_8 = 104,
        PAD_9 = 105
    }

    export const Key = {
        Alt: ['Alt'] as ReadonlyArray<string>,
        Control: ['Control'] as ReadonlyArray<string>,
        Shift: ['Shift'] as ReadonlyArray<string>,
        Enter: ['Enter'] as ReadonlyArray<string>,
        Tab: ['Tab'] as ReadonlyArray<string>,
        ArrowDown: ['ArrowDown', 'Down'] as ReadonlyArray<string>,
        ArrowLeft: ['ArrowLeft', 'Left'] as ReadonlyArray<string>,
        ArrowRight: ['ArrowRight', 'Right'] as ReadonlyArray<string>,
        ArrowUp: ['ArrowUp', 'Up'] as ReadonlyArray<string>,
        Backspace: ['Backspace'] as ReadonlyArray<string>,
        Delete: ['Delete', 'Del'] as ReadonlyArray<string>,
        Spacebar: ['Spacebar', ' '] as ReadonlyArray<string>,
        Escape: ['Escape', 'Esc'] as ReadonlyArray<string>,
        OSXMeta: ['Meta'] as ReadonlyArray<string>,
        Negative: ['-'] as ReadonlyArray<string>,
        // Row Numbers 0-9
        ROW_0: ['0'] as ReadonlyArray<string>,
        ROW_1: ['1'] as ReadonlyArray<string>,
        ROW_2: ['2'] as ReadonlyArray<string>,
        ROW_3: ['3'] as ReadonlyArray<string>,
        ROW_4: ['4'] as ReadonlyArray<string>,
        ROW_5: ['5'] as ReadonlyArray<string>,
        ROW_6: ['6'] as ReadonlyArray<string>,
        ROW_7: ['7'] as ReadonlyArray<string>,
        ROW_8: ['8'] as ReadonlyArray<string>,
        ROW_9: ['9'] as ReadonlyArray<string>,
        // Numpad numbers 0-9
        PAD_0: ['0'] as ReadonlyArray<string>,
        PAD_1: ['1'] as ReadonlyArray<string>,
        PAD_2: ['2'] as ReadonlyArray<string>,
        PAD_3: ['3'] as ReadonlyArray<string>,
        PAD_4: ['4'] as ReadonlyArray<string>,
        PAD_5: ['5'] as ReadonlyArray<string>,
        PAD_6: ['6'] as ReadonlyArray<string>,
        PAD_7: ['7'] as ReadonlyArray<string>,
        PAD_8: ['8'] as ReadonlyArray<string>,
        PAD_9: ['9'] as ReadonlyArray<string>
    };
}

/**
 * KeyCode matcher interface with some defaults.
 * If the default key codes don't satisfy your needs you can add you own matchers in the `default` key.
 */
export interface KeyCodeSwitch {
    Alt?: (event: KeyboardEvent) => void;
    Control?: (event: KeyboardEvent) => void;
    Shift?: (event: KeyboardEvent) => void;
    Enter?: (event: KeyboardEvent) => void;
    Tab?: (event: KeyboardEvent) => void;
    ArrowDown?: (event: KeyboardEvent) => void;
    ArrowLeft?: (event: KeyboardEvent) => void;
    ArrowRight?: (event: KeyboardEvent) => void;
    ArrowUp?: (event: KeyboardEvent) => void;
    Backspace?: (event: KeyboardEvent) => void;
    Delete?: (event: KeyboardEvent) => void;
    Spacebar?: (event: KeyboardEvent) => void;
    Escape?: (event: KeyboardEvent) => void;
    OSXCommand?: (event: KeyboardEvent) => void;
    Negative?: (event: KeyboardEvent) => void;
    other?: {
        [key: string]: {
            matcherFn: (event: KeyboardEvent) => boolean;
            onMatch: (event: KeyboardEvent) => void;
        };
    };
    default?: (event: KeyboardEvent) => void;
}

/**
 * Creates a KeyCodeSwitch type where the provided keys are required and non nullable
 * ```ts
 * const matcher: KeyCodeSwitchPick<'Alt'> = {
 *   Alt: () => {}
 * };
 * ```
 * Returns:
 * ```ts
 * KeyCodeSwitch {
 *   Alt: (event: KeyboardEvent) => void;
 *   Control?: (event: KeyboardEvent) => void;
 *   // (...)
 * }
 * ```
 */
export type KeyCodeSwitchPick<K extends keyof KeyCodeSwitch> = {
    [P in K]: NonNullable<KeyCodeSwitch[P]>;
};

/**
 * Executes a function based on the `keyCode` of the `KeyboardEvent`
 * A list of standard key codes: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 * A list of `key` fixes for older browsers: https://github.com/inexorabletash/polyfill/blob/master/keyboard.js#L584
 * This is helful too: https://keycode.info/
 *
 * @param event The KeyboardEvent to inspect
 * @param matcher The KeyCodeSwitch matcher
 */
export const keyboardEventSwitch = (matcher: KeyCodeSwitch) => {
    // Each if else statement tries to match the given event.key/keyCode against all possible standard/non-standadr codes for a given key

    const predicateToAction = (
        event: KeyboardEvent
    ): {
        predicate: () => boolean;
        action: () => void;
        name: string;
    }[] => [
        {
            predicate: () =>
                matcher.Alt !== undefined &&
                !![...Keyboard.Key.Alt, Keyboard.KeyCode.Alt].find(key => keyCode(event) === key),
            action: () => matcher.Alt?.call(null, event),
            name: 'Alt'
        },
        {
            predicate: () =>
                matcher.Control !== undefined &&
                !![...Keyboard.Key.Control, Keyboard.KeyCode.Control].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Control?.call(null, event),
            name: 'Control'
        },
        {
            predicate: () =>
                matcher.Shift !== undefined &&
                !![...Keyboard.Key.Shift, Keyboard.KeyCode.Shift].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Shift?.call(null, event),
            name: 'Shift'
        },
        {
            predicate: () =>
                matcher.Enter !== undefined &&
                !![...Keyboard.Key.Enter, Keyboard.KeyCode.Enter].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Enter?.call(null, event),
            name: 'Enter'
        },
        {
            predicate: () =>
                matcher.Tab !== undefined &&
                !![...Keyboard.Key.Tab, Keyboard.KeyCode.Tab].find(key => keyCode(event) === key),
            action: () => matcher.Tab?.call(null, event),
            name: 'Tab'
        },
        {
            predicate: () =>
                matcher.ArrowDown !== undefined &&
                !![...Keyboard.Key.ArrowDown, Keyboard.KeyCode.ArrowDown].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.ArrowDown?.call(null, event),
            name: 'ArrowDown'
        },
        {
            predicate: () =>
                matcher.ArrowLeft !== undefined &&
                !![...Keyboard.Key.ArrowLeft, Keyboard.KeyCode.ArrowLeft].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.ArrowLeft?.call(null, event),
            name: 'ArrowLeft'
        },
        {
            predicate: () =>
                matcher.ArrowRight !== undefined &&
                !![...Keyboard.Key.ArrowRight, Keyboard.KeyCode.ArrowRight].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.ArrowRight?.call(null, event),
            name: 'ArrowRight'
        },
        {
            predicate: () =>
                matcher.ArrowUp !== undefined &&
                !![...Keyboard.Key.ArrowUp, Keyboard.KeyCode.ArrowUp].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.ArrowUp?.call(null, event),
            name: 'ArrowUp'
        },
        {
            predicate: () =>
                matcher.Backspace !== undefined &&
                !![...Keyboard.Key.Backspace, Keyboard.KeyCode.Backspace].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Backspace?.call(null, event),
            name: 'Backspace'
        },
        {
            predicate: () =>
                matcher.Delete !== undefined &&
                !![...Keyboard.Key.Delete, Keyboard.KeyCode.Delete].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Delete?.call(null, event),
            name: 'Delete'
        },
        {
            predicate: () =>
                matcher.Spacebar !== undefined &&
                !![...Keyboard.Key.Spacebar, Keyboard.KeyCode.Spacebar].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Spacebar?.call(null, event),
            name: 'Spacebar'
        },
        {
            predicate: () =>
                matcher.Escape !== undefined &&
                !![...Keyboard.Key.Escape, Keyboard.KeyCode.Escape].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Escape?.call(null, event),
            name: 'Escape'
        },
        {
            predicate: () =>
                matcher.OSXCommand !== undefined &&
                !![
                    ...Keyboard.Key.OSXMeta,
                    Keyboard.KeyCode.OSXMetaLeft,
                    Keyboard.KeyCode.OSXMetaRight
                ].find(key => keyCode(event) === key) &&
                navigator.platform.substr(0, 3) === 'Mac',
            action: () => matcher.OSXCommand?.call(null, event),
            name: 'OSXCommand'
        },
        {
            predicate: () =>
                matcher.Negative !== undefined &&
                !![...Keyboard.Key.Negative, Keyboard.KeyCode.Negative].find(
                    key => keyCode(event) === key
                ),
            action: () => matcher.Negative?.call(null, event),
            name: 'Negative'
        },
        {
            predicate: () => {
                if (matcher.other !== undefined) {
                    const other = matcher.other;
                    return Object.keys(other).some(customKey => {
                        const { matcherFn } = other[customKey];
                        return matcherFn(event);
                    });
                }
                return false;
            },
            action: () => {
                if (matcher.other) {
                    const other = matcher.other;
                    Object.keys(other).forEach(customKey => {
                        const { matcherFn, onMatch } = other[customKey];
                        if (matcherFn(event) === true) {
                            onMatch(event);
                        }
                    });
                }
            },
            name: 'other'
        },
        {
            predicate: () => matcher.default !== undefined,
            action: () => {
                if (matcher.default) {
                    matcher.default(event);
                }
            },
            name: 'default'
        }
    ];

    return {
        withEvent(event: KeyboardEvent) {
            const match = predicateToAction(event).filter(p2a => p2a.predicate());
            if (match.length > 0) {
                match[0].action();
            }
        }
    };
};

/**
 * Returns true if the KeyboardEvent was originated from a numeric (0,1,2,3,4,5,6,7,8,9) key (from the row or keypad)
 *
 * @param event The KeyboardEvent
 */
export const isNumericKey = (event: KeyboardEvent): boolean =>
    !![
        Keyboard.KeyCode.PAD_0,
        Keyboard.KeyCode.PAD_1,
        Keyboard.KeyCode.PAD_2,
        Keyboard.KeyCode.PAD_3,
        Keyboard.KeyCode.PAD_4,
        Keyboard.KeyCode.PAD_5,
        Keyboard.KeyCode.PAD_6,
        Keyboard.KeyCode.PAD_7,
        Keyboard.KeyCode.PAD_8,
        Keyboard.KeyCode.PAD_9,
        Keyboard.KeyCode.ROW_0,
        Keyboard.KeyCode.ROW_1,
        Keyboard.KeyCode.ROW_2,
        Keyboard.KeyCode.ROW_3,
        Keyboard.KeyCode.ROW_4,
        Keyboard.KeyCode.ROW_5,
        Keyboard.KeyCode.ROW_6,
        Keyboard.KeyCode.ROW_7,
        Keyboard.KeyCode.ROW_8,
        Keyboard.KeyCode.ROW_9,
        ...Keyboard.Key.PAD_0,
        ...Keyboard.Key.PAD_1,
        ...Keyboard.Key.PAD_2,
        ...Keyboard.Key.PAD_3,
        ...Keyboard.Key.PAD_4,
        ...Keyboard.Key.PAD_5,
        ...Keyboard.Key.PAD_6,
        ...Keyboard.Key.PAD_7,
        ...Keyboard.Key.PAD_8,
        ...Keyboard.Key.PAD_9,
        ...Keyboard.Key.ROW_0,
        ...Keyboard.Key.ROW_1,
        ...Keyboard.Key.ROW_2,
        ...Keyboard.Key.ROW_3,
        ...Keyboard.Key.ROW_4,
        ...Keyboard.Key.ROW_5,
        ...Keyboard.Key.ROW_6,
        ...Keyboard.Key.ROW_7,
        ...Keyboard.Key.ROW_8,
        ...Keyboard.Key.ROW_9
    ].find(key => keyCode(event) === key);

/**
 * Returns true if the KeyboardEvent was originated from an arrow key
 *
 * @param event The KeyboardEvent
 */
export const isArrowKey = (event: KeyboardEvent): boolean =>
    !![
        Keyboard.KeyCode.ArrowLeft,
        Keyboard.KeyCode.ArrowRight,
        Keyboard.KeyCode.ArrowUp,
        Keyboard.KeyCode.ArrowDown,
        ...Keyboard.Key.ArrowLeft,
        ...Keyboard.Key.ArrowRight,
        ...Keyboard.Key.ArrowUp,
        ...Keyboard.Key.ArrowDown
    ].find(key => keyCode(event) === key);
