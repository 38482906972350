import { AixAction, ErrorWrapper, type } from '@trade-platform/ui-shared';
import { Brand, BrandingRequest } from '../../models/branding/model';

export class BrandingActionTypes {
    // Get Active Branding
    static readonly GET_ACTIVE_BRANDING = type('[Branding] Get Active Branding');
    static readonly GET_ACTIVE_BRANDING_SUCCESS = type('[Branding] Get Active Branding Success');

    // Load Branding
    static readonly LOAD_BRANDING = type('[Branding] Load Branding');
    static readonly LOAD_BRANDING_SUCCESS = type('[Branding] Load Branding Success');
    static readonly LOAD_BRANDING_FAILURE = type('[Branding] Load Branding Failure');

    // Apply Branding
    static readonly APPLY_BRANDING = type('[Branding] Apply Branding');
    static readonly APPLY_BRANDING_SUCCESS = type('[Branding] Apply Branding Success');
    static readonly APPLY_BRANDING_FAILURE = type('[Branding] Apply Branding Failure');

    // Save Branding
    static readonly SAVE_BRANDING = type('[Branding] Save Branding');
    static readonly SAVE_BRANDING_SUCCESS = type('[Branding] Save Branding Success');
    static readonly SAVE_BRANDING_FAILURE = type('[Branding] Save Branding Failure');

    // Preview Branding
    static readonly PREVIEW_BRANDING = type('[Branding] Preview Branding');
    static readonly PREVIEW_BRANDING_SUCCESS = type('[Branding] Preview Branding Sucesss');

    // Clear Preview Branding
    static readonly CLEAR_PREVIEW_BRANDING = type('[Branding] Clear Preview Branding');
    static readonly CLEAR_PREVIEW_BRANDING_SUCCESS = type(
        '[Branding] Clear Preview Branding Success'
    );

    // Get Active Preview Branding
    static readonly GET_ACTIVE_PREVIEW_BRANDING = type('[Branding] Get Active Preview Branding');
    static readonly GET_ACTIVE_PREVIEW_BRANDING_SUCCESS = type(
        '[Branding] Get Active Preview Branding Success'
    );
}

export type BrandingActions =
    | GetActiveBrandingAction
    | GetActiveBrandingSuccessAction
    | LoadBrandingAction
    | LoadBrandingSuccessAction
    | LoadBrandingFailureAction
    | ApplyBrandingAction
    | ApplyBrandingSuccessAction
    | ApplyBrandingFailureAction
    | SaveBrandingAction
    | SaveBrandingSuccessAction
    | SaveBrandingFailureAction
    | PreviewBrandingAction
    | PreviewBrandingSuccessAction
    | ClearPreviewBrandingAction
    | ClearPreviewBrandingSuccessAction
    | GetActivePreviewBrandingAction
    | GetActivePreviewBrandingSuccessAction;

// Get Active Branding
export class GetActiveBrandingAction implements AixAction {
    readonly type = BrandingActionTypes.GET_ACTIVE_BRANDING;

    constructor() {}
}
export class GetActiveBrandingSuccessAction implements AixAction {
    readonly type = BrandingActionTypes.GET_ACTIVE_BRANDING_SUCCESS;

    constructor(public payload: Brand) {}
}

// Load Branding
export class LoadBrandingAction implements AixAction {
    readonly type = BrandingActionTypes.LOAD_BRANDING;

    constructor() {}
}
export class LoadBrandingSuccessAction implements AixAction {
    readonly type = BrandingActionTypes.LOAD_BRANDING_SUCCESS;

    constructor(public payload: Brand) {}
}
export class LoadBrandingFailureAction implements AixAction {
    readonly type = BrandingActionTypes.LOAD_BRANDING_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// Apply Branding
export class ApplyBrandingAction implements AixAction {
    readonly type = BrandingActionTypes.APPLY_BRANDING;

    constructor() {}
}
export class ApplyBrandingSuccessAction implements AixAction {
    readonly type = BrandingActionTypes.APPLY_BRANDING_SUCCESS;

    constructor(public payload: Brand) {}
}
export class ApplyBrandingFailureAction implements AixAction {
    readonly type = BrandingActionTypes.APPLY_BRANDING_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// Save Branding
export class SaveBrandingAction implements AixAction {
    readonly type = BrandingActionTypes.SAVE_BRANDING;

    constructor(public payload: BrandingRequest) {}
}
export class SaveBrandingSuccessAction implements AixAction {
    readonly type = BrandingActionTypes.SAVE_BRANDING_SUCCESS;

    constructor(public payload: Brand) {}
}
export class SaveBrandingFailureAction implements AixAction {
    readonly type = BrandingActionTypes.SAVE_BRANDING_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// PreviewBranding
export class PreviewBrandingAction implements AixAction {
    readonly type = BrandingActionTypes.PREVIEW_BRANDING;

    constructor(public payload: Brand) {}
}
export class PreviewBrandingSuccessAction implements AixAction {
    readonly type = BrandingActionTypes.PREVIEW_BRANDING_SUCCESS;

    constructor(public payload: Brand) {}
}
export class ClearPreviewBrandingAction implements AixAction {
    readonly type = BrandingActionTypes.CLEAR_PREVIEW_BRANDING;

    constructor() {}
}
export class ClearPreviewBrandingSuccessAction implements AixAction {
    readonly type = BrandingActionTypes.CLEAR_PREVIEW_BRANDING_SUCCESS;

    constructor() {}
}
export class GetActivePreviewBrandingAction implements AixAction {
    readonly type = BrandingActionTypes.GET_ACTIVE_PREVIEW_BRANDING;

    constructor() {}
}
export class GetActivePreviewBrandingSuccessAction implements AixAction {
    readonly type = BrandingActionTypes.GET_ACTIVE_PREVIEW_BRANDING_SUCCESS;

    constructor(public payload: Brand) {}
}
