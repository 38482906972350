import { JsonDecoder } from 'ts.data.json';
import { OrderMarkNeedsClarificationResponse } from '../../models/order-needs-clarification/model';

export const orderMarkNeedsClarificationResponseDecoder =
    JsonDecoder.object<OrderMarkNeedsClarificationResponse>(
        {
            id: JsonDecoder.string,
            status: JsonDecoder.string
        },
        'OrderMarkNeedsActionResponse'
    );
