import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { OrderCancelActions, OrderCancelActionTypes } from './actions';
import { ErrorWrapper } from '@trade-platform/ui-shared';

export type OrderCancelState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderCancelReducer = (
    state: OrderCancelState = initialState,
    action: OrderCancelActions
): OrderCancelState => {
    switch (action.type) {
        case OrderCancelActionTypes.ORDER_CANCEL:
            return inProgress();
        case OrderCancelActionTypes.ORDER_CANCEL_SUCCESS:
            return success(null);
        case OrderCancelActionTypes.ORDER_CANCEL_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
