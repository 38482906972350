import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderCancelEnvelopeRequest } from '@trade-platform/ui-shared';

@Injectable()
export class OrderCancelEnvelopeService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    orderCancelEnvelopeService(payload: OrderCancelEnvelopeRequest): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.environment.api}orders/${payload.orderId}/cancelEnvelope`,
            {
                voidedReason: payload.voidedReason
            }
        );
    }
}
