import {
    AixAction,
    ErrorWrapper,
    OrderIdPayload,
    OrderMakeChangesResponse,
    type
} from '@trade-platform/ui-shared';

export class OrderMakeChangesActionTypes {
    static readonly ORDER_MAKE_CHANGES = type('[OrderMakeChanges] Make Changes');
    static readonly ORDER_MAKE_CHANGES_SUCCESS = type('[OrderMakeChanges] Make Changes Success');
    static readonly ORDER_MAKE_CHANGES_FAILURE = type('[OrderMakeChanges] Make Changes Failure');

    static readonly ORDER_TRIGGER_MAKE_CHANGES = type('[OrderMakeChanges] Trigger Make Changes');
}

export type OrderMakeChangesActions =
    | OrderMakeChangesAction
    | OrderMakeChangesSuccessAction
    | OrderMakeChangesFailureAction;

export class OrderMakeChangesAction implements AixAction {
    readonly type = OrderMakeChangesActionTypes.ORDER_MAKE_CHANGES;

    constructor(public payload: OrderIdPayload) {}
}
export class OrderMakeChangesSuccessAction implements AixAction {
    readonly type = OrderMakeChangesActionTypes.ORDER_MAKE_CHANGES_SUCCESS;

    constructor(public payload: OrderMakeChangesResponse) {}
}
export class OrderMakeChangesFailureAction implements AixAction {
    readonly type = OrderMakeChangesActionTypes.ORDER_MAKE_CHANGES_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class OrderTriggerMakeChangesAction implements AixAction {
    readonly type = OrderMakeChangesActionTypes.ORDER_TRIGGER_MAKE_CHANGES;

    constructor() {}
}
