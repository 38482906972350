import {
    AixAction,
    ErrorWrapper,
    type,
    UserByAdvisor,
    UsersTrainingBody,
    UsersTrainingResponse
} from '@trade-platform/ui-shared';

export class UsersByAdvisorActionTypes {
    static readonly LOAD_USERS_BY_ADVISOR = type('[Account Registrations] Load Users By Advisor');
    static readonly LOAD_USERS_BY_ADVISOR_SUCCESS = type(
        '[Account Registrations] Load Users By Advisor Success'
    );
    static readonly LOAD_USERS_BY_ADVISOR_FAILURE = type(
        '[Account Registrations] Load Users By Advisor Failure'
    );
    static readonly LOAD_ADVISORS_BY_FIRM_USER = type(
        '[Account Registrations] Load Soliciting Advisors by Firm User'
    );
    static readonly LOAD_ADVISORS_BY_FIRM_USER_SUCCESS = type(
        '[Account Registrations] Load Soliciting Advisors by Firm User Success'
    );
    static readonly LOAD_ADVISORS_BY_FIRM_USER_FAILURE = type(
        '[Account Registrations] Load Soliciting Advisors by Firm User Failure'
    );
    static readonly LOAD_USERS_TRAINING = type('[Account Registrations] Load Users Training');
    static readonly LOAD_USERS_TRAINING_SUCCESS = type(
        '[Account Registrations] Load Users Training Success'
    );
    static readonly LOAD_USERS_TRAINING_FAILURE = type(
        '[Account Registrations] Load Users Training Failure'
    );
    static readonly COMPLETE_TRAINING = type('[Account Registrations] Complete Training');
}

export type UsersByAdvisorActions =
    | LoadUsersByAdvisorAction
    | LoadUsersByAdvisorSuccessAction
    | LoadUsersByAdvisorFailureAction
    | LoadAdvisorsByFirmUserAction
    | LoadAdvisorsByFirmUserSuccessAction
    | LoadAdvisorsByFirmUserFailureAction
    | LoadUsersTrainingAction
    | LoadUsersTrainingSuccessAction
    | LoadUsersTrainingFailureAction
    | CompleteTrainingAction;

// Load Users by Advisor
export class LoadUsersByAdvisorAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR;

    constructor(public payload: string) {}
}

export class LoadUsersByAdvisorSuccessAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR_SUCCESS;

    constructor(public payload: UserByAdvisor[]) {}
}

export class LoadUsersByAdvisorFailureAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_USERS_BY_ADVISOR_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

// Load Users Training Information
export class LoadUsersTrainingAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_USERS_TRAINING;

    constructor(public payload: UsersTrainingBody) {}
}

export class LoadUsersTrainingSuccessAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_USERS_TRAINING_SUCCESS;

    constructor(public payload: UsersTrainingResponse) {}
}

export class LoadUsersTrainingFailureAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_USERS_TRAINING_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class CompleteTrainingAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.COMPLETE_TRAINING;

    constructor() {}
}

// Load Soliciting Advisors by Firm User

export class LoadAdvisorsByFirmUserAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_ADVISORS_BY_FIRM_USER;

    constructor() {}
}

export class LoadAdvisorsByFirmUserSuccessAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_ADVISORS_BY_FIRM_USER_SUCCESS;

    constructor(public payload: UserByAdvisor[]) {}
}

export class LoadAdvisorsByFirmUserFailureAction implements AixAction {
    readonly type = UsersByAdvisorActionTypes.LOAD_ADVISORS_BY_FIRM_USER_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
