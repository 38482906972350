import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { acceptTermResponseDecoder, termDecoder } from '../../decoders/terms/decoder';
import { AcceptTermResponse, TermsAndConditions } from '../../models/terms/model';

@Injectable()
export class TermsService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getTerms(): Observable<TermsAndConditions> {
        return this.http
            .get<TermsAndConditions>(`${this.environment.api}terms`)
            .pipe(concatMap(p => fromPromise(termDecoder.decodeToPromise(p))));
    }

    acceptTerms(): Observable<AcceptTermResponse> {
        return this.http
            .post<AcceptTermResponse>(`${this.environment.api}terms/accept`, null)
            .pipe(concatMap(p => fromPromise(acceptTermResponseDecoder.decodeToPromise(p))));
    }
}
