import { Component, input, model, output } from '@angular/core';

@Component({
    selector: 'aix-flag',
    styleUrls: ['./aix-flag.component.scss'],
    templateUrl: './aix-flag.component.html',
    standalone: true
})
export class AixFlagComponent {
    value = model<boolean>(false);
    onIcon = input.required();
    offIcon = input.required();

    valueChange = output<boolean>();

    onClick() {
        this.value.set(!this.value());
        this.valueChange.emit(this.value());
    }
}
