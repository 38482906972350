import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgClass } from '@angular/common';

@Component({
    selector: 'aix-header-section',
    templateUrl: './aix-header-section.html',
    styleUrls: ['./aix-header-section.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, AixDataTestingDirective]
})
export class AixHeaderSectionComponent {
    title = input<string | undefined>();
    sectionClasses = input<any>();

    constructor() {}
}
