import { AixAction, type } from '@trade-platform/ui-shared';

export class ShowMakeChangesModalActionTypes {
    static readonly SHOW_MODAL = type('[Make Changes Modal] Show Modal');
}

export class ShowMakeChangesModalAction implements AixAction {
    readonly type = ShowMakeChangesModalActionTypes.SHOW_MODAL;

    constructor() {}
}

export type ShowMakeChangesModalActions = ShowMakeChangesModalAction;
