import { JsonDecoder } from 'ts.data.json';
import {
    Distributions,
    DistributionsData,
    DistributionsBankInformation,
    DistributionsAddress
} from '../../models/distributions/model';

// Distributions

export const distributionsAddressDecoder = JsonDecoder.object<DistributionsAddress>(
    {
        name: JsonDecoder.optional(JsonDecoder.string),
        street: JsonDecoder.optional(JsonDecoder.string),
        city: JsonDecoder.optional(JsonDecoder.string),
        state: JsonDecoder.optional(JsonDecoder.string),
        zip: JsonDecoder.optional(JsonDecoder.string),
        accountNumber: JsonDecoder.optional(JsonDecoder.string)
    },
    'DistributionsAddress'
);

export const distributionsBankInformationDecoder = JsonDecoder.object<DistributionsBankInformation>(
    {
        name: JsonDecoder.optional(JsonDecoder.string),
        abaRoutingNumber: JsonDecoder.optional(JsonDecoder.string),
        accountNumber: JsonDecoder.optional(JsonDecoder.string),
        accountType: JsonDecoder.optional(JsonDecoder.string)
    },
    'DistributionsBankInformation'
);

export const distributionsDataDecoder = JsonDecoder.object<DistributionsData>(
    {
        type: JsonDecoder.optional(JsonDecoder.string),
        bankInformation: JsonDecoder.optional(distributionsBankInformationDecoder),
        address: JsonDecoder.optional(distributionsAddressDecoder)
    },
    'DistributionsData'
);

export const distributionsDecoder = JsonDecoder.object<Distributions>(
    {
        dividends: JsonDecoder.optional(distributionsDataDecoder),
        capitalGains: JsonDecoder.optional(distributionsDataDecoder),
        longTermCapitalGains: JsonDecoder.optional(distributionsDataDecoder)
    },
    'Distributions'
);
