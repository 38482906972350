@if (!(terms$ | async)?.body) {
<aix-loading [transparent]="true"></aix-loading>
}
<main>
    <aix-page-section>
        <ng-container header>
            <h1>End User Access Agreement</h1>
        </ng-container>
        <ng-container controls>
            <div class="-controls">
                <aix-print-button [type]="'link'"></aix-print-button>
            </div>
        </ng-container>
    </aix-page-section>

    <section>
        <form #formGroupRef="ngForm" name="acknowledgementsForm">
            <article
                #scrollContainer
                class="terms-body"
                [innerHtml]="(terms$ | async)?.body || ''"
            ></article>

            <div class="u-mt24 aix-toolbar aix-toolbar-euaa hide-if-print">
                <label class="checkbox-custom checkbox-custom-label-size euaa-checkbox">
                    <input
                        type="checkbox"
                        [value]="acceptedTerms"
                        (change)="acceptedTerms = !acceptedTerms"
                        name="termsAndConditions"
                        ngModel
                        required
                    />
                    <span class="checkbox-custom__indicator"></span>
                    <div class="-text--info">
                        <span>I have read and accept the End User Access Agreement</span>
                    </div>
                </label>
            </div>
            <div class="u-mt24 aix-toolbar hide-if-print">
                <aix-button
                    type="submit"
                    class="u-full-width"
                    (click)="acceptTerms()"
                    [isDisabled]="!acceptedTerms || !formGroupRef.valid"
                    [buttonType]="acceptButtonType"
                    [buttonLabel]="'Accept'"
                >
                </aix-button>
            </div>
        </form>
    </section>
</main>
