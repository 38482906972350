import {
    AixAction,
    ErrorWrapper,
    OrderResendPayload,
    OrderResendResponse,
    type
} from '@trade-platform/ui-shared';

export class OrderResendActionTypes {
    static readonly ORDER_RESEND = type('[Order Resend] Order Resend');
    static readonly ORDER_RESEND_SUCCESS = type('[Order Resend] Order Resend Success');
    static readonly ORDER_RESEND_FAILURE = type('[Order Resend] Order Resend Failure');
}

export type OrderResendActions = OrderResend | OrderResendSuccess | OrderResendFailure;

export class OrderResend implements AixAction {
    readonly type = OrderResendActionTypes.ORDER_RESEND;

    constructor(public payload: OrderResendPayload) {}
}

export class OrderResendSuccess implements AixAction {
    readonly type = OrderResendActionTypes.ORDER_RESEND_SUCCESS;

    constructor(public payload: OrderResendResponse) {}
}

export class OrderResendFailure implements AixAction {
    readonly type = OrderResendActionTypes.ORDER_RESEND_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
