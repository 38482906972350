import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorWrapper } from '../models/common';

export const getErrorText = (error: HttpErrorResponse): string => {
    const requestId = error.headers.has('request-id') ? error.headers.get('request-id') : null;
    return requestId
        ? `We're sorry the platform has encountered an issue. Please contact <a href="mailto:support@aixplatform.com">support@aixplatform.com</a> and include the following issue code.<br/>Issue Code:&nbsp;${requestId}.<br/><br/>A support ticket will be opened on your behalf and provided to you via email.`
        : ErrorWrapper.defaultErrorText;
};

/**
 * Higher Order function that creates a preconfigured pipeable catchError operator for Http responses.
 * It supports Http errors and other types of errors like json decoder errors.
 * We could
 *
 * @param returnFn A function that returns the ngrx `Observable<Action>` to dispatch
 */
export const catchHttpError = (returnFn: (error: ErrorWrapper) => Observable<Action>) =>
    catchError<Action, Observable<Action>>((error: string | HttpErrorResponse) => {
        let err: ErrorWrapper;
        if (error instanceof HttpErrorResponse) {
            err = new ErrorWrapper(error.message, {
                errorText: getErrorText(error),
                status: error.status
            });
        } else {
            err = new ErrorWrapper(error);
        }
        return returnFn(err);
    });
