// --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
//
//  No dependencies allowed other than field-config.interface
//
//  This is a requirement to alllow external tooling to use this file.
// @see apps/forms-ui/collect-forms.js comment for details.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

import {
    AnyOfFieldConfig,
    BaseFieldConfig,
    ButtonFieldConfig,
    CalculatedFieldConfig,
    CheckboxFieldConfig,
    Checkboxgroup2FieldConfig,
    CheckboxgroupFieldConfig,
    ControlFieldConfig,
    CurrencyFieldConfig,
    DateFieldConfig,
    EinFieldConfig,
    ExpandableTextFieldConfig,
    FieldGroupConfig,
    FieldGroupLightConfig,
    FreeTextFieldConfig,
    InputFieldConfig,
    IntlPhoneFieldConfig,
    NotificationConfig,
    NumberFieldConfig,
    OneOfFieldConfig,
    OptionFieldConfig,
    PageHeaderConfig,
    PercentageFieldConfig,
    PlaceholderFieldConfig,
    RadiogroupFieldConfig,
    RepeaterFieldConfig,
    SectionHeaderConfig,
    SectionHeaderGroupConfig,
    SelectFieldConfig,
    SeveralFieldConfig,
    SsnFieldConfig,
    TelephoneFieldConfig,
    ToggleFieldConfig,
    ZipFieldConfig
} from './field-config.interface';

// -------------------------------------------
//
//  Utils
//
// -------------------------------------------

type WithoutType<T> = Omit<T, 'type'>;

const baseFieldConfig = (): BaseFieldConfig => ({
    refId: undefined as any,
    type: undefined as any
});

const controlFieldConfig = (): ControlFieldConfig => {
    const base = baseFieldConfig();
    return {
        ...base,
        group: undefined as any,
        name: undefined as any,
        validation: undefined as any
    };
};

const severalFieldConfig = (): SeveralFieldConfig => ({});

const anyOfFieldConfig = (): AnyOfFieldConfig => ({
    ...severalFieldConfig(),
    anyOf: undefined as any
});

const placeholderFieldConfig = (): PlaceholderFieldConfig => ({});

const calculatedFieldConfig = (): CalculatedFieldConfig => ({});

const inputFieldConfig = (): InputFieldConfig => ({
    ...controlFieldConfig(),
    ...placeholderFieldConfig(),
    ...calculatedFieldConfig(),
    type: undefined as any,
    value: undefined
});

const oneOfFieldConfig = (): OneOfFieldConfig => ({
    ...severalFieldConfig(),
    oneOf: undefined as any
});

// -------------------------------------------
//
//  Core factory functions
//
// -------------------------------------------

/**
 * Generates a 'checkbox' Field
 * @param partialField An object with the field properties you want to set.
 */
export const checkboxField = (
    partialField: WithoutType<CheckboxFieldConfig>
): CheckboxFieldConfig => {
    const defaultField: CheckboxFieldConfig = {
        ...controlFieldConfig(),
        type: 'checkbox'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates an 'option' Field (Used internally by the DynamicFormStore)
 * @param partialField An object with the field properties you want to set.
 */
export const optionField = (partialField: WithoutType<OptionFieldConfig>): OptionFieldConfig => {
    const defaultField: OptionFieldConfig = {
        ...controlFieldConfig(),
        host: undefined as any,
        type: 'option'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'checkboxGroup' Field
 * @param partialField An object with the field properties you want to set.
 */
export const checkboxGroupField = (
    partialField: WithoutType<CheckboxgroupFieldConfig>
): CheckboxgroupFieldConfig => {
    const defaultField: CheckboxgroupFieldConfig = {
        ...controlFieldConfig(),
        type: 'checkboxGroup',
        ...anyOfFieldConfig()
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'checkboxGroup2' Field
 * @param partialField An object with the field properties you want to set.
 */
export const checkboxGroup2Field = (
    partialField: WithoutType<Checkboxgroup2FieldConfig>
): Checkboxgroup2FieldConfig => {
    const defaultField: Checkboxgroup2FieldConfig = {
        ...controlFieldConfig(),
        type: 'checkboxGroup2',
        ...anyOfFieldConfig()
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'currency' Field
 * @param partialField An object with the field properties you want to set.
 */
export const currencyField = (
    partialField: WithoutType<CurrencyFieldConfig>
): CurrencyFieldConfig => {
    const defaultField: CurrencyFieldConfig = {
        ...inputFieldConfig(),
        type: 'currency',
        maskType: 'Amount'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'date' Field
 * @param partialField An object with the field properties you want to set.
 */
export const dateField = (partialField: WithoutType<DateFieldConfig>): DateFieldConfig => {
    const defaultField: DateFieldConfig = {
        ...inputFieldConfig(),
        type: 'date',
        maskType: 'DateFormat',
        maskFormat: 'MM/dd/yyyy'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'dropdown' Field
 * @param partialField An object with the field properties you want to set.
 */
export const dropdownField = (partialField: WithoutType<SelectFieldConfig>): SelectFieldConfig => {
    const defaultField: SelectFieldConfig = {
        ...controlFieldConfig(),
        ...oneOfFieldConfig(),
        ...placeholderFieldConfig(),
        placeholder: '',
        type: 'dropdown'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'freeText' Field
 * @param partialField An object with the field properties you want to set.
 */
export const freeTextField = (
    partialField: WithoutType<FreeTextFieldConfig>
): FreeTextFieldConfig => {
    const defaultField: FreeTextFieldConfig = {
        ...baseFieldConfig(),
        type: 'freeText',
        text: undefined
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'expandableText' Field
 * @param partialField An object with the field properties you want to set.
 */
export const expandableTextField = (
    partialField: WithoutType<ExpandableTextFieldConfig>
): ExpandableTextFieldConfig => {
    const defaultField: ExpandableTextFieldConfig = {
        ...baseFieldConfig(),
        type: 'expandableText',
        text: undefined
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'notification' Field
 * @param partialField An object with the field properties you want to set.
 */
export const notificationField = (
    partialField: WithoutType<NotificationConfig>
): NotificationConfig => {
    const defaultField: NotificationConfig = {
        ...baseFieldConfig(),
        type: 'notification',
        text: undefined,
        makeFormInvalid: false
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'group' Field
 * @param partialField An object with the field properties you want to set.
 */
export const groupField = (partialField: WithoutType<FieldGroupConfig>): FieldGroupConfig => {
    const defaultField: FieldGroupConfig = {
        ...baseFieldConfig(),
        type: 'group',
        classNames: undefined as any,
        children: undefined as any
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'groupLight' Field
 * @param partialField An object with the field properties you want to set.
 */
export const groupLightField = (
    partialField: WithoutType<FieldGroupLightConfig>
): FieldGroupLightConfig => {
    const defaultField: FieldGroupLightConfig = {
        ...baseFieldConfig(),
        type: 'groupLight',
        children: undefined as any
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates an 'textInput' Field
 * @param partialField An object with the field properties you want to set.
 */
export const textInputField = (partialField: WithoutType<InputFieldConfig>): InputFieldConfig => {
    const defaultField: InputFieldConfig = {
        ...inputFieldConfig(),
        type: 'textInput'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/*
 * Generates an 'number' Field
 * @param partialField An object with the field properties you want to set.
 */
export const numberField = (partialField: WithoutType<NumberFieldConfig>): NumberFieldConfig => {
    const defaultField: NumberFieldConfig = {
        ...controlFieldConfig(),
        ...placeholderFieldConfig(),
        ...calculatedFieldConfig(),
        type: 'number',
        value: undefined
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates an 'percentage' Field
 * @param partialField An object with the field properties you want to set.
 */
export const percentageField = (
    partialField: WithoutType<PercentageFieldConfig>
): PercentageFieldConfig => {
    const defaultField: PercentageFieldConfig = {
        ...inputFieldConfig(),
        type: 'percentage',
        allowNegative: undefined as any,
        allowDecimal: undefined as any
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates an 'radioGroup' Field
 * @param partialField An object with the field properties you want to set.
 */
export const radioGroupField = (
    partialField: WithoutType<RadiogroupFieldConfig>
): RadiogroupFieldConfig => {
    const defaultField: RadiogroupFieldConfig = {
        ...controlFieldConfig(),
        ...oneOfFieldConfig(),
        type: 'radioGroup'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates an 'repeater' Field
 * @param partialField An object with the field properties you want to set.
 */
export const repeaterField = (
    partialField: WithoutType<RepeaterFieldConfig>
): RepeaterFieldConfig => {
    const defaultField: RepeaterFieldConfig = {
        ...controlFieldConfig(),
        type: 'repeater',
        dataTemplate: undefined as any,
        template: undefined as any,
        value: undefined as any
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'ssn' Field
 * @param partialField An object with the field properties you want to set.
 */
export const ssnField = (partialField: WithoutType<SsnFieldConfig>): SsnFieldConfig => {
    const defaultField: SsnFieldConfig = {
        ...inputFieldConfig(),
        type: 'ssn',
        maskType: 'SSNDash'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'ein' Field
 * @param partialField An object with the field properties you want to set.
 */
export const einField = (partialField: WithoutType<EinFieldConfig>): EinFieldConfig => {
    const defaultField: EinFieldConfig = {
        ...inputFieldConfig(),
        type: 'ein',
        maskType: 'CustomNumber',
        maskFormat: '##-#######'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'telephone' Field
 * @param partialField An object with the field properties you want to set.
 */
export const telephoneField = (
    partialField: WithoutType<TelephoneFieldConfig>
): TelephoneFieldConfig => {
    const defaultField: TelephoneFieldConfig = {
        ...inputFieldConfig(),
        maskType: 'Phone',
        type: 'telephone'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'intl-phone' Field
 * @param partialField An object with the field properties you want to set.
 */
export const intlPhoneField = (
    partialField: WithoutType<IntlPhoneFieldConfig>
): IntlPhoneFieldConfig => {
    const defaultField: IntlPhoneFieldConfig = {
        ...inputFieldConfig(),
        maskType: 'Phone',
        type: 'intlPhone',
        countryCodeName: ''
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'textarea' Field
 * @param partialField An object with the field properties you want to set.
 */
export const textareaField = (partialField: WithoutType<InputFieldConfig>): InputFieldConfig => {
    const defaultField: InputFieldConfig = {
        ...inputFieldConfig(),
        type: 'textarea'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'toggle' Field
 * @param partialField An object with the field properties you want to set.
 */
export const toggleField = (partialField: WithoutType<ToggleFieldConfig>): ToggleFieldConfig => {
    const defaultField: ToggleFieldConfig = {
        ...controlFieldConfig(),
        type: 'toggle',
        labelTrue: undefined as any,
        labelFalse: undefined as any
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'zip' Field
 * @param partialField An object with the field properties you want to set.
 */
export const zipField = (partialField: WithoutType<ZipFieldConfig>): ZipFieldConfig => {
    const defaultField: ZipFieldConfig = {
        ...inputFieldConfig(),
        type: 'zip',
        maskType: 'CustomNumber',
        maskFormat: '~Z#####-####'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

/**
 * Generates a 'button' Field
 * @param partialField An object with the field properties you want to set.
 */
export const buttonField = (partialField: WithoutType<ButtonFieldConfig>): ButtonFieldConfig => {
    const defaultField: ButtonFieldConfig = {
        ...baseFieldConfig(),
        type: 'button'
    };
    return {
        ...defaultField,
        ...partialField
    };
};

// -------------------------------------------
//
//  Composite factory functions
//
// -------------------------------------------

/**
 * Generates a 'freeText' Field for section headers
 * @param partialField An object with the title and the type of heading you want to set.
 */
export const sectionHeaderField = (partialField: SectionHeaderConfig): FreeTextFieldConfig => {
    const defaultField: FreeTextFieldConfig = {
        ...baseFieldConfig(),
        type: 'freeText',
        text: `<div class="header--section">
    <div class="-heading"><h3>${partialField.title}</h3></div>
</div>`
    };
    return {
        ...defaultField
    };
};

/**
 * Generates a 'freeText' Field for section headers
 * @param partialField An object with the title and the type of heading you want to set.
 */
export const sectionHeaderGroupField = (
    partialField: SectionHeaderGroupConfig
): FieldGroupConfig => {
    const defaultField: FieldGroupConfig = {
        ...baseFieldConfig(),
        type: 'group',
        classNames: undefined as any,
        children: [
            {
                ...baseFieldConfig(),
                type: 'freeText',
                text: `<div class="header--section"><div class="-heading"><h3>${partialField.label}</h3></div></div>`
            },
            ...partialField.children
        ]
    };

    if (partialField.relations) {
        defaultField.relations = partialField.relations;
    }

    return {
        ...defaultField
    };
};

/**
 * Generates a 'freeText' Field for page headers
 * @param partialField An object with the title and the type of heading you want to set.
 */
export const pageHeaderField = (partialField: PageHeaderConfig): FreeTextFieldConfig => {
    const defaultField: FreeTextFieldConfig = {
        ...baseFieldConfig(),
        type: 'freeText',
        text: `<div class="header--page">
    <div class="-heading"><h2>${partialField.title}</h2></div>
</div>`
    };
    return {
        ...defaultField
    };
};

// -------------------------------------------
//
//  CalcExp Builder
//
// -------------------------------------------

interface Builder {
    build(): string;
}

export class CalcExpBuilder {
    /**
     * @example
     * CalcExpBuilder.calc(
     *   '+',
     *   CalcExpBuilder.expression('2'),
     *   CalcExpBuilder.expression('7'),
     *   CalcExpBuilder.expression('3')
     * ).build();
     * // '2 + 7 + 3'
     */
    static calc(operation: '+' | '-' | '*' | '/' | string, ...exp: Builder[]): Builder {
        return {
            build() {
                return exp.map(e => e.build()).join(` ${operation} `);
            }
        };
    }

    /**
     * @example
     * CalcExpBuilder.addAll('1, 2, 3').build();
     * // 'addAll(1, 2, 3)'
     */
    static addAll(expressionBuilder: Builder): Builder {
        return {
            build() {
                return `addAll(${expressionBuilder.build()})`;
            }
        };
    }

    /**
     * @example
     * CalcExpBuilder
     *   .repeater('myRepeater')
     *   .forEach('@price')
     *   .build();
     * // 'Repeater("myRepeater").forEach("@price")'
     */
    static repeater(repeaterRefId: string) {
        return {
            forEach(expression: string): Builder {
                return {
                    build() {
                        return `Repeater("${repeaterRefId}").forEach("${expression}")`;
                    }
                };
            }
        };
    }

    /**
     * @example
     * CalcExpBuilder.expression('2 + 4');
     * // '2 + 4'
     */
    static expression(expression: string): Builder {
        return {
            build() {
                return expression;
            }
        };
    }
}
