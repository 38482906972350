import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-ui-document-action-bar',
    templateUrl: './document-action-bar.component.html',
    standalone: true,
    imports: [AixDataTestingDirective, NgClass]
})
export class AixUIDocumentActionBarComponent {
    @Input() isOffline = false;

    constructor() {}
}
