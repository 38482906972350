<div class="aix-form__container aix-flex-grid__col">
    <ng-template #headerTemplate>
        <span class="-text--large u-pl16" [data-testing]="'label'">
            <span
                >{{ title() }}
                @if (selectedOptionLabel) {
                <span>– {{ selectedOptionLabel }}</span>
                }</span
            ><span>&nbsp;</span><span>*</span>
        </span>
    </ng-template>

    <ng-template #bodyTemplate>
        <div class="radio-custom-expansion-panel-content u-block">
            @for (section of options(); track section) {
            <div>
                @if (section.label) {
                <h3 class="header--section u-mb24">
                    {{ section.label }}
                </h3>
                }
                <div class="radio-custom-expansion-panel-content-section">
                    @for (column of section.children; track column) {
                    <div class="radio-custom-column__1of2">
                        @for (group of column; track group) {
                        <div class="u-pb32">
                            <h5>
                                {{ group.label }}
                            </h5>
                            @for (option of group.children; track option) {
                            <div class="u-pt8">
                                <div class="aix-form__group">
                                    <div class="radio-custom-wrapper-full">
                                        <label
                                            class="radio-custom radio-custom-label-size"
                                            [data-testing]="option[valueField()]"
                                            [class.aix-disabled]="isDisabled() || option.disabled"
                                        >
                                            <input
                                                type="radio"
                                                [name]="name"
                                                [value]="option[valueField()]"
                                                [checked]="value() === option[valueField()]"
                                                (click)="onUserInput($event, option)"
                                                [class.aix-disabled]="
                                                    isDisabled() || option.disabled
                                                "
                                                [attr.disabled]="
                                                    isDisabled() || option.disabled ? '' : null
                                                "
                                                [attr.tabindex]="
                                                    isDisabled() || option.disabled ? '-1' : ''
                                                "
                                            />
                                            <span
                                                class="radio-custom__indicator"
                                                [class.aix-form__control--invalid]="
                                                    !value() &&
                                                    isRequired() &&
                                                    !isDisabled() &&
                                                    !option.disabled
                                                "
                                                [class.aix-form__control--invalid-required]="
                                                    !value() &&
                                                    isRequired() &&
                                                    !isDisabled() &&
                                                    !option.disabled
                                                "
                                                [class.aix-form__control--disabled-required]="
                                                    isDisabled() || option.disabled
                                                "
                                            ></span>
                                            <span
                                                class="radio-custom__label radio-custom__label--xl"
                                            >
                                                <span [data-testing]="'title'">
                                                    {{ option[labelField()] }}
                                                    @if (descriptionField() &&
                                                    option[descriptionField()]) {
                                                    <span class="radio-custom__sublabel">
                                                        {{ option[descriptionField()] }}
                                                    </span>
                                                    }
                                                </span>
                                            </span>
                                            @if (option[tooltipField()]) {
                                            <span
                                                aixTooltip
                                                [aixTooltipContent]="option[tooltipField()]"
                                                [data-testing]="option[valueField()] + '__tooltip'"
                                            ></span>
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
            }
        </div>
    </ng-template>

    <aix-expansion-panel
        #expansionPanelRef
        class="u-full-width"
        [isClosed]="!!value()"
        [data-testing]="'expansion-panel'"
        [headerTemplate]="headerTemplate"
        [bodyTemplate]="bodyTemplate"
        [arrowPosition]="'right'"
    >
    </aix-expansion-panel>
</div>
