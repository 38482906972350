import { JsonDecoder } from 'ts.data.json';
import {
    AccountInvestment,
    AccountRegistration,
    MergedAccount,
    PositionWithAccounts,
    RegistrationWithPositions
} from '../../models/registrations/model';

export const AccountInvestmentDecoder = JsonDecoder.object<AccountInvestment>(
    {
        accountId: JsonDecoder.string,
        cusip: JsonDecoder.string
    },
    'AccountInvestment'
);

export const accountRegistrationDecoder = JsonDecoder.object<AccountRegistration>(
    {
        id: JsonDecoder.string,
        displayName: JsonDecoder.string,
        accountType: JsonDecoder.string,
        accountTypeName: JsonDecoder.string,
        contactNames: JsonDecoder.array(JsonDecoder.string, 'string[]'),
        customerAccountNumber: JsonDecoder.string,
        externalCustodianId: JsonDecoder.string,
        externalCustodianName: JsonDecoder.string,
        lastMaintenanceDate: JsonDecoder.string,
        totalMarketValue: JsonDecoder.number,
        investments: JsonDecoder.optional(
            JsonDecoder.array<AccountInvestment>(AccountInvestmentDecoder, 'AccountInvestment[]')
        ).map(investments => investments || [])
    },
    'AccountRegistration'
);

export const accountRegistrationsDecoder = JsonDecoder.array<AccountRegistration>(
    accountRegistrationDecoder,
    'AccountRegistration[]'
);

export const mergedAccountDecoder = JsonDecoder.object<MergedAccount>(
    {
        id: JsonDecoder.string,
        initialTransactionTransfer: JsonDecoder.boolean,
        accountGroupCreatedByUserId: JsonDecoder.string,
        accountGroupCreatedAt: JsonDecoder.string
    },
    'MergedAccount'
);

export const mergedAccountsDecoder = JsonDecoder.array<MergedAccount>(
    mergedAccountDecoder,
    'MergedAccount[]'
);

export const oldAccountPositionDecoder = JsonDecoder.object<PositionWithAccounts>(
    {
        id: JsonDecoder.string,
        displayName: JsonDecoder.string,
        accountId: JsonDecoder.string,
        recordedAt: JsonDecoder.string,
        symbol: JsonDecoder.string,
        cusip: JsonDecoder.string,
        name: JsonDecoder.string,
        securityType: JsonDecoder.string,
        assetClass: JsonDecoder.string,
        marketValue: JsonDecoder.number,
        units: JsonDecoder.number,
        unitPrice: JsonDecoder.number,
        dateOpened: JsonDecoder.string,
        dateClosed: JsonDecoder.string,
        displayForPerformance: JsonDecoder.boolean,
        mergedAccounts: JsonDecoder.optional(mergedAccountsDecoder)
    },
    'PositionWithAccounts'
);

export const oldAccountPositionsDecoder = JsonDecoder.array<PositionWithAccounts>(
    oldAccountPositionDecoder,
    'PositionWithAccounts[]'
);

export const registrationWithPositionsDecoder = JsonDecoder.object<RegistrationWithPositions>(
    {
        registration: accountRegistrationDecoder,
        positionsWithAccounts: oldAccountPositionsDecoder
    },
    'RegistrationWithPositions'
);
