import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderDocusignSendActions, OrderDocusignSendActionTypes } from './actions';

export type OrderDocusignSendState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderDocusignSendReducer = (
    state: OrderDocusignSendState = initialState,
    action: OrderDocusignSendActions
): OrderDocusignSendState => {
    switch (action.type) {
        case OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND:
            return inProgress();
        case OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND_SUCCESS:
            return success(null);
        case OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND_FAILURE:
            return failure(action.payload.error);
        case OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND_RESET:
            return notAsked();
        default:
            return state;
    }
};
