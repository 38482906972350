import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderStartReview,
    OrderStartReviewActionTypes,
    OrderStartReviewFailure,
    OrderStartReviewSuccess
} from './actions';
import { OrderStartReviewService } from './service';

@Injectable()
export class OrderStartReviewEffects {
    constructor(
        private actions$: Actions,
        private orderStartReviewService: OrderStartReviewService
    ) {}

    orderStartReview$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderStartReview>(OrderStartReviewActionTypes.ORDER_START_REVIEW),
            switchMap(action => {
                return this.orderStartReviewService
                    .orderStartReview(action.payload.orderId, action.payload.skipReview || false)
                    .pipe(
                        map(_ => new OrderStartReviewSuccess()),
                        catchHttpError(error => of(new OrderStartReviewFailure({ error })))
                    );
            })
        )
    );
}
