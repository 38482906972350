import { AixAction, ErrorWrapper, OrderDocusignPayload, type } from '@trade-platform/ui-shared';

export class OrderDocusignSendActionTypes {
    static readonly ORDER_DOCUSIGN_SEND = type('[Order Docusign] Order Docusign');
    static readonly ORDER_DOCUSIGN_SEND_SUCCESS = type('[Order Docusign] Order Docusign Success');
    static readonly ORDER_DOCUSIGN_SEND_FAILURE = type('[Order Docusign] Order Docusign Failure');
    static readonly ORDER_DOCUSIGN_SEND_RESET = type('[Order Docusign] Order Docusign Reset');
}

export type OrderDocusignSendActions =
    | OrderDocusignSend
    | OrderDocusignSendSuccess
    | OrderDocusignSendFailure
    | OrderDocusignSendReset;

export class OrderDocusignSend implements AixAction {
    readonly type = OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND;

    constructor(public payload: OrderDocusignPayload) {}
}

export class OrderDocusignSendSuccess implements AixAction {
    readonly type = OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND_SUCCESS;
}

export class OrderDocusignSendFailure implements AixAction {
    readonly type = OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class OrderDocusignSendReset implements AixAction {
    readonly type = OrderDocusignSendActionTypes.ORDER_DOCUSIGN_SEND_RESET;
}
