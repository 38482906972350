import {
    AnyOfFieldConfig,
    BaseFieldConfig,
    CalculatedFieldConfig,
    ControlFieldConfig,
    DataWhenAction,
    ExpandableTextFieldConfig,
    FieldConfig,
    FieldGroupConfig,
    FieldGroupLightConfig,
    FieldWhenAction,
    FreeTextFieldConfig,
    MaskFormatFieldConfig,
    NotificationConfig,
    OneOfFieldConfig,
    ParametrizedStoreAction,
    RelationConnector,
    RelationWhenAction,
    RepeaterFieldConfig
} from './field-config.interface';

// -------------------------------------------------------------
//
// Types
//
// -------------------------------------------------------------

/**
 * Used to configure SetOrderPathValueAction storeActions
 * @see https://github.com/trade-platform/aix-ui/blob/master/apps/forms-ui/docs/other/store-actions.md#parametrized-store-actions
 */
export type SetOrderPathValueActionParams = {
    storePath: string;
    actionValue:
        | 'passthrough' // send the value as is
        | {
              [whenValueIs: string]: any; // [property value]: value to send
          };
};

/**
 * Used to configure SetFormDataAction storeActions
 * @see https://github.com/trade-platform/aix-ui/blob/master/apps/forms-ui/docs/other/store-actions.md#parametrized-store-actions
 */
export type SetFormDataActionParams = {
    refId: string;
    connective?: RelationConnector;
    when: (FieldWhenAction | DataWhenAction)[];
    newValue?: Record<string, unknown>;
    params?: SetOrderPathValueActionParams;
};

// -------------------------------------------------------------
//
// Type guards
//
// -------------------------------------------------------------

export const isFieldGroupConfig = (config: FieldConfig): config is FieldGroupConfig => {
    return config.type === 'group';
};

export const isFieldGroupLightConfig = (config: FieldConfig): config is FieldGroupLightConfig => {
    return config.type === 'groupLight';
};

export const isFreeTextConfig = (config: BaseFieldConfig): config is FreeTextFieldConfig => {
    return config.type === 'freeText';
};

export const isExpandableTextConfig = (
    config: BaseFieldConfig
): config is ExpandableTextFieldConfig => {
    return config.type === 'expandableText';
};

export const isAnyOfConfig = (config: any): config is AnyOfFieldConfig => {
    // eslint-disable-next-line no-prototype-builtins
    return config.hasOwnProperty('anyOf');
};

export const isOneOfConfig = (config: any): config is OneOfFieldConfig => {
    // eslint-disable-next-line no-prototype-builtins
    return config.hasOwnProperty('oneOf');
};

export const isNotificationConfig = (config: BaseFieldConfig): config is NotificationConfig => {
    return config.type === 'notification';
};

export const isRepeaterConfig = (config: FieldConfig): config is RepeaterFieldConfig => {
    return config.type === 'repeater';
};

export const isControlConfig = (config: BaseFieldConfig): config is ControlFieldConfig => {
    return (
        config.type === 'textInput' ||
        config.type === 'percentage' ||
        config.type === 'currency' ||
        config.type === 'toggle' ||
        config.type === 'radioGroup' ||
        config.type === 'checkboxGroup' ||
        config.type === 'checkboxGroup2' ||
        config.type === 'select' ||
        config.type === 'textarea' ||
        config.type === 'checkbox' ||
        config.type === 'number' ||
        config.type === 'dropdown' ||
        config.type === 'date' ||
        config.type === 'repeater' ||
        config.type === 'zip' ||
        config.type === 'telephone' ||
        config.type === 'intlPhone' ||
        config.type === 'ssn' ||
        config.type === 'ein'
    );
};

export const isMaskFormatControl = (
    config: BaseFieldConfig
): config is BaseFieldConfig & MaskFormatFieldConfig => {
    return (
        config.type === 'textInput' ||
        config.type === 'percentage' ||
        config.type === 'currency' ||
        config.type === 'number' ||
        config.type === 'date' ||
        config.type === 'zip' ||
        config.type === 'telephone' ||
        config.type === 'ssn' ||
        config.type === 'ein'
    );
};

export const isCalculatedExpressionControl = (
    config: ControlFieldConfig
): config is ControlFieldConfig & CalculatedFieldConfig => {
    return (
        config.type === 'textInput' ||
        config.type === 'percentage' ||
        config.type === 'currency' ||
        config.type === 'number' ||
        config.type === 'textarea'
    );
};

export const isFieldWhenAction = (
    when: RelationWhenAction | FieldWhenAction | DataWhenAction
): when is FieldWhenAction => when !== null && when !== undefined && 'refId' in when;

export const isDataWhenAction = (
    when: RelationWhenAction | FieldWhenAction | DataWhenAction
): when is DataWhenAction => when !== null && when !== undefined && 'dataPath' in when;

export const isRelationWhenAction = (
    when: RelationWhenAction | FieldWhenAction | DataWhenAction
): when is RelationWhenAction => when !== null && when !== undefined && 'when' in when;

// -------------------------------------------------------------
//
// Utils
//
// -------------------------------------------------------------

/**
 * creates a ParametrizedStoreAction<SetOrderPathValueActionParams> storeAction
 * @see https://github.com/trade-platform/aix-ui/blob/master/apps/forms-ui/docs/other/store-actions.md#parametrized-store-actions
 */
export function setOrderAction(params: SetOrderPathValueActionParams) {
    return {
        actionName: 'SetOrderPathValueAction',
        params
    } as ParametrizedStoreAction<SetOrderPathValueActionParams>;
}

/**
 * creates a ParametrizedStoreAction<SetOrderPathValueActionParams> storeAction
 * @see https://github.com/trade-platform/aix-ui/blob/master/apps/forms-ui/docs/other/store-actions.md#parametrized-store-actions
 */
export function setFormDataAction(params: SetFormDataActionParams) {
    return {
        actionName: 'SetFormDataAction',
        params
    } as ParametrizedStoreAction<SetFormDataActionParams>;
}

/**
 * Flattens the hierarchical FieldConfig[] structure
 */
export const flattenFieldConfig = (fields: FieldConfig[]) => {
    let flattened: FieldConfig[] = [];
    fields.forEach(f => {
        if (isFieldGroupConfig(f) || isFieldGroupLightConfig(f)) {
            flattened.push(f);
            flattened = flattened.concat(flattenFieldConfig(f.children as FieldConfig[]));
        } else if (Array.isArray(f)) {
            f.forEach(i => {
                flattened = flattened.concat(flattenFieldConfig([i]));
            });
        } else {
            flattened.push(f);
        }
    });
    return flattened;
};
