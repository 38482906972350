import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import { OrderSign, OrderSignActionTypes, OrderSignFailure, OrderSignSuccess } from './actions';
import { OrderSignService } from './service';

@Injectable()
export class OrderSignEffects {
    constructor(private actions$: Actions, private orderSignService: OrderSignService) {}

    orderSign$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderSign>(OrderSignActionTypes.ORDER_SIGN),
            switchMap(action =>
                this.orderSignService.orderSign(action.payload.orderId).pipe(
                    map(res => new OrderSignSuccess(res)),
                    catchHttpError(error => of(new OrderSignFailure({ error })))
                )
            )
        )
    );
}
