import { Component, forwardRef, input, model, output, TemplateRef, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AixExpansionPanelComponent } from '../aix-expansion-panel/expansion-panel';
import { v4 as uuid } from 'uuid';
import { AixTooltipDirective } from '../../directives/tooltip/aix-tooltip';
import { NgFor, NgIf } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

export interface AixRadioGroupColumnOptions {
    disabled?: boolean;
    isTitle?: boolean;
    [key: string]: any;
}

@Component({
    selector: 'aix-radio-group-column',
    styleUrls: ['./aix-radio-group-column.component.scss'],
    templateUrl: './aix-radio-group-column.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixRadioGroupColumnComponent)
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective, NgIf, NgFor, AixTooltipDirective, AixExpansionPanelComponent]
})
export class AixRadioGroupColumnComponent implements ControlValueAccessor {
    expansionPanelRef = viewChild<AixExpansionPanelComponent>('expansionPanelRef');
    headerTemplate = viewChild<TemplateRef<any>>('headerTemplate');
    bodyTemplate = viewChild<TemplateRef<any>>('bodyTemplate');

    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    options = input<AixRadioGroupColumnOptions[][]>();
    labelField = input.required<string>();
    valueField = input.required<string>();
    descriptionField = input<string>();
    tooltipField = input<string>();
    config = input<any>();
    isRequired = input(false);
    isDisabled = input(false);
    isValid = input(false);
    title = input('');
    value = model('');
    placeholder = input('');
    headerOffset = input(0);

    valueChanges = output<string>();
    onSelectOption = output<any>();
    setDirty = output();

    private isDirty = false;

    name = `radioGroup${uuid()}`;
    selectedOptionLabel: string | undefined;

    constructor() {}

    valueToSend(rawValue: string) {
        this.value.set(rawValue ?? null);
        this.valueChanges.emit(rawValue);
        return rawValue;
    }

    onUserInput(evt: Event | ClipboardEvent, option: any) {
        if (!this.isDirty) {
            this.setDirty.emit();
            this.isDirty = true;
        }

        this.selectedOptionLabel = option[this.labelField()]
            ? option[this.labelField()]
            : undefined;
        this.onSelectOption.emit(option);
        this.doAutoscroll();

        const val = this.valueToSend((evt.target as HTMLInputElement).value);
        this._propagateChanges(val);
    }

    doAutoscroll() {
        const element = this.headerTemplate()?.elementRef.nativeElement;
        const elementPosition = element.parentElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - this.headerOffset();

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        this.expansionPanelRef()?.toggle();
    }

    trackByOptionArrayFn(index: number, options: AixRadioGroupColumnOptions[], valueField: string) {
        return options.map((opt: any) => opt[valueField]).join('-');
    }

    trackByOptionFn(index: number, item: any, valueField: string) {
        return item[valueField];
    }

    writeValue(value: any) {
        this.valueToSend(value);
    }

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
