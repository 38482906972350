/**
 * Update Higher Order Reducer
 */

import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ApiFormError } from '@trade-platform/ui-utils';
import { ErrorWrapper } from '../models/common';
import { UpdateActions, UpdateActionTypes } from './actions';

export function updateReducerFactory<
    UpdatePayload,
    UpdatePayloadExtras,
    UpdateResult,
    UpdateSuffix
>(reducerSuffix: UpdateSuffix) {
    type UpdateState = RemoteData<UpdateResult, ErrorWrapper | ApiFormError>;

    const reducer = (
        state: UpdateState,
        action: UpdateActions<UpdatePayload, UpdatePayloadExtras, UpdateResult, UpdateSuffix>
    ): UpdateState => {
        switch (action.type) {
            case UpdateActionTypes.UPDATE:
                return inProgress({} as UpdateResult);
            case UpdateActionTypes.UPDATE_SUCCESS:
                return success(action.payload.result);
            case UpdateActionTypes.UPDATE_FAILURE:
                return failure(action.payload.error);
            case UpdateActionTypes.RESET:
                return notAsked();
            default:
                return state;
        }
    };

    return (
        state: UpdateState = notAsked(),
        action: UpdateActions<UpdatePayload, UpdatePayloadExtras, UpdateResult, UpdateSuffix>
    ): UpdateState => {
        if (action.payload) {
            switch (action.payload.reducerSuffix) {
                case reducerSuffix:
                    return [reducer].reduce((acc, fn) => fn(acc, action), state);
                default:
                    return state;
            }
        } else {
            return state;
        }
    };
}
