import { JsonDecoder } from 'ts.data.json';
import { OrderApprovalForm } from '../../models/order-approval-forms/model';

export const orderApprovalFormDecoder = JsonDecoder.object<OrderApprovalForm>(
    {
        id: JsonDecoder.string
    },
    'OrderApprovalForm'
);

export const orderApprovalFormsResponse = JsonDecoder.array<OrderApprovalForm>(
    orderApprovalFormDecoder,
    'OrderApprovalForm[]'
);
