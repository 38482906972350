import { OrderStepsModel } from '../../models/order-steps/model';
import { OrderStepsActions, OrderStepsActionTypes } from './actions';

const initialState = (): OrderStepsModel => ({
    currentStep: 1
});

export function orderStepsReducer(
    state: OrderStepsModel = initialState(),
    action: OrderStepsActions
): OrderStepsModel {
    switch (action.type) {
        case OrderStepsActionTypes.SET_CURRENT_STEP:
            return { ...state, currentStep: action.payload.currentStep };
        default:
            return state;
    }
}
