import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule } from '@angular/forms';
import { OrderFormComment } from '@trade-platform/ui-shared';
import { commentStatus } from '@trade-platform/lib-enums';
import { NgClass } from '@angular/common';

@Component({
    selector: 'aix-order-form-resolve-comment',
    templateUrl: 'order-form-resolve-comment.html',
    styleUrls: ['order-form-resolve-comment.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, FormsModule]
})
export class AixOrderFormResolveCommentComponent implements OnChanges {
    @Input() comment: OrderFormComment;
    @Input() parentForm: UntypedFormGroup;

    resolved = false;

    constructor(private ref: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.comment && changes.comment.currentValue) {
            this.resolved = changes.comment.currentValue.status === commentStatus.resolved;
            this.ref.detectChanges();
        }
    }

    onCheckChange(resolved: boolean) {
        (this.parentForm.get(this.comment.id.toString()) as UntypedFormControl).setValue(resolved);
        this.ref.detectChanges();
    }
}
