import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { privacyPolicyDecoder } from '../../decoders/privacy-policy/decoder';
import { PrivacyPolicy } from '../../models/privacy-policy/model';

@Injectable()
export class PrivacyPolicyService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getPrivacyPolicy(): Observable<PrivacyPolicy> {
        return this.http
            .get<PrivacyPolicy>(`${this.environment.api}privacy`)
            .pipe(concatMap(p => fromPromise(privacyPolicyDecoder.decodeToPromise(p))));
    }
}
