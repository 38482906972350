import {
    AixAction,
    ErrorWrapper,
    OrderMarkNeedsClarificationRequest,
    type
} from '@trade-platform/ui-shared';

export class OrderAdminActionTypes {
    static readonly ORDER_NIGO_TO_NEEDS_CLARIFICATION = type(
        '[OrderAdmin] Order Nigo To Needs Clarification'
    );
    static readonly ORDER_NIGO_TO_NEEDS_CLARIFICATION_SUCCESS = type(
        '[OrderAdmin] Order Nigo To Needs Clarification Success'
    );
    static readonly ORDER_NIGO_TO_NEEDS_CLARIFICATION_FAILURE = type(
        '[OrderAdmin] Order Nigo To Needs Clarification Failure'
    );

    static readonly ORDER_TO_WETSIGN = type('[OrderAdmin] Order ESign To Wetsign');
    static readonly ORDER_TO_WETSIGN_SUCCESS = type('[OrderAdmin] Order ESign To Wetsign Success');
    static readonly ORDER_TO_WETSIGN_FAILURE = type('[OrderAdmin] Order ESign To Wetsign Failure');

    static readonly ORDER_MARK_COMPLETE = type('[OrderAdmin] Order Mark Complete');
    static readonly ORDER_MARK_COMPLETE_SUCCESS = type('[OrderAdmin] Order Mark Complete Success');
    static readonly ORDER_MARK_COMPLETE_FAILURE = type('[OrderAdmin] Order Mark Complete Failure');

    static readonly ORDER_RESUBMIT = type('[OrderAdmin] Order Resubmit');
    static readonly ORDER_RESUBMIT_SUCCESS = type('[OrderAdmin] Order Resubmit Success');
    static readonly ORDER_RESUBMIT_FAILURE = type('[OrderAdmin] Order Resubmit Failure');

    static readonly ORDER_PUSH_UPDATED_FORM = type('[OrderAdmin] Push Updated Form To Order');
    static readonly ORDER_PUSH_UPDATED_FORM_SUCCESS = type(
        '[OrderAdmin] Push Updated Form To Order Success'
    );
    static readonly ORDER_PUSH_UPDATED_FORM_FAILURE = type(
        '[OrderAdmin] Push Updated Form To Order Failure'
    );

    static readonly ORDER_MOVE_BACK_IN_WORKFLOW = type('[OrderAdmin] Order Move Back In Workflow');
    static readonly ORDER_MOVE_BACK_IN_WORKFLOW_SUCCESS = type(
        '[OrderAdmin] Order Move Back In Workflow Success'
    );
    static readonly ORDER_MOVE_BACK_IN_WORKFLOW_FAILURE = type(
        '[OrderAdmin] Order Move Back In Workflow Failure'
    );

    static readonly ORDER_MOVE_FORWARD_IN_WORKFLOW = type(
        '[OrderAdmin] Order Move Forward In Workflow'
    );
    static readonly ORDER_MOVE_FORWARD_IN_WORKFLOW_SUCCESS = type(
        '[OrderAdmin] Order Move Forward In Workflow Success'
    );
    static readonly ORDER_MOVE_FORWARD_IN_WORKFLOW_FAILURE = type(
        '[OrderAdmin] Order Move Forward In Workflow Failure'
    );
}

export type OrderAdminActions =
    | OrderNigoToNeedsClarificationAction
    | OrderNigoToNeedsClarificationSuccessAction
    | OrderNigoToNeedsClarificationFailureAction
    | OrderToWetsignAction
    | OrderToWetsignSuccessAction
    | OrderToWetsignFailureAction
    | AdminMarkOrderCompleteAction
    | AdminMarkOrderCompleteSuccessAction
    | AdminMarkOrderCompleteFailureAction
    | AdminResubmitOrderAction
    | AdminResubmitOrderSuccessAction
    | AdminResubmitOrderFailureAction
    | AdminPushUpdatedFormToOrderAction
    | AdminPushUpdatedFormToOrderSuccessAction
    | AdminPushUpdatedFormToOrderFailureAction
    | AdminMoveOrderBackInWorkflowAction
    | AdminMoveOrderBackInWorkflowSuccessAction
    | AdminMoveOrderBackInWorkflowFailureAction
    | AdminMoveOrderForwardInWorkflowAction
    | AdminMoveOrderForwardInWorkflowSuccessAction
    | AdminMoveOrderForwardInWorkflowFailureAction;

export class OrderNigoToNeedsClarificationAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_NIGO_TO_NEEDS_CLARIFICATION;

    constructor(public payload: OrderMarkNeedsClarificationRequest) {}
}
export class OrderNigoToNeedsClarificationSuccessAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_NIGO_TO_NEEDS_CLARIFICATION_SUCCESS;
}
export class OrderNigoToNeedsClarificationFailureAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_NIGO_TO_NEEDS_CLARIFICATION_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class OrderToWetsignAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_TO_WETSIGN;

    constructor(public payload: { orderId: string }) {}
}
export class OrderToWetsignSuccessAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_TO_WETSIGN_SUCCESS;
}
export class OrderToWetsignFailureAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_TO_WETSIGN_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class AdminMarkOrderCompleteAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MARK_COMPLETE;

    constructor(public payload: { orderId: string }) {}
}
export class AdminMarkOrderCompleteSuccessAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MARK_COMPLETE_SUCCESS;
}
export class AdminMarkOrderCompleteFailureAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MARK_COMPLETE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class AdminResubmitOrderAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_RESUBMIT;

    constructor(public payload: { orderId: string }) {}
}
export class AdminResubmitOrderSuccessAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_RESUBMIT_SUCCESS;
}
export class AdminResubmitOrderFailureAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_RESUBMIT_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class AdminPushUpdatedFormToOrderAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_PUSH_UPDATED_FORM;

    constructor(public payload: { orderId: string; formId: string }) {}
}
export class AdminPushUpdatedFormToOrderSuccessAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_PUSH_UPDATED_FORM_SUCCESS;
}
export class AdminPushUpdatedFormToOrderFailureAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_PUSH_UPDATED_FORM_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class AdminMoveOrderBackInWorkflowAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MOVE_BACK_IN_WORKFLOW;

    constructor(public payload: { orderId: string }) {}
}
export class AdminMoveOrderBackInWorkflowSuccessAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MOVE_BACK_IN_WORKFLOW_SUCCESS;
}
export class AdminMoveOrderBackInWorkflowFailureAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MOVE_BACK_IN_WORKFLOW_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class AdminMoveOrderForwardInWorkflowAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MOVE_FORWARD_IN_WORKFLOW;

    constructor(public payload: { orderId: string }) {}
}
export class AdminMoveOrderForwardInWorkflowSuccessAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MOVE_FORWARD_IN_WORKFLOW_SUCCESS;
}
export class AdminMoveOrderForwardInWorkflowFailureAction implements AixAction {
    readonly type = OrderAdminActionTypes.ORDER_MOVE_FORWARD_IN_WORKFLOW_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
