<ul class="aix-viewer__header br-primary__color">
    @for (tab of tabs(); track tab.title()) {
    <li
        (click)="selectTab(tab)"
        class="aix-viewer__header-title br-primary__color"
        [ngClass]="{
            'br-primary__border-color': tab.active(),
            inactive: !tab.active()
        }"
    >
        {{ tab.title() }}
    </li>
    }
</ul>

<div class="aix-viewer__content">
    <section class="-no-padding">
        <div class="aix-viewer__body-content">
            <ng-content></ng-content>
        </div>
    </section>
</div>
