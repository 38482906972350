import {
    AixAction,
    ErrorWrapper,
    OrderApprovalResponse,
    OrderApprovePayload,
    OrderIdPayload,
    type
} from '@trade-platform/ui-shared';

export class OrderApproveActionTypes {
    static readonly ORDER_APPROVE_START = type('[Order Approve Start] Order Approve Start');
    static readonly ORDER_APPROVE_START_SUCCESS = type(
        '[Order Approve Start] Order Approve Start Success'
    );
    static readonly ORDER_APPROVE_START_FAILURE = type(
        '[Order Approve Start] Order Approve Start Failure'
    );
    static readonly ORDER_APPROVE_START_RESET = type(
        '[Order Approve Start] Order Approve Start Reset'
    );

    static readonly ORDER_APPROVE_RESUME = type('[Order Approve Resume] Order Approve Resume');
    static readonly ORDER_APPROVE_RESUME_SUCCESS = type(
        '[Order Approve Resume] Order Approve Resume Success'
    );
    static readonly ORDER_APPROVE_RESUME_FAILURE = type(
        '[Order Approve Resume] Order Approve Resume Failure'
    );

    static readonly ORDER_APPROVE = type('[Order Approve] Order Approve');
    static readonly ORDER_APPROVE_SUCCESS = type('[Order Approve] Order Approve Success');
    static readonly ORDER_APPROVE_FAILURE = type('[Order Approve] Order Approve Failure');
}

export type OrderApproveActions =
    | OrderApproveStart
    | OrderApproveStartSuccess
    | OrderApproveStartFailure
    | OrderApproveStartReset
    | OrderApproveResume
    | OrderApproveResumeSuccess
    | OrderApproveResumeFailure
    | OrderApprove
    | OrderApproveSuccess
    | OrderApproveFailure;

export class OrderApproveStart implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_START;

    constructor(public payload: OrderIdPayload) {}
}
export class OrderApproveStartSuccess implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_START_SUCCESS;

    constructor() {}
}
export class OrderApproveStartFailure implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_START_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
export class OrderApproveStartReset implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_START_RESET;

    constructor() {}
}

export class OrderApproveResume implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_RESUME;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderApproveResumeSuccess implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_RESUME_SUCCESS;

    constructor() {}
}

export class OrderApproveResumeFailure implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_RESUME_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}

export class OrderApprove implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE;

    constructor(public payload: OrderApprovePayload) {}
}
export class OrderApproveSuccess implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_SUCCESS;

    constructor(public payload: OrderApprovalResponse) {}
}
export class OrderApproveFailure implements AixAction {
    readonly type = OrderApproveActionTypes.ORDER_APPROVE_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
