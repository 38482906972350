import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderFinalizeActions, OrderFinalizeActionTypes } from './actions';

export type OrderFinalizeState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderFinalizeReducer = (
    state: OrderFinalizeState = initialState,
    action: OrderFinalizeActions
): OrderFinalizeState => {
    switch (action.type) {
        case OrderFinalizeActionTypes.ORDER_FINALIZE:
            return inProgress();
        case OrderFinalizeActionTypes.ORDER_FINALIZE_RESET:
            return notAsked();
        case OrderFinalizeActionTypes.ORDER_FINALIZE_SUCCESS:
            return success(null);
        case OrderFinalizeActionTypes.ORDER_FINALIZE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
