<div class="doc-preview">
    <aix-thumbnail
        [thumbnail]="data()?.thumbnail"
        [id]="data()?.id"
        (actionClick)="onActionClicked($event)"
    ></aix-thumbnail>

    <div class="flex-left-top">
        <div class="flex-col flex-left-top u-ml4 full-width">
            <div class="doc-preview__title-container full-width">
                <p class="doc-preview__title">{{ data()?.displayName }}</p>
            </div>
            <div class="doc-preview__description">{{ data()?.subtype }}</div>
            <div class="doc-preview__date">
                {{ data()?.documentDate | date : 'shortDate' : 'UTC' }}
            </div>
        </div>

        <!-- admin -->
        @if (isAdmin()) {
        <aix-inline-dropdown
            [dropdownOptions]="['View', 'Download', 'Remove']"
            (optionSelected)="onOptionSelected($event, data()?.id)"
            style="padding-top: 12px"
        >
        </aix-inline-dropdown>
        }
        <!-- !admin -->
        @if (!isAdmin()) {
        <aix-loading-button
            [isLoading]="downloading()"
            (click)="onClickDownload(data()?.id)"
            [buttonStyle]="'tertiary'"
            [icon]="'fa-regular fa-download'"
            style="padding-top: 12px"
        ></aix-loading-button>
        }
    </div>
</div>
