/**
 * Action Types
 */
import { type } from '../models/type-cache';
import { AixAction } from '../aix-action';

export class DynamicFormsActionTypes {
    static readonly SHOW_SECOND_AUTH_PERSON = type('[DynamicForms] Show Second Authorized Person');
    static readonly HIDE_SECOND_AUTH_PERSON = type('[DynamicForms] Hide Second Authorized Person');
    static readonly SHOW_AUTH_PERSON = type('[DynamicForms] Show Authorized Person');
    static readonly HIDE_AUTH_PERSON = type('[DynamicForms] Hide Authorized Person');
}

/**
 * Actions
 */
export class ShowSecondAuthorizedPersonAction implements AixAction {
    readonly type = DynamicFormsActionTypes.SHOW_SECOND_AUTH_PERSON;

    constructor() {}
}

export class HideSecondAuthorizedPersonAction implements AixAction {
    readonly type = DynamicFormsActionTypes.HIDE_SECOND_AUTH_PERSON;

    constructor() {}
}

export class ShowAuthorizedPersonAction implements AixAction {
    readonly type = DynamicFormsActionTypes.SHOW_AUTH_PERSON;

    constructor(public payload: number) {}
}

export class HideAuthorizedPersonAction implements AixAction {
    readonly type = DynamicFormsActionTypes.HIDE_AUTH_PERSON;

    constructor(public payload: number) {}
}
