import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'aixConsole',
    standalone: true
})
export class AixConsolePipe implements PipeTransform {
    transform(obj: any) {
        console.log(obj);
    }
}
