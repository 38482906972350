export class Init {
    readonly type = 'INIT';

    constructor() {}
}

export class InitSSO {
    readonly type = 'INIT_SSO';

    constructor() {}
}

export class Register {
    readonly type = 'REGISTER';

    constructor() {}
}

export class LoadTokenSuccess {
    readonly type = 'TOKEN_SUCCESS';

    constructor() {}
}

export class LoadTokenFailure {
    readonly type = 'TOKEN_FAILURE';

    constructor(public errors: string[]) {}
}

export class IgnoreOutages {
    readonly type = 'IGNORE_OUTAGES';

    constructor() {}
}

export class HasOutages {
    readonly type = 'HAS_OUTAGES';

    constructor() {}
}

export class HasNoOutages {
    readonly type = 'HAS_NO_OUTAGES';

    constructor() {}
}

export class LoadOutagesFailure {
    readonly type = 'OUTAGES_FAILURE';

    constructor(public errors: string[]) {}
}

export class LoadProfileFailure {
    readonly type = 'PROFILE_FAILURE';

    constructor(public errors: string[]) {}
}

export class TermsAccepted {
    readonly type = 'TERMS_ACCEPTED';

    constructor() {}
}

export class TermsNotAccepted {
    readonly type = 'TERMS_NOT_ACCEPTED';

    constructor() {}
}

export class Redirect {
    readonly type = 'REDIRECT';

    constructor() {}
}

export class Logout {
    readonly type = 'LOGOUT';

    constructor(public urlAfterLogin: string | null) {}
}

export type LoginEvent =
    | Init
    | InitSSO
    | Register
    | LoadTokenSuccess
    | LoadTokenFailure
    | IgnoreOutages
    | HasOutages
    | HasNoOutages
    | LoadOutagesFailure
    | LoadProfileFailure
    | TermsAccepted
    | TermsNotAccepted
    | Redirect
    | Logout;
