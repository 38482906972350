import { ChangeDetectorRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { differenceInYears, subYears, addDays, format } from 'date-fns';
import { padEnd } from 'lodash-es';

import { combineLatest, merge, Observable, of } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { parseFormsDate } from '@trade-platform/ui-utils';
import {
    ControlFieldConfig,
    FieldConfig,
    flattenFieldConfig,
    FreeTextFieldConfig,
    isControlConfig,
    isExpandableTextConfig,
    isFreeTextConfig,
    isNotificationConfig
} from '@trade-platform/form-fields';
import { FieldEvent } from './components/field.interface';
import { DynamicFormControlState, DynamicFormState } from './dynamic-form-store/model';
import { FormUID } from './dynamic-form-store/utils';
import { fold, isRemoteData } from 'ngx-remotedata';

/**
 * Generates a unique refId.
 * refIds have to start with an a-z A-Z character.
 */

export const generateRefId = () => `ref-${uuid()}`;

// ----------------------------
// Derived Value Computations
// ----------------------------

export function getStoreObservableByPath(path: string, store: Store<any>): Observable<any> {
    const res = store.pipe(
        select(state => {
            return path.split('.').reduce((acc, cur) => {
                if (!acc) {
                    return null;
                }
                let val;
                /**
                 * When `acc[cur]` is a `RemoteData` we default to `undefined`
                 * for all `RemoteData` variants except for the `Success` variant.
                 */
                if (acc[cur] !== undefined && acc[cur] !== null && isRemoteData(acc[cur])) {
                    val = fold(
                        () => undefined,
                        () => undefined,
                        () => undefined,
                        value => value,
                        acc[cur]
                    );
                } else {
                    val = acc[cur];
                }
                return val;
            }, state);
        })
    );
    return res;
}

export type ObservableMapFuncts = { [index: string]: (...any: any[]) => Observable<any> };
export const addDefaultObservableMapFuncts = (observableMapFuncts?: ObservableMapFuncts) => {
    const observableMapFuncts_ = observableMapFuncts || {};
    return {
        ...observableMapFuncts_,
        /**
         * To be used in form fields' `value` property.
         * i.e. `value: '$aixReplace("Full name: $1 $2, email: $3", @[order.account.investors.0.firstName], @[order.account.investors.0.lastName], @[order.account.investors.0.emailPrimary])'`
         *
         * @param str A string with tokens to be substituted with store observable values. (Tokens are index 1 based, meaning that the first token is `$1`, not `$0`)
         *            i.e. `"Full name: $1 $2, email: $3"`
         * @param observables The store observable references to be used as token substitution values.
         */
        aixReplace(str: string, observables: Observable<any>[]) {
            return combineLatest(observables).pipe(
                map(values => {
                    return values.reduce((acc: string, value: string, index: number) => {
                        return acc.replace(`$${index + 1}`, value);
                    }, str);
                })
            );
        },
        /**
         * To be used in form fields' `value` property.
         * i.e. `value: '$aixConcatAll(List("order.account.investors").forEach("email: @emailPrimary name: @fullName"), "\r")'`
         *
         * @param expression The string with @tokens that we have to replace with actual values
         *                   i.e. `"email: @emailPrimary name: @fullName"`
         * @param storeObservables An array of observables and their associated tokens to iterate over.
         * @param separator The separator between the replaced expressions.
         *                  i.e. `", "`
         * @param tokensPerExpression How many `@` tokens there are for expression.
         */
        aixConcatAll(
            expression: string,
            storeObservables: { token: string; observable: Observable<any> }[],
            separator: string,
            tokensPerExpression: number
        ) {
            const observables = storeObservables.map(storeObs => storeObs.observable);
            return combineLatest(observables).pipe(
                map(values => {
                    const numExpressions = storeObservables.length / tokensPerExpression;
                    return new Array(numExpressions).fill({ expression }).map((ex, index) => {
                        const startIndex = index * tokensPerExpression;
                        return {
                            ...ex,
                            values: values.slice(startIndex, startIndex + tokensPerExpression)
                        };
                    });
                }),
                map((values: { expression: string; values: string[] }[]) => {
                    return values
                        .map(value => {
                            return value.values.reduce(
                                (
                                    replacedExpression: string,
                                    currentValue: string,
                                    index: number
                                ) => {
                                    replacedExpression = replacedExpression.replace(
                                        storeObservables[index].token,
                                        currentValue
                                    );
                                    return replacedExpression;
                                },
                                value.expression
                            );
                        })
                        .join(separator);
                })
            );
        },
        calculateAgeFromDate: (dateString$: Observable<string>) => {
            return dateString$.pipe(
                map(dateString => {
                    const date = parseFormsDate(dateString);
                    if (date) {
                        return differenceInYears(new Date(), date);
                    }
                    return null;
                })
            );
        },
        lastYear: () => {
            return of(subYears(new Date(), 1).toISOString());
        },
        calculateDatePlusDays: (dateString$: Observable<string>, days: number) => {
            return dateString$.pipe(
                map(dateString => {
                    const date = parseFormsDate(dateString);
                    if (date) {
                        return format(addDays(date, days), 'MM/dd/yyyy');
                    }
                    return null;
                })
            );
        }
    };
};

/**
 *
 * @param fields The FieldConfig where we search for special syntaxes in 'value', 'oneOf' or 'anyOf'
 * @param store The Store where the observables will be searched in.
 * @param observableMapFuncts Higher Order Observable functions that can be used in value expressions.
 * i.e. `value="$someThing(@[order.account.owner.name])"` someThing would have to be declared here.
 * An example:
 * const mapFuncts = {
 *      extractFirstRepCode: (obs: Observable<ProfileRepCode[]>) => {
 *          return obs.pipe(map(value => value[0].id));
 *      }
 * };
 */
export const computeDerivedValues = (
    fields: FieldConfig[],
    store: Store<any>,
    observableMapFuncts_?: ObservableMapFuncts
): FieldConfig[] => {
    const observableMapFuncts = addDefaultObservableMapFuncts(observableMapFuncts_);
    const props = ['value', 'oneOf', 'anyOf', 'text'];
    const flattened = flattenFieldConfig(fields);

    const ctrls = flattened.filter(
        field =>
            isControlConfig(field) ||
            isFreeTextConfig(field) ||
            isExpandableTextConfig(field) ||
            isNotificationConfig(field)
    );
    // Matches i.e. @[order.account.owner.name], the content; order.account.owner.name; is in captured group $1.
    const inMemoryValueRegExp = /^@\[([^\]]+)\]$/;

    // Matches i.e. $someThing(@[order.account.owner.name]), someThing is in captured group $1, order.account.owner.name in $2.
    const mapValueRegExp = /^\$([^\(]+)\(@\[([^\(]+)\]\)$/;

    // Matches i.e. $someThing(), someThing is in captured group $1.
    const mapValueRegExp2 = /^\$([^\(]+)\(\)$/;

    // Matches i.e. $aixReplace("name: $1, email: $2", @[d.e.name], @[a.b.email])
    const mapReplaceValueRegExp = /^\$aixReplace\("([^"]+)", ?((?:@\[[^\(]+\],? ?){1,20})\)$/;

    // Matches i.e. $calculateDatePlusDays(@[d.e.date], {number of days})
    const mapCalculateDatePlusDaysValueRegExp = /^\$calculateDatePlusDays\(@\[([^\(]+)\], (\d+)\)$/;

    // Matches.....
    const mapConcatAllValueRegExp =
        /^\$aixConcatAll\(List\("([^"]+)"\)\.forEach\("([^"]+)"\), ?"([^"]+)"\)$/;

    ctrls.forEach(field => {
        props.forEach(prop => {
            if (typeof field[prop as keyof FieldConfig] === 'string') {
                if (
                    (prop === 'value' || prop === 'text') &&
                    mapReplaceValueRegExp.test(
                        (field as ControlFieldConfig & FreeTextFieldConfig)[prop]
                    )
                ) {
                    mapReplaceValueFn(
                        field as ControlFieldConfig & FreeTextFieldConfig,
                        prop,
                        mapReplaceValueRegExp,
                        store,
                        observableMapFuncts
                    );
                } else if (
                    prop === 'value' &&
                    mapConcatAllValueRegExp.test((field as ControlFieldConfig).value)
                ) {
                    mapConcatAllValueFn(
                        field as ControlFieldConfig,
                        prop,
                        mapConcatAllValueRegExp,
                        store,
                        observableMapFuncts
                    );
                } else if (
                    prop === 'value' &&
                    mapCalculateDatePlusDaysValueRegExp.test((field as ControlFieldConfig).value)
                ) {
                    mapCalculateDatePlusDaysValueFn(
                        field as ControlFieldConfig,
                        prop,
                        mapCalculateDatePlusDaysValueRegExp,
                        store,
                        observableMapFuncts
                    );
                } else {
                    if (mapValueRegExp.test((field as Record<string, any>)[prop])) {
                        mapValueFn(
                            field as ControlFieldConfig & FreeTextFieldConfig,
                            prop as keyof ControlFieldConfig & FreeTextFieldConfig,
                            mapValueRegExp,
                            observableMapFuncts,
                            store
                        );
                    } else if (mapValueRegExp2.test((field as Record<string, any>)[prop])) {
                        const anyField = field as Record<string, any>;
                        const funcName: keyof typeof observableMapFuncts =
                            anyField[prop].match(mapValueRegExp2)[1];
                        const fn = observableMapFuncts[funcName] as () => Observable<any>;
                        anyField[prop] = fn();
                    } else if (inMemoryValueRegExp.test((field as Record<string, any>)[prop])) {
                        const anyField = field as Record<string, any>;
                        anyField[prop] = getStoreObservableByPath(
                            anyField[prop].match(inMemoryValueRegExp)[1],
                            store
                        );
                    }
                }
            }
        });
    });
    return fields;
};

// Derived Value Computations Utils

function mapReplaceValueFn(
    field: ControlFieldConfig & FreeTextFieldConfig,
    prop: keyof ControlFieldConfig | keyof FreeTextFieldConfig,
    mapReplaceValueRegExp: RegExp,
    store: Store<any>,
    observableMapFuncts: ObservableMapFuncts
) {
    const [, stringWithTokens, storePathStrings] = field[prop].match(mapReplaceValueRegExp);
    const storePathRegExp = () => /@\[([^\]]+)\]*/g;
    const storeObservables = [];
    let storePaths: string[] | null;
    const regExp = storePathRegExp();
    while ((storePaths = regExp.exec(storePathStrings))) {
        const [, storePath] = storePaths;
        const derivedValueComputation = getStoreObservableByPath(storePath, store);
        storeObservables.push(derivedValueComputation);
    }
    (field as any)[prop] = observableMapFuncts.aixReplace(stringWithTokens, storeObservables);
}

function mapConcatAllValueFn(
    field: ControlFieldConfig,
    prop: keyof ControlFieldConfig,
    mapConcatAllValueRegExp: RegExp,
    store: Store<any>,
    observableMapFuncts: ObservableMapFuncts
) {
    const [, groupPath, expression, separator] = field[prop].match(mapConcatAllValueRegExp);
    let groupArray: any[];
    groupArray = [];
    getStoreObservableByPath(groupPath, store)
        .subscribe((arr: any[]) => {
            groupArray = arr;
        })
        .unsubscribe();
    const storeRefRegExp = () => /@([a-zA-Z0-9_]+)/g;
    const storeObservables: {
        token: string;
        observable: Observable<any>;
    }[] = [];
    let tokensPerExpression = 0;
    groupArray.forEach((_, index) => {
        const regExp = storeRefRegExp();
        let storeRef;
        tokensPerExpression = 0;
        while ((storeRef = regExp.exec(expression))) {
            const [atStorePathKey, storePathKey] = storeRef;
            const storePath = `${groupPath}.${index}.${storePathKey}`;
            const derivedValueComputation = getStoreObservableByPath(storePath, store);
            storeObservables.push({
                token: atStorePathKey,
                observable: derivedValueComputation
            });
            tokensPerExpression++;
        }
    });
    (field as any)[prop] = observableMapFuncts.aixConcatAll(
        expression,
        storeObservables,
        separator,
        tokensPerExpression
    );
}

function mapCalculateDatePlusDaysValueFn(
    field: ControlFieldConfig,
    prop: keyof ControlFieldConfig,
    mapReplaceValueRegExp: RegExp,
    store: Store<any>,
    observableMapFuncts: ObservableMapFuncts
) {
    const [, storePath, value] = field[prop].match(mapReplaceValueRegExp);
    const storeObservable = getStoreObservableByPath(storePath, store);
    (field as any)[prop] = observableMapFuncts.calculateDatePlusDays(storeObservable, value);
}

function mapValueFn(
    field: ControlFieldConfig & FreeTextFieldConfig,
    prop: keyof ControlFieldConfig | keyof FreeTextFieldConfig,
    mapValueRegExp: RegExp,
    observableMapFuncts: ObservableMapFuncts,
    store: Store<any>
) {
    const regexpRes = field[prop].match(mapValueRegExp);
    const mapFunctName: string = regexpRes[1];
    const path: string = regexpRes[2];
    const mapFunct = observableMapFuncts[mapFunctName];
    if (!mapFunct) {
        throw new Error(
            `Error parsing derived form value expression "${
                field.value
            }" in field: "${JSON.stringify(field)}"`
        );
    }
    const derivedValueComputation = getStoreObservableByPath(path, store);
    (field as any)[prop] = mapFunct(derivedValueComputation);
}

// Change detection utils

// This tells how often we calculate show relations
// auditTime => runs after x ms passed since last call and at least once after x ms passed since last call
const SHOW_RELATIONS_AUDIT_TIME = 1000 / 10;

export const showRelationsHeuristic = (
    store: Store<Record<string, DynamicFormState>>,
    formUID: FormUID,
    cd: ChangeDetectorRef
) => {
    return merge(
        store.pipe(
            select(state => {
                return state[formUID.value] ? state[formUID.value].controls : {};
            })
        ),
        store.pipe(
            select(state => {
                return state[formUID.value] ? state[formUID.value].data : {};
            })
        )
    )
        .pipe(auditTime(SHOW_RELATIONS_AUDIT_TIME))
        .subscribe(_ => {
            cd.detectChanges();
        });
};

const unMaskFloatValue = (maskedValue: number | string | null): string => {
    const val: string | null =
        typeof maskedValue === 'number' ? maskedValue.toString() : maskedValue;
    const cleanedValue = val ? val.replace(/[^0-9\.]/g, '') : val;
    const splitted = cleanedValue && cleanedValue.length > 0 ? cleanedValue.split('.') : [];
    const wholeNumber = splitted.shift();
    const decimals = splitted && splitted.length > 0 ? splitted.join('') : '';
    return wholeNumber && wholeNumber.length > 0 && cleanedValue
        ? `${wholeNumber}${decimals && decimals.length > 0 ? '.' + decimals : ''}`
        : '';
};

// Calculate Decimal Limited value

export const calculateDecimalLimitedValue = (
    value: string | number | null,
    decimalLimit = 2,
    allowDecimal = true,
    allowNegative = false
): string | null => {
    if (value === null) {
        return null;
    }

    const unmaskedValue = unMaskFloatValue(value);

    let res: string;
    const [integerPart, decimalPart] = unmaskedValue.split('.');
    if (allowDecimal && unmaskedValue !== '') {
        const decimal = decimalPart || '';
        res = `${integerPart}.${padEnd(decimal, decimalLimit, '0').substr(0, decimalLimit)}`;
    } else {
        res = integerPart;
    }

    const result: string[] = ('' + value).split('');
    const negative = allowNegative && result[0] === '-' && value !== 0;

    res = negative ? ['-'].concat(res).join('') : res;

    return res;
};

/**
 * Creates events so the host dynamic control can Bubble them down the Html tree
 * and groups can display notifications when server validation errors are found.
 */
export const handleServerValdationErrors = (
    ctrl: DynamicFormControlState,
    dispatchEvent: (evt: CustomEvent<{ refId: string; message: string }>) => void
) => {
    if (ctrl.injectedServerValidationErrors.length > 0) {
        ctrl.injectedServerValidationErrors.forEach(message => {
            const aixEvt = new CustomEvent(FieldEvent.SERVER_VALIDATION_ERROR, {
                detail: {
                    refId: ctrl.fieldConfig.refId as string,
                    message:
                        message +
                        '<p class="u-fw500">Changes will not be saved until all errors are resolved.</p>'
                },
                bubbles: true,
                cancelable: true
            });
            dispatchEvent(aixEvt);
        });
    }
};
