import { SharedRepCodesGridModel } from '../../models/shared-rep-codes-grid/model';
import { SharedRepCodesGridActions, SharedRepCodesGridActionTypes } from './actions';

const initialState = (): SharedRepCodesGridModel => ({
    notification: null,
    rowData: null
});

export function SharedRepCodesGridReducer(
    state: SharedRepCodesGridModel = initialState(),
    action: SharedRepCodesGridActions
): SharedRepCodesGridModel {
    switch (action.type) {
        case SharedRepCodesGridActionTypes.OPEN_NOTIFICATION:
            return { ...state, notification: action.payload.notification };
        case SharedRepCodesGridActionTypes.CLOSE_NOTIFICATION:
            return { ...state, notification: null };
        case SharedRepCodesGridActionTypes.OPEN_UNSHARE_MODAL:
            return { ...state, rowData: action.payload.rowData };
        default:
            return state;
    }
}
