import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderResendResponse } from '@trade-platform/ui-shared';
import { OrderResendActions, OrderResendActionTypes } from './actions';

export type OrderResendState = RemoteData<OrderResendResponse, ErrorWrapper>;
const initialState = notAsked<OrderResendResponse, ErrorWrapper>();

export const orderResendReducer = (
    state: OrderResendState = initialState,
    action: OrderResendActions
): OrderResendState => {
    switch (action.type) {
        case OrderResendActionTypes.ORDER_RESEND:
            return inProgress();
        case OrderResendActionTypes.ORDER_RESEND_SUCCESS:
            return success(action.payload);
        case OrderResendActionTypes.ORDER_RESEND_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
