import { FieldConfig } from '@trade-platform/form-fields';

export const FieldEvent = {
    CHANGE: 'aixFormControlChange',
    SCROLL: 'aixScrollToField',
    SERVER_VALIDATION_ERROR: 'aixServerValidationError'
};

export interface Field {
    config: FieldConfig;
}
