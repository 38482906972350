import {
    AixAction,
    ErrorWrapper,
    OrderMarkNigoRequest,
    OrderMarkNigoResponse,
    type
} from '@trade-platform/ui-shared';

export class OrderNigoActionTypes {
    static readonly ORDER_MARK_NIGO = type('[OrderNigo] Mark Order NIGO');
    static readonly ORDER_MARK_NIGO_SUCCESS = type('[OrderNigo] Mark Order NIGO Success');
    static readonly ORDER_MARK_NIGO_FAILURE = type('[OrderNigo] Mark Order Nigo Failure');
}

export type OrderNigoActions =
    | OrderMarkNigoAction
    | OrderMarkNigoSuccessAction
    | OrderMarkNigoFailureAction;

export class OrderMarkNigoAction implements AixAction {
    readonly type = OrderNigoActionTypes.ORDER_MARK_NIGO;

    constructor(public payload: OrderMarkNigoRequest) {}
}
export class OrderMarkNigoSuccessAction implements AixAction {
    readonly type = OrderNigoActionTypes.ORDER_MARK_NIGO_SUCCESS;

    constructor(public payload: OrderMarkNigoResponse) {}
}
export class OrderMarkNigoFailureAction implements AixAction {
    readonly type = OrderNigoActionTypes.ORDER_MARK_NIGO_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
