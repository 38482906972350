import { isArray } from 'lodash-es';
import { objectHasValue } from './object-utils';

/**
 * Converts the pdf name to an url friendly pdf filename.
 * Replaces spaces by '_' and the appends '.pdf' extension.
 *
 * @param name The pdf name
 */
export const pdfName = (name: string): string => {
    if (!name) {
        return '';
    }
    return name.replace(/ /g, '-') + '.pdf';
};

/**
 * Returns true when the string is not null nor undefined nor ''
 * @param obj
 * @deprecated Use `isNonEmptyString` instead.
 *             It's not a drop-in replacement, so you may need to adjust your logic first.
 * @see isNonEmptyString
 */
export const stringIsNotEmpty = (obj: any) => objectHasValue(obj) && obj !== '';

/**
 * Returns `true` when `s` is a `string` and its `length` is more than `0`.
 *
 * @example
 * ```ts
 * isNonEmptyString(null) // false
 * isNonEmptyString(undefined) // false
 * isNonEmptyString(true as any) // false
 * isNonEmptyString('') // false
 * isNonEmptyString('a') // true
 * ```
 */
export const isNonEmptyString = (s?: string) => typeof s === 'string' && s.length > 0;

/**
 * Returns a number from a number or a currency ''
 * @param value
 */
export const currencyToNumber = (value: any) => {
    let result = value;

    if (isNaN(value)) {
        if (value.indexOf('$') > -1) {
            result = value.substring(1);
        }
        result = parseInt(result.split(',').join('').split('.')[0], 10);
    }

    return result;
};

/**
 * Returns an object transformed to string with a query string format
 * @param obj
 */
export const stringify = (obj: any) => {
    const params = new URLSearchParams();
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (value && isArray(value)) {
            value.forEach(val => params.append(key, val));
        } else {
            params.append(key, value ?? '');
        }
    });
    return params.toString();
};
