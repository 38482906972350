import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderReinstateAction,
    OrderReinstateActionTypes,
    OrderReinstateFailureAction,
    OrderReinstateSuccessAction
} from './actions';
import { OrderReinstateService } from './service';

@Injectable()
export class OrderReinstateEffects {
    constructor(private actions$: Actions, private service: OrderReinstateService) {}

    orderReinstate$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderReinstateAction>(OrderReinstateActionTypes.ORDER_REINSTATE),
            switchMap(action =>
                this.service.orderReinstate(action.payload.orderId).pipe(
                    map(res => new OrderReinstateSuccessAction(res)),
                    catchHttpError(error => of(new OrderReinstateFailureAction({ error })))
                )
            )
        )
    );
}
