import { ErrorWrapper } from '@trade-platform/ui-shared';
import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { BrandingActions, BrandingActionTypes } from './actions';
import { Brand } from '../../models/branding/model';

export type BrandingState = RemoteData<Brand, ErrorWrapper>;

export function brandingReducer(
    state: BrandingState = notAsked(),
    action: BrandingActions
): BrandingState {
    switch (action.type) {
        // Load Branding
        case BrandingActionTypes.LOAD_BRANDING:
            return inProgress();
        case BrandingActionTypes.LOAD_BRANDING_SUCCESS:
            return success(action.payload);
        case BrandingActionTypes.LOAD_BRANDING_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export function applyBrandingReducer(
    state: BrandingState = notAsked(),
    action: BrandingActions
): BrandingState {
    switch (action.type) {
        // Apply Branding
        case BrandingActionTypes.APPLY_BRANDING:
            return inProgress();
        case BrandingActionTypes.APPLY_BRANDING_SUCCESS:
            return success(action.payload);
        case BrandingActionTypes.APPLY_BRANDING_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export function saveBrandingReducer(
    state: BrandingState = notAsked(),
    action: BrandingActions
): BrandingState {
    switch (action.type) {
        // Save Branding
        case BrandingActionTypes.SAVE_BRANDING:
            return inProgress();
        case BrandingActionTypes.SAVE_BRANDING_SUCCESS:
            return success(action.payload);
        case BrandingActionTypes.SAVE_BRANDING_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}

export function previewBrandingReducer(
    state: BrandingState = notAsked(),
    action: BrandingActions
): BrandingState {
    switch (action.type) {
        // Preview Branding
        case BrandingActionTypes.PREVIEW_BRANDING:
            return inProgress();
        case BrandingActionTypes.PREVIEW_BRANDING_SUCCESS:
            return success(action.payload);
        default:
            return state;
    }
}

export function clearPreviewBrandingReducer(
    state: BrandingState = notAsked(),
    action: BrandingActions
): BrandingState {
    switch (action.type) {
        // Clear Preview Branding
        case BrandingActionTypes.CLEAR_PREVIEW_BRANDING:
            return inProgress();
        case BrandingActionTypes.CLEAR_PREVIEW_BRANDING_SUCCESS:
            return success(null as any);
        default:
            return state;
    }
}

export function activePreviewBrandingReducer(
    state: BrandingState = notAsked(),
    action: BrandingActions
): BrandingState {
    switch (action.type) {
        // Get Active Preview Branding
        case BrandingActionTypes.GET_ACTIVE_PREVIEW_BRANDING:
            return inProgress();
        case BrandingActionTypes.GET_ACTIVE_PREVIEW_BRANDING_SUCCESS:
            return success(action.payload);
        default:
            return state;
    }
}
