import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
    AixOrderFormCommentsComponent,
    OrderFormCommentsMode
} from '../../order-comments/order-form-comments/order-form-comments';
import { formStatus } from '@trade-platform/lib-enums';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixExpansionPanelComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { DocumentFile, OrderForm, OrderFormComment } from '@trade-platform/ui-shared';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'aix-sidebar',
    templateUrl: 'sidebar.html',
    styleUrls: ['./sidebar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixButtonComponent,
        NgFor,
        AixDataTestingDirective,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        AixOrderFormCommentsComponent,
        AixExpansionPanelComponent
    ]
})
export class AixSidebarComponent implements OnInit, OnDestroy {
    @Input()
    parentForm: UntypedFormGroup;
    @Input()
    comments: OrderFormComment[];
    @Input()
    isReadOnly: boolean | undefined | null = false;

    clickElem = output<string>();
    clickCancel = output();

    subscriptions: Subscription[] = [];
    arrayForms: OrderForm[] = [];
    completedForms = 0;
    selectedFormId = '';
    selectedForm: DocumentFile;

    isCommentsClosed = false;
    commentsMode: OrderFormCommentsMode = 'resolve';

    orderFormCommentsHeaderClass = {
        'order-form-comments__no-border': true,
        'order-form-comments__header': true
    };
    orderFormCommentsBodyClass = {
        'order-form-comments__no-border': true
    };

    readonly formStatus = formStatus;

    goToOverviewButtonType = BUTTON_TYPE.secondary;
    cancelButtonType = BUTTON_TYPE.link;

    constructor(
        public router: Router,
        public ref: ChangeDetectorRef,
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                this.selectedFormId = params.formId;
                this.selectedForm = {
                    id: '',
                    type: '',
                    formId: params.formId
                } as DocumentFile;
            }),
            this.store.actions.getOrder.success$.subscribe(action => {
                this.arrayForms = Object.keys(action.payload.order.forms)
                    .map(itm => action.payload.order.forms[itm])
                    .filter(f => f.isOnboarded !== false)
                    .sort((a, b): number => a.index - b.index);
                this.completedForms = this.arrayForms.filter(
                    form => form.status === formStatus.completed
                ).length;
                this.loadOrderFormComments();
                this.cd.detectChanges();
            })
        );
    }

    loadOrderFormComments() {
        const order = this.store.order;
        this.store.actions.getFormComments.dispatch({
            data: {
                orderId: order.id
            }
        });
    }

    clickEvent(id: string) {
        this.clickElem.emit(id);
    }

    goToOverview() {
        const order = this.store.order;
        if (order) {
            this.router.navigate(this.store.routes.process.overview(order.id));
        }
    }

    cancelOrder() {
        this.clickCancel.emit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
