import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { sanitizeHtml } from '../security';

@Pipe({
    name: 'aixSanitize',
    standalone: true
})
export class AixSanitizePipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    transform(text: string) {
        return this.domSanitizer.bypassSecurityTrustHtml(sanitizeHtml(text));
    }
}
