@if ((privacyPolicyState$ | async | isInProgress) || (privacyPolicyState$ | async | isNotAsked)) {
<aix-loading [relative]="true"></aix-loading>
}
<aix-error-box [states]="states"></aix-error-box>
@if ((privacyPolicyState$ | async | isSuccess)) {
<div class="privacy-policy-container">
    <div class="privacy-policy-form">
        <div class="privacy-policy-header">
            <h1 class="privacy-policy-title">Privacy Policy</h1>
            <div class="privacy-policy-icons">
                <aix-print-button [type]="'link'"></aix-print-button>
            </div>
        </div>

        <article
            class="privacy-policy-body"
            [innerHTML]="(privacyPolicy$ | async)?.body | unescapeQuotes | sanitizeHtml"
        ></article>
    </div>
</div>
}
