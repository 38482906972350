import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unescapeQuotes',
    standalone: true
})
export class UnescapeQuotesPipe implements PipeTransform {
    transform(value?: string) {
        if (value) {
            return value.replace(/\\"/g, '"');
        } else {
            return '';
        }
    }
}
