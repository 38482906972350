<!-- HEADER -->
<div
    class="aix-expansion-header"
    [data-testing]="'expansion-header'"
    [class.disabled]="isDisabled()"
    [class.aix-expansion-header__right]="arrowPosition() === 'right'"
    [ngClass]="headerNgClass()"
    (click)="toggle()"
>
    @if (arrowPosition() === 'left') {
    <span class="aix-expansion-icon u-mr8" [ngClass]="arrowNgClass()">
        <i
            class="fa-solid fa-lg"
            [ngClass]="{ 'fa-caret-down': !isClosed(), 'fa-caret-up': isClosed() }"
        ></i>
    </span>
    }
    <ng-container
        [ngTemplateOutlet]="headerTemplate()"
        [ngTemplateOutletContext]="headerTemplateContext()"
    ></ng-container>
    @if (arrowPosition() === 'right') {
    <span class="aix-expansion-icon u-mr16" [ngClass]="arrowNgClass()">
        <i
            class="fa-solid fa-lg"
            [ngClass]="{ 'fa-caret-down': !isClosed(), 'fa-caret-up': isClosed() }"
        ></i>
    </span>
    }
</div>

<!-- BODY -->
<div
    class="aix-expansion-container"
    [data-testing]="'expansion-container'"
    [class.hidden]="isClosed()"
    [ngClass]="bodyNgClass()"
>
    <ng-container *ngTemplateOutlet="bodyTemplate()"></ng-container>
</div>
