import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<IEnvironment>('ENVIRONMENT');

export interface IEnvironment {
    id: string;
    appVersion: string;
    production: boolean;
    enableDebugging: boolean;
    assets: Assets;
    auth0: Auth0;
    login: Login;
    api: string;
    outagesApi: string;
    adminApi: string;
    redirectApi?: string;
    apiDomain: string;
    adminApiDomain: string;
    brandingBucket?: string;
    ignoreOutages: boolean;
    contactDetails: ContactDetails;
    sentry: Sentry;
    fileUploadMaxSize: string;
    environment?: string;
    connectionsBucket?: string;
    docusignRedirectHost: string;
}

export interface Assets {
    logo: string;
}

export interface Auth0 {
    key: string;
    domain: string;
}

export interface ContactDetails {
    supportEmail: string;
    supportPhone: string;
    docusignEmail: string;
}

export interface Login {
    scope: string;
    response_type: string;
    audience: string;
    redirect_uri: string;
}

export interface Sentry {
    swallowExceptions: boolean;
    reportToSentry: boolean;
    environment: string;
    sendLatestActions: boolean;
    dsn: string;
}
