import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export interface Footnote {
    id: string;
    content: string;
}
@Injectable({
    providedIn: 'root'
})
export class AixFootnoteService {
    private _itemCountMap = new Map<string, number>(); // keep track of number of references to each item
    private _items: Footnote[] = [];

    private _itemsSub = new BehaviorSubject<Footnote[]>(this._items);
    public items$ = this._itemsSub.asObservable();

    /**
     * Sort items list by id
     */
    sortItems(): void {
        this._items.sort((a: Footnote, b: Footnote) => {
            return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
        });
    }

    /**
     * Add item to the items list.
     * If the item already exists, just increase its reference count.
     * If the items does not exist, add it to the items list.
     * @param item {Footnote} the item to add to the items list
     */
    addItem(item: Footnote): void {
        if (this._itemCountMap.has(item.id)) {
            const currentCount = this._itemCountMap.get(item.id) || 0;
            this._itemCountMap.set(item.id, currentCount + 1);
        } else {
            this._itemCountMap.set(item.id, 1);
            this._items.push(item);
            this.sortItems();
            this._itemsSub.next(this._items);
        }
    }

    /**
     * Remove item from the items list.
     * If the item has multiple references to it, just decrease its reference count.
     * If the item only has one reference to it, remove the item from the items list.
     * @param id {string} the id of the item to remove from the items list
     */
    removeItem(id: string): void {
        if (this._itemCountMap.has(id)) {
            let currentCount = this._itemCountMap.get(id) || 1;
            this._itemCountMap.set(id, --currentCount);

            if (currentCount === 0) {
                this._itemCountMap.delete(id);
                this._items = this._items.filter(i => i.id !== id);
                this._itemsSub.next(this._items);
            }
        }
    }
}
