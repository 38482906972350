import { padEnd, parseInt } from 'lodash-es';

export interface NumberMask {
    prefix?: string;
    suffix?: string;
    includeThousandsSeparator?: boolean;
    thousandsSeparatorSymbol?: string;
    allowDecimal?: boolean;
    decimalSymbol?: string;
    decimalLimit?: number;
    integerLimit?: number | null;
    requireDecimal?: boolean;
    allowNegative?: boolean;
    allowLeadingZeroes?: boolean;
}

export type DateFormat = 'mmddyyyy' | 'mmdd' | 'mmyyyy' | 'yyyy' | null | undefined;

// CUSTOM MASKS

export const handleCaret = (
    positions: number[],
    e: KeyboardEvent,
    inputElement: HTMLInputElement,
    posStart: number,
    posEnd?: number
) => {
    let sum;
    if (e) {
        switch (e.key) {
            case 'Delete':
                sum = 0;
                break;
            case 'Backspace':
                // Don't move the caret if there's a text selection
                sum = posStart !== posEnd ? 0 : -1;
                break;
            default:
                sum = positions.reduce((prev: number, current: number, i: number) => {
                    return prev === 2 || posStart === current ? 2 : 1;
                }, 1);
        }

        try {
            if (!(e.key.toLowerCase() === 'v' && (e.metaKey || e.ctrlKey))) {
                inputElement.setSelectionRange(
                    Math.max(posStart + sum, 0),
                    Math.max(posStart + sum, 0),
                    'none'
                );
            }
        } catch (e) {
            // Try catch for non IE or Safari browsers
            // Inputs type "number" doesn't have support of setSelectionRange in evergreen browsers
        }
    }
};

export const createSeparatorMask = (positions: number[] = [], length?: number, separator = '-') => {
    return (rawValue: string) => {
        rawValue = rawValue ? rawValue.slice(0, length ? length : rawValue.length) : rawValue;

        if (rawValue && rawValue.length > 0) {
            const result = rawValue.split('');
            positions.sort((a, b) => a - b);

            positions.forEach((element: number, i: number) => {
                if (rawValue.length > element) {
                    result.splice(element + i, 0, separator);
                }
            });

            return result.join('');
        }

        return rawValue;
    };
};

export const unMaskSeparatorValue = (
    maskedValue: string | null | undefined,
    maxLength?: number
): string | null | undefined => {
    return maskedValue
        ? maskedValue
              .toString()
              .replace(/[^0-9]/g, '')
              .slice(0, maxLength)
        : maskedValue;
};

export const createNumericalMask = (
    indicator = '$',
    position: 'start' | 'end' = 'start',
    decimal = 2,
    allowNegative = false
) => {
    return (rawValue: string): string => {
        if (rawValue && rawValue.length > 0) {
            let result: string[] = rawValue.split('');
            let negative = false;

            if (allowNegative && result[0] === '-') {
                negative = true;
            }

            //remove extra minus
            result = result.join('').replace(/\-/g, '').split('');

            //remove leading 0s
            while (result[0] === '0' && result[1] && result[1] !== '.') {
                result.shift();
            }

            //remove extra decimals (if any)
            result = result.slice(
                0,
                result.indexOf('.') >= 0 ? result.indexOf('.') + decimal + 1 : result.length
            );

            const separated = result.join('').split('.');
            const whole = separated[0].split('');
            const decimalSeparated = separated[1] ? separated[1].split('') : [];

            //Add commas
            let totalRounds = Math.floor(separated[0].length / 3);
            totalRounds = separated[0].length % 3 === 0 ? totalRounds - 1 : totalRounds;

            for (let i = 0; i < totalRounds; i++) {
                whole.splice(separated[0].length - 3 * (i + 1), 0, ',');
            }

            result =
                rawValue.indexOf('.') < 0 || decimal === 0
                    ? whole
                    : whole.concat('.', ...decimalSeparated);
            result = allowNegative && negative ? ['-'].concat(result) : result;
            if (position === 'end') {
                result = result.concat(indicator);
            } else {
                result = indicator.split('').concat(...result);
            }
            return result.join('');
        }
        return rawValue;
    };
};

export const unMaskNumericalValue = (maskedValue: number | string | null): string => {
    const val: string | null =
        typeof maskedValue === 'number' ? maskedValue.toString() : maskedValue;
    const cleanedValue = val ? val.replace(/[^\d\.\-]/g, '') : val;
    const wholeNumber = cleanedValue && cleanedValue.length > 0 ? cleanedValue.split('.')[0] : '';
    return wholeNumber.length > 0 && cleanedValue ? cleanedValue : '';
};

export const unMaskFloatValue = (maskedValue: number | string | null): string => {
    const val: string | null =
        typeof maskedValue === 'number' ? maskedValue.toString() : maskedValue;
    const cleanedValue = val ? val.replace(/[^0-9\.]/g, '') : val;
    const splitted = cleanedValue && cleanedValue.length > 0 ? cleanedValue.split('.') : [];
    const wholeNumber = splitted.shift();
    const decimals = splitted && splitted.length > 0 ? splitted.join('') : '';
    return wholeNumber && wholeNumber.length > 0 && cleanedValue
        ? `${wholeNumber}${decimals && decimals.length > 0 ? '.' + decimals : ''}`
        : '';
};

export const getMaskPositions = (maskedValue: string, indicator = '$'): number[] => {
    const specialPositions: number[] = [];
    for (let i = 0; i < maskedValue.length; i++) {
        if (maskedValue[i] === indicator || maskedValue[i] === ',') {
            specialPositions.push(i);
        }
    }

    return specialPositions;
};

const ISODateRegExp = () =>
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/;

/**
 * Formats the given UTC date string to a date string of the given format;
 * If the given date string is not a valid UTC date string, the passed dateString is returned;
 * @param dateString The UTC date string to format;
 * @param dateFormat The date format to convert the UTC date string to;
 */
export const formatUTCDateString = (dateString: string, dateFormat: DateFormat) => {
    if (ISODateRegExp().test(dateString.split('T')[0])) {
        const [YYYY, MM, DD] = dateString.split('T')[0].split('-');

        switch (dateFormat) {
            case 'yyyy':
                return YYYY;
            case 'mmdd':
                return `${MM}/${DD}`;
            case 'mmyyyy':
                return `${MM}/${YYYY}`;
            case 'mmddyyyy':
            default:
                return `${MM}/${DD}/${YYYY}`;
        }
    }

    return dateString;
};

export const createDateMask = (dateFormat: DateFormat) => {
    return (rawValue: string): string => {
        if (rawValue && rawValue.length > 0) {
            let mm, dd, yyyy: string;
            let parsedMm: number;
            let rawDate: string[];

            //check if ISOString format
            if (ISODateRegExp().test(rawValue.split('T')[0])) {
                return formatUTCDateString(rawValue, dateFormat);
            }

            rawValue = rawValue.replace(/[^0-9\/]/g, ''); // clean unwanted characters
            switch (dateFormat) {
                case 'yyyy':
                    return rawValue.slice(0, 4);
                case 'mmdd':
                    // clean duplicated slashes
                    rawDate = [];
                    rawValue.split('/').forEach(elem => {
                        if (elem && elem !== '') {
                            rawDate.push(elem);
                        }
                    });

                    mm = rawDate[0] ? rawDate[0] : '';
                    dd = rawDate[1] ? rawDate[1] : '';

                    if (dd.length > 2) {
                        dd = dd.slice(0, 2);
                    }

                    parsedMm = parseInt(mm, 10);
                    if (parsedMm && parsedMm > 1) {
                        if (parsedMm < 10) {
                            mm = mm.indexOf('0') > -1 ? mm : `0${mm}`;
                        }
                        if (parsedMm > 12) {
                            mm = '12';
                        }
                    } else if (parsedMm === 0 && mm.length === 2) {
                        mm = '01';
                    } else if (dd && dd !== '' && mm.length < 2) {
                        mm = mm.indexOf('0') > -1 ? mm : `0${mm}`;
                    }

                    if (mm.length > 2) {
                        mm = mm.slice(-2);
                    }

                    if (mm) {
                        if (dd) {
                            return `${mm}/${dd}`;
                        }
                        return mm.length < 2 ? `${mm}` : `${mm}/`;
                    }

                    break;
                case 'mmyyyy':
                    // clean duplicated slashes
                    rawDate = [];
                    rawValue.split('/').forEach(elem => {
                        if (elem && elem !== '') {
                            rawDate.push(elem);
                        }
                    });

                    mm = rawDate[0] ? rawDate[0] : '';
                    yyyy = rawDate[1] ? rawDate[1] : '';

                    if (yyyy.length > 4) {
                        yyyy = yyyy.slice(0, 4);
                    }

                    parsedMm = parseInt(mm, 10);
                    if (parsedMm && parsedMm > 1) {
                        if (parsedMm < 10) {
                            mm = mm.indexOf('0') > -1 ? mm : `0${mm}`;
                        }
                        if (parsedMm > 12) {
                            mm = '12';
                        }
                    } else if (parsedMm === 0 && mm.length === 2) {
                        mm = '01';
                    } else if (yyyy && yyyy !== '' && mm.length < 2) {
                        mm = mm.indexOf('0') > -1 ? mm : `0${mm}`;
                    }

                    if (mm.length > 2) {
                        mm = mm.slice(-2);
                    }

                    if (mm) {
                        if (yyyy) {
                            return `${mm}/${yyyy}`;
                        }
                        return mm.length < 2 ? `${mm}` : `${mm}/`;
                    }

                    break;
                case 'mmddyyyy':
                default:
                    // clean duplicated slashes
                    rawDate = [];
                    rawValue.split('/').forEach(elem => {
                        if (elem && elem !== '') {
                            rawDate.push(elem);
                        }
                    });

                    mm = rawDate[0] ? rawDate[0] : '';
                    dd = rawDate[1] ? rawDate[1] : '';
                    yyyy = rawDate[2] ? rawDate[2] : '';

                    if (yyyy.length > 4) {
                        yyyy = yyyy.slice(0, 4);
                    }

                    parsedMm = parseInt(mm, 10);
                    if (parsedMm && parsedMm > 1) {
                        if (parsedMm < 10) {
                            mm = mm.indexOf('0') > -1 ? mm : `0${mm}`;
                        }
                        if (parsedMm > 12) {
                            mm = '12';
                        }
                    } else if (parsedMm === 0 && mm.length === 2) {
                        mm = '01';
                    } else if (dd && dd !== '' && mm.length < 2) {
                        mm = mm.indexOf('0') > -1 ? mm : `0${mm}`;
                    }

                    if (mm.length > 2) {
                        mm = mm.slice(-2);
                    }

                    const parsedDd: number = parseInt(dd, 10);
                    if (parsedDd && parsedDd > 3) {
                        if (parsedDd < 10) {
                            dd = dd.indexOf('0') > -1 ? dd : `0${dd}`;
                        }
                        if (parsedDd > 31) {
                            dd = '31';
                        }
                    } else if (parsedDd === 0 && dd.length === 2) {
                        dd = '01';
                    } else if (yyyy && yyyy !== '' && dd.length < 2) {
                        dd = dd.indexOf('0') > -1 ? dd : `0${dd}`;
                    }

                    if (dd.length > 2) {
                        dd = dd.slice(-2);
                    }

                    if (mm) {
                        if (dd) {
                            if (yyyy) {
                                return `${mm}/${dd}/${yyyy}`;
                            }
                            return dd.length < 2 ? `${mm}/${dd}` : `${mm}/${dd}/`;
                        }
                        return mm.length < 2 ? `${mm}` : `${mm}/`;
                    }
                    break;
            }
        }
        return rawValue;
    };
};

export const unMaskDateValue = (
    dateFormat: 'mmddyyyy' | 'mmdd' | 'mmyyyy' | 'yyyy' | null | undefined,
    maskedValue: string | null
): string => {
    const now: Date = new Date();
    let mm = '';
    let dd = '';
    let yyyy = '';
    let fullDate: string[] = [];

    if (
        !maskedValue ||
        maskedValue === '' ||
        isNaN(parseInt(maskedValue, 10)) ||
        typeof maskedValue !== 'string'
    ) {
        return '';
    }

    now.setUTCHours(0, 0, 0, 0);

    // Validates other possible options
    const reOpt = /([^0-9\-TZ:.])/g;
    // Validates ISO date format
    const re =
        /[+-]?\d{4}(-[01]\d(-[0-3]\d(T[0-2]\d:[0-5]\d:?([0-5]\d(\.\d+)?)?[+-][0-2]\d:[0-5]\dZ?)?)?)?/g;

    if (!reOpt.test(maskedValue) && re.test(maskedValue)) {
        // It's an ISO string
        fullDate = maskedValue.split('T')[0].split('-');
        [yyyy, mm, dd] = fullDate;
    } else {
        // It's an input value
        maskedValue = maskedValue.replace(/[^0-9\/]/g, '');

        maskedValue.split('/').forEach(elem => {
            if (elem && elem !== '') {
                fullDate.push(elem);
            }
        });
        maskedValue = fullDate.join('/');

        if (dateFormat === 'yyyy') {
            [yyyy] = fullDate;
        } else if (dateFormat === 'mmdd') {
            [mm, dd] = fullDate;
        } else if (dateFormat === 'mmyyyy') {
            [mm, yyyy] = fullDate;
        } else if (dateFormat === 'mmddyyyy' || !dateFormat) {
            [mm, dd, yyyy] = fullDate;
        }
    }

    //set max length
    mm = mm ? mm.slice(0, 2) : mm;
    mm = mm && parseInt(mm, 10) > 12 ? '12' : mm;
    mm = mm && parseInt(mm, 10) <= 0 && mm.length === 2 ? '01' : mm;
    dd = dd ? dd.slice(0, 2) : dd;
    dd = dd && parseInt(dd, 10) > 31 ? '31' : dd;
    dd = dd && parseInt(dd, 10) <= 0 && dd.length === 2 ? '01' : dd;
    yyyy = yyyy ? yyyy.slice(0, 4) : yyyy;
    yyyy = yyyy && parseInt(yyyy, 10) <= 0 && yyyy.length === 4 ? '0001' : yyyy;

    switch (dateFormat) {
        case 'yyyy':
            if (yyyy && yyyy.length === 4) {
                now.setUTCFullYear(parseInt(yyyy, 10), 0, 1);
                return now.toISOString();
            } else {
                return yyyy;
            }
        case 'mmdd':
            if (mm && dd && dd.length === 2) {
                // Pau - Set Year and Month, in that order
                now.setUTCFullYear(now.getUTCFullYear(), parseInt(mm, 10) - 1, parseInt(dd, 10));
                return now.toISOString();
            } else {
                return maskedValue;
            }
        case 'mmyyyy':
            if (mm && yyyy && yyyy.length === 4) {
                // Pau - Set Year and Month, in that order
                now.setUTCFullYear(parseInt(yyyy, 10), parseInt(mm, 10) - 1, 1);
                return now.toISOString();
            } else {
                return maskedValue;
            }
        case 'mmddyyyy':
        default:
            if (dd && mm && yyyy && yyyy.length === 4) {
                // Pau - Set Year, Month and day, in that order
                now.setUTCFullYear(parseInt(yyyy, 10), parseInt(mm, 10) - 1, parseInt(dd, 10));
                return now.toISOString();
            } else {
                return maskedValue;
            }
    }
};

export const getDatePositions = (date: string): number[] => {
    return date.split('').reduce(function (posArray: number[], elem, index) {
        if (elem === '/') {
            posArray.push(index);
        }
        return posArray;
    }, []);
};

export const handleDecimal = (
    value: string | number | null,
    allowDecimal = true,
    decimalLimit = 2
): string | null => {
    const unmaskedValue = unMaskFloatValue(value);
    if (value === null) {
        return null;
    }

    let res: string;
    const [integerPart, decimalPart] = unmaskedValue.split('.');
    if (allowDecimal && integerPart) {
        const integer = parseFloat(integerPart).toString() || '0';
        const decimal = decimalPart || '';
        res = `${integer}.${padEnd(decimal, decimalLimit, '0').substr(0, decimalLimit)}`;
    } else {
        res = integerPart;
    }

    return res;
};

export const handleNegatives = (value: string): string => {
    let res = value === '-' ? '' : value;
    // Remove dollar and percentage symbols
    res = res.split('$').join('').split('%').join('');
    // If value is 0 and negative we remove the negative symbol
    res = parseFloat(res) === 0 && res.split('')[0] === '-' ? '0' : res;

    return res;
};
