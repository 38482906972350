import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderTakeOfflineActions, OrderTakeOfflineActionTypes } from './actions';

export type OrderTakeOfflineState = RemoteData<null, ErrorWrapper>; // TODO: Update this to use type
const initialState = notAsked<null, ErrorWrapper>();

export const orderTakeOfflineReducer = (
    state: OrderTakeOfflineState = initialState,
    action: OrderTakeOfflineActions
): OrderTakeOfflineState => {
    switch (action.type) {
        case OrderTakeOfflineActionTypes.ORDER_TAKE_OFFLINE:
            return inProgress();
        case OrderTakeOfflineActionTypes.ORDER_TAKE_OFFLINE_SUCCESS:
            return success(action.payload);
        case OrderTakeOfflineActionTypes.ORDER_TAKE_OFFLINE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
