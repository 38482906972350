@if (config.label && config.label !== '') {
<p [data-testing]="config.refId! + '__label'">
    <span>{{ config.label }}</span
    ><span>&nbsp;</span><span>{{ templateData.fieldIsRequired ? '*' : '' }}</span>
    @if (config.infoTooltip) {
    <span
        aixTooltip
        [aixTooltipContent]="config.infoTooltip"
        [data-testing]="config.refId! + '__infotooltip'"
    ></span>
    }
</p>
} @if (config.description) {
<p class="aix-form__group-message u-mt14">
    {{ config.description }}
</p>
}
<div
    class="u-mb20"
    class="checkboxgroup-container"
    [class.checkboxgroup-container__invalid]="
        templateData.ctrlHasUncompleteError && templateData.fieldIsDirty
    "
    #inputField
>
    <div class="aix-flex-grid aix-form__grid">
        <div class="aix-form__container aix-form__group">
            <div>
                @for (option of templateData.options; track trackByFn(i, option); let i = $index) {
                <div
                    class="checkbox-custom-wrapper"
                    [data-testing]="'checkboxgroup-item'"
                    [class.checkbox-custom-wrapper-columns]="
                        !config.columnLayout || config.columnLayout === 'two'
                    "
                    [ngClass]="bodyProperties"
                >
                    <label
                        class="checkbox-custom checkbox-custom-label-size"
                        [class.aix-disabled]="templateData.fieldIsDisabled || option.disabled"
                        [class.checkbox-custom--indented]="
                            option.parentRefId &&
                            config.columnLayout &&
                            config.columnLayout === 'one'
                        "
                        [data-testing]="config.refId! + '_' + option.value + '__label'"
                    >
                        <input
                            [checked]="templateData.optionsData[option.refId!] === true"
                            [aixDynamicNextPendingField]="{
                                config: config,
                                margin: ceil(i / 2) * 35
                            }"
                            type="checkbox"
                            (change)="onCheckChange($event, option)"
                            [class.aix-disabled]="templateData.fieldIsDisabled || option.disabled"
                            [attr.data-checked]="templateData.optionsData[option.refId!] === true"
                            [data-testing]="config.refId! + '_' + option.value"
                            [attr.tabindex]="templateData.fieldIsDisabled ? '-1' : ''"
                        />
                        <span
                            class="checkbox-custom__indicator"
                            [class.aix-form__control--dirty]="templateData.fieldIsDirty"
                            [class.aix-form__control--invalid]="
                                templateData.ctrlHasUncompleteError ||
                                templateData.ctrlHasRequiredChecksError ||
                                templateData.ctrlHasOverError
                            "
                            [class.aix-form__control--invalid-required]="
                                templateData.ctrlHasUncompleteError
                            "
                            [class.aix-form__control--invalid-over]="
                                templateData.ctrlHasOverError ||
                                (!templateData.ctrlHasUncompleteError &&
                                    templateData.ctrlHasRequiredChecksError)
                            "
                        >
                        </span>
                        <span
                            class="checkbox-custom__label checkbox-custom__label--xl"
                            [data-testing]="'checkbox-group-item-label'"
                        >
                            {{ option.label }}
                            @if (option.tooltip) {
                            <span
                                aixTooltip
                                [aixTooltipContent]="option.tooltip"
                                [data-testing]="config.refId! + '_' + option.value + '__tooltip'"
                            ></span>
                            } @if (option.subLabel) {
                            <span class="checkbox-custom__sublabel">
                                {{ option.subLabel }}
                            </span>
                            }
                        </span>
                    </label>
                </div>
                }
            </div>
        </div>
    </div>
</div>

<div class="form-error--container">
    @if (!templateData.ctrlHasUncompleteError && (templateData.validationError | isJust)) {
    <div class="form-error" [data-testing]="config.refId! + '_validation_error'">
        {{ templateData.validationError | withDefault : '-- Impossible state --' }}
    </div>
    }
</div>
