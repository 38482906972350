<div class="canvas" [ngStyle]="canvasHeight">
    <div class="item-container">
        @for (item of visibleProvider;track item) {
        <div class="item-container__page" [ngStyle]="item.styles">
            <aix-pdf
                [width]="rowWidth"
                [height]="rowHeight"
                [rotation]="rotation"
                [style.transform]="'rotate(' + rotation + 'deg)'"
                [pdf]="pdfDoc"
                [page]="item.page"
                [class.selected]="itemSelected"
                (onRenderError)="onRenderError($event)"
                (click)="onClickPage(item)"
            >
                {{ item.text }}
            </aix-pdf>
        </div>
        }
    </div>
</div>
