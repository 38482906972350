import { entityType } from '@trade-platform/lib-enums';
import { getFromStorage } from '@trade-platform/ui-utils';
import { constants } from '../../utils/constants';
import { Profile } from '../../models/profile/model';

export const isActiveInvestor = (profile: Profile) => {
    return !!profile.roles.find(role => role.name === constants.profileTypes.ACTIVE_INVESTOR);
};

export const isFeatureEnabled = (feature: string) => {
    const profile: Profile | null | undefined = getFromStorage('profile');

    if (!profile || !profile.features) {
        return false;
    }

    const features: string[] = profile.features.map(feat => feat.name);
    return features.indexOf(feature) > -1;
};

export const hasEntityType = (type: string) => {
    const profile: Profile | null | undefined = getFromStorage('profile');

    if (
        (type === entityType.firm && profile?.firm) ||
        (type === entityType.holdingOption && profile?.holdingOption) ||
        (type === entityType.fundSponsor && profile?.fundSponsor)
    ) {
        return true;
    } else {
        return false;
    }
};

export const hasOrganizationType = (type: string) => {
    const profile: Profile | null | undefined = getFromStorage('profile');

    return profile?.organization?.type === type;
};

export const hasRoles = (roles: string[]) => {
    const profile: Profile | null | undefined = getFromStorage('profile');

    if (profile) {
        return profile.roles.some(role => roles.includes(role.name));
    }
    return false;
};
