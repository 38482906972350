@if (hasMore()) { @if (showMore()) {
<a
    (click)="change()"
    class="aix-btn aix-btn--link br-link"
    [data-testing]="'showLessButton' + title()"
>
    Show Less...
</a>
} @if (!showMore()){
<a
    (click)="change()"
    class="aix-btn aix-btn--link br-link"
    [data-testing]="'showMoreButton' + title()"
>
    Show More@if (showMoreSelectedNumber > 0) {
    <span> ({{ showMoreSelectedNumber }} selected)</span>
    }...
</a>
} }
