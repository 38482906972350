import { JsonDecoder } from 'ts.data.json';
import { HoldingOption } from '../../models/holding-option/model';

export const holdingOptionDecoder = JsonDecoder.object<HoldingOption>(
    {
        id: JsonDecoder.number,
        name: JsonDecoder.string,
        shortCode: JsonDecoder.string,
        addressLine1: JsonDecoder.optional(JsonDecoder.string),
        addressLine2: JsonDecoder.optional(JsonDecoder.string),
        city: JsonDecoder.optional(JsonDecoder.string),
        state: JsonDecoder.optional(JsonDecoder.string),
        zip: JsonDecoder.optional(JsonDecoder.string),
        phone: JsonDecoder.optional(JsonDecoder.string),
        accountPrefix: JsonDecoder.optional(JsonDecoder.string),
        accountPostfix: JsonDecoder.optional(JsonDecoder.string),
        externalCustodianId: JsonDecoder.optional(JsonDecoder.string),
        custodianTaxId: JsonDecoder.optional(JsonDecoder.string)
    },
    'HoldingOption'
);

export const holdingOptionListDecoder = JsonDecoder.array<HoldingOption>(
    holdingOptionDecoder,
    'HoldingOption[]'
);
