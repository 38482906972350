import { ActionTypes, StylesActions } from './actions';

export interface StylesState {
    bannerOffsetPx: number;
}

const initialState: StylesState = {
    bannerOffsetPx: 0
};

export function stylesReducer(
    state: StylesState = initialState,
    action: StylesActions
): StylesState {
    switch (action.type) {
        case ActionTypes.SET_BANNER_OFFSET:
            return {
                bannerOffsetPx: action.payload.bannerOffsetPx
            };
        case ActionTypes.CLEAR_BANNER_OFFSET:
            return {
                bannerOffsetPx: 0
            };
        default:
            return state;
    }
}
