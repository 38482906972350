import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'aixPluralize'
})
export class AixPluralizePipe implements PipeTransform {
    transform(word: string, pluralWord: string, n: number): string {
        return n === 1 ? word : pluralWord;
    }
}
