import { Injectable } from '@angular/core';
import { OutagesService, TermsService, AixUsersService } from '@trade-platform/ui-shared';

@Injectable()
export class AixLoginApiService {
    constructor(
        public outagesService: OutagesService,
        public termsService: TermsService,
        public profileService: AixUsersService
    ) {}
}
