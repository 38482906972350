import { CheckboxGroupControlState } from '../../dynamic-form-store/model';
import { DynamicFormValidator, OptionFieldConfig } from '@trade-platform/form-fields';

export const requiredChecksRegExp = () => /requiredChecks\.(all|\d+)/;

export const REQUIRED_CHECKS_VALIDATOR_KEY = 'requiredChecks';

export const isRequiredChecksValidator = (value: unknown): value is RequiredChecksValidator =>
    value instanceof RequiredChecksValidator;

export const requiredChecksValidatorFactory = (requiredChecks: 'all' | number) =>
    new RequiredChecksValidator(requiredChecks);

/**
 * Checks if the checkboxgroup has been checked at least the provided number of times.
 * @param requiredChecks `'all'` if all checks must be checked, or a `number` otherwise.
 */
class RequiredChecksValidator implements DynamicFormValidator {
    constructor(public readonly requiredChecks: 'all' | number) {}
    validate(
        ctrlState: CheckboxGroupControlState,
        selectedOptions: OptionFieldConfig[]
    ): Record<string, any> | null {
        const checkedOptions = selectedOptions.length;

        // All required
        if (this.requiredChecks === 'all') {
            const nOptions = ctrlState.extra.options.length;
            return nOptions === checkedOptions
                ? null
                : { [REQUIRED_CHECKS_VALIDATOR_KEY]: 'All options must be checked.' };
        }

        // {n} required
        return checkedOptions >= this.requiredChecks
            ? null
            : {
                  [REQUIRED_CHECKS_VALIDATOR_KEY]: `At least ${this.requiredChecks} options must be checked.`
              };
    }
}
