import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '@trade-platform/ui-shared';
import { OrderDocumentRemoveActions, OrderDocumentRemoveActionTypes } from './actions';

export type OrderDocumentRemoveState = RemoteData<null, ErrorWrapper>;
const initialState = notAsked<null, ErrorWrapper>();

export const orderDocumentRemoveReducer = (
    state: OrderDocumentRemoveState = initialState,
    action: OrderDocumentRemoveActions
): OrderDocumentRemoveState => {
    switch (action.type) {
        case OrderDocumentRemoveActionTypes.ORDER_DOCUMENT_REMOVE:
            return inProgress();
        case OrderDocumentRemoveActionTypes.ORDER_DOCUMENT_REMOVE_SUCCESS:
            return success(null);
        case OrderDocumentRemoveActionTypes.ORDER_DOCUMENT_REMOVE_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
};
