import {
    AixAction,
    ErrorWrapper,
    OrderIdPayload,
    OrderSignPayload,
    type
} from '@trade-platform/ui-shared';

export class OrderSignActionTypes {
    static readonly ORDER_SIGN = type('[Order Sign] Order Sign');
    static readonly ORDER_SIGN_SUCCESS = type('[Order Sign] Order Sign Success');
    static readonly ORDER_SIGN_FAILURE = type('[Order Sign] Order Sign Failure');
}

export type OrderSignActions = OrderSign | OrderSignSuccess | OrderSignFailure;

export class OrderSign implements AixAction {
    readonly type = OrderSignActionTypes.ORDER_SIGN;

    constructor(public payload: OrderIdPayload) {}
}

export class OrderSignSuccess implements AixAction {
    readonly type = OrderSignActionTypes.ORDER_SIGN_SUCCESS;

    constructor(public payload: OrderSignPayload) {}
}

export class OrderSignFailure implements AixAction {
    readonly type = OrderSignActionTypes.ORDER_SIGN_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
