import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AixAction } from '../aix-action';

@Injectable()
export class AixEffectActions {
    _actions = new Subject<AixAction>();

    /**
     * Emits only when the dispatched action matches the provided type.
     * @param type The action (string) type.
     */
    ofType(type: string) {
        return this._actions.pipe(filter(action => action.type === type));
    }

    /**
     * Emits only when the dispatched action matches the provided class.
     * @param klass The action class, must implement Action.
     */
    ofClass<T extends AixAction>(clazz: new (...rest: any[]) => T) {
        const isClazz = (action: AixAction): action is T => action instanceof clazz;
        return this._actions.pipe(filter<AixAction, T>(isClazz));
    }

    _nextAction(action: AixAction) {
        this._actions.next(action);
    }
}

@Injectable()
export class AixDispatcher extends ActionsSubject {
    constructor(private actions: AixEffectActions) {
        super();
    }

    next(action: AixAction) {
        super.next(action);
        this.actions._nextAction(action);
    }
}
