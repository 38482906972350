import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    LoadOrderForm,
    LoadOrderFormActionTypes,
    LoadOrderFormFailure,
    LoadOrderFormSuccess
} from './actions';
import { OrderFormService } from './service';

@Injectable()
export class OrderFormEffects {
    constructor(private actions$: Actions, private service: OrderFormService) {}

    loadOrderForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadOrderForm>(LoadOrderFormActionTypes.LOAD_ORDER_FORM),
            switchMap(action =>
                this.service.getOrderForm(action.payload.orderId, action.payload.formId).pipe(
                    map(
                        orderForm =>
                            new LoadOrderFormSuccess({
                                form: orderForm
                            })
                    ),
                    catchHttpError(error =>
                        of(
                            new LoadOrderFormFailure({
                                error
                            })
                        )
                    )
                )
            )
        )
    );
}
