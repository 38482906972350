import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'aixMaskSSN',
    standalone: true
})
export class AixMaskSSNPipe implements PipeTransform {
    transform(text: string): string {
        const ssn = text.split('-').join('');
        const lastNumbers = ssn.slice(ssn.length - 5, ssn.length - 1);

        return `***-**-${lastNumbers}`;
    }
}
