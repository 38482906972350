import {
    AixAction,
    ErrorWrapper,
    LoadOrdersPayload,
    OrderRecord,
    OrdersSuffix,
    Page,
    type
} from '@trade-platform/ui-shared';

export class OrdersActionTypes {
    static readonly LOAD_ORDERS = type('[Orders] Load Orders');
    static readonly LOAD_ORDERS_SUCCESS = type('[Orders] Load Orders Success');
    static readonly LOAD_ORDERS_FAILURE = type('[Orders] Load Orders Failure');
}

export type OrdersActions = LoadOrders | LoadOrdersSuccess | LoadOrdersFailure;

export class LoadOrders implements AixAction {
    readonly type = OrdersActionTypes.LOAD_ORDERS;

    constructor(public payload: LoadOrdersPayload) {}
}

export class LoadOrdersSuccess implements AixAction {
    readonly type = OrdersActionTypes.LOAD_ORDERS_SUCCESS;

    constructor(public payload: { ordersPage: Page<OrderRecord>; reducerSuffix: OrdersSuffix }) {}
}

export class LoadOrdersFailure implements AixAction {
    readonly type = OrdersActionTypes.LOAD_ORDERS_FAILURE;

    constructor(public payload: { error: ErrorWrapper; reducerSuffix: OrdersSuffix }) {}
}
