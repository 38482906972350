<aix-checkbox
    [config]="config"
    [isStandalone]="false"
    [isValid]="!templateData.ctrlIsInvalid"
    [isDisabled]="templateData.fieldIsDisabled"
    [isRequired]="templateData.fieldIsRequired"
    [isDirty]="templateData.fieldIsDirty"
    [valueToRender]="templateData.valueToRender"
    [infoTooltip]="config.infoTooltip ?? ''"
    [bodyClassNames]="bodyClassNames"
    [aixDynamicNextPendingField]="{ config: config }"
    [headerClassNames]="headerClassNames"
    (valueChanges)="userInputEmitter$.next($event)"
    (setDirty)="setDirty()"
>
    {{ config.label }}
</aix-checkbox>
