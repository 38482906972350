import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderMakeChangesResponse } from '@trade-platform/ui-shared';
import { OrderMakeChangesActions, OrderMakeChangesActionTypes } from './actions';

export type OrderMakeChangesState = RemoteData<OrderMakeChangesResponse, ErrorWrapper>;
const initialState = notAsked<OrderMakeChangesResponse, ErrorWrapper>();

export function orderMakeChangesReducer(
    state: OrderMakeChangesState = initialState,
    action: OrderMakeChangesActions
): OrderMakeChangesState {
    switch (action.type) {
        case OrderMakeChangesActionTypes.ORDER_MAKE_CHANGES:
            return inProgress({} as OrderMakeChangesResponse);
        case OrderMakeChangesActionTypes.ORDER_MAKE_CHANGES_SUCCESS:
            return success(action.payload);
        case OrderMakeChangesActionTypes.ORDER_MAKE_CHANGES_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
