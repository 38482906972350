@if ((!authService.isLoggedInAuth0() || isLoggingIn) && router.url !== '/register' && router.url !== '/outage') {
<aix-loading></aix-loading>
} @if (authService.isLoggedInAuth0()) {
<aix-nav-bar class="hide-if-print"></aix-nav-bar>
}
<div class="u-relative hide-if-print">
    <div class="container u-relative">
        <aix-flash-messages></aix-flash-messages>
    </div>
</div>

<aix-inactivity-modal (logout)="logout()"></aix-inactivity-modal>

<div id="app" [class.register]="router.url === '/register'">
    <router-outlet></router-outlet>
</div>

@if (authService.isLoggedInAuth0()) {
<aix-footer class="hide-if-print"></aix-footer>
}
