export interface FlashMessageCloseBehaviour {
    isClosable: boolean;
}

/**
 * The flash message will be closed manually via a close button
 */
export class CloseByButtonBehaviour implements FlashMessageCloseBehaviour {
    isClosable = true;
}

/**
 * The flash message will be closed automatically via a timeout
 */
export class CloseByTimerBehaviour implements FlashMessageCloseBehaviour {
    isClosable = false;
    /**
     * In milliseconds
     */
    timeToAutoClose: number;

    constructor(timeToAutoClose = 5000) {
        this.timeToAutoClose = timeToAutoClose;
    }
}

/**
 * The flash message will be closed programatically via RemoveFlashMessageAction
 */
export class CloseByActionBehaviour implements FlashMessageCloseBehaviour {
    isClosable = false;
}

export enum FlashMessageType {
    SUCCESS,
    WARN,
    ERROR,
    DEFAULT
}

export interface FlashMessage {
    uid: string;
    text: string;
    type: FlashMessageType;
    closeBehaviour: FlashMessageCloseBehaviour;
}
