import { ErrorWrapper, OrdersStatusHistory } from '@trade-platform/ui-shared';
import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { LoadOrdersStatusHistoryActions, LoadOrdersStatusHistoryActionTypes } from './actions';

export type OrdersStatusHistoryState = RemoteData<OrdersStatusHistory[], ErrorWrapper>;

export function ordersStatusHistoryReducer(
    state: OrdersStatusHistoryState = notAsked(),
    action: LoadOrdersStatusHistoryActions
): OrdersStatusHistoryState {
    switch (action.type) {
        case LoadOrdersStatusHistoryActionTypes.LOAD_ORDERS_STATUS_HISTORY:
            return inProgress([] as OrdersStatusHistory[]);
        case LoadOrdersStatusHistoryActionTypes.LOAD_ORDERS_STATUS_HISTORY_SUCCESS:
            return success(action.payload);
        case LoadOrdersStatusHistoryActionTypes.LOAD_ORDERS_STATUS_HISTORY_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
