import {
    ErrorWrapper,
    AixAction,
    type,
    OrderIdPayload,
    OrdersStatusHistory
} from '@trade-platform/ui-shared';

export class LoadOrdersStatusHistoryActionTypes {
    static readonly LOAD_ORDERS_STATUS_HISTORY = type('[Orders] Load Orders Status History');
    static readonly LOAD_ORDERS_STATUS_HISTORY_SUCCESS = type(
        '[Orders] Load Orders Status History Success'
    );
    static readonly LOAD_ORDERS_STATUS_HISTORY_FAILURE = type(
        '[Orders] Load Orders Status History Failure'
    );
}

export type LoadOrdersStatusHistoryActions =
    | LoadOrdersStatusHistory
    | LoadOrdersStatusHistorySuccess
    | LoadOrdersStatusHistoryFailure;

export class LoadOrdersStatusHistory implements AixAction {
    readonly type = LoadOrdersStatusHistoryActionTypes.LOAD_ORDERS_STATUS_HISTORY;

    constructor(public payload: OrderIdPayload) {}
}

export class LoadOrdersStatusHistorySuccess implements AixAction {
    readonly type = LoadOrdersStatusHistoryActionTypes.LOAD_ORDERS_STATUS_HISTORY_SUCCESS;

    constructor(public payload: OrdersStatusHistory[]) {}
}

export class LoadOrdersStatusHistoryFailure implements AixAction {
    readonly type = LoadOrdersStatusHistoryActionTypes.LOAD_ORDERS_STATUS_HISTORY_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
