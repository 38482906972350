import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
import { emDash } from '@trade-platform/ui-shared';
import { ValueFormatterParams } from 'ag-grid-community';

export const currencyFormatter = (params: ValueFormatterParams) => {
    const value = new CurrencyPipe('en-US').transform(params.value, 'USD', 'symbol');
    return value ?? '';
};

export const currencyFormatterOrEmDash = (params: ValueFormatterParams) => {
    const value = new CurrencyPipe('en-US').transform(params.value, 'USD', 'symbol');
    return value ?? emDash;
};

export const sharesFormatter = (params: ValueFormatterParams) => {
    const value = new DecimalPipe('en-US').transform(params.value, '.3-3');
    return params.value !== 0 ? value ?? '' : params.value;
};

export class OrderNumberPipe implements PipeTransform {
    constructor() {}

    transform(value: number): string | null {
        return value >= 0 ? this.leftPad(value, 6) : null;
    }

    leftPad(value: number, length: number): string {
        const pad = new Array(length + 1).join('0');

        return '#' + (pad + value).slice(-pad.length);
    }
}

export const orderNumberFormatter = (params: { value: any }) =>
    new OrderNumberPipe().transform(params.value);

export const dateFormatter = (params: ValueFormatterParams) => {
    return (
        new DatePipe('en-US', 'UTC', {
            timezone: 'UTC',
            dateFormat: 'en-US'
        }).transform(params.value, 'M/d/yy') || ''
    );
};
