import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper, OrderMarkNeedsClarificationResponse } from '@trade-platform/ui-shared';
import { OrderNeedsClarificationActions, OrderNeedsClarificationActionTypes } from './actions';

export type OrderNeedsClarificationState = RemoteData<
    OrderMarkNeedsClarificationResponse,
    ErrorWrapper
>;
const initialState = notAsked<OrderMarkNeedsClarificationResponse, ErrorWrapper>();

export function orderNeedsClarificationReducer(
    state: OrderNeedsClarificationState = initialState,
    action: OrderNeedsClarificationActions
): OrderNeedsClarificationState {
    switch (action.type) {
        case OrderNeedsClarificationActionTypes.ORDER_MARK_NEEDS_CLARIFICATION:
            return inProgress({} as OrderMarkNeedsClarificationResponse);
        case OrderNeedsClarificationActionTypes.ORDER_MARK_NEEDS_CLARIFICATION_SUCCESS:
            return success(action.payload);
        case OrderNeedsClarificationActionTypes.ORDER_MARK_NEEDS_CLARIFICATION_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
