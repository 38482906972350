export interface CountryCode {
    name: string;
    id: string;
    value: string;
}

export const countryCodes = [
    { name: 'United States', id: 'US', value: '1' },
    { name: 'Afghanistan (‫افغانستان‬‎)', id: 'AF', value: '93' },
    { name: 'Albania (Shqipëri)', id: 'AL', value: '355' },
    { name: 'Algeria (‫الجزائر‬‎)', id: 'DZ', value: '213' },
    { name: 'American Samoa', id: 'AS', value: '1' },
    { name: 'Andorra', id: 'AD', value: '376' },
    { name: 'Angola', id: 'AO', value: '244' },
    { name: 'Anguilla', id: 'AI', value: '1' },
    { name: 'Antigua and Barbuda', id: 'AG', value: '1' },
    { name: 'Argentina', id: 'AR', value: '54' },
    { name: 'Armenia (Հայաստան)', id: 'AM', value: '374' },
    { name: 'Aruba', id: 'AW', value: '297' },
    { name: 'Ascension Island', id: 'AC', value: '247' },
    { name: 'Australia', id: 'AU', value: '61' },
    { name: 'Austria (Österreich)', id: 'AT', value: '43' },
    { name: 'Azerbaijan (Azərbaycan)', id: 'AZ', value: '994' },
    { name: 'Bahamas', id: 'BS', value: '1' },
    { name: 'Bahrain (‫البحرين‬‎)', id: 'BH', value: '973' },
    { name: 'Bangladesh (বাংলাদেশ)', id: 'BD', value: '880' },
    { name: 'Barbados', id: 'BB', value: '1' },
    { name: 'Belarus (Беларусь)', id: 'BY', value: '375' },
    { name: 'Belgium (België)', id: 'BE', value: '32' },
    { name: 'Belize', id: 'BZ', value: '501' },
    { name: 'Benin (Bénin)', id: 'BJ', value: '229' },
    { name: 'Bermuda', id: 'BM', value: '1' },
    { name: 'Bhutan (འབྲུག)', id: 'BT', value: '975' },
    { name: 'Bolivia', id: 'BO', value: '591' },
    { name: 'Bosnia and Herzegovina (Босна и Херцеговина)', id: 'BA', value: '387' },
    { name: 'Botswana', id: 'BW', value: '267' },
    { name: 'Brazil (Brasil)', id: 'BR', value: '55' },
    { name: 'British Indian Ocean Territory', id: 'IO', value: '246' },
    { name: 'British Virgin Islands', id: 'VG', value: '1' },
    { name: 'Brunei', id: 'BN', value: '673' },
    { name: 'Bulgaria (България)', id: 'BG', value: '359' },
    { name: 'Burkina Faso', id: 'BF', value: '226' },
    { name: 'Burundi (Uburundi)', id: 'BI', value: '257' },
    { name: 'Cambodia (កម្ពុជា)', id: 'KH', value: '855' },
    { name: 'Cameroon (Cameroun)', id: 'CM', value: '237' },
    { name: 'Canada', id: 'CA', value: '1' },
    { name: 'Cape Verde (Kabu Verdi)', id: 'CV', value: '238' },
    { name: 'Caribbean Netherlands', id: 'BQ', value: '599' },
    { name: 'Cayman Islands', id: 'KY', value: '1' },
    { name: 'Central African Republic (République centrafricaine)', id: 'CF', value: '236' },
    { name: 'Chad (Tchad)', id: 'TD', value: '235' },
    { name: 'Chile', id: 'CL', value: '56' },
    { name: 'China (中国)', id: 'CN', value: '86' },
    { name: 'Christmas Island', id: 'CX', value: '61' },
    { name: 'Cocos (Keeling) Islands', id: 'CC', value: '61' },
    { name: 'Colombia', id: 'CO', value: '57' },
    { name: 'Comoros (‫جزر القمر‬‎)', id: 'KM', value: '269' },
    { name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', id: 'CD', value: '243' },
    { name: 'Congo (Republic) (Congo-Brazzaville)', id: 'CG', value: '242' },
    { name: 'Cook Islands', id: 'CK', value: '682' },
    { name: 'Costa Rica', id: 'CR', value: '506' },
    { name: 'Côte d’Ivoire', id: 'CI', value: '225' },
    { name: 'Croatia (Hrvatska)', id: 'HR', value: '385' },
    { name: 'Cuba', id: 'CU', value: '53' },
    { name: 'Curaçao', id: 'CW', value: '599' },
    { name: 'Cyprus (Κύπρος)', id: 'CY', value: '357' },
    { name: 'Czech Republic (Česká republika)', id: 'CZ', value: '420' },
    { name: 'Denmark (Danmark)', id: 'DK', value: '45' },
    { name: 'Djibouti', id: 'DJ', value: '253' },
    { name: 'Dominica', id: 'DM', value: '1' },
    { name: 'Dominican Republic (República Dominicana)', id: 'DO', value: '1' },
    { name: 'Ecuador', id: 'EC', value: '593' },
    { name: 'Egypt (‫مصر‬‎)', id: 'EG', value: '20' },
    { name: 'El Salvador', id: 'SV', value: '503' },
    { name: 'Equatorial Guinea (Guinea Ecuatorial)', id: 'GQ', value: '240' },
    { name: 'Eritrea', id: 'ER', value: '291' },
    { name: 'Estonia (Eesti)', id: 'EE', value: '372' },
    { name: 'Eswatini', id: 'SZ', value: '268' },
    { name: 'Ethiopia', id: 'ET', value: '251' },
    { name: 'Falkland Islands (Islas Malvinas)', id: 'FK', value: '500' },
    { name: 'Faroe Islands (Føroyar)', id: 'FO', value: '298' },
    { name: 'Fiji', id: 'FJ', value: '679' },
    { name: 'Finland (Suomi)', id: 'FI', value: '358' },
    { name: 'France', id: 'FR', value: '33' },
    { name: 'French Guiana (Guyane française)', id: 'GF', value: '594' },
    { name: 'French Polynesia (Polynésie française)', id: 'PF', value: '689' },
    { name: 'Gabon', id: 'GA', value: '241' },
    { name: 'Gambia', id: 'GM', value: '220' },
    { name: 'Georgia (საქართველო)', id: 'GE', value: '995' },
    { name: 'Germany (Deutschland)', id: 'DE', value: '49' },
    { name: 'Ghana (Gaana)', id: 'GH', value: '233' },
    { name: 'Gibraltar', id: 'GI', value: '350' },
    { name: 'Greece (Ελλάδα)', id: 'GR', value: '30' },
    { name: 'Greenland (Kalaallit Nunaat)', id: 'GL', value: '299' },
    { name: 'Grenada', id: 'GD', value: '1' },
    { name: 'Guadeloupe', id: 'GP', value: '590' },
    { name: 'Guam', id: 'GU', value: '1' },
    { name: 'Guatemala', id: 'GT', value: '502' },
    { name: 'Guernsey', id: 'GG', value: '44' },
    { name: 'Guinea (Guinée)', id: 'GN', value: '224' },
    { name: 'Guinea-Bissau (Guiné Bissau)', id: 'GW', value: '245' },
    { name: 'Guyana', id: 'GY', value: '592' },
    { name: 'Haiti', id: 'HT', value: '509' },
    { name: 'Honduras', id: 'HN', value: '504' },
    { name: 'Hong Kong (香港)', id: 'HK', value: '852' },
    { name: 'Hungary (Magyarország)', id: 'HU', value: '36' },
    { name: 'Iceland (Ísland)', id: 'IS', value: '354' },
    { name: 'India (भारत)', id: 'IN', value: '91' },
    { name: 'Indonesia', id: 'ID', value: '62' },
    { name: 'Iran (‫ایران‬‎)', id: 'IR', value: '98' },
    { name: 'Iraq (‫العراق‬‎)', id: 'IQ', value: '964' },
    { name: 'Ireland', id: 'IE', value: '353' },
    { name: 'Isle of Man', id: 'IM', value: '44' },
    { name: 'Israel (‫ישראל‬‎)', id: 'IL', value: '972' },
    { name: 'Italy (Italia)', id: 'IT', value: '39' },
    { name: 'Jamaica', id: 'JM', value: '1' },
    { name: 'Japan (日本)', id: 'JP', value: '81' },
    { name: 'Jersey', id: 'JE', value: '44' },
    { name: 'Jordan (‫الأردن‬‎)', id: 'JO', value: '962' },
    { name: 'Kazakhstan (Казахстан)', id: 'KZ', value: '7' },
    { name: 'Kenya', id: 'KE', value: '254' },
    { name: 'Kiribati', id: 'KI', value: '686' },
    { name: 'Kosovo', id: 'XK', value: '383' },
    { name: 'Kuwait (‫الكويت‬‎)', id: 'KW', value: '965' },
    { name: 'Kyrgyzstan (Кыргызстан)', id: 'KG', value: '996' },
    { name: 'Laos (ລາວ)', id: 'LA', value: '856' },
    { name: 'Latvia (Latvija)', id: 'LV', value: '371' },
    { name: 'Lebanon (‫لبنان‬‎)', id: 'LB', value: '961' },
    { name: 'Lesotho', id: 'LS', value: '266' },
    { name: 'Liberia', id: 'LR', value: '231' },
    { name: 'Libya (‫ليبيا‬‎)', id: 'LY', value: '218' },
    { name: 'Liechtenstein', id: 'LI', value: '423' },
    { name: 'Lithuania (Lietuva)', id: 'LT', value: '370' },
    { name: 'Luxembourg', id: 'LU', value: '352' },
    { name: 'Macau (澳門)', id: 'MO', value: '853' },
    { name: 'Madagascar (Madagasikara)', id: 'MG', value: '261' },
    { name: 'Malawi', id: 'MW', value: '265' },
    { name: 'Malaysia', id: 'MY', value: '60' },
    { name: 'Maldives', id: 'MV', value: '960' },
    { name: 'Mali', id: 'ML', value: '223' },
    { name: 'Malta', id: 'MT', value: '356' },
    { name: 'Marshall Islands', id: 'MH', value: '692' },
    { name: 'Martinique', id: 'MQ', value: '596' },
    { name: 'Mauritania (‫موريتانيا‬‎)', id: 'MR', value: '222' },
    { name: 'Mauritius (Moris)', id: 'MU', value: '230' },
    { name: 'Mayotte', id: 'YT', value: '262' },
    { name: 'Mexico (México)', id: 'MX', value: '52' },
    { name: 'Micronesia', id: 'FM', value: '691' },
    { name: 'Moldova (Republica Moldova)', id: 'MD', value: '373' },
    { name: 'Monaco', id: 'MC', value: '377' },
    { name: 'Mongolia (Монгол)', id: 'MN', value: '976' },
    { name: 'Montenegro (Crna Gora)', id: 'ME', value: '382' },
    { name: 'Montserrat', id: 'MS', value: '1' },
    { name: 'Morocco (‫المغرب‬‎)', id: 'MA', value: '212' },
    { name: 'Mozambique (Moçambique)', id: 'MZ', value: '258' },
    { name: 'Myanmar (Burma) (မြန်မာ)', id: 'MM', value: '95' },
    { name: 'Namibia (Namibië)', id: 'NA', value: '264' },
    { name: 'Nauru', id: 'NR', value: '674' },
    { name: 'Nepal (नेपाल)', id: 'NP', value: '977' },
    { name: 'Netherlands (Nederland)', id: 'NL', value: '31' },
    { name: 'New Caledonia (Nouvelle-Calédonie)', id: 'NC', value: '687' },
    { name: 'New Zealand', id: 'NZ', value: '64' },
    { name: 'Nicaragua', id: 'NI', value: '505' },
    { name: 'Niger (Nijar)', id: 'NE', value: '227' },
    { name: 'Nigeria', id: 'NG', value: '234' },
    { name: 'Niue', id: 'NU', value: '683' },
    { name: 'Norfolk Island', id: 'NF', value: '672' },
    { name: 'North Korea (조선 민주주의 인민 공화국)', id: 'KP', value: '850' },
    { name: 'North Macedonia (Северна Македонија)', id: 'MK', value: '389' },
    { name: 'Northern Mariana Islands', id: 'MP', value: '1' },
    { name: 'Norway (Norge)', id: 'NO', value: '47' },
    { name: 'Oman (‫عُمان‬‎)', id: 'OM', value: '968' },
    { name: 'Pakistan (‫پاکستان‬‎)', id: 'PK', value: '92' },
    { name: 'Palau', id: 'PW', value: '680' },
    { name: 'Palestine (‫فلسطين‬‎)', id: 'PS', value: '970' },
    { name: 'Panama (Panamá)', id: 'PA', value: '507' },
    { name: 'Papua New Guinea', id: 'PG', value: '675' },
    { name: 'Paraguay', id: 'PY', value: '595' },
    { name: 'Peru (Perú)', id: 'PE', value: '51' },
    { name: 'Philippines', id: 'PH', value: '63' },
    { name: 'Poland (Polska)', id: 'PL', value: '48' },
    { name: 'Portugal', id: 'PT', value: '351' },
    { name: 'Puerto Rico', id: 'PR', value: '1' },
    { name: 'Qatar (‫قطر‬‎)', id: 'QA', value: '974' },
    { name: 'Réunion (La Réunion)', id: 'RE', value: '262' },
    { name: 'Romania (România)', id: 'RO', value: '40' },
    { name: 'Russia (Россия)', id: 'RU', value: '7' },
    { name: 'Rwanda', id: 'RW', value: '250' },
    { name: 'Saint Barthélemy', id: 'BL', value: '590' },
    { name: 'Saint Helena', id: 'SH', value: '290' },
    { name: 'Saint Kitts and Nevis', id: 'KN', value: '1' },
    { name: 'Saint Lucia', id: 'LC', value: '1' },
    { name: 'Saint Martin (Saint-Martin (partie française))', id: 'MF', value: '590' },
    { name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', id: 'PM', value: '508' },
    { name: 'Saint Vincent and the Grenadines', id: 'VC', value: '1' },
    { name: 'Samoa', id: 'WS', value: '685' },
    { name: 'San Marino', id: 'SM', value: '378' },
    { name: 'São Tomé and Príncipe (São Tomé e Príncipe)', id: 'ST', value: '239' },
    { name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', id: 'SA', value: '966' },
    { name: 'Senegal (Sénégal)', id: 'SN', value: '221' },
    { name: 'Serbia (Србија)', id: 'RS', value: '381' },
    { name: 'Seychelles', id: 'SC', value: '248' },
    { name: 'Sierra Leone', id: 'SL', value: '232' },
    { name: 'Singapore', id: 'SG', value: '65' },
    { name: 'Sint Maarten', id: 'SX', value: '1' },
    { name: 'Slovakia (Slovensko)', id: 'SK', value: '421' },
    { name: 'Slovenia (Slovenija)', id: 'SI', value: '386' },
    { name: 'Solomon Islands', id: 'SB', value: '677' },
    { name: 'Somalia (Soomaaliya)', id: 'SO', value: '252' },
    { name: 'South Africa', id: 'ZA', value: '27' },
    { name: 'South Korea (대한민국)', id: 'KR', value: '82' },
    { name: 'South Sudan (‫جنوب السودان‬‎)', id: 'SS', value: '211' },
    { name: 'Spain (España)', id: 'ES', value: '34' },
    { name: 'Sri Lanka (ශ්‍රී ලංකාව)', id: 'LK', value: '94' },
    { name: 'Sudan (‫السودان‬‎)', id: 'SD', value: '249' },
    { name: 'Suriname', id: 'SR', value: '597' },
    { name: 'Svalbard and Jan Mayen', id: 'SJ', value: '47' },
    { name: 'Sweden (Sverige)', id: 'SE', value: '46' },
    { name: 'Switzerland (Schweiz)', id: 'CH', value: '41' },
    { name: 'Syria (‫سوريا‬‎)', id: 'SY', value: '963' },
    { name: 'Taiwan (台灣)', id: 'TW', value: '886' },
    { name: 'Tajikistan', id: 'TJ', value: '992' },
    { name: 'Tanzania', id: 'TZ', value: '255' },
    { name: 'Thailand (ไทย)', id: 'TH', value: '66' },
    { name: 'Timor-Leste', id: 'TL', value: '670' },
    { name: 'Togo', id: 'TG', value: '228' },
    { name: 'Tokelau', id: 'TK', value: '690' },
    { name: 'Tonga', id: 'TO', value: '676' },
    { name: 'Trinidad and Tobago', id: 'TT', value: '1' },
    { name: 'Tunisia (‫تونس‬‎)', id: 'TN', value: '216' },
    { name: 'Turkey (Türkiye)', id: 'TR', value: '90' },
    { name: 'Turkmenistan', id: 'TM', value: '993' },
    { name: 'Turks and Caicos Islands', id: 'TC', value: '1' },
    { name: 'Tuvalu', id: 'TV', value: '688' },
    { name: 'U.S. Virgin Islands', id: 'VI', value: '1' },
    { name: 'Uganda', id: 'UG', value: '256' },
    { name: 'Ukraine (Україна)', id: 'UA', value: '380' },
    { name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', id: 'AE', value: '971' },
    { name: 'United Kingdom', id: 'GB', value: '44' },
    { name: 'Uruguay', id: 'UY', value: '598' },
    { name: 'Uzbekistan (Oʻzbekiston)', id: 'UZ', value: '998' },
    { name: 'Vanuatu', id: 'VU', value: '678' },
    { name: 'Vatican City (Città del Vaticano)', id: 'VA', value: '39' },
    { name: 'Venezuela', id: 'VE', value: '58' },
    { name: 'Vietnam (Việt Nam)', id: 'VN', value: '84' },
    { name: 'Wallis and Futuna (Wallis-et-Futuna)', id: 'WF', value: '681' },
    { name: 'Western Sahara (‫الصحراء الغربية‬‎)', id: 'EH', value: '212' },
    { name: 'Yemen (‫اليمن‬‎)', id: 'YE', value: '967' },
    { name: 'Zambia', id: 'ZM', value: '260' },
    { name: 'Zimbabwe', id: 'ZW', value: '263' },
    { name: 'Åland Islands', id: 'AX', value: '358' }
];
